// @ts-nocheck
import {
    BaseQueryBuilderPayload,
  } from "@bikdotai/bik-component-library";
import { FilterActions } from "./models";
import { getFilters } from "../../../services/helpers/QueryBuilderHelper";
import { SpecialResponse } from "./utils";

export async function userPropertyFilterAPIHandler(
    payload: BaseQueryBuilderPayload<undefined | { propertyName: string; searchKey?: string }>,
    storeId: string,
    isSpecial: boolean
  ) {
    if (!payload.query) {
      const response = await getFilters({
        storeId,
        token: storeId,
        action: FilterActions.GET_USER_PROPERTY_NAMES,
      });
  
      if (response.data) {
        if(isSpecial) {
          return SpecialResponse
        }
        return response;
      }
    }

    if (!payload.query?.propertyName && payload.query?.searchKey) {
      const response = await getFilters({
        storeId,
        token: storeId,
        action: FilterActions.SEARCH_USER_PROPERTY_NAMES,
        searchText: payload.query?.searchKey,
      });
  
      if (response.data) {
        return response;
      }
    }
  
    if (payload.query?.propertyName && !payload.query?.searchKey) {
      const response = await getFilters({
        storeId,
        token: storeId,
        action: FilterActions.GET_USER_PROPERTY_VALUES,
        filterName: payload.query.propertyName,
      });
  
      if (response.data) {
        return response;
      }
    }

    if (payload.query?.propertyName && payload.query?.searchKey) {
      const response = await getFilters({
        storeId,
        token: storeId,
        action: FilterActions.SEARCH_USER_PROPERTY_VALUES,
        filterName: payload.query.propertyName,
        searchText: payload.query.searchKey,
      });
  
      if (response.data) {
        return response;
      }
    }
  
    return { status: 200 };
  }

  export const segmentFilterApiHandler = async (
    payload: BaseQueryBuilderPayload<undefined>,
    storeId: string
  ) => {
    if (!payload.query) {
      const response = await getFilters({
        storeId,
        token: storeId,
        action: FilterActions.GET_SEGMENT_NAMES,
      });
  
      if (response.data) {
        return response;
      }
    }
  
    return { status: 200 };
  };

  export const eventFilterApiHandler = async (
    payload: BaseQueryBuilderPayload<
      | undefined
      | { eventName: string; propertyName: string; searchKey?: string }
    >,
    storeId: string,
  ) => {
    if (!payload.query) {
      const response = await getFilters({
        storeId,
        token: storeId,
        action: FilterActions.GET_EVENT_NAMES,
      });
  
      if (response.data) {
        let newResponse = {...response};
        if(newResponse.data?.hasOwnProperty('Segment')) {
          delete newResponse.data["Segment"]
        }
        return response;
      }
    }

    if (
      payload.query?.searchKey &&
      !payload.query?.eventName &&
      !payload.query?.propertyName
    ) {
      const response = await getFilters({
        storeId,
        token: storeId,
        action: FilterActions.SEARCH_EVENT_NAMES,
        searchText: payload.query?.searchKey,
      });
  
      if (response.data) {
        return response;
      }
    }
  
    if (payload.query?.eventName && !payload.query?.propertyName && !payload.query?.searchKey) {
      const response = await getFilters({
        storeId,
        token: storeId,
        action: FilterActions.GET_EVENT_PROPERTY_NAMES,
        eventId: payload.query.eventName,
      });
  
      if (response.data) {
        return response;
      }
    }

    if (
      payload.query?.eventName &&
      payload.query?.searchKey &&
      !payload.query?.propertyName
    ) {
      const response = await getFilters({
        storeId,
        token: storeId,
        action: FilterActions.SEARCH_EVENT_PROPERTY_NAMES,
        eventId: payload.query.eventName,
        searchText: payload.query?.searchKey,
      });
  
      if (response.data) {
        return response;
      }
    }
  
    if (payload.query?.eventName && payload.query?.propertyName && !payload.query?.searchKey) {
      const response = await getFilters({
        storeId,
        token: storeId,
        action: FilterActions.GET_EVENT_PROPERTY_VALUES,
        eventId: payload.query.eventName,
        property: payload.query?.propertyName,
      });
  
      if (response.data) {
        return response;
      }
    }

    if (
      payload.query?.eventName &&
      payload.query?.searchKey &&
      payload.query?.propertyName
    ) {
      const response = await getFilters({
        storeId,
        token: storeId,
        action: FilterActions.SEARCH_EVENT_PROPERTY_VALUES,
        eventId: payload.query.eventName,
        property: payload.query?.propertyName,
        searchText: payload.query?.searchKey,
      });
  
      if (response.data) {
        return response;
      }
    }
  
    return { status: 200 };
  };
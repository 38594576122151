import { Icon } from "./icons-props";

const GoogleSheetUpdate: Icon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.08335 0.740723L13.6667 5.32406L11.375 5.74072L9.08335 5.32406L8.66669 3.03239L9.08335 0.740723Z"
        fill="#188038"
      />
      <path
        d="M9.08334 5.32406V0.740723H1.58334C0.892718 0.740723 0.333344 1.3001 0.333344 1.99072V17.8241C0.333344 18.5147 0.892718 19.0741 1.58334 19.0741H12.4167C13.1073 19.0741 13.6667 18.5147 13.6667 17.8241V5.32406H9.08334Z"
        fill="#34A853"
      />
      <path
        d="M2.83337 7.82397V13.8656H11.1667V7.82397H2.83337ZM6.47921 12.824H3.87504V11.3656H6.47921V12.824ZM6.47921 10.324H3.87504V8.86564H6.47921V10.324ZM10.125 12.824H7.52087V11.3656H10.125V12.824ZM10.125 10.324H7.52087V8.86564H10.125V10.324Z"
        fill="white"
      />
      <rect width="15.75" height="21" fill="url(#pattern0)" />
      <g clip-path="url(#clip0_131_1713)">
        <rect x="8" y="8" width="16" height="16" rx="8" fill="white" />
        <path
          d="M12.4897 15.1841C12.8163 13.5514 14.2857 12.3269 16 12.3269C17.2245 12.3269 18.2041 12.8984 18.8572 13.7963L20.245 12.1637C19.2654 11.0208 17.7143 10.2861 16 10.2861C13.1428 10.2861 10.7754 12.4086 10.3672 15.1841H8.65292L11.5101 18.4494L14.3673 15.1841H12.4897ZM20.4899 13.5514L17.6327 16.8167H19.5103C19.1021 18.4494 17.7143 19.6739 16 19.6739C14.7755 19.6739 13.7959 19.1025 13.1428 18.2045L11.755 19.7555C12.7346 20.98 14.2857 21.7147 16 21.7147C18.8572 21.7147 21.2246 19.5923 21.6327 16.8167H23.3471L20.4899 13.5514Z"
          fill="#34A853"
        />
      </g>
    </svg>
  );
};

export default GoogleSheetUpdate;

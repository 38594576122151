import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationDataState } from "./storeDataStateType";

const initialState: NotificationDataState = {
  errorMessage: "",
  successMessage: "",
  warningMessage: "",
};

const notificationDataStateSlice = createSlice({
  name: "Notification state",
  initialState: initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<NotificationDataState>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const notificationDataStateActions = notificationDataStateSlice.actions;
export default notificationDataStateSlice;

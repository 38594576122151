import { FunctionComponent, useState } from "react";
import DeleteIcon from "../../../icons/DeleteIcon";
import EditIcon from "../../../icons/editIcon";
import useGlobalVariableHook from "./GlobalVariableHook";
import Modal from "../../../ui-components/modal";
import { DeleteVariablesModal } from "../modals";
import { VariableStyle } from "./style";
import { VariableEditor } from "./VariableEditor";

interface TableProps {
  storeId: string;
}

export const VariableDataTypes: any[] = [
  { id: 1, text: "String" },
  { id: 2, text: "Number" },
  { id: 3, text: "Integer" },
  { id: 4, text: "Boolean" },
];

interface ModalControls {
  showModal: boolean,
  id: string
}

interface EditModes {
  [key: string]: boolean
}

export const VariablesTable: FunctionComponent<TableProps> = (props) => {
  const {globalVariableList, updateGlobalVariableList} = useGlobalVariableHook(props.storeId)
  const [editModes, setEditModes] = useState<EditModes>({});
  const [modalControls, setModalControls] = useState<ModalControls>({
    showModal: false,
    id: "",
  });

  const onModalCancel = () => {
    setModalControls({ showModal: false, id: "" });
  };

  const onModalDelete = () => {
    const listCopy = [...globalVariableList]
    const idx = listCopy.findIndex((item) => {
      return item.name === modalControls.id;
    });
    listCopy.splice(idx, 1);
    updateGlobalVariableList(listCopy);
    setModalControls({ showModal: false, id: "" });
  };

  return (
    <VariableStyle>
      {modalControls.showModal && (
        <Modal>
          <DeleteVariablesModal
            onClickCancel={onModalCancel}
            onClickDelete={onModalDelete}
          />
        </Modal>
      )}
      <>
        <div className={"page_title"}>Variable Management</div>
        <VariableEditor
          storeId={props.storeId}
          onUpdate={updateGlobalVariableList}
          globalVariableList={globalVariableList}
        />
        <div>
          <div className={"var_list_title"}>Variables</div>
          <table className="card-table">
            <colgroup>
              <col style={{ width: "28%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "34%" }} />
              <col style={{ width: "18%" }} />
            </colgroup>
            <tbody>
              {globalVariableList.map((item, idx) => {
                if (editModes[item.name]) {
                  return (
                    <tr>
                      <td colSpan={4}>
                        <VariableEditor
                          mode={"edit"}
                          variableData={item}
                          setEditModes={setEditModes}
                          storeId={props.storeId}
                          onUpdate={updateGlobalVariableList}
                          globalVariableList={globalVariableList}
                        />
                      </td>
                    </tr>
                  );
                }

                return (
                  <tr className="variable-card">
                    <td>
                      <div className="variable_name">{`{{${item.name}}}`}</div>
                    </td>

                    <td>
                      <div className="variable_type">{item.type}</div>
                    </td>

                    <td>
                      <div className="variable_value">
                        {item.defaultValue.toString()}
                      </div>
                    </td>

                    <td>
                      <div className="variable_actions">
                        <div
                          className="variable_actions__icon_wrapper"
                          onClick={() =>
                            setEditModes((prevState: any) => {
                              return { ...prevState, [item.name]: true };
                            })
                          }
                        >
                          <EditIcon />
                        </div>
                        <div
                          className="variable_actions__icon_wrapper"
                          onClick={() =>
                            setModalControls({ showModal: true, id: item.name })
                          }
                        >
                          <DeleteIcon />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    </VariableStyle>
  );
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {GlobalVariablesState} from "./storeDataStateType";

const initialState: GlobalVariablesState = {
    globalVariablesList: {}
}

const globalVariablesDataSlice = createSlice({
    name: 'GlobalVariableData',
    initialState: initialState,
    reducers: {
        setGlobalVariablesList: (state, action:PayloadAction<GlobalVariablesState> ) => {
            const { globalVariablesList} = action.payload;
            state.globalVariablesList = globalVariablesList;
            return state;
        }
    }
});

export const globalVariablesDataAction = globalVariablesDataSlice.actions;
export default globalVariablesDataSlice;

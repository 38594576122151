const BackTrack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10 6.66683C10 6.20659 9.62694 5.8335 9.16671 5.8335H3.83907L4.91649 4.75609C5.24192 4.43065 5.24192 3.90301 4.91649 3.57757C4.59105 3.25214 4.06341 3.25214 3.73797 3.57757L1.23797 6.07757C0.912537 6.40301 0.912537 6.93065 1.23797 7.25609L3.73797 9.75609C4.06341 10.0815 4.59105 10.0815 4.91649 9.75609C5.24192 9.43065 5.24192 8.90301 4.91649 8.57758L3.83907 7.50016H9.16671C9.62694 7.50016 10 7.12707 10 6.66683Z"
        fill="#B92321"
      />
      <path
        d="M10.8334 15.0002C10.8334 14.5399 11.2065 14.1668 11.6667 14.1668L12.5001 14.1668C14.341 14.1668 15.8334 12.6744 15.8334 10.8335C15.8334 9.58016 15.1417 8.48838 14.1192 7.91911C13.6888 7.67948 13.4077 7.17627 13.5806 6.71502C13.729 6.31937 14.1591 6.0963 14.5446 6.26926C16.2867 7.05082 17.5001 8.80046 17.5001 10.8335C17.5001 13.5949 15.2615 15.8335 12.5001 15.8335H11.6667C11.2065 15.8335 10.8334 15.4604 10.8334 15.0002Z"
        fill="#B92321"
      />
      <path
        d="M6.66671 15.0002C6.66671 14.5399 6.29361 14.1668 5.83337 14.1668L3.33337 14.1668C2.87314 14.1668 2.50004 14.5399 2.50004 15.0002C2.50004 15.4604 2.87314 15.8335 3.33337 15.8335L5.83337 15.8335C6.29361 15.8335 6.66671 15.4604 6.66671 15.0002Z"
        fill="#B92321"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.0429 0.886781C14.4738 1.04838 14.6922 1.52872 14.5306 1.95966L8.28058 18.6263C8.11898 19.0573 7.63864 19.2756 7.2077 19.114C6.77677 18.9524 6.55843 18.472 6.72003 18.0411L12.97 1.37445C13.1316 0.943519 13.612 0.725181 14.0429 0.886781Z"
        fill="#B92321"
      />
    </svg>
  );
};

export default BackTrack;

// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import {
  QueryBuilder,
  BaseQueryBuilderPayload,
  StyledModal,
  Tag,
  BikShimmer,
  DropdownPopover,
  TitleSmall,
  COLORS,
  StateComponent,
  StateInterface,
} from "@bikdotai/bik-component-library";
import {
  fetchCtwaAdIds,
  fetchCtwaAsTriggers,
} from "../../services/helpers/QueryBuilderHelper";
import { InstaDropdownContainer, InstaDropdownWrapper } from "./styled";
import { extractHeaderAndSubHeader, extractIgMedia } from "./utils";
import { QueryValidator } from "./queryValidator";
import { WarnIcon } from "../../icons/warnIcon";
import { MetaLogoIcon } from "../../icons/metaLogo";
import ChevronDownIcon from "../../icons/chevron_down";
import { SingleOption } from "@bikdotai/bik-component-library/dist/esm/components/dropdown/type";
import { dasboardUrl } from "../../config";
import { cloneDeep } from "lodash";

let builderState = {};
const queryValidator = new QueryValidator();

const CtwaBuilder: React.FC<{
  visible: boolean;
  onClose: Function;
  storeId: string;
  accountId: string;
  nodes: [];
  mode: string;
  onSave: Function;
}> = (props) => {
  const [mode, setMode] = useState<string>('create');
  const [errorState, setErrState] = useState(undefined);
  const [errorMsg, setErrorMsg] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [adId, setAdId] = useState<string>("");
  const [selectedAdAccount, setSelectedAdAccount] = useState<SingleOption>();
  const [adAccounts, setAdAccounts] = useState<SingleOption[]>([]);
  const [disableDD, setDisableDD] = useState<boolean>(false);
  const ctwaQbRef = useRef();

  useEffect(() => {
    populateAdIds();
    return () => {
      setMode('create');
      setErrorMsg(false);
      setSelectedAdAccount(undefined);
      setDisableDD(false);
    }
  }, []);

  useEffect(() => {
    if(props.mode) {
      setMode(props.mode)
    }
  },[props.mode])

  const eventsTriggerApiHandler = async (
    payload: BaseQueryBuilderPayload<
      | undefined
      | { triggerName: string }
      | { triggerName: string; propertyName: string }
    >,
    storeId: string
  ) => {
    if (!payload.query) {
      const response = await fetchCtwaAsTriggers({
        storeId: storeId,
      });

      if (response.data) {
        return response.data;
      }
    }
    if (payload.query?.triggerName && !payload.query?.propertyName) {
      const response = await fetchCtwaAsTriggers({
        storeId: storeId,
        ...payload,
      });
      if (response.data) {
        return response.data;
      }
    }
    if (payload.query?.triggerName && payload.query?.propertyName) {
      const response = await fetchCtwaAsTriggers({
        storeId: storeId,
        adId,
        adAccountId: selectedAdAccount?.value,
        ...payload,
      });
      if (response.data && response.data.data) {
        return response.data;
      }
    }
    return { status: 200 };
  };

  const parseBuilderData = () => {
    const { isError, state } = checkForErros();
    if (isError) return;
    const { id, subHeader } = extractHeaderAndSubHeader(state);
    const { tag } = extractIgMedia(state);
    props.onSave({
      ...state,
      header: state.nodes[0].metas[0].value,
      subHeader,
      id,
      type: "ctwa",
      tag,
      adAccountId: selectedAdAccount?.value
    });
  };

  const checkForErros = () => {
    const errorStateRes = queryValidator.validateCtwa({
      include: builderState,
    });
    setErrState({ ...errorStateRes[1].include });
    setErrorMsg(errorStateRes[0]);
    return { isError: errorStateRes[0], state: errorStateRes[1].include };
  };

  const formatAdAccounts = (data: any) => {
    return data.map((item: any, i: number) => {
      return {
        label: item.name,
        value: item.id.replace("act_", ""),
        selected: (i === 0 && !props.accountId) || (props.accountId === item.id.replace("act_", "")),
      };
    });
  };

  const populateAdIds = async () => {
    setIsLoading(true);
    const resp = await fetchCtwaAdIds({ storeId: props.storeId });
    if (resp.status === 200 && resp?.accounts) {
      const formatted: SingleOption[] = formatAdAccounts(resp.accounts);
      setAdAccounts(formatted);
      const match = formatted.find((item) => item.selected);
      if(match) {
        setSelectedAdAccount(match);
      } else if(props.accountId) {
        setSelectedAdAccount(undefined)
        setMode('view');
        setDisableDD(true);
        setErrorMsg('Trigger was created using a different ad account. Please connect the correct ad account to edit the trigger');
      } else {
        setSelectedAdAccount(formatted[0]);
      }
    }
    setIsLoading(false);
  };

  const AdsDropdown = () => {
    return (
      <InstaDropdownContainer>
        <DropdownPopover
          width="200px"
          disabled={isLoading || !adAccounts.length || disableDD}
          onSelect={(c: any) => {
            setSelectedAdAccount(c);
            const data = adAccounts;
            const modified: SingleOption[] = data
              .filter((d: any) => typeof d.value !== "undefined")
              .map((d: any) => {
                if (d.value === c.value) {
                  return {
                    label: d.label,
                    value: d.value,
                    selected: true,
                  };
                } else if (typeof d.value !== "undefined") {
                  return {
                    label: d.label,
                    value: d.value,
                  };
                }
              });
            if (c.value !== selectedAdAccount?.value) {
              const existing = cloneDeep(props.nodes);
              if(!existing.length) return;
              existing[0].properties = [];
              if (ctwaQbRef && ctwaQbRef.current) {
                ctwaQbRef.current.fillState({nodes : existing});
              }
            }
            setAdAccounts(modified);
          }}
          options={adAccounts}
        >
          <InstaDropdownWrapper>
            <MetaLogoIcon />
            <>
              {isLoading ? (
                <TitleSmall className="dropdown-text">Loading ...</TitleSmall>
              ) : (
                <>
                  {!adAccounts.length ? (
                    <TitleSmall className="dropdown-text">
                      No accounts connected
                    </TitleSmall>
                  ) : (
                    <TitleSmall className="dropdown-text">
                      {selectedAdAccount?.label || "Select an account"}
                    </TitleSmall>
                  )}
                </>
              )}
            </>
            <ChevronDownIcon color={COLORS.content.primary} />
          </InstaDropdownWrapper>
        </DropdownPopover>
      </InstaDropdownContainer>
    );
  };

  return (
    <>
      <StyledModal
        headingTitle="Create trigger based on CTWA events"
        centralContainerStyles={{ width: "1032px", height: "560px" }}
        open={props.visible}
        onClose={() => props.onClose()}
        zIndex={200}
        headerRightCustomElement={<AdsDropdown />}
        primaryButton={
          !isLoading && !!adAccounts.length && !disableDD
            ? {
                buttonType: "primary",
                buttonText: "Create",
                onClick: () => parseBuilderData(),
              }
            : undefined
        }
        footerLeftCustomElement={
          errorMsg && (
            <Tag tagText={errorMsg} type="negative" LeadingIcon={WarnIcon} />
          )
        }
      >
        <div style={{ padding: 24 }}>
          {!isLoading ? (
            <>
              {!adAccounts.length ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "100%",
                    alignItems: "center",
                  }}
                >
                  <StateComponent
                    states={StateInterface.ERROR}
                    heading="No ad accounts connected"
                    subline="Please connect atleast one ad account from CTWA."
                    isButtonEnabled={true}
                    buttonText="Go to CTWA"
                    onButtonClick={() => window.open(`${dasboardUrl}/journeys/settings/ctwa`,'_blank')}
                    buttonType="secondary"
                    width={undefined}
                  />
                </div>
              ) : (
                <QueryBuilder
                  ref={ctwaQbRef}
                  mode={mode}
                  state={
                    props.nodes && props.nodes.length
                      ? { nodes: props.nodes }
                      : undefined
                  }
                  selectorKey="eventTrigger"
                  addNodeBtnText="Add Trigger"
                  queryPreText="Whenever user sends via CTWA"
                  infinite={false}
                  onStateChange={(state: any) => {
                    builderState = state;
                    const properties = state.nodes[0].properties;
                    const ad = properties.find(
                      (item) => item.dataType === "specificAd"
                    );
                    const iceBreaker = properties.find(
                      (item) => item.name === "icebreakers"
                    );
                    if (
                      !iceBreaker &&
                      errorMsg === "Please select a specific ad first"
                    ) {
                      return setErrorMsg(false);
                    }
                    if (iceBreaker && !ad) {
                      return setErrorMsg("Please select a specific ad first");
                    }
                    if (ad && ad.value && !!ad.value.length) {
                      setAdId(ad.value[0].id);
                    } else {
                      setAdId("");
                    }
                  }}
                  errorState={errorState}
                  typesAPIHandlers={{
                    EVENTS_TRIGGER: (
                      payload: BaseQueryBuilderPayload<
                        | { triggerName: string }
                        | { triggerName: string; propertyName: string }
                        | undefined
                      >
                    ) => eventsTriggerApiHandler(payload, props.storeId),
                  }}
                />
              )}
            </>
          ) : (
            <div>
              <BikShimmer
                boxes={[
                  { height: "24px", width: "400px" },
                  { height: "24px", width: "120px" },
                ]}
              />
              <div style={{ marginLeft: 24 }}>
                <BikShimmer
                  boxes={[
                    { height: "24px", width: "120px" },
                    { height: "24px", width: "400px" },
                  ]}
                />
              </div>
              <div style={{ marginLeft: 48 }}>
                <BikShimmer
                  boxes={[
                    { height: "24px", width: "120px" },
                    { height: "24px", width: "200px" },
                  ]}
                />
              </div>
            </div>
          )}
        </div>
      </StyledModal>
    </>
  );
};

export default CtwaBuilder;

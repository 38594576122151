import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HomeState } from "./storeDataStateType";
import { SummaryAnalyticsViewTabName } from "../components/header/SummaryAnalytics";

const initialState: HomeState = {
  flowName: "",
  storeId: "",
  flowId: "",
  type: "",
  channel: "",
  userData: {
    uid: "",
    email: "",
    loginType: "",
    agentId: null,
  },
  compilationStatus: "",
  saveFlow: false,
  publishFlow: false,
  status: false,
  skipStatModal: false,
  lastUpdated: "",
  closeLoader: false,
  analyticsData: {
    startDate: new Date().getTime(),
    endDate: new Date().getTime(),
    nodeAnalytics: {},
    flowAnalytics: {},
    shimmer: false,
  },
  createdAt: "",
  showPwModal: false,
  retryAccess: false,
  integration: {
    errorType: "",
    nodeId: "",
    name: "",
  },
  journeyType: "utility",
};

const homeDataSlice = createSlice({
  name: "HomeData",
  initialState: initialState,
  reducers: {
    addState: (state, action: PayloadAction<HomeState>) => {
      state = { ...action.payload };
      return state;
    },
    updateState: (state, action: PayloadAction<Partial<HomeState>>) => {
      state = { ...state, ...action.payload };
      return state;
    },
    setSelectedView: (
      state,
      action: PayloadAction<SummaryAnalyticsViewTabName>
    ) => {
      state.analyticsData = {
        ...state.analyticsData,
        selectedView: action.payload,
      };
    },
  },
});

export const homeDataAction = homeDataSlice.actions;
export default homeDataSlice;

import { TitleRegular, BodySecondary, COLORS, BodyPrimary, ButtonRegular, Tooltip, IconButton, BodyCaption } from "@bikdotai/bik-component-library"
import { editor } from "monaco-editor"
import { json } from "react-router-dom"
import { generateUniqueId } from "../../app/action-block/Util"
import { isProd, baseUrlProd, baseUrlStaging, getChatbotEndpoints } from "../../config"
import CheckIcon from "../../icons/checkIcon"
import DuplicateIcon from "../../icons/duplicateIcon"
import InfoIcon from "../../icons/infoIcon"
import PasteIcon from "../../icons/pasteIcon"
import StepOne from "../../icons/stepOne"
import StepTwo from "../../icons/stepTwo"
import WrapTextIcon from "../../icons/wrapText"
import { Body } from "../ig-modals/style"
import { CopyUrlStyled, CopyButton, EditorLayout, EditorHeaderStyled, TooltipContainerStyled, EditorWrapper } from "./styles"
import { Editor } from "@monaco-editor/react"
import exp from "constants"

const Page1 = (props: any) => {

    const {
        setIsClickActive,
        setJson,
        json,
        setStepOneError,
        stepOneError,
        setTabId,
        setPayloadVariable,
        setSelectedPayloadVars,
        setCells,
        storeId,
        flowId,
        isClickActive
    } = props


    return <Body>
        <CopyUrlStyled>
            <div className="copy">
                <StepOne />
                <div>
                    <div>
                        <TitleRegular>Set-up Webhook URL on third-party app</TitleRegular>
                        <BodySecondary color={COLORS.content.secondary}>Copy-Paste this URL to the settings in the third-party application or code to send data to Bik when a particular data update happens.</BodySecondary>
                    </div>

                    <div className="copy__space_divison"></div>

                    <div className="copy__url_copy">
                        <BodyPrimary color={COLORS.content.secondary}>
                            {`${getChatbotEndpoints()}/webhook/${storeId}?flow=${flowId}`}
                        </BodyPrimary>

                        <CopyButton onClick={() => {
                            setIsClickActive(true)
                            setTimeout(() => {
                                setIsClickActive(false)
                            }, 3000)

                            navigator.clipboard.writeText(`${getChatbotEndpoints()}/webhook/${storeId}?flow=${flowId}`)
                        }}>
                            {
                                isClickActive ?
                                    <CheckIcon height={`${20}px`} width={`${20}px`} color={COLORS.content.brand} /> : <DuplicateIcon height={`${20}px`} width={`${20}px`} color={COLORS.content.brand} />
                            }
                            <ButtonRegular color={COLORS.content.brand}>{isClickActive ? `Copied` : `Copy`}</ButtonRegular>
                        </CopyButton>

                    </div>
                </div>
            </div>
        </CopyUrlStyled>

        <div style={{
            marginTop: 56,
        }}></div>

        <div style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 16
        }}>
            <StepTwo />
            <div>
                <div>
                    <TitleRegular>Get Sample Data</TitleRegular>
                    <BodySecondary color={COLORS.content.secondary}>Paste a sample of the data from the webhook from the third-party application</BodySecondary>
                </div>
                <div style={{
                    marginTop: 16,
                }}></div>

                <EditorLayout width={'932px'}>
                    <EditorHeaderStyled stepOneError={stepOneError} width={'932px'}>
                        <BodySecondary color={stepOneError ? COLORS.content.negative : COLORS.content.primary}>Paste Sample Payload</BodySecondary>
                        <TooltipContainerStyled>
                            <div className="tooltip-container">
                                <div className="tooltip-container__paste"
                                    style={{borderRight: '1px solid rgb(224, 224, 224)'}}
                                    onClick={() => {
                                        try {
                                            navigator.clipboard.readText().then(
                                            clipText => {
                                                setJson(clipText)
                                                return
                                            })
                                        }
                                        catch(e) {
                                            console.error(e)
                                        }
                                     
                                    }}
                                >
                                    <Tooltip
                                        body={'Paste'}
                                        title={''}
                                        width={300}
                                        placement={'bottom'}>
                                        <IconButton Icon={PasteIcon} />
                                    </Tooltip>
                                </div>
                                <div className="tooltip-container__wrap"
                                    onClick={() => {
                                        try {
                                            const prettyJson = JSON.stringify(JSON.parse(json), null, 2)
                                            setJson(
                                                prettyJson
                                            )
                                        } catch (e) {
                                            console.log(e)
                                        }
                                    }}
                                >
                                    <Tooltip
                                        body={'Wrap'}
                                        title={''}
                                        width={300}
                                        placement={'bottom'}>
                                        <IconButton Icon={WrapTextIcon} />
                                    </Tooltip>

                                </div>
                            </div>
                        </TooltipContainerStyled>
                    </EditorHeaderStyled>
                    <EditorWrapper stepOneError={stepOneError} width={'932px'}>
                        <Editor
                            value={json}
                            height={"248px"}
                            width={"900px"}
                            defaultLanguage="json"
                            options={{
                                minimap: { enabled: false },
                                renderLineHighlight: 'none',
                                wordWrap: "on",
                                wrappingStrategy: "advanced",
                                lineNumbersMinChars: 2,
                                contextmenu: false,
                                scrollBeyondLastLine: false,
                                hideCursorInOverviewRuler: true,
                                overviewRulerLanes: 0,
                                lineNumbers: 'off',
                                fontSize: 14,
                                scrollbar: {
                                    vertical: 'hidden',
                                    horizontal: 'hidden'
                                },
                            }}
                            onChange={(value: string | undefined, ev: editor.IModelContentChangedEvent) => {
                                setSelectedPayloadVars({})
                                setCells([
                                    {
                                        cellId: generateUniqueId(),
                                        payloadVar: '',
                                        existingVar: ''
                                    }]
                                )
                                setTabId(1)
                                setPayloadVariable('')

                                value ? setJson(value) : setJson('')
                                if (value) {
                                    try {
                                        JSON.parse(value!)
                                        setStepOneError(false)
                                    } catch (e) {

                                    }
                                } else {
                                    setStepOneError(false)
                                }
                            }}
                        />
                    </EditorWrapper>

                </EditorLayout>
                <div style={{
                    marginTop: 12,
                }}></div>
                {stepOneError && <div style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 6
                }}>
                    <InfoIcon width={16} height={16} color={COLORS.content.negative} />
                    <BodyCaption color={COLORS.content.negative}>Please enter a valid payload</BodyCaption>
                </div>}
            </div>
        </div>
    </Body>
}

export default Page1;
import { Icon } from "./icons-props";

const PlusIcon: Icon = (props) => {
  const { width, height, color } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      height={height || "20"}
      width={width || "20"}
      color={color || "#616161"}
      className="icon"
      style={{ display: "block" }}
    >
      <path
        fill="currentColor"
        d="M13 5a1 1 0 1 0-2 0v6H5a1 1 0 1 0 0 2h6v6a1 1 0 1 0 2 0v-6h6a1 1 0 1 0 0-2h-6V5Z"
      ></path>
    </svg>
  );
};
export default PlusIcon;

import { useMemo, useState } from "react";
import { useAppSelector } from "../../../state/store";
import { addPastProfiles } from "../helpers/AddPastProfileApi";
import { captureErrorToSentry } from "../../../utilities/sentryHelper";
import {
  calculateFromDate,
  calculateToDate,
  getAlreadyAddedDays,
} from "../helpers/AddPastProfileHelpers";

export const useInvokeAddPastProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [apiStatus, setApiStatus] = useState(false);

  const homeState = useAppSelector((state) => state.homeState);

  const callPastProfileApi = async (
    maxDelayDays: number,
    alreadyAddedDays: number,
    customerCount: number,
    flowEnabledDate: string
  ) => {
    if (customerCount <= 0) {
      setError(
        "Please try with a different event or different delay. No new profiles found to add!"
      );
      return;
    }

    setIsLoading(true);

    const payload = {
      store_id: homeState.storeId,
      flow_id: homeState.flowId,
      no_of_customers: customerCount,
      no_of_days: maxDelayDays + alreadyAddedDays,
      from_date: calculateFromDate(
        flowEnabledDate,
        maxDelayDays,
        alreadyAddedDays
      ),
      to_date: calculateToDate(flowEnabledDate, alreadyAddedDays),
    };

    try {
      const response = await addPastProfiles(payload);
      setIsLoading(false);

      if (response?.errors) {
        setError(response?.errors?.[0]?.message);
      } else if (response?.success) {
        setApiStatus(true);
      } else {
        setError("Something went wrong while adding past profiles");
      }
    } catch (e) {
      setIsLoading(false);
      captureErrorToSentry(e, "Error in useGetMaxDelay");
    }
  };

  return {
    isLoading,
    error,
    apiStatus,
    callPastProfileApi,
  };
};

import React, { ReactNode } from "react";
import { PopoverWrapper, PopoverContent } from "./popover.style";

export interface PopOverProps {
  children: ReactNode;
  buttonRect: DOMRect | null;
  visible: boolean;
  width: number;
  height: number;
}

const PopOver = React.forwardRef<HTMLDivElement, PopOverProps>(
  ({ visible, buttonRect, width, height, children }: PopOverProps, ref) => {
    return (
      <>
        <PopoverWrapper>
          <PopoverContent
            visible={visible}
            buttonRect={buttonRect}
            width={width}
            height={height}
            ref={ref}
          >
            {children}
          </PopoverContent>
        </PopoverWrapper>
      </>
    );
  }
);

export default PopOver;

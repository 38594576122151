import React, {FunctionComponent, useState} from 'react';
import {AdminTableStyles} from "../core/TableStyles";
import {IntegrationService} from "./IntegrationsService";

interface OwnProps {
    storeId: string,
    shopifyProductList: any[]
}

type Props = OwnProps;

const ShopifyProductsDashboard: FunctionComponent<Props> = (props) => {

    const repo = new IntegrationService();

    const [shopifyProductSyncMessage, setShopifyProductSyncMessage] = useState('')

    async function handleShopifyProductsSync() {
        const res = await repo.triggerShopifyProductsSync(props.storeId);
        setShopifyProductSyncMessage(res);
    }

    return (
        <div>
            <div>
                <AdminTableStyles style={{padding: 16}}>
                    <h2>Shopify Products</h2>
                    <p>Total {props.shopifyProductList.length} products absent</p>
                    {props.shopifyProductList.length > 0 &&
                    <>
                        <button onClick={() => handleShopifyProductsSync()}>Sync everything</button>
                        <p>{shopifyProductSyncMessage}</p>
                        <h4>Absent Product</h4>
                        <table>
                            <tr>
                                <th>ID</th>
                                <th>Content Id</th>
                                <th>Name</th>
                            </tr>
                            {props.shopifyProductList.map((product, key) => {
                                return (
                                    <tr>
                                        <td>{product.id}</td>
                                        <td>{product.combinations[0].id}</td>
                                        <td>{product.name}</td>
                                    </tr>
                                )
                            })}
                        </table>
                    </>
                    }
                </AdminTableStyles>
            </div>
        </div>
    );
};

export default ShopifyProductsDashboard;

import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { NodeSubtypeMappingState } from "./storeDataStateType";

const initialState = {
    data: {},
    type: '',
    count: 0,
}

const nodeSubtypeMappingSlice = createSlice ({
    name: 'nodeSubtypeMapping',
    initialState: initialState,
    reducers: {
        updateState: (state, action: PayloadAction<NodeSubtypeMappingState>) => {
            const {type, count} = action.payload;
            state.data = {
                ...state.data,
                [type]: count
            }
            return state;
        },
        insertState: (state, action: PayloadAction<NodeSubtypeMappingState>) => {
            state = {...action.payload};
            return state;
        }
    }
})

export const nodeSubtypeMappingActions = nodeSubtypeMappingSlice.actions;
export default nodeSubtypeMappingSlice;
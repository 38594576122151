import { Icon } from "./icons-props";

export const MinimizeIcon: Icon = (props) => {
  const { height, width, color } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.29289 20.2929L7.58579 15L4 15C3.44772 15 3 14.5523 3 14C3 13.4477 3.44772 13 4 13L9.99931 13L10.003 13C10.257 13.0008 10.5108 13.0977 10.705 13.2908L10.7092 13.295C10.804 13.3904 10.8757 13.5001 10.9241 13.6172C10.973 13.7351 11 13.8644 11 14L11 20C11 20.5523 10.5523 21 10 21C9.44772 21 9 20.5523 9 20L9 16.4142L3.70711 21.7071C3.31658 22.0976 2.68342 22.0976 2.29289 21.7071C1.90237 21.3166 1.90237 20.6834 2.29289 20.2929Z"
        fill={color || "#212121"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.7071 3.70711L16.4142 9H20C20.5523 9 21 9.44772 21 10C21 10.5523 20.5523 11 20 11H14.0007L13.997 11C13.743 10.9992 13.4892 10.9023 13.295 10.7092L13.2908 10.705C13.196 10.6096 13.1243 10.4999 13.0759 10.3828C13.027 10.2649 13 10.1356 13 10L13 4C13 3.44772 13.4477 3 14 3C14.5523 3 15 3.44772 15 4V7.58579L20.2929 2.29289C20.6834 1.90237 21.3166 1.90237 21.7071 2.29289C22.0976 2.68342 22.0976 3.31658 21.7071 3.70711Z"
        fill={color || "#212121"}
      />
    </svg>
  );
};

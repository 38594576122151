import styled from "styled-components";

const Component404Style = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
`;

const Component404 = () => {
    return (
        <Component404Style>
            <h1>404: Not found</h1>
        </Component404Style>
    )
}

export default Component404;
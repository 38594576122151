import { Icon } from "./icons-props";

const DuplicateIcon: Icon = (props) => {
    const { width, height, color, backgroundColor } = props;
    return (
      <svg width={width || "20"} height={height || "20"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_5182_855)">
          <path d="M2.74406 2.74424C2.90034 2.58796 3.1123 2.50016 3.33331 2.50016H10.8333C11.0543 2.50016 11.2663 2.58796 11.4226 2.74424C11.5788 2.90052 11.6666 3.11248 11.6666 3.3335V4.16683C11.6666 4.62707 12.0397 5.00016 12.5 5.00016C12.9602 5.00016 13.3333 4.62707 13.3333 4.16683V3.3335C13.3333 2.67045 13.0699 2.03457 12.6011 1.56573C12.1322 1.09689 11.4964 0.833496 10.8333 0.833496H3.33331C2.67027 0.833496 2.03439 1.09689 1.56555 1.56573C1.0967 2.03457 0.833313 2.67045 0.833313 3.3335V10.8335C0.833313 11.4965 1.09671 12.1324 1.56555 12.6013C2.03439 13.0701 2.67027 13.3335 3.33331 13.3335H4.16665C4.62688 13.3335 4.99998 12.9604 4.99998 12.5002C4.99998 12.0399 4.62688 11.6668 4.16665 11.6668H3.33331C3.1123 11.6668 2.90034 11.579 2.74406 11.4228C2.58778 11.2665 2.49998 11.0545 2.49998 10.8335V3.3335C2.49998 3.11248 2.58778 2.90052 2.74406 2.74424Z" fill={color || "#616161"}/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.16665 6.66683C7.78593 6.66683 6.66665 7.78612 6.66665 9.16683V16.6668C6.66665 18.0475 7.78593 19.1668 9.16665 19.1668H16.6666C18.0474 19.1668 19.1666 18.0475 19.1666 16.6668V9.16683C19.1666 7.78612 18.0474 6.66683 16.6666 6.66683H9.16665ZM8.33331 9.16683C8.33331 8.70659 8.70641 8.3335 9.16665 8.3335H16.6666C17.1269 8.3335 17.5 8.70659 17.5 9.16683V16.6668C17.5 17.1271 17.1269 17.5002 16.6666 17.5002H9.16665C8.70641 17.5002 8.33331 17.1271 8.33331 16.6668V9.16683Z" fill={color || "#616161"}/>
        </g>
        <defs>
          <clipPath id="clip0_5182_855">
            <rect width="20" height="20" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    );
}

export default DuplicateIcon;

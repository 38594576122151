import { useDispatch } from 'react-redux';
import DeleteIcon from '../../icons/DeleteIcon';
import { NodeActionActions } from '../../state/nodeActionState';
import { EdgeStyle } from './style';
import { useDetectAnalytics } from './useDetectAnalytics';
import {EdgeProps, getBezierPath} from "reactflow";

const CustomEdge = (props: EdgeProps) => {
    const {id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style, markerEnd} = props;
    const foreignObjectSize = 40;
    const dispatcher = useDispatch ();
    const isAnalytics = useDetectAnalytics();


    const [path, labelX, labelY] = getBezierPath ({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    const onEdgeClick = (evt: any) => {
        evt.stopPropagation();
        dispatcher (NodeActionActions.updateState ({
            action: "delete",
            type: "edge",
            edgeData: props,
            nodeId: ''
        }));
      };

      const handleEdgeHover = (type: string) => {
        const isEnter = type === 'enter';
        dispatcher (NodeActionActions.updateState ({
            action: "hover",
            type: "edge",
            edgeData: isEnter ? props : null,
            nodeId: null
        }));
      }
    
    return (
        <>
            <path
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={path}
                markerEnd={markerEnd} 
            />
            {!isAnalytics && <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize}
                x={labelX - foreignObjectSize / 2}
                y={labelY - foreignObjectSize / 2}
                className="edgebutton-foreignobject"
                requiredExtensions="http://www.w3.org/1999/xhtml"
            >
                <EdgeStyle onMouseEnter={() => handleEdgeHover('enter')} onMouseLeave={() => handleEdgeHover('leave')} onClick={(event) => onEdgeClick(event)}>
                    <DeleteIcon width={"22px"} height={"30px"} color={"#616161"} />
                </EdgeStyle>
            </foreignObject>}
        </>
    );
}

export default CustomEdge;
// @ts-nocheck
import {
  HTTPMethods,
  RequestExecutor,
} from "../../api-helpers/RequestExecutor";
import { getCampaignEndPoints, getDMEndpoints } from "../../config";

export const getEventsName = async (payload: any) => {
  const url = getCampaignEndPoints() + "/queryBuilderApiFunctions-getFilters";

  const response = await RequestExecutor.sendData<{
    data: any;
  }>({
    apiPath: url,
    requestBody: { ...payload, useNew: true },
  });
  if (response.status !== 200) return {};
  const newResponse: any = {
    status: 200,
    data: {
      status: 200,
      data:
        payload?.channel === "instagram"
          ? {
              Integrations: [
                {
                  id: "paymentLinkUpdate",
                  name: "Payment link update",
                  description:
                    "When there is an update in the payment link like paid, expired",
                },
              ],
              // Segment: [...response?.data["Segment"]],
              Inbox: [...response?.data["Inbox"]],
            }
          : payload?.channel === "whatsapp"
          ? {
              "E-commerce": [
                ...response?.data["E-commerce"],
                {
                  id: "whatsAppCart",
                  name: "WhatsApp Cart",
                  description: "When a cart request is received on WhatsApp",
                },
              ],
              Integrations: [...response?.data["Integrations"]],
              Acquisitions: [...response?.data["Acquisitions"]],
              // Segment: [...response?.data["Segment"]],
              Inbox: [...response?.data["Inbox"]],
            }
          : {
              "E-commerce": [...response?.data["E-commerce"]],
              Integrations: [...response?.data["Integrations"]],
              Acquisitions: [...response?.data["Acquisitions"]],
              //Segment: [...response?.data["Segment"]],
              Inbox: [...response?.data["Inbox"]],
            },
    },
  };
  if (payload.channel !== "instagram") {
    if (response?.data?.hasOwnProperty("Online Store")) {
      newResponse.data.data["Online Store"] = [
        ...response?.data["Online Store"],
      ];
    }
  }

  if(payload.channel === "whatsapp" && payload.category && newResponse.data.data[payload.category] ){
    const chosenOptions = newResponse.data.data[payload.category] 
    const copy = {...newResponse.data.data}
    delete copy[payload.category]
    newResponse.data.data = {
      [payload.category]: chosenOptions,
      ...newResponse.data.data
    }
  }
  return newResponse;
};

export const getFilters = async (payload: any) => {
  const url = getCampaignEndPoints() + "/queryBuilderApiFunctions-getFilters";

  const response = await RequestExecutor.sendData<{
    data: any;
  }>({
    apiPath: url,
    requestBody: { ...payload, useNew: true },
  });

  return response;
};

export const getEventsProperties = async (payload: any) => {
  const url =
    getCampaignEndPoints() +
    "/queryBuilderApiFunctions-getEventsPropertiesKeys";

  const response = await RequestExecutor.sendData<{
    data: any;
  }>({
    apiPath: url,
    requestBody: payload,
  });

  return response;
};

export const getEventPropertyValues = async (payload: any) => {
  const url =
    getCampaignEndPoints() +
    "/queryBuilderApiFunctions-getEventsPropertiesValue";

  const response = await RequestExecutor.sendData<{
    data: any;
  }>({
    apiPath: url,
    requestBody: payload,
  });

  return response;
};

export const fetchIgAsTriggers = async (payload: any) => {
  const url = getDMEndpoints() + "/queryBuilderFunctions-getIgTriggers";
  try {
    const apiDetailsRes: any = await RequestExecutor.fetch({
      apiPath: url,
      apiMethod: HTTPMethods.POST,
      requestBody: payload,
    });
    if (apiDetailsRes.status !== 200) {
      return {};
    }

    if (apiDetailsRes.data.status !== 200) {
      return {};
    }

    return apiDetailsRes;
  } catch (err) {
    return {};
  }
};

export const getIgAdsMedia = async (payload: {
  storeId: string;
  instagramId: string;
  adAccountId: string;
}) => {
  const url = getDMEndpoints() + "/instagramApiFunctions-getIgAdsData";
  try {
    const apiDetailsRes: any = await RequestExecutor.fetch({
      apiPath: url,
      apiMethod: HTTPMethods.POST,
      requestBody: payload,
    });
    if (apiDetailsRes.status !== 200) {
      return {};
    }

    if (apiDetailsRes.data.status !== 200) {
      return {};
    }

    return apiDetailsRes.data;
  } catch (err) {
    return {};
  }
};

export const fetchAdAccounts = async (payload: { storeId: string }) => {
  const url = getDMEndpoints() + "/instagramApiFunctions-getIgAdAccounts";

  try {
    const apiDetailsRes: any = await RequestExecutor.fetch({
      apiPath: url,
      apiMethod: HTTPMethods.POST,
      requestBody: payload,
    });
    if (apiDetailsRes.status !== 200) {
      return {};
    }

    if (apiDetailsRes.data.status !== 200) {
      return {};
    }

    return apiDetailsRes.data;
  } catch (err) {
    return {};
  }
};

export const fetchInstaIds = async (payload: any) => {
  const url =
    getDMEndpoints() + "/urlGeneratorApiFunctions-getMultiChannelInfo";
  try {
    const apiDetailsRes: any = await RequestExecutor.fetch({
      apiPath: url,
      apiMethod: HTTPMethods.POST,
      requestBody: payload,
    });
    if (apiDetailsRes.status !== 200) {
      return {};
    }

    if (apiDetailsRes.data.status !== 200) {
      return {};
    }

    return apiDetailsRes.data;
  } catch (err) {
    return {};
  }
};

export const fetchCtwaAdIds = async (payload: { storeId: string }) => {
  const url = getDMEndpoints() + "/fbeLoginApiFunctions-getAdAccounts";
  try {
    const apiDetailsRes: any = await RequestExecutor.fetch({
      apiPath: url,
      apiMethod: HTTPMethods.POST,
      requestBody: payload,
    });
    if (apiDetailsRes.status !== 200) {
      return {};
    }

    if (apiDetailsRes.data.status !== 200) {
      return {};
    }

    return apiDetailsRes.data;
  } catch (err) {
    return {};
  }
};

export const fetchCtwaAsTriggers = async (payload: any) => {
  const url = getDMEndpoints() + "/queryBuilderFunctions-getCtwaTriggers";

  try {
    const apiDetailsRes: any = await RequestExecutor.fetch({
      apiPath: url,
      apiMethod: HTTPMethods.POST,
      requestBody: payload,
    });
    if (apiDetailsRes.status !== 200) {
      return {};
    }

    if (apiDetailsRes.data.status !== 200) {
      return {};
    }

    return apiDetailsRes;
  } catch (err) {
    console.log("err", err);
    return {};
  }
};

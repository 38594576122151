export const MessageProviderNodeIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="8" fill="#E6F4E7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7518 9.16887C17.1314 8.95011 17.5619 8.83496 18 8.83496C18.4382 8.83496 18.8686 8.95012 19.2483 9.16889L19.25 9.1699L25.0833 12.5032C25.3916 12.6812 25.6575 12.9225 25.8641 13.2102C25.9144 13.2605 25.959 13.3181 25.9964 13.3826C26.0285 13.4382 26.0535 13.496 26.0717 13.5548C26.2431 13.8994 26.3329 14.2795 26.3333 14.6657V21.3341C26.3329 21.7725 26.2172 22.2031 25.9978 22.5827C25.7784 22.9622 25.463 23.2774 25.0833 23.4966L25.0801 23.4985L19.25 26.8299L19.2485 26.8308C18.9842 26.9832 18.6952 27.0853 18.3961 27.1333C18.2783 27.1971 18.1434 27.2333 18 27.2333C17.8567 27.2333 17.7218 27.1971 17.604 27.1333C17.3049 27.0853 17.0159 26.9832 16.7515 26.8308L16.75 26.8299L10.9199 23.4985L10.9167 23.4966C10.537 23.2774 10.2216 22.9622 10.0022 22.5827C9.78285 22.2031 9.66712 21.7725 9.66667 21.3341V14.6657C9.66707 14.2795 9.75696 13.8993 9.9284 13.5547C9.94656 13.4959 9.97156 13.4382 10.0037 13.3826C10.041 13.3182 10.0856 13.2606 10.1359 13.2103C10.3424 12.9226 10.6083 12.6812 10.9167 12.5032L10.9199 12.5014L16.7518 9.16887ZM18.8333 25.1485L24.25 22.0532L24.2513 22.0525C24.3773 21.9794 24.4819 21.8747 24.5548 21.7486C24.6279 21.6221 24.6665 21.4785 24.6667 21.3324V15.1145L18.8333 18.4889V25.1485ZM17.1667 18.4889V25.1485L11.75 22.0532L11.7487 22.0525C11.6227 21.9794 11.5181 21.8747 11.4452 21.7486C11.3721 21.6222 11.3336 21.4788 11.3333 21.3328V15.1145L17.1667 18.4889ZM18.4199 10.6151L23.8009 13.69L18 17.0455L12.1992 13.6899L17.5801 10.6151L17.5833 10.6133C17.71 10.5401 17.8537 10.5016 18 10.5016C18.1463 10.5016 18.29 10.5401 18.4167 10.6133L18.4199 10.6151Z"
        fill="#1A872C"
      />
    </svg>
  );
};

import { TitleSmall, BodyCaption, COLORS, Button, IconButton } from "@bikdotai/bik-component-library";
import styled from "styled-components";
import ArrowLeftIcon from "../../icons/arrowLeft";
import ArrowRightIcon from "../../icons/ArrowRightIcon";

export const TooltipContainer = styled.div`
  padding: 8px;
  background-color: ${COLORS.surface.standard};
  border-radius: 4px;
  width: 280px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

export const RightContainer = styled.div`
  display: flex;
`;

export const ShowTourButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 6px;
  border-radius: 4px;
  &:hover {
    background-color: ${COLORS.background.base};
  }
`;

const LeftArrowStyle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        background-color:${COLORS.background.base};
    }
    padding: 8px;
    border-radius: 4px;
`


const TourTooltip: React.FC<any> = ({
    continuous,
    index,
    step,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
    isLastStep,
    skipProps,
}) => (
    <TooltipContainer {...tooltipProps}>
        {step.title && (
            <TitleSmall style={{ marginBottom: 8 }}>{step.title}</TitleSmall>
        )}
        <BodyCaption style={{ color: COLORS.content.primary }}>
            {step.content}
        </BodyCaption>
        <ButtonContainer>
            <div>
                {' '}
                {continuous && (
                    <Button {...skipProps} buttonText="Skip" buttonType="tertiaryGray" />
                )}
            </div>
            <RightContainer>
                {index > 0 && (

                    <LeftArrowStyle {...backProps}>
                        <ArrowLeftIcon width={16} height={16} color={"#212121"} />
                    </LeftArrowStyle>

                )}
                {isLastStep && (
                    <Button {...closeProps} buttonText="Complete" buttonType="primary" />
                )}
                {!isLastStep && (
                    <div {...primaryProps} style={{
                        backgroundColor: COLORS.background.brandLight,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '1px solid #E0E0E0',
                        padding: '8px',
                        borderRadius: '4px'
                    }}>
                        <ArrowRightIcon width={16} height={16} color={COLORS.content.brand} />
                    </div>
                )}
            </RightContainer>
        </ButtonContainer>
    </TooltipContainer>
);

export default TourTooltip;

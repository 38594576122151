import {FunctionComponent, useEffect, useState} from "react";
import {SuperAdminAccess} from "./SuperAdminAccess";
import {TableStyle} from "./EntryPoint.style";
import {useAppSelector} from "../../state/store";
import {useDispatch} from "react-redux";
import BikayiButton from "../../ui-components/button";
import {publishRequestStateActions} from "../../state/publishState";

interface TableProps {

}

export const PublishTable: FunctionComponent<TableProps> = (props) => {
    const allPublishRequestList = useAppSelector<any>((state) => state.publishRequestState?.publishRequests || {});
    const [itemList, setItemList] = useState<any>({});
    const superAdminFns = new SuperAdminAccess();
    const [selectedFlows, setSelectedFlows] = useState<{[key: string]: boolean}>({});
    const dispatcher = useDispatch();
    const [tabElementName, setTabElementName] = useState<string>('OPEN');
    const [showCloseReqButton, setShowCloseReqButton] = useState(false);
    const storeId = useAppSelector(state => state.homeState?.storeId)

    useEffect(() => {
        filterFlowStatus();
    }, [allPublishRequestList, tabElementName])

    useEffect(() => {
        const selectedFlowsNumber = Object.keys(selectedFlows).filter(key => {
            return selectedFlows[key]
        }).length;
        if (selectedFlowsNumber) {
            setShowCloseReqButton(true);
        } else {
            setShowCloseReqButton(false);
        }
    }, [selectedFlows])

    const onClickArchive = async (flowId: string) => {
        const itemListUpdated = {...allPublishRequestList};
        itemListUpdated[flowId] = {
            ...itemListUpdated[flowId],
            'status': 'published'
        }
        await superAdminFns.archivePublishRequests(storeId, itemListUpdated);
        const updatedFlows = {...itemListUpdated};
        dispatcher(publishRequestStateActions.setPublishRequests({
            publishRequests: updatedFlows
        }));
    }

    const filterFlowStatus = () => {
        if (tabElementName === 'OPEN') {
            const selectedKeys = Object.keys(allPublishRequestList).filter(key => {
                return allPublishRequestList[key]?.status !== 'published'
            });
            let selectedMap: { [x: string]: any; } = {};
            selectedKeys.forEach(key => {
                selectedMap[key] = allPublishRequestList[key]
            })
            setItemList(selectedMap)
        } else {
            const selectedKeys = Object.keys(allPublishRequestList).filter(key => {
                return allPublishRequestList[key]?.status === 'published'
            });
            let selectedMap: { [x: string]: any; } = {};
            selectedKeys.forEach(key => {
                selectedMap[key] = allPublishRequestList[key]
            })
            setItemList(selectedMap)
        }
    }

    const closeRequestsBulk = async () => {
        const itemListUpdated = {...allPublishRequestList};
        Object.keys(selectedFlows).forEach(flowId => {
          if(selectedFlows[flowId]) {
            itemListUpdated[flowId] = {
                ...itemListUpdated[flowId],
                'status': 'published'
            }
          }
        })
        await superAdminFns.archivePublishRequests(storeId, itemListUpdated);
        dispatcher(publishRequestStateActions.setPublishRequests({
            publishRequests: itemListUpdated
        }));
        setSelectedFlows({});
        setShowCloseReqButton(false);
    }

    const selectFlows = (flowId: string) => {
        let selectedFlowsUpdated = {...selectedFlows};
        selectedFlowsUpdated[flowId] = !selectedFlows[flowId] || false;
        setSelectedFlows(selectedFlowsUpdated);
    }

    return (
        <>
            <div className="header__tabs">
                <div className={tabElementName === 'OPEN' ? "header__tab-elements-selected" : "header__tab-elements"} onClick={() => setTabElementName('OPEN')}>
                    Open
                </div>
                <div className={tabElementName === 'CLOSED' ? "header__tab-elements-selected" : "header__tab-elements"} onClick={() => setTabElementName('CLOSED')}>
                    Closed
                </div>
                {showCloseReqButton &&
                <div style={{margin: '12px 12px 12px auto'}}>
                    <BikayiButton
                        buttonSize="large"
                        buttonName="Close request"
                        buttonStyle='primary'
                        buttonWidth='fit-content'
                        buttonHeight={'32px'}
                        click={closeRequestsBulk}
                    />
                </div>
                }

            </div>
            <TableStyle>
                <div className='table-wrapper'>
                    <table>
                        <thead>
                        {tabElementName === 'OPEN' && <td></td>}
                        <td>
                            Flow name
                        </td>
                        <td>
                            Category
                        </td>
                        {tabElementName === 'OPEN' && <td></td>}
                        </thead>
                        {Object.keys(itemList)!.map((key, index) => {
                            const value = itemList[key];
                            return(
                                <tbody>
                                <tr
                                >
                                    {tabElementName === 'OPEN' &&
                                    <td><
                                        input
                                        key={key}
                                        type="checkbox"
                                        value={selectedFlows[key] as any}
                                        onClick={(event) => {
                                            selectFlows(key);
                                            event.stopPropagation();
                                        }}
                                    />
                                    </td>
                                    }
                                    <td>{value.flowName}</td>
                                    <td>{value.categoryName}</td>
                                    {tabElementName === 'OPEN' &&
                                    <td className="archive-button"
                                        onClick={(event) => {
                                            onClickArchive(key).then().catch()
                                        }}
                                    >
                                        Mark as closed
                                    </td>
                                    }
                                </tr>
                                </tbody>
                            )}
                        )}
                    </table>
                </div>
            </TableStyle>
        </>
    )
}

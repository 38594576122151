const DynamicIcon = (props: any) => {
  const { width, height } = props;
  return (
    <svg
      width={width || "40"}
      height={height || "40"}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="4" fill="#F0F0F0" />
      <g clip-path="url(#clip0_658_20235)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.833 13.334C10.833 11.9533 11.9523 10.834 13.333 10.834H16.6663C18.0471 10.834 19.1663 11.9533 19.1663 13.334V16.6673C19.1663 18.048 18.0471 19.1673 16.6663 19.1673H13.333C11.9523 19.1673 10.833 18.048 10.833 16.6673V13.334ZM13.333 12.5007C12.8728 12.5007 12.4997 12.8737 12.4997 13.334V16.6673C12.4997 17.1276 12.8728 17.5007 13.333 17.5007H16.6663C17.1266 17.5007 17.4997 17.1276 17.4997 16.6673V13.334C17.4997 12.8737 17.1266 12.5007 16.6663 12.5007H13.333Z"
          fill="#616161"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.833 23.334C10.833 21.9533 11.9523 20.834 13.333 20.834H16.6663C18.0471 20.834 19.1663 21.9533 19.1663 23.334V26.6673C19.1663 28.048 18.0471 29.1673 16.6663 29.1673H13.333C11.9523 29.1673 10.833 28.048 10.833 26.6673V23.334ZM13.333 22.5007C12.8728 22.5007 12.4997 22.8737 12.4997 23.334V26.6673C12.4997 27.1276 12.8728 27.5006 13.333 27.5006H16.6663C17.1266 27.5006 17.4997 27.1276 17.4997 26.6673V23.334C17.4997 22.8737 17.1266 22.5007 16.6663 22.5007H13.333Z"
          fill="#616161"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.333 20.834C21.9523 20.834 20.833 21.9533 20.833 23.334V26.6673C20.833 28.048 21.9523 29.1673 23.333 29.1673H26.6663C28.0471 29.1673 29.1663 28.048 29.1663 26.6673V23.334C29.1663 21.9533 28.0471 20.834 26.6663 20.834H23.333ZM22.4997 23.334C22.4997 22.8737 22.8728 22.5007 23.333 22.5007H26.6663C27.1266 22.5007 27.4997 22.8737 27.4997 23.334V26.6673C27.4997 27.1276 27.1266 27.5006 26.6663 27.5006H23.333C22.8728 27.5006 22.4997 27.1276 22.4997 26.6673V23.334Z"
          fill="#616161"
        />
        <path
          d="M24.9997 11.6673C25.4599 11.6673 25.833 12.0404 25.833 12.5007V14.1673H27.4997C27.9599 14.1673 28.333 14.5404 28.333 15.0007C28.333 15.4609 27.9599 15.834 27.4997 15.834H25.833V17.5007C25.833 17.9609 25.4599 18.334 24.9997 18.334C24.5394 18.334 24.1663 17.9609 24.1663 17.5007V15.834H22.4997C22.0394 15.834 21.6663 15.4609 21.6663 15.0007C21.6663 14.5404 22.0394 14.1673 22.4997 14.1673H24.1663V12.5007C24.1663 12.0404 24.5394 11.6673 24.9997 11.6673Z"
          fill="#616161"
        />
      </g>
      <defs>
        <clipPath id="clip0_658_20235">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(10 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DynamicIcon;

import {
    COLORS,
    StyledModal,
    TitleMedium,
    BodySecondary, TitleRegular,
    SearchBar
} from "@bikdotai/bik-component-library"
import React, { JSX, cloneElement, useEffect, useState } from "react"
import { Header, Container, SideBarChipStyle, ActionEventsBlockStyle } from "./style"
import TriggerNodeIcon from "../../icons/triggerNodeIcon";
import SendIcon from "../../icons/sendIcon";
import MessageIcon from "../../icons/messageIcon";
import StoryIcon from "../../icons/stories";
import SpecialDateIcon from '../../icons/calendar';
import AdsIcon from "../../icons/ads";
import AddEvent from "../../icons/addEvent";
import { cloneDeep } from "lodash";
import { Actions, ActionEventsList, WhatsAppActions, WhatsappActionEventsList } from './models'
import ErrScreen from "./errScreen";
import LogInIcon from "../../icons/logIn";
import LogOutIcon from "../../icons/logout";
import OrderIcon from "../../icons/order";

import ChatCheckedIcon from "../../icons/chatCheck";
import MoneyIcon from "../../icons/moneyIcon";
import CharmIcon from "../../icons/charmIcon";
import EmptyHeartIcon from "../../icons/emptyHeart";
import AtIcon from "../../icons/atIcon";
import styled from "styled-components";
import { channel } from "diagnostics_channel";
import CalendarIcon from "../../icons/calendar";
import Box2 from "../../icons/box2";
import JigSawIcon from "../../icons/jigsaw";
import WhatsappIcon from "../../icons/whatsappIcon";
import ShoppingCartIcon from "../../icons/shoppingCartIcon";
import UserPlusIcon from "../../icons/userPlus";
import DoubleMessageIcon from "../../icons/doubleMessageIcon";
import TruckIcon from "../../icons/truckIcon";
import BackInStockIcon from "../../icons/backInStockIcon";
import PromocodeIcon from "../../icons/promocodeIcon";
import ReceiptIcon from "../../icons/receiptIcon";
import WebhookIcon from "../../icons/webhookIcon";
import { TriggerTypes } from "../AutomationTrigger/AutomationTrigger";
import WhatsAppStroke from "../../icons/whatsappStroke";
import { Beta } from "../../ui-components/header-component/headerComponent";


type SideBarActionsModel = {
    id: string,
    icon: JSX.Element,
    displayName: string,
    isBeta?: boolean
}

type ActionEvents = {
    id: string,
    icon: JSX.Element,
    displayName: string,
    description: string,
    isHidden: boolean,
    isBeta?: boolean
}


const sideBarActionsWhatsApp: SideBarActionsModel[] = [
    {
        id: WhatsAppActions.ALL,
        icon: <TriggerNodeIcon color={COLORS.icon.placeholder} width={"20px"} height={"20px"} />,
        displayName: 'All'
    },
    {
        id: WhatsAppActions.WHATSAPPMESSAGE,
        icon: <SendIcon color={COLORS.icon.placeholder} width={"20px"} height={"20px"} />,
        displayName: 'WhatsApp Messages'
    },
    {
        id: WhatsAppActions.USEREVENT,
        icon: <AddEvent color={COLORS.icon.placeholder} width={"20px"} height={"20px"} />,
        displayName: 'User Event'
    },
    {
        id: WhatsAppActions.PRODUCTALERTS,
        icon: <Box2 color={COLORS.icon.placeholder} width={"20px"} height={"20px"} />,
        displayName: 'Product Alerts',
        isBeta: true
    },
    // {
    //     id: Actions.ADS,
    //     icon: <AdsIcon color={COLORS.icon.placeholder} width={"20px"} height={"20px"} />,
    //     displayName: 'Ads'
    // },
    {
        id: WhatsAppActions.ANNIVERSARYDATES,
        icon: <CalendarIcon color={COLORS.icon.placeholder} width={"20px"} height={"20px"} />,
        displayName: 'Anniversary dates'
    },
    {
        id: WhatsAppActions.INTEGRATIONS,
        icon: <JigSawIcon color={COLORS.icon.placeholder} width={"20px"} height={"20px"} />,
        displayName: 'Integrations'
    },
    // {
    //     id: Actions.SPECIALDATES,
    //     icon: <SpecialDateIcon color={COLORS.icon.placeholder} width={"20px"} height={"20px"} />,
    //     displayName: 'Special Dates'
    // },
]

const sideBarActions: SideBarActionsModel[] = [
    {
        id: Actions.ALL,
        icon: <TriggerNodeIcon color={COLORS.icon.placeholder} width={"20px"} height={"20px"} />,
        displayName: 'All'
    },
    {
        id: Actions.DIRECTMESSAGE,
        icon: <SendIcon color={COLORS.icon.placeholder} width={"20px"} height={"20px"} />,
        displayName: 'Direct message'
    },
    {
        id: Actions.COMMENTS,
        icon: <MessageIcon color={COLORS.icon.placeholder} width={"20px"} height={"20px"} />,
        displayName: 'Comments'
    },
    {
        id: Actions.STORIES,
        icon: <StoryIcon color={COLORS.icon.placeholder} width={"20px"} height={"20px"} />,
        displayName: 'Stories'
    },
    // {
    //     id: Actions.ADS,
    //     icon: <AdsIcon color={COLORS.icon.placeholder} width={"20px"} height={"20px"} />,
    //     displayName: 'Ads'
    // },
    {
        id: Actions.EVENTS,
        icon: <AddEvent color={COLORS.icon.placeholder} width={"20px"} height={"20px"} />,
        displayName: 'Events'
    },
    // {
    //     id: Actions.SPECIALDATES,
    //     icon: <SpecialDateIcon color={COLORS.icon.placeholder} width={"20px"} height={"20px"} />,
    //     displayName: 'Special Dates'
    // },
]

const whatsAppActionEvents: { [key: string]: { isHidden: boolean, displayName: string, children: ActionEvents[] } } = {
    [WhatsAppActions.WHATSAPPMESSAGE]: {
        isHidden: false,
        displayName: 'Message Received',
        children: [
            {
                id: WhatsappActionEventsList.WHATSAPPMEASSAGE,
                icon: <WhatsAppStroke color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
                displayName: 'Whatsapp Message',
                description: "Journey starts when someone sends you a WhatsApp directly.",
                isHidden: false
            },
            {
                id: WhatsappActionEventsList.CLICKONWHATSAPPADS,
                icon: <SendIcon color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
                displayName: 'Click-to-Whatsapp-Ads',
                description: "Journey starts when someone messages you through an Ad.",
                isHidden: false
            }
        ]
    },
    [WhatsAppActions.USEREVENT]: {
        isHidden: false,
        displayName: 'User Events',
        children: [
            {
                id: WhatsappActionEventsList.ECOMMERCE,
                icon: <ShoppingCartIcon color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
                displayName: 'E-Commerce',
                description: "Customer interacts with your store, such as viewing products, adding to cart, or placing an order.",
                isHidden: false
            },
            {
                id: WhatsappActionEventsList.ACQUISITIONS,
                icon: <UserPlusIcon color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
                displayName: 'Acquisitions',
                description: "When new leads or customers are acquired, such as through form submissions or clicks.",
                isHidden: false
            },
            {
                id: WhatsappActionEventsList.HELPDESK,
                icon: <DoubleMessageIcon color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
                displayName: 'Helpdesk',
                description: "When there are customer support activities, including ticket updates and chat sessions.",
                isHidden: false
            },
            {
                id: WhatsappActionEventsList.POSTPURCHASE,
                icon: <TruckIcon color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
                displayName: 'Post-Purchase',
                description: "When post-purchase activities occur, like delivery updates or order cancellations.",
                isHidden: false
            },
            {
                id: WhatsappActionEventsList.INTEGRATIONS,
                icon: <JigSawIcon color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
                displayName: 'Integrations',
                description: "When external services are interacted with, such as subscriptions and reviews.",
                isHidden: false
            }
        ]
    },

    [WhatsAppActions.PRODUCTALERTS]: {
        isHidden: false,
        displayName: 'Product Updates',
        children: [
            {
                id: WhatsappActionEventsList.BACKINSTOCK,
                icon: <BackInStockIcon color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
                displayName: 'Back in Stock',
                description: "Journey starts when a product is restocked.",
                isHidden: false,
                isBeta: true
            },
            {
                id: WhatsappActionEventsList.PRICEDROP,
                icon: <PromocodeIcon color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
                displayName: 'Price Drop',
                description: "Journey starts when the price of a product drops.",
                isHidden: false,
                isBeta: true
            }
        ]
    },

    [WhatsAppActions.ANNIVERSARYDATES]: {
        isHidden: false,
        displayName: 'Anniversary dates',
        children: [
            {
                id: WhatsappActionEventsList.FIRSTORDERDATE,
                icon: <CalendarIcon color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
                displayName: 'First order date',
                description: "Journey starts relative to your customer's first order date.",
                isHidden: false
            },
            {
                id: WhatsappActionEventsList.LASTORDERDATE,
                icon: <CalendarIcon color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
                displayName: 'Last order date',
                description: "Journey starts relative to your customer's last order date.",
                isHidden: false
            },
            {
                id: WhatsappActionEventsList.LIFETIMEORDERVALUE,
                icon: <ReceiptIcon color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
                displayName: 'Lifetime Order Value',
                description: "Journey starts when total order value of customer reaches a certain value.",
                isHidden: false
            }
        ]
    },

    [WhatsAppActions.INTEGRATIONS]: {
        isHidden: false,
        displayName: 'Webhooks',
        children: [
            {
                id: WhatsappActionEventsList.WEBHOOKS,
                icon: <WebhookIcon color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
                displayName: 'Webhooks',
                description: "Journey starts when an external service sends data to Bik's URL.",
                isHidden: false
            }
        ]
    }

}

const actionEvents: { [key: string]: { isHidden: boolean, displayName: string, children: ActionEvents[] } } = {
    [Actions.DIRECTMESSAGE]: {
        isHidden: false,
        displayName: 'Direct message',
        children: [
            {
                id: ActionEventsList.DIRECTMESSAGE,
                icon: <SendIcon color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
                displayName: 'Direct message',
                description: "Journey starts when someone sends you a direct message on Instagram.",
                isHidden: false
            },
            {
                id: ActionEventsList.DIRECTMESSAGEADS,
                icon: <SendIcon color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
                displayName: 'Direct message on Ads',
                description: "Journey starts when you receive direct messages on Instagram Ads.",
                isHidden: false
            },
            {
                id: ActionEventsList.DIRECTMESSAGEPOSTS,
                icon: <SendIcon color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
                displayName: 'Direct message on Posts',
                description: "Journey starts when you receive direct messages on Instagram Posts.",
                isHidden: false
            },
            // {
            //     id: ActionEventsList.DIRECTMESSAGEREELS,
            //     icon: <SendIcon color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
            //     displayName: 'Direct message on Reels',
            //     description: "Journey starts when you receive direct messages on Instagram Reels.",
            //     isHidden: false
            // }
        ]
    },
    [Actions.COMMENTS]: {
        isHidden: false,
        displayName: 'Comments',
        children: [
            {
                id: ActionEventsList.COMMENTSONPOST,
                icon: <MessageIcon color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
                displayName: 'Comments on post',
                description: "Journey starts when someone comments on your Instagram post.",
                isHidden: false
            },
            {
                id: ActionEventsList.COMMENTSONREEL,
                icon: <MessageIcon color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
                displayName: 'Comments on reel',
                description: "Journey starts when someone comments on your Instagram reel.",
                isHidden: false
            },
            {
                id: ActionEventsList.COMMENTSONAD,
                icon: <MessageIcon color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
                displayName: 'Comments on Ads',
                description: "Journey starts when someone comments on your Instagram Ads.",
                isHidden: false
            }
        ]
    },
    [Actions.STORIES]: {
        isHidden: false,
        displayName: 'Story',
        children: [
            {
                id: ActionEventsList.REPLIES,
                icon: <CharmIcon color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
                displayName: 'Replies on story',
                description: "Journey starts when someone replies on your story.",
                isHidden: false
            },
            // {
            //     id: ActionEventsList.REACTIONS,
            //     icon: <EmptyHeartIcon color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
            //     displayName: 'Reacts on story',
            //     description: "Journey starts when someone reacts on your story.",
            //     isHidden: false
            // },
            {
                id: ActionEventsList.MENTIONS,
                icon: <AtIcon color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
                displayName: 'Mentioned in story',
                description: "Journey starts when someone mentions you in their story.",
                isHidden: false
            },
        ]
    },
    [Actions.EVENTS]: {
        isHidden: false,
        displayName: 'Events',
        children: [
            // {
            //     id: ActionEventsList.EVENTS_ENTER_SEGMENT,
            //     icon: <LogInIcon color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
            //     displayName: 'Entered a segment',
            //     description: "Journey starts for someone when they enter a segment.",
            //     isHidden: false
            // },
            // {
            //     id: ActionEventsList.EVENTS_EXIT_SEGMENT,
            //     icon: <LogOutIcon color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
            //     displayName: "Exited a segment",
            //     description: "Journey starts for someone when they exit a segment.",
            //     isHidden: false
            // },
            {
                id: ActionEventsList.EVENTS_CHAT_CLOSE,
                icon: <ChatCheckedIcon color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
                displayName: 'Closed a chat session',
                description: "Journey starts for someone when their chat session gets closed.",
                isHidden: false
            },
            {
                id: ActionEventsList.EVENTS_PAYLINK_UPDATE,
                icon: <MoneyIcon color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
                displayName: 'Payment Link Update',
                description: "Journey starts when a payment is successful or link expires.",
                isHidden: false
            }
        ]
    },
    // [Actions.SPECIALDATES]: {
    //     isHidden: false,
    //     displayName: 'Special Dates',
    //     children: [
    //         {
    //             id: ActionEventsList.FIRST_ORDER_DATE,
    //             icon: <OrderIcon color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
    //             displayName: 'First Order Date',
    //             description: "The date when the first order was placed by the user",
    //             isHidden: false
    //         },
    //         {
    //             id: ActionEventsList.LAST_ORDER_DATE,
    //             icon: <OrderIcon color={COLORS.content.primaryInverse} width={"24px"} height={"24px"} />,
    //             displayName: 'Last Order Date',
    //             description: "The date when the most recent order was placed by the user",
    //             isHidden: false
    //         }
    //     ]
    // }
}

const IgTriggersSelection = (props: {
    getSelectedBlock: (id: string) => void
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    channel: string;
    igClass?: string
}) => {
    const [selectedItem, setSelectedItem] = useState<SideBarActionsModel>(
        props.channel === 'instagram' ? sideBarActions[0] : sideBarActionsWhatsApp[0]
    );
    const [showSearch, setShowSearch] = useState(true);
    const [filteredActions, setFilteredActions] = useState<{
        [key: string]: { isHidden: boolean, displayName: string, children: ActionEvents[] }
    }>(
        props.channel === 'instagram' ? cloneDeep(actionEvents) : cloneDeep(whatsAppActionEvents)
    );
    const [showErrScreen, setShowErrScreen] = useState<boolean>(false)
    const [searchText, setSearchText] = useState<string>('')
    const [resetSearch, setResetSearch] = useState<boolean>(false)

    const selectSideBar = (item: SideBarActionsModel) => {
        const copy = props.channel === 'instagram' ? cloneDeep(actionEvents) : cloneDeep(whatsAppActionEvents);
        Object.keys(filteredActions).forEach((key) => {
            if (item.id === Actions.ALL) {
                copy[key].isHidden = false
            } else {
                copy[key].isHidden = key !== item.id
            }
        })
        setFilteredActions(copy);
        setShowSearch(item.id === Actions.ALL)
        setSelectedItem(item);
    }

    const search = (searchQuery: string) => {
        const copy = props.channel === 'instagram' ? cloneDeep(actionEvents) : cloneDeep(whatsAppActionEvents);
        if (searchQuery.length) {
            let anyVisible = false
            Object.keys(filteredActions).forEach((key) => {
                copy[key].isHidden = true
                const childrenCopy: ActionEvents[] = [];
                copy[key].children.forEach((child) => {
                    const _child = { ...child };
                    const displayNameMatch = child.displayName.toLowerCase().includes(searchQuery.toLowerCase());
                    const descriptionMatch = child.description.toLowerCase().includes(searchQuery.toLowerCase());
                    _child.isHidden = !(displayNameMatch || descriptionMatch);
                    if (!_child.isHidden) {
                        anyVisible = true
                    }
                    childrenCopy.push(_child);
                    if (copy[key].isHidden) {
                        copy[key].isHidden = !(displayNameMatch || descriptionMatch)
                    }
                })
                copy[key].children = childrenCopy;
            })
            if (!anyVisible) {
                setShowErrScreen(true)
            } else {
                setShowErrScreen(false)
            }
            setFilteredActions(copy)
        } else {
            setFilteredActions(props.channel === 'instagram' ? cloneDeep(actionEvents) : cloneDeep(whatsAppActionEvents))
        }
    }

    return <StyledModal open={true} zIndex={200} centralContainerStyles={{ width: "1032px", height: "560px" }} onClose={() => props.setShowModal(false)}>
        <Container>
            <Header>
                <TitleMedium>{props.channel === 'instagram' ? 'Create Instagram Trigger' : 'Create Whatsapp Trigger'}</TitleMedium>
            </Header>
            <div style={{ display: "flex", height: "100%" }}>
                <div
                    style={{ minWidth: 240, background: COLORS.surface.subdued, padding: "16px 4px" }}
                >
                    {(props.channel === 'instagram' ? sideBarActions : sideBarActionsWhatsApp).map((chipItem) => {
                        return (
                            <Chip
                                key={chipItem.id}
                                item={chipItem}
                                onSelect={(item) => { selectSideBar(item); setShowErrScreen(false) }}
                                selected={selectedItem.id === chipItem.id}
                            />
                        )
                    })}
                </div>
                <Body>
                    {showSearch && (
                        <div>
                            <SearchBar
                                isEnabled={true}
                                onChange={(searchText) => { search(searchText); setSearchText(searchText); setResetSearch(false) }}
                                onEnter={(searchText) => { search(searchText); setSearchText(searchText); setResetSearch(false) }}
                                placeholder={'Search'}
                                variant={'small'}
                                width={'320px'}
                                onClickCross={() => {
                                    setFilteredActions(props.channel === 'instagram' ? cloneDeep(actionEvents) : cloneDeep(whatsAppActionEvents))
                                    setShowErrScreen(false)
                                    setSearchText('')
                                    setResetSearch(true)
                                }}
                                reset={resetSearch}
                            />
                        </div>
                    )}
                    {showErrScreen &&
                        <div style={{
                            minHeight: '90%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <ErrScreen onClickBtn={() => {
                                setFilteredActions(props.channel === 'instagram' ? cloneDeep(actionEvents) : cloneDeep(whatsAppActionEvents))
                                setShowErrScreen(false)
                                setSearchText('')
                                setResetSearch(true)
                            }
                            }
                                body={`Sorry we could not find any results for '${searchText}'`}
                                title={'No results found'}
                            />
                        </div>
                    }
                    {!showErrScreen && Object.keys(filteredActions).map((key: string) => {
                        let shouldDisable = false

                        if (props.igClass && props.channel === 'instagram') {

                            if (props.igClass === 'keywords' && (key === 'comments' || key === 'stories' || key === 'events')) {
                                shouldDisable = true
                            }

                            if (props.igClass === 'igTriggers' && (key === 'directMessage' || key === 'events')) {
                                shouldDisable = true
                            }

                            if (props.igClass === 'events' && (key === 'comments' || key === 'stories' || key === 'directMessage')) {
                                shouldDisable = true
                            }
                        }

                        return (
                            filteredActions[key].isHidden ? null :
                                <div style={{ marginTop: 24, marginBottom: 8 }}>
                                    <div style={{ marginBottom: 12 }}>
                                        <TitleMedium>{filteredActions[key].displayName}</TitleMedium>
                                    </div>
                                    <div style={{ display: "flex", flexWrap: "wrap", gap: 24 }}>
                                        {filteredActions[key].children.map((actionEvent) => {
                                            return (
                                                actionEvent.isHidden ? null :
                                                    <ActionEventsBlock
                                                        item={actionEvent}
                                                        onSelect={() => {
                                                            props.getSelectedBlock(actionEvent.id)
                                                        }}
                                                        selected={true}
                                                        disabled={
                                                            function () {
                                                                if (props.channel === 'instagram') {
                                                                    if (key === 'directMessage' && props.igClass === 'igTriggers' && [ActionEventsList.DIRECTMESSAGEADS, ActionEventsList.DIRECTMESSAGEPOSTS, ActionEventsList.DIRECTMESSAGEREELS].includes(actionEvent.id as ActionEventsList)) {
                                                                        return false
                                                                    }

                                                                    if (key === 'directMessage' && props.igClass === 'keywords' && actionEvent.id !== ActionEventsList.DIRECTMESSAGE) {
                                                                        return true
                                                                    }
                                                                } else {
                                                                    switch (props.igClass) {
                                                                        case TriggerTypes.keyword:
                                                                            return actionEvent.id === WhatsappActionEventsList.WHATSAPPMEASSAGE ? false : true
                                                                        case TriggerTypes.event:
                                                                            return [
                                                                                WhatsappActionEventsList.ECOMMERCE,
                                                                                WhatsappActionEventsList.ACQUISITIONS,
                                                                                WhatsappActionEventsList.HELPDESK,
                                                                                WhatsappActionEventsList.POSTPURCHASE,
                                                                                WhatsappActionEventsList.INTEGRATIONS,
                                                                            ].includes(actionEvent.id as WhatsappActionEventsList) ? false : true
                                                                        case TriggerTypes.ctwa:
                                                                            return actionEvent.id === WhatsappActionEventsList.CLICKONWHATSAPPADS ? false : true
                                                                        case TriggerTypes.webhook:
                                                                            return actionEvent.id === WhatsappActionEventsList.WEBHOOKS ? false : true
                                                                        case TriggerTypes.relay:
                                                                            return [WhatsappActionEventsList.FIRSTORDERDATE, WhatsappActionEventsList.LASTORDERDATE,
                                                                            WhatsappActionEventsList.LIFETIMEORDERVALUE].includes(actionEvent.id as WhatsappActionEventsList) ? false : true
                                                                        case TriggerTypes.businessEvents:
                                                                            return [WhatsappActionEventsList.BACKINSTOCK,
                                                                            WhatsappActionEventsList.PRICEDROP].includes(actionEvent.id as WhatsappActionEventsList) ? false : true
                                                                        default:
                                                                            return false
                                                                    }
                                                                }

                                                                return shouldDisable
                                                            }()
                                                        }
                                                    />
                                            )
                                        })}
                                    </div>
                                </div>
                        )
                    })}
                </Body>
            </div>

        </Container>
    </StyledModal>
}

const Chip = (props: { item: SideBarActionsModel, onSelect: (item: SideBarActionsModel) => void, selected: boolean }) => {
    return (
        <div onClick={() => props.onSelect(props.item)}>
            <SideBarChipStyle isActive={props.selected}>
                {React.cloneElement(props.item.icon, { color: props.selected ? COLORS.content.brand : COLORS.icon.placeholder })}
                <BodySecondary
                    color={props.selected ? COLORS.content.brand : COLORS.content.primary}
                    style={{ fontWeight: props.selected ? 600 : 400 }}
                >
                    {props.item.displayName}
                </BodySecondary>
                {
                    props.item.isBeta ? <Beta /> : <></>
                }
            </SideBarChipStyle>
        </div>
    )
}

const ActionEventsBlock = (props: { item: ActionEvents, onSelect: (item: SideBarActionsModel) => void, selected: boolean, disabled: boolean }) => {
    const { disabled } = props;
    return (
        <div onClick={() =>
            !disabled && props.onSelect(props.item)
        }>
            <ActionEventsBlockStyle isActive={props.selected} disabled={disabled}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 24
                }}>
                    <div className="icon-holder">
                        {props.item.icon}
                    </div>
                </div>
                <div style={{ marginTop: 16 }}>
                    <div style={{ marginBottom: 8, display: 'flex', flexDirection: 'row', gap: 8 }}>
                        <TitleRegular color={disabled ? COLORS.content.inactive : COLORS.content.secondary}>
                            {props.item.displayName}
                        </TitleRegular>
                        {props.item.isBeta ? <Beta /> : <></>}
                    </div>
                    <div>
                        <BodySecondary color={disabled ? COLORS.content.inactive : COLORS.content.secondary}>
                            {props.item.description}
                        </BodySecondary>
                    </div>
                </div>
            </ActionEventsBlockStyle>
        </div>
    )
}


const Body = styled.div`
    overflow-y: scroll;
    ::-webkit-scrollbar {
        width: 0 !important;
    }
    padding: 24px 0px 100px 36px;
    flex-grow: 1;
    height: 560px;
`

export default IgTriggersSelection
const ClassifyIcon = () => {
    return <div style={{
        background: "linear-gradient(93deg, #FFE6EE 0.37%, #FFF0E7 100%)"
    }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1 4C1 2.34315 2.34315 1 4 1H8C9.65685 1 11 2.34315 11 4V8C11 9.65685 9.65685 11 8 11H4C2.34315 11 1 9.65685 1 8V4ZM4 3C3.44772 3 3 3.44772 3 4V8C3 8.55228 3.44772 9 4 9H8C8.55228 9 9 8.55228 9 8V4C9 3.44772 8.55228 3 8 3H4Z" fill="url(#paint0_linear_90_1916)" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 14.3431 2.34315 13 4 13H8C9.65685 13 11 14.3431 11 16V20C11 21.6569 9.65685 23 8 23H4C2.34315 23 1 21.6569 1 20V16ZM4 15C3.44772 15 3 15.4477 3 16V20C3 20.5523 3.44772 21 4 21H8C8.55228 21 9 20.5523 9 20V16C9 15.4477 8.55228 15 8 15H4Z" fill="url(#paint1_linear_90_1916)" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 13C14.3431 13 13 14.3431 13 16V20C13 21.6569 14.3431 23 16 23H20C21.6569 23 23 21.6569 23 20V16C23 14.3431 21.6569 13 20 13H16ZM15 16C15 15.4477 15.4477 15 16 15H20C20.5523 15 21 15.4477 21 16V20C21 20.5523 20.5523 21 20 21H16C15.4477 21 15 20.5523 15 20V16Z" fill="url(#paint2_linear_90_1916)" />
        <path d="M18 2C18.5523 2 19 2.44772 19 3V5H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H19V9C19 9.55228 18.5523 10 18 10C17.4477 10 17 9.55228 17 9V7H15C14.4477 7 14 6.55228 14 6C14 5.44772 14.4477 5 15 5H17V3C17 2.44772 17.4477 2 18 2Z" fill="url(#paint3_linear_90_1916)" />
        <defs>
            <linearGradient id="paint0_linear_90_1916" x1="1" y1="2.83333" x2="23.8971" y2="3.90931" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF166A" />
                <stop offset="1" stop-color="#FB5F08" />
            </linearGradient>
            <linearGradient id="paint1_linear_90_1916" x1="1" y1="2.83333" x2="23.8971" y2="3.90931" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF166A" />
                <stop offset="1" stop-color="#FB5F08" />
            </linearGradient>
            <linearGradient id="paint2_linear_90_1916" x1="1" y1="2.83333" x2="23.8971" y2="3.90931" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF166A" />
                <stop offset="1" stop-color="#FB5F08" />
            </linearGradient>
            <linearGradient id="paint3_linear_90_1916" x1="1" y1="2.83333" x2="23.8971" y2="3.90931" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF166A" />
                <stop offset="1" stop-color="#FB5F08" />
            </linearGradient>
        </defs>
    </svg>
    </div>
}

export default ClassifyIcon;
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useDetectAnalytics = () => {
  const [isAnalytics, setIsAnalytics] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.endsWith("/analytics") || location.pathname.includes("/analytics/login/")) {
      setIsAnalytics(true);
    } else {
      setIsAnalytics(false);
    }
  }, [location]);

  return isAnalytics;
};

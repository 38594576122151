import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {CustomisationState} from "./storeDataStateType";

const initialState: CustomisationState = {
    customisations: {}
}

const customisationSlice = createSlice({
    name: 'Customisations',
    initialState: initialState,
    reducers: {
        setCustomisations: (state, action: PayloadAction<CustomisationState>) => {
            return {
                ...state,
                ...action.payload
            };
        },
    }
});

export const customisationStateActions = customisationSlice.actions;
export default customisationSlice;
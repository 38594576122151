import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import {
  AvailableFeaturesInteface,
  setAvailaleVersions,
  setIsVersionLoaded,
} from '../state/versionState';
import {useAppSelector} from "../state/store";
import {FirebaseService} from "../services/firebase/FirebaseService";


export const useVersionJet = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const homeState = useAppSelector((state) => state.homeState);
  const dispatch = useDispatch();

  const versionDoc = useSelector<
    {
      versionState: AvailableFeaturesInteface;
    },
    AvailableFeaturesInteface
  >(state => state.versionState);


  useEffect(() => {
    if (versionDoc?.isVersionLoaded) {
      return;
    }
    fetchAavailableVersions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAavailableVersions = async () => {
    const firebaseService = new FirebaseService(
      () => {},
      () => {}
    );
    const allDocs = await firebaseService.fetchAllFeatures();
    if (allDocs) {
      dispatch(setAvailaleVersions(allDocs));
    }
    dispatch(setIsVersionLoaded(true));
    setIsLoading(false);
  };

  const isVersionApplicabletoPlan = (feature: string) => {
    if (!versionDoc?.availableFeatures[feature]) return true;
    const storeIdList = versionDoc?.availableFeatures[feature]?.storeIds;
    if (storeIdList.includes('ALL')) return true;
    if (storeIdList.includes(homeState?.storeId)) {
      return true;
    }
    return false;
  };

  return {
    versionDoc,
    isLoading,
    fetchAavailableVersions,
    isVersionApplicabletoPlan,
  };
};
import { TitleRegular, COLORS, BodySecondary, Button, VariablePicker, PickerType } from "@bikdotai/bik-component-library"
import { generateUniqueId } from "../../app/action-block/Util"
import BulbIcon from "../../icons/bulbIcon"
import InfoIcon from "../../icons/infoIcon"
import PlusIcon from "../../icons/plusIcon"
import Cell from "./cell"
import { LeftPanelStyled, CellHeaderStyled, StyledDash, RightPanelStyled } from "./styles"
import { RecordEventHelper } from "../../utilities/RecordEventHelpers";
import {
  AmplitudeEventStep,
  AmplitudeKeys,
  TargetPlatform,
} from "../../utilities/AnalyticsTypes";

const Page3 = (props: any) => {
    const {
        variablesList,
        setSelectedCellId,
        setChooseType,
        setMappingPayloadVar,
        mappingPayloadVar,
        setFocusedCell,
        focusedCell,
        chooseType,
        selectedVarTree,
        pageThreeError,
        cells,
        setCells
    } = props;

    const eventHelper = new RecordEventHelper();

    return <div style={{
        overflow: 'hidden',
        padding: 24
    }}>
        <div style={{
            display: "flex",
            flexDirection: "row",
            minHeight: "400px",
            maxHeight: "400px",
            overflow: 'hidden',
        }}>
            <LeftPanelStyled>
                <TitleRegular color={COLORS.content.primary}>
                    Map Payload Variables to Existing Variables (Optional)
                </TitleRegular>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: 16,
                }}>
                    <CellHeaderStyled><BodySecondary color={COLORS.content.primary}>Payload Variable</BodySecondary></CellHeaderStyled>
                    <CellHeaderStyled><BodySecondary color={COLORS.content.primary}>Existing Variable</BodySecondary></CellHeaderStyled>
                </div>

                <div style={{
                    marginTop: 8,
                }}>
                    <table style={{
                        borderCollapse: 'separate',
                        borderSpacing: '0px 0px',
                    }}>
                        <tbody>
                            {
                                cells.map((item: any, index: number) => {
                                    return <Cell isFirstCell={index === 0} isLastCell={index === cells.length - 1}
                                        isError={pageThreeError[item.cellId] ? true : false}
                                        focusPlace={focusedCell.cellId === item.cellId ? focusedCell.place : ''}
                                        key={item.cellId}
                                        onLeftCellClick={() => {
                                            setSelectedCellId(item.cellId)
                                            setChooseType('payload')
                                            setFocusedCell({
                                                place: 'left',
                                                cellId: item.cellId
                                            })
                                        }}
                                        onRightCellClick={() => {
                                            setChooseType('existing')
                                            setSelectedCellId(item.cellId)
                                            setFocusedCell({
                                                place: 'right',
                                                cellId: item.cellId
                                            })
                                        }}
                                        cellId={item.cellId} onDelete={(cellId: string) => {
                                            setCells((prev: any) => {
                                                return prev.filter((item: any) => item.cellId !== cellId)
                                            })
                                        }}
                                        payloadVar={item.payloadVar}
                                        existingVar={item.existingVar}
                                        onDeletePayload={(sCellId) => {
                                            setCells((prev: any) => {
                                                return prev.map((item: any) => {
                                                    if (item.cellId === sCellId) {
                                                        return {
                                                            ...item,
                                                            payloadVar: ''
                                                        }
                                                    } else {
                                                        return item
                                                    }
                                                })
                                            })
                                        }}
                                        onDeleteExisting={(sCellId) => {
                                            setCells((prev: any) => {
                                                return prev.map((item: any) => {
                                                    if (item.cellId === sCellId) {
                                                        return {
                                                            ...item,
                                                            existingVar: ''
                                                        }
                                                    } else {
                                                        return item
                                                    }
                                                })
                                            })
                                        }}
                                    />
                                })
                            }
                        </tbody>
                    </table>
                </div>

                {(Object.values(pageThreeError).filter(i => i).length) ? <div style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 6,
                    alignItems: 'center',
                    marginTop: 8
                }}>
                    <InfoIcon width={16} height={16} color={COLORS.content.negative} />
                    <BodySecondary color={COLORS.content.negative}>{'Please map valid variables with each other'}</BodySecondary>
                </div> : <></>}

                <div style={{
                    marginTop: 24,
                    marginBottom: 24,
                }}
                >
                    <StyledDash isActive={false}>
                        <Button
                            onClick={() => {
                                setCells((prev: any) => {
                                    return [...prev, {
                                        cellId: generateUniqueId(),
                                        payloadVar: '',
                                        existingVar: ''
                                    }]
                                })
                            }}
                            LeadingIcon={PlusIcon}
                            buttonText="Add Field"
                            buttonType="dashBold"
                            size="medium"
                        />
                    </StyledDash>
                </div>

            </LeftPanelStyled>
            <div style={{
                border: `1px solid ${COLORS.content.inactive}`,
                marginTop: -24,
                width: "1px",
                minHeight: "424px",
                maxHeight: "424px",
                overflow: 'scroll'
            }}>
            </div>
            <RightPanelStyled>

                {
                    chooseType === 'info' ? <div className="bulb-box">
                        <div className="bulb-box__icon">
                            <BulbIcon />
                        </div>
                        <div className="bulb-box__content">
                            <ul style={{
                                marginTop: 8
                            }}>
                                <li style={{
                                    marginBottom: 8
                                }}>This will store the values of Payload Variables to Existing Variables. If existing variable is an id, it will populate all other variable in the same category.</li>
                                <li>Eg. Mapping order ID with a payload variable will populate all other order variables, such as order value, order date, order status etc.</li>
                            </ul>
                        </div>
                    </div> :
                        <VariablePicker autoOpenFirst key={chooseType} onSelect={(variable) => {
                            setMappingPayloadVar({...variable})
                            eventHelper.trackEvent(
                                TargetPlatform.Amplitude,
                                AmplitudeKeys.service_used,
                                {
                                  step: AmplitudeEventStep.variable_used,
                                  variableName: variable.displayName,
                                  screen: 'journey_builder',
                                }
                              );}} 
                            type={PickerType.EMBEDDED} 
                            data={chooseType === 'payload' ? selectedVarTree : variablesList} 
                        />
                    }
            </RightPanelStyled>

        </div>
    </div>
}

export default Page3;
import { Icon } from "./icons-props";

const AiAssistantIcon: Icon = (props) => {
  const { width, height } = props;
  return (
    <svg
      width={width || "32"}
      height={height || "32"}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="4" fill="url(#paint0_linear_626_7097)" />
      <path
        d="M13 6C12.4477 6 12 6.44772 12 7C12 7.55228 12.4477 8 13 8H15V10H10C7.23858 10 5 12.2386 5 15V21C5 23.7614 7.23858 26 10 26H20C20.5523 26 21 25.5523 21 25C21 24.4477 20.5523 24 20 24H10C8.34315 24 7 22.6569 7 21V15C7 13.3431 8.34315 12 10 12H22C23.6569 12 25 13.3431 25 15V18C25 18.5523 25.4477 19 26 19C26.5523 19 27 18.5523 27 18V15C27 12.2386 24.7614 10 22 10H17V8H19C19.5523 8 20 7.55228 20 7C20 6.44772 19.5523 6 19 6H13Z"
        fill="url(#paint1_linear_626_7097)"
      />
      <path
        d="M11 16.5C11 15.6716 11.6716 15 12.5 15C13.3284 15 14 15.6716 14 16.5C14 17.3284 13.3284 18 12.5 18C11.6716 18 11 17.3284 11 16.5Z"
        fill="url(#paint2_linear_626_7097)"
      />
      <path
        d="M18 16.5C18 15.6716 18.6716 15 19.5 15C20.3284 15 21 15.6716 21 16.5C21 17.3284 20.3284 18 19.5 18C18.6716 18 18 17.3284 18 16.5Z"
        fill="url(#paint3_linear_626_7097)"
      />
      <path
        d="M14 20C13.4477 20 13 20.4477 13 21C13 21.5523 13.4477 22 14 22H18C18.5523 22 19 21.5523 19 21C19 20.4477 18.5523 20 18 20H14Z"
        fill="url(#paint4_linear_626_7097)"
      />
      <path
        d="M24.3412 22.5773L23.6745 21.3272C23.6001 21.1876 23.4 21.1876 23.3255 21.3272L22.6587 22.5773C22.6403 22.6119 22.6119 22.6403 22.5773 22.6588L21.3272 23.3255C21.1876 23.3999 21.1876 23.6001 21.3272 23.6745L22.5773 24.3412C22.6119 24.3597 22.6403 24.3881 22.6587 24.4227L23.3255 25.6728C23.4 25.8124 23.6001 25.8124 23.6745 25.6728L24.3412 24.4227C24.3597 24.3881 24.388 24.3597 24.4227 24.3412L25.6727 23.6745C25.8124 23.6001 25.8124 23.3999 25.6727 23.3255L24.4227 22.6588C24.388 22.6403 24.3597 22.6119 24.3412 22.5773Z"
        fill="url(#paint5_linear_626_7097)"
      />
      <path
        d="M27.0047 20.9464L26.6047 20.1963C26.56 20.1126 26.44 20.1126 26.3953 20.1963L25.9952 20.9464C25.9842 20.9672 25.9672 20.9842 25.9464 20.9953L25.1963 21.3953C25.1126 21.44 25.1126 21.56 25.1963 21.6047L25.9464 22.0047C25.9672 22.0158 25.9842 22.0328 25.9952 22.0536L26.3953 22.8037C26.44 22.8874 26.56 22.8874 26.6047 22.8037L27.0047 22.0536C27.0158 22.0328 27.0328 22.0158 27.0536 22.0047L27.8036 21.6047C27.8874 21.56 27.8874 21.44 27.8036 21.3953L27.0536 20.9953C27.0328 20.9842 27.0158 20.9672 27.0047 20.9464Z"
        fill="url(#paint6_linear_626_7097)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_626_7097"
          x1="2.54975e-07"
          y1="2.66666"
          x2="33.3049"
          y2="4.23172"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFE6EE" />
          <stop offset="1" stop-color="#FFF0E7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_626_7097"
          x1="5"
          y1="7.66666"
          x2="27.8865"
          y2="8.84969"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF166A" />
          <stop offset="1" stop-color="#FB5F08" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_626_7097"
          x1="5"
          y1="7.66666"
          x2="27.8865"
          y2="8.84969"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF166A" />
          <stop offset="1" stop-color="#FB5F08" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_626_7097"
          x1="5"
          y1="7.66666"
          x2="27.8865"
          y2="8.84969"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF166A" />
          <stop offset="1" stop-color="#FB5F08" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_626_7097"
          x1="5"
          y1="7.66666"
          x2="27.8865"
          y2="8.84969"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF166A" />
          <stop offset="1" stop-color="#FB5F08" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_626_7097"
          x1="21"
          y1="21.4167"
          x2="26.2039"
          y2="21.6612"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF166A" />
          <stop offset="1" stop-color="#FB5F08" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_626_7097"
          x1="25"
          y1="20.25"
          x2="28.1223"
          y2="20.3967"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF166A" />
          <stop offset="1" stop-color="#FB5F08" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AiAssistantIcon;

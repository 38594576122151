import { FunctionComponent } from "react";
import {
  StateModalComponent,
  StateInterface,
} from "@bikdotai/bik-component-library";

interface Props {
  onClickClose: () => void;
  partnerName: string;
  errType: string;
}

const IntegrationsNotAvailableModal: FunctionComponent<Props> = (props) => {
  const { partnerName, onClickClose, errType } = props;
  return (
    <StateModalComponent
      onClose={onClickClose}
      isButtonEnabled={false}
      states={StateInterface.ERROR}
      width={400}
      heading={
        errType === "integrationsNotAvailable"
          ? `${partnerName} is not integrated`
          : `Sorry we could not fetch the integration`
      }
      subline={
        errType === "integrationsNotAvailable"
          ? `Please integrate your ${partnerName} account to use this block`
          : `Please try after some time`
      }
    />
  );
};

export default IntegrationsNotAvailableModal;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {LocalVariablesState} from "./storeDataStateType";

const initialState = {
    key:'',
    value:'',
    localVariablesList: {},
    localVariablesListType: {}
}

const localVariablesDataSlice = createSlice({
    name: 'localVariableData',
    initialState: initialState,
    reducers: {
        addLocalVariables: (state, action: PayloadAction<LocalVariablesState>) => {
            const { key, value, type } = action.payload;
            // @ts-ignore
            state.localVariablesList[key] = value;
            // @ts-ignore
            state.localVariablesListType[key] = type;
            return state;
        },
        setLocalVariablesList: (state, action:PayloadAction<LocalVariablesState> ) => {
            const { localVariablesList, localVariablesListType} = action.payload;
            state.localVariablesList = localVariablesList;
            if (localVariablesListType) {
                state.localVariablesListType = localVariablesListType
            }
            return state;
        }
    }
});

export const localVariablesDataAction = localVariablesDataSlice.actions;
export default localVariablesDataSlice;

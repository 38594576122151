import { FilterActions } from "../../app/action-block/filter/models";
import { getEventsName } from "../../services/helpers/QueryBuilderHelper";

export const findDescription = (triggerName: string, allEventNames: any) => {
  if (!allEventNames) return "";
  for (const eventCategory in allEventNames) {
    for (const event of allEventNames[eventCategory]) {
      if (event.id === triggerName) {
        return event.description;
      }
    }
  }
  return "";
};

export const getAllEventNames = async (storeId: string, channel: string) => {
    const response = await getEventsName({
      storeId,
      token: storeId,
      action: FilterActions.GET_EVENT_NAMES,
      channel,
    });
    if (response.data && response.data.data) {
      return response.data.data;
    } else {
      return false;
    }
  };

export const getIndex = (lastNode: { [key: string]: any }) => {
    if (lastNode && Object.keys(lastNode).length) {
      const id: string = lastNode.id;
      return Number(id.replace("N", ""));
    }
    return 1;
  };

export const canConnectEdge = (edge_data: any, reactFlowInstance: any) => {
    try {
      const { fromNodeId, sourceHandler, toNodeId } = edge_data;
      const latestEdges = reactFlowInstance?.getEdges() ?? [];
      const alreadyConnected = latestEdges.some(
        (edge: any) =>
          edge.source === fromNodeId && edge.sourceHandle === sourceHandler
      );
      const circularConnection = fromNodeId === toNodeId;
      const invalidConnection = edge_data.sourceHandler === "target";
      return !(alreadyConnected || circularConnection || invalidConnection);
    } catch (error: any) {
      return true;
    }
  };

export const enableWebhookVars = (res: any) => {
    const resKeys: string[] = Object.keys(res?.mappingToStaticVariable || {})
    const formattedKeys = resKeys.map(k => k.replace('{{', '').replace('}}', ''))

    const headerMappings: { [key: string]: string[] } = {
      'custom': ['Custom variables'],
      'session': ['Session variables'],
      'flowVariables': ['Flow variables'],
      'store': ['Store variables'],
      'checkout': ['Checkout variables'],
      'customer': ['Customer variables'],
      'catalog': ['Catalog variables'],
      'paymentLink': ['Payment variables'],
      'product': ['Product variables'],
      'order': ['Order variables'],
      'cart': ['Cart variables'],
    }

    const selectedHeaders = []
    const selectedValues = []
    const idList = ['store.id', 'customer.id', 'cart.id', "order.id", "product.id", "paymentLink.id", "catalog.id"]


    for (let key of formattedKeys) {
      if (key.includes('.')) {
        const head = key.split('.')[0]
        if (idList.includes(key)) {
          selectedHeaders.push(...headerMappings[head])
        } else {
          selectedValues.push(key)
        }
      }
    }

    return { selectedHeaders, selectedValues }
}
const EmojiIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <g clip-path="url(#clip0_706_25769)">
          <path
            d="M4.93272 9.46671C5.22635 9.24649 5.64251 9.30511 5.86396 9.59726L5.86706 9.60121C5.87102 9.60622 5.87857 9.61561 5.88959 9.62875C5.9117 9.65508 5.94751 9.69615 5.99634 9.7471C6.09454 9.84957 6.2421 9.98859 6.43317 10.1275C6.81788 10.4073 7.34974 10.6667 7.99939 10.6667C8.64903 10.6667 9.1809 10.4073 9.56561 10.1275C9.75668 9.98859 9.90424 9.84957 10.0024 9.7471C10.0513 9.69615 10.0871 9.65508 10.1092 9.62875C10.1202 9.61561 10.1278 9.60622 10.1317 9.60121L10.1348 9.59726C10.3563 9.30511 10.7724 9.24649 11.0661 9.46671C11.3606 9.68762 11.4203 10.1055 11.1994 10.4L11.1987 10.4009L11.1979 10.402L11.1961 10.4044L11.1913 10.4106L11.1771 10.4288C11.1656 10.4433 11.1501 10.4626 11.1305 10.4859C11.0914 10.5325 11.0361 10.5956 10.9651 10.6696C10.8237 10.8172 10.6171 11.0115 10.3498 11.2059C9.81788 11.5927 9.01641 12 7.99939 12C6.98237 12 6.1809 11.5927 5.64894 11.2059C5.38168 11.0115 5.17507 10.8172 5.03369 10.6696C4.96272 10.5956 4.90739 10.5325 4.8683 10.4859C4.84873 10.4626 4.83316 10.4433 4.82166 10.4288L4.80746 10.4106L4.80266 10.4044L4.80083 10.402L4.80006 10.4009L4.79939 10.4C4.57847 10.1055 4.63817 9.68762 4.93272 9.46671Z"
            fill="white"
          />
          <path
            d="M9.33268 6.66667C9.33268 5.93029 9.92964 5.33333 10.666 5.33333C11.4024 5.33333 11.9993 5.93029 11.9993 6.66667C11.9993 7.40305 11.4024 8 10.666 8C9.92964 8 9.33268 7.40305 9.33268 6.66667Z"
            fill="white"
          />
          <path
            d="M5.33268 5.33333C4.5963 5.33333 3.99935 5.93029 3.99935 6.66667C3.99935 7.40305 4.5963 8 5.33268 8C6.06906 8 6.66602 7.40305 6.66602 6.66667C6.66602 5.93029 6.06906 5.33333 5.33268 5.33333Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99935 0.666668C3.94926 0.666668 0.666016 3.94991 0.666016 8C0.666016 12.0501 3.94926 15.3333 7.99935 15.3333C12.0494 15.3333 15.3327 12.0501 15.3327 8C15.3327 3.94991 12.0494 0.666668 7.99935 0.666668ZM1.99935 8C1.99935 4.68629 4.68564 2 7.99935 2C11.3131 2 13.9993 4.68629 13.9993 8C13.9993 11.3137 11.3131 14 7.99935 14C4.68564 14 1.99935 11.3137 1.99935 8Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_706_25769">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>{" "}
    </>
  );
};

export default EmojiIcon;

import {FunctionComponent, useState} from "react";
import AdminSearchBar from "../core/AdminSearchBar";
import {IntegrationService} from "../integrations/IntegrationsService";

interface OwnProps {
}

type Props = OwnProps;

const ScopesDashboard: FunctionComponent<Props> = (props) => {
    const scopes: string[] = [
        'read_draft_orders',
        'write_draft_orders',
        'read_checkouts',
        'write_checkouts',
        'read_content',
        'write_content',
        'read_customers',
        'write_customers',
        'read_discounts',
        'write_discounts',
        'read_inventory',
        'write_inventory',
        'read_legal_policies',
        'read_marketing_events',
        'write_marketing_events',
        'read_orders',
        'write_orders',
        'read_payment_terms',
        'write_payment_terms',
        'read_price_rules',
        'write_price_rules',
        'read_products',
        'write_products',
        'read_product_listings',
        'read_shipping',
        'write_shipping',
        'write_script_tags',
        'draft_order'
    ]

    const [scopesFromInput, setScopesFromInput] = useState('');
    const [storeId, setStoreId] = useState('');
    const [response, setResponse] = useState('')

    const fetchScopes = () => {
        const integrationsService = new IntegrationService();
        integrationsService.fetchScopes(storeId).then((_response) => {
            setResponse(JSON.stringify(_response))
        }).catch();
    }

    const addScopes = () => {
        if (!scopesFromInput || scopesFromInput.includes(" ") || scopesFromInput.length === 0) {
            window.alert('invalid scopes, please remove spaces etc');
            return;
        }
        const toSend = scopesFromInput.split(",").map((scope) => scope.trim().replace(/"/g, ""));
        if (toSend.length === 0) {
            window.alert('invalid scopes, please remove spaces etc');
            return;
        }
        const integrationsService = new IntegrationService();
        integrationsService.findLinkForScopes(storeId, toSend).then((_response) => {
            setResponse(_response)
        }).catch();
    }

    return (
        <div>
            <AdminSearchBar
                mandatoryKeys={['storeId']}
                optionalKeys={['scopes']}
                onSubmit={(storeId, values) => {
                    setStoreId(storeId);
                    setScopesFromInput(values['scopes']);
                }}
            />

            {
                storeId && (<div style={{padding: 16}}>
                    <div>Showing results for {storeId} and scopes {scopesFromInput}</div>
                    <div style={{marginTop: 8}}>
                        <b>Usual Scopes :</b> <pre style={{width: '90%', whiteSpace: 'pre-wrap', wordWrap: 'break-word'}}>{JSON.stringify(scopes)}</pre>
                    </div>

                    <div>
                        <button onClick={() => {
                            fetchScopes()
                        }} style={{marginRight: 8}}>Fet1ch scopes
                        </button>
                        <button onClick={() => {
                            addScopes();
                        }}>Add scopes
                        </button>
                    </div>

                    <div style={{marginTop: 16}}>
                        Response : {response}
                    </div>
                </div>)
            }


        </div>
    )
};

export default ScopesDashboard;

import styled, { keyframes } from "styled-components";
import { backgroundBasedOnSubType, borderColorBasedOnSubType } from "../flow-builder/util";

const slideIn = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
`;

export const IconContainer = styled.div<{ subType: string; channel: string }>`
  background: ${(props) => backgroundBasedOnSubType(props.subType, props.channel)};
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  border-radius: 4px;
`;

export const BlockContainer = styled.div<{ subType: string; channel: string }>`
  position: relative;
  margin-top: 16px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 104px;
  height: 104px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.stroke.primary};
  cursor: pointer;
  &:hover {
    border: 1px solid ${(props) => borderColorBasedOnSubType(props.subType, props.channel)};
  }
`;

export const SideModalStyles = styled.div<{ isOpen: boolean }>`
  background: white;
  position: absolute;
  height: calc(100% - 56px);
  width: 400px;
  z-index: 15;
  top: 0;
  right: 0;
  padding: 0 0 16px 16px;
  overflow-y: auto;
  margin-top: 56px;
  animation: ${(props) => (props.isOpen ? slideIn : slideOut)} 0.5s forwards;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);

  .header {
    padding-bottom: 4px;
    ${(props) => props.theme.fonts.titleRegular}
  }

  .caption {
    ${(props) => props.theme.fonts.bodyCaption}
    color: ${(props) => props.theme.colors.content.secondary};
    padding-bottom: 16px;
  }

  .sticky-top {
    position: sticky;
    top: 0;
    background: white;
    padding-top: 16px;
  }

  .section {
    padding-bottom: 16px;

    &__header-container {
      display: flex;
      flex-direction: row;
    }
    &__header {
      ${(props) => props.theme.fonts.titleSmall}
    }

    &__bar {
      border-top: 1px solid ${(props) => props.theme.colors.stroke.primary};
      flex-grow: 1;
      margin-top: 10px;
      margin-left: 8px;
    }
  }

  .block {
    &__outer-container {
      margin-bottom: 2px;
      display: flex;
      flex-wrap: wrap;
    }

    &__caption {
      margin-top: 8px;
      color: ${(props) => props.theme.colors.content.secondary};
      ${(props) => props.theme.fonts.bodyCaption};
      white-space: pre-wrap;
      word-break: break-word;
      text-align: center;
    }
  }
`;

import Styled from "styled-components";
import { DropDownDataProps, DropDownStyleDataProps } from "./index";

export const DropdownMain = Styled.div.attrs(
  (props: DropDownDataProps) => {
    return { id: props.dropdownId || props.id || "dropdownMain", width: `${props.width || '100%'}` };
  }
)<DropDownDataProps>`
   .label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #212121;
   }
`;

export const DropDownDiv = Styled.div`
   position: relative;
   #dropdownPositionUp {
    bottom: 52px;
    top: unset;
   }
`;

export const InputWrapper = Styled.div.attrs(
  (props: DropDownStyleDataProps | DropDownDataProps) => ({
    className: [
      props.value?.length ? "padding" : null,
      //props.listShow ? "focus" : null,
      props.error ? "error" : null,
      props.subText
    ],
  })
)`
  width: 100%;
  display: flex;
  align-items: center;
  background: #FFF;
  padding: 12px 16px;
  //border: 1px solid #E0E0E0;
  border: ${(props) =>
    props.subText ? "1px solid #b92321 !important" : "1px solid #E0E0E0"};
  outline: ${(props) =>
    props.subText ? "1px solid #b92321 !important" : "1px solid white"};
  margin-top:  ${(props) => (props.marginTop ? props.marginTop : "8px")};//8px;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "4px"};
  box-sizing: border-box;
  margin-bottom: 0;
  //outline: 1px solid white;
  height: ${(props) => (props.height ? props.height : "100%")};
  max-height: ${(props) => {
    if (props.maxHeight) {
      return props.maxHeight;
    } else {
      return "58px";
    }
  }};;

  @media (max-width: 768px) {
    max-height: ${(props) =>
      props.maxHeightInMobileView ? props.maxHeightInMobileView : "58px"};
  }

  &.padding {
    
  }

  &.focus {
    border: 1px solid #731DCF;
    outline: 1px solid #731DCF !important;
  }

  &.error {
    border: 1px solid red;
    outline: 1px solid red !important;
  }
`;

export const Input = Styled.div`
   width: 90%;
   color: grey;
   max-height: 39px;
   white-space: nowrap;
   overflow: hidden;
   display: flex;
   flex-direction: column;
   ${(props) => props.theme.fonts.bodyPrimary}
`;

export const PlaceHolder = Styled.div.attrs((props: DropDownStyleDataProps) => {
  return {
    className: [
      props.value?.length ? "inputPlaceholder caption" : "body-small",
    ],
  };
})<DropDownStyleDataProps>`
  color: ${(props) => (props.error ? "red" : "grey")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "24px")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
`;

export const InputValue = Styled.div.attrs((props: DropDownStyleDataProps) => {
  return { className: [props.valueFont ? props.valueFont : null] };
})<DropDownStyleDataProps>`
  color: ${(props) => (props.valueColor ? props.valueColor : "#000")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "24px")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
`;

export const InputSVG = Styled.div`
   width: fit-content;
   display: flex;
   justify-content: flex-end;
   align-items: center;
`;

export const HeaderItem = Styled.li.attrs((props: DropDownStyleDataProps) => {})`

  li:hover {
    background-color: blue !important;
  }
  
  display: flex;
  align-items: center;
  padding-left: 16px;
  height: 32px;
  background: #F0F0F0;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #616161;
`

export const ListItem = Styled.li.attrs((props: DropDownStyleDataProps) => {
  return { className: [props.valueFont ? props.valueFont : null] };
})<DropDownStyleDataProps>`
  padding: ${(props) =>
    props.optionPadding ? props.optionPadding : "14px 16px"};
  cursor: pointer;
  color: ${(props) => (props.valueColor ? props.valueColor : "#000")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #E0E0E0;
  background-color: unset;

  p {
    margin-bottom: 0;
  }
  .item-header {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #212121;
  }
  .item-subHeader {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #616161;
  }
`;

export const List = Styled.ul`
   margin: 0;
   list-style: none;
   padding: 0;

   .disabled {
    color: grey;
  }

  li:hover {
    background-color: #FAFAFA !important;
  }
`;

export const ListWrapper = Styled.div.attrs((props: DropDownStyleDataProps) => {
  return {
    id: [props.dropdataPosition === "UP" ? "dropdownPositionUp" : null],
  };
})<DropDownStyleDataProps>`
  width: 100%;
  border: 1px solid #d6dbe2;
  box-sizing: border-box;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  overflow-y: auto;
  margin-top: 2px;
  position: absolute;
  background-color: #fff;
  top: 0;
  max-height: 196px;
  z-index: 90;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ErrorMain = Styled.div.attrs((props: DropDownStyleDataProps) => {
  return { className: [props.error ? "error" : "subText"] };
})<DropDownStyleDataProps>`
  &.error {
    color: orange;
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 12px;
  }

  &.subText {
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #B92321;
  }
`;

import { CustomNodeIconProps } from "../utilities/flowBuilder.utility";

const AtTheStartIcon = (props: CustomNodeIconProps) => {
  const { backgroundColor, color, height, width } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox={`0 0 ${width || "24"} ${height || "24"}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.66 13.1602C5.01935 13.1602 4.5 12.6408 4.5 12.0002C4.5 11.3595 5.01935 10.8402 5.66 10.8402C6.30065 10.8402 6.82 11.3595 6.82 12.0002C6.82 12.6408 6.30065 13.1602 5.66 13.1602ZM2.5 12.0002C2.5 13.7454 3.91478 15.1602 5.66 15.1602C7.05579 15.1602 8.24021 14.2552 8.65855 13L11.6602 13L11.6602 17.1667C11.6602 18.1792 12.481 19 13.4935 19L19.2467 19L17.9538 20.2929C17.5632 20.6834 17.5632 21.3166 17.9538 21.7071C18.3443 22.0976 18.9774 22.0976 19.368 21.7071L22.0144 19.0607C22.6002 18.4749 22.6002 17.5251 22.0144 16.9393L19.368 14.2929C18.9774 13.9024 18.3443 13.9024 17.9538 14.2929C17.5632 14.6834 17.5632 15.3166 17.9538 15.7071L19.2466 17L13.6602 17L13.6602 7L19.2467 7L17.9538 8.29289C17.5632 8.68342 17.5632 9.31658 17.9538 9.70711C18.3443 10.0976 18.9774 10.0976 19.368 9.70711L22.0144 7.06066C22.6002 6.47487 22.6002 5.52512 22.0144 4.93934L19.368 2.29289C18.9774 1.90237 18.3443 1.90237 17.9538 2.29289C17.5632 2.68342 17.5632 3.31658 17.9538 3.70711L19.2466 5L13.4935 5C12.481 5 11.6602 5.82081 11.6602 6.83333L11.6602 11L8.65845 11C8.24002 9.74496 7.05568 8.84015 5.66 8.84015C3.91478 8.84015 2.5 10.2549 2.5 12.0002ZM20.1602 18.0865L20.2466 18L20.1602 17.9135L20.1602 18.0865Z"
        fill={color || "#731DCF"}
      />
    </svg>
  );
};

export default AtTheStartIcon;

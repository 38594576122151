import styled from "styled-components";
import { COLORS } from "@bikdotai/bik-component-library";

export const TriggerNodeStyle = styled.div`
  .customNode {
    &__header {
      &__icon {
        background-color: #efe6f9;
      }
      &__menu {
        width: 10px;
      }
    }
    &__body {
      &__description-trigger {
        gap: 10px;
        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
        .keyword-list {
          height: 38px;
          align-items: center;
          overflow: hidden;
          white-space: nowrap;
          padding: 10px;
          text-overflow: ellipsis;
          background: #fafafa;
          border: 1px solid #e0e0e0;
          border-radius: 4px;
          line-height: 15px;
        }
      }
    }
    &__child-nodes {
      gap: 16px;
    }
  }
`;

export const AutomationTrigger = styled.div<{hasError?: boolean}>`
  width: 100%;
  border: 1px solid ${props => props.hasError ? `${COLORS.content.negative}` : `${COLORS.stroke.primary}`};
  background-color: ${COLORS.surface.subdued};
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  padding: 9px 12px;
  margin-top: 8px;
  .content-wrapper {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .single-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 175px;
    display: block;
  }
`;

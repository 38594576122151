import {
  BodyPrimary,
  BodyPrimaryLink,
  COLORS,
  StyledModal,
} from "@bikdotai/bik-component-library";

/**
 * Info Modal to show use can't add the past profile again
 */
export const PastProfileAlreadyAddedModal = (props: {
  alreadyAddedDays: number;
  open: boolean;
  formattedFlowEnabledDate: string;
  formattedLastProfileAdditionDate: string;
  onClose: () => void;
}) => (
  <StyledModal
    open={props.open}
    headingTitle="Past Profiles already added"
    wrapperStyle={{ margin: "8px 0px", padding: "8px 0px" }}
    centralContainerStyles={{ margin: "10px" }}
    headingSubtitle={""}
    width={"440px"}
    onClose={props.onClose}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        margin: "15px 25px",
      }}
    >
      <BodyPrimary color={COLORS.content.secondary}>
        Past profiles have already been added for {props.alreadyAddedDays} days
        before Flow went active on {props.formattedFlowEnabledDate}.
      </BodyPrimary>
      <BodyPrimary color={COLORS.content.secondary}>
        You can edit the flow and increase it's duration & try adding past
        profiles again, or exit.{" "}
      </BodyPrimary>
    </div>
  </StyledModal>
);

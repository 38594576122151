import * as React from "react";
import { memo } from "react";
import { Icon } from "./icons-props";

const SvgComponent: Icon = ({color}) => {
    const isMobile  = true;
    return (
        <>
            {isMobile ? (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M4.41107 6.91058C4.73651 6.58514 5.26414 6.58514 5.58958 6.91058L10.0003 11.3213L14.4111 6.91058C14.7365 6.58514 15.2641 6.58514 15.5896 6.91058C15.915 7.23602 15.915 7.76366 15.5896 8.08909L10.5896 13.0891C10.2641 13.4145 9.73651 13.4145 9.41107 13.0891L4.41107 8.08909C4.08563 7.76366 4.08563 7.23602 4.41107 6.91058Z" fill={color ? color: "#474B52"}/>
                </svg>
            ) : (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z" fill={color? color : "#474B52"}/>
                </svg>
            )}
        </>
    );
};

const ArrowDown = memo(SvgComponent);
export default ArrowDown;

import {DebugNodeAnalytics, DebugRuns, RunModel} from "./RunsModel";
import {HTTPMethods, RequestExecutor} from "../../../api-helpers/RequestExecutor";
import {getChatbotEndpoints} from "../../../config";

export class RunsService {
    convert(key: string, run: any): RunModel {
        const keyTokens = key.split('_');
        const flowId = keyTokens[0];
        const sessionId = keyTokens[1];
        return {
            sessionId,
            flowId,
            flowName: run.flow_name,
            customerId: run.customerId,
            time: run.epoch_time,

            nodes: run.nodes.map((node: any) => {
                return {
                    nodeId: node.node_id,
                    nodeSubType: node.node_subtype,
                    nodeIndex: node.node_index,
                }
            })
        }
    }

    async fetchDataFromApi(storeId: string, phoneNumber: string | undefined, days: number = 1) {
        let url = getChatbotEndpoints() + `/get-flow-execution-data/${storeId}/${days}?store_id=${storeId}`

        if (phoneNumber) {
            url = getChatbotEndpoints() + `/get-flow_execution-data-by-customer/${storeId}/${encodeURIComponent(phoneNumber)}/${days}?store_id=${storeId}`
        }

        return await RequestExecutor.fetch(
            {
                apiPath: url,
                apiMethod: HTTPMethods.GET
            }
        )
    }

    async fetchData(storeId: string, phoneNumber: string | undefined): Promise<DebugRuns> {
        const responseJson: any = await this.fetchDataFromApi(storeId, phoneNumber);
        const data = responseJson.data.data;
        const runs = Object.keys(data).map((key) => {
            return this.convert(key, data[key]);
        })
        return {
            runs: runs.reverse()
        }
    }

    getDebugAnalayticsForFlow(flowId: string, runs : DebugRuns): DebugNodeAnalytics {
        const associatedRuns = runs.runs.filter((run) => run.flowId === flowId);
        const ret: DebugNodeAnalytics = {
            totalRuns: associatedRuns.length,
            analytics: {}
        };
        associatedRuns.forEach((run) => {
            const nodeIds = run.nodes.map((node) => node.nodeId);
            const uniqueNodeIds = nodeIds.filter((nodeId, index) => {
                return nodeIds.indexOf(nodeId) === index;
            });

            uniqueNodeIds.forEach((nodeId) => {
                ret.analytics[nodeId] = ret.analytics[nodeId] || 0;
                ret.analytics[nodeId]++;
            });
        });
        return ret;
    }
}

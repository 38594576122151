import React, { ChangeEvent, FunctionComponent, MouseEventHandler } from "react";
import { Icon } from "../../icons/icons-props"
import ButtonStyle, { Loader } from "./Button.style"

export interface ButtonDataProps {
    buttonName?: string | React.ReactNode;
    buttonStyle?: "outline" | "primary" | "secondary";
    isLoading?: boolean;
    isDisabled?: boolean;
    buttonSize?: "small" | "large";
    click?: (e: React.ChangeEvent<HTMLElement>) => void;
    textColor?: string;
    backgroundColor?: string;
    buttonType?: "parent-relative" | "content-relative";
    buttonPadding?: string;
    buttonMargin?: string;
    loaderBackground?: string;
    IconLeft?: Icon;
    IconRight?: Icon;
    buttonWidth?: string;
    fontSize?: string;
    fontWeight?: string;
    buttonHeight?: string;
    className?: string;
    style?: {[key: string]: string | number};
    textWrap?: boolean;
}

type Props = ButtonDataProps;

const BikayiButton: FunctionComponent<Props> = (props) => {
    const { IconLeft, IconRight } = props;

    const onButtonClick = (e: React.ChangeEvent<HTMLElement>)=> {
        e.stopPropagation();
        props.click?.(e);
    }
    return (
        <ButtonStyle
            {...props}
            onClick={
                onButtonClick as unknown as MouseEventHandler<HTMLButtonElement> &
                    ((e: ChangeEvent<HTMLElement>) => void)
            }
        >
            {IconLeft && (
                <IconLeft
                    height={20}
                    width={20}
                    style={{ marginRight: 8 }}
                    color={props.textColor}
                />
            )}
            {IconRight && (
                <IconRight
                    height={18}
                    width={18}
                    style={{ marginLeft: 8 }}
                    color={props.textColor}
                />
            )}
            {!props.isLoading ? (props.buttonName) : (
                <div>
                    <Loader {...props} />
                </div>
            )}
        </ButtonStyle>
    );
};

export default BikayiButton;

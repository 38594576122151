import { CustomNodeIconProps } from "../utilities/flowBuilder.utility";

const TriggerNodeIcon = (props: CustomNodeIconProps) => {
    const {backgroundColor, color, height, width} = props;
    return (
        <svg width={width || "20"} height={height || "20"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1651 0.902214C11.5045 1.04952 11.7061 1.40291 11.6602 1.76999L10.944 7.49997H17.5C17.8233 7.49997 18.1175 7.68702 18.2546 7.97984C18.3918 8.27266 18.3472 8.61838 18.1402 8.86679L9.80682 18.8668C9.56999 19.151 9.17415 19.245 8.83481 19.0977C8.49546 18.9504 8.29385 18.597 8.33974 18.2299L9.05598 12.5H2.49997C2.17662 12.5 1.88246 12.3129 1.74531 12.0201C1.60816 11.7273 1.65278 11.3815 1.85979 11.1331L10.1931 1.13314C10.4299 0.84895 10.8258 0.754907 11.1651 0.902214ZM4.27917 10.8333H9.99997C10.239 10.8333 10.4665 10.9359 10.6247 11.1151C10.7829 11.2943 10.8565 11.5328 10.8269 11.77L10.3461 15.6163L15.7208 9.16663H9.99997C9.76094 9.16663 9.53343 9.06399 9.37524 8.8848C9.21705 8.70561 9.14342 8.46712 9.17307 8.22994L9.65385 4.38368L4.27917 10.8333Z" fill={color || "#731DCF"}/>
        </svg>
    );
}

export default TriggerNodeIcon;
import {
  BodyPrimaryLink,
  BodyTiny,
  COLORS,
  StateInterface,
  StateModalComponent,
  StyledModal,
} from "@bikdotai/bik-component-library";
import WhatsappIcon from "../../icons/whatsappIcon";
import { CountModalShimmer } from "./helpers/AddPastProfileHelpers";
import { CountCard } from "./sub-components/CountCard";
import { useGetCustomerCount } from "./customhooks/useGetCustomerCount";
import styled from "styled-components";
import { useAppSelector } from "../../state/store";
import Email from "../../icons/email";
import ArrowLeftIcon from "../../icons/arrowLeft";
import { useInvokeAddPastProfile } from "./customhooks/useInvokeAddpastProfile";
import { useDispatch } from "react-redux";
import { NodeActionActions } from "../../state/nodeActionState";
import { useEffect } from "react";

export type EventTriggerCountType = {
  channelId: string;
  header: string;
  subHeader: string;
  id: string;
  count: number;
};

/**
 * Displays the count of profiles to be added
 * Uses 'useGetCustomerCount' to get count and 'useInvokeAddPastProfile' to call add past profile API.
 *
 */
export const CountModal = (props: {
  open: boolean;
  maxDelayDays: number;
  alreadyAddedDays: number;
  flowEnabledDate: string;
  onClose: () => void;
  onEdit: () => void;
}) => {
  const {
    open,
    maxDelayDays,
    alreadyAddedDays,
    flowEnabledDate,
    onClose,
    onEdit,
  } = props;

  const { isLoading, error, customerCountForEventTrigger, totalCustomerCount } =
    useGetCustomerCount(maxDelayDays, alreadyAddedDays, flowEnabledDate, open);

  const {
    isLoading: addProfileLoading,
    error: addProfileError,
    apiStatus: addProfileSuccess,
    callPastProfileApi,
  } = useInvokeAddPastProfile();

  const dispatcher = useDispatch();

  const currentChannel = useAppSelector((state) => state.homeState.channel);

  // Changing the state, to initialize props for the modal.
  useEffect(() => {
    if (addProfileSuccess)
      dispatcher(
        NodeActionActions.updateState({
          nodeId: "",
          type: "",
          action: "add-past-profiles-success",
        })
      );
  }, [addProfileSuccess]);

  // Customer count Loading state
  if (isLoading) {
    return <CountModalShimmer />;
  }

  // Error state - Either Customer count or Add past Profile
  if (error || addProfileError) {
    return (
      <StateModalComponent
        states={StateInterface.ERROR}
        heading={"Past Profiles can't be added"}
        subline={error || addProfileError}
        isButtonEnabled={false}
        width={400}
        onClose={onClose}
      />
    );
  }

  // Add past profile API call loading
  if (addProfileLoading) {
    return (
      <StateModalComponent
        states={StateInterface.LOADING}
        heading={"Loading Past Profiles"}
        subline={"This might take a while..."}
        isButtonEnabled={false}
        width={400}
        onClose={onClose}
      />
    );
  }

  // Add past profile API success
  if (addProfileSuccess) {
    return (
      <StateModalComponent
        states={StateInterface.SUCCESS}
        heading={"Past Profiles added"}
        subline={`Journey is now live for another ${totalCustomerCount} past customers`}
        isButtonEnabled={false}
        width={400}
        onClose={onClose}
      />
    );
  }

  return (
    <StyledModal
      headingTitle={`${totalCustomerCount} new Profiles`}
      wrapperStyle={{
        textAlign: "center",
        margin: "15px",
        height: "520px",
        overflow: "scroll",
      }}
      //@ts-ignore
      headingSubtitle={
        alreadyAddedDays ? (
          <>
            will be added for {maxDelayDays} days before last profile addition.
          </>
        ) : (
          <>will be added for {maxDelayDays} days before flow became active.</>
        )
      }
      secondaryButton={{
        buttonText: "Edit",
        buttonType: "tertiaryGray",
        onClick: onEdit,
        LeadingIcon: () => (
          <div style={{ display: "flex" }}>
            <ArrowLeftIcon
              width={20}
              height={20}
              color={COLORS.content.secondary}
            />
          </div>
        ),
      }}
      primaryButton={{
        buttonText: "Add Past Profiles",
        onClick: () => {
          callPastProfileApi(
            maxDelayDays,
            alreadyAddedDays,
            totalCustomerCount,
            flowEnabledDate
          );
          return;
        },
      }}
      onClose={onClose}
      width={"494px"}
      open={open}
    >
      {Object.keys(customerCountForEventTrigger ?? [])?.map((channelId) => {
        if (
          !customerCountForEventTrigger?.[channelId]?.customerCountList?.length
        ) {
          return <></>;
        }

        const countObjects =
          customerCountForEventTrigger?.[channelId]?.customerCountList;

        return (
          <StyledCountContainer>
            <div style={{ display: "flex", gap: "5px" }}>
              {currentChannel === "whatsapp" ? (
                <WhatsappIcon width={12} height={12} />
              ) : currentChannel === "email" ? (
                <Email width={12} height={12} />
              ) : (
                <></>
              )}
              <BodyTiny>
                {customerCountForEventTrigger?.[channelId]?.displayName}
              </BodyTiny>
            </div>

            {countObjects?.map((countObj) => {
              return (
                <CountCard
                  header={countObj.header}
                  subHeader={countObj.subHeader}
                  count={countObj.count}
                  channel={currentChannel}
                />
              );
            })}
          </StyledCountContainer>
        );
      })}
    </StyledModal>
  );
};

export const StyledCountContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin: 15px;
  margin-bottom: 10px;
`;

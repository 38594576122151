import { CustomNodeIconProps } from "../utilities/flowBuilder.utility";

const ImageIcon = (props: CustomNodeIconProps) => {
    const {backgroundColor, color, height, width} = props;
    return (
        <svg width={width || "28"} height={height || "28"} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.33342 5.99996C7.49247 5.99996 6.00008 7.49234 6.00008 9.33329C6.00008 11.1742 7.49247 12.6666 9.33342 12.6666C11.1744 12.6666 12.6667 11.1742 12.6667 9.33329C12.6667 7.49234 11.1744 5.99996 9.33342 5.99996ZM8.66675 9.33329C8.66675 8.9651 8.96523 8.66663 9.33342 8.66663C9.7016 8.66663 10.0001 8.9651 10.0001 9.33329C10.0001 9.70148 9.7016 9.99996 9.33342 9.99996C8.96523 9.99996 8.66675 9.70148 8.66675 9.33329Z" fill="#9E9E9E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M27.3334 23.3333C27.3334 25.5424 25.5426 27.3333 23.3334 27.3333H4.66515C2.45675 27.3324 0.666748 25.5419 0.666748 23.3333V4.66663C0.666748 2.45749 2.45761 0.666626 4.66675 0.666626H23.3334C25.5426 0.666626 27.3334 2.45749 27.3334 4.66663V23.3333ZM4.66675 3.33329C3.93037 3.33329 3.33341 3.93025 3.33341 4.66663V23.3333C3.33341 23.905 3.69328 24.3927 4.19887 24.5822L18.3906 10.3905C18.9113 9.86979 19.7555 9.86979 20.2762 10.3905L24.6667 14.781V4.66663C24.6667 3.93025 24.0698 3.33329 23.3334 3.33329H4.66675ZM24.6667 23.3333V18.5522L19.3334 13.2189L7.8857 24.6666H23.3334C24.0698 24.6666 24.6667 24.0697 24.6667 23.3333Z" fill="#9E9E9E"/>
        </svg>
    );
}

export default ImageIcon;
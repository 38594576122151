import {
  COLORS,
  InputStateI,
  StateInterface,
  StateModalComponent,
} from "@bikdotai/bik-component-library";
import { useEffect, useState } from "react";
import { useGetMaxDelay } from "./customhooks/useGetMaxDelay";
import { CountModal } from "./CountModal";
import { formatDateForAddPastProfileModal } from "./helpers/AddPastProfileHelpers";
import { ZeroDelayModal } from "./ZeroDelayModal";
import { NonZeroDelayModal } from "./NonZeroDelayModal";
import CrossTagIcon from "../../icons/crossTagIcon";
import { PastProfileAlreadyAddedModal } from "./PastProfileAlreadyAddedModal";

export type MaxDelayType = "at_the_start" | "throughout_the_flow";

export type AddPastProfileModalType = {
  open: boolean;
  onClose: () => void;
  flowEnabledDate: string;
  alreadyAddedDays: number;
  lastProfileAdditionDate: string;
};

/**
 * Index file for all the modals. When user clicks Add past profile from menu,
 * this file navigates the user to the modal.
 *
 * 1. Loading due to api call - Loading modal
 * 2. Error in api call - Error modal
 * 3. Add past profile button clicked - Count Modal
 * 4. User comes for second time but max delay is 0 - Already Added past profile modal
 * 5. User comes for first time but max delay is 0 - Zero delay modal
 * 6. User comes for first time but max delay > 0 - Non zero delay modal
 */
export const AddPastProfileModal = (props: AddPastProfileModalType) => {
  const {
    open,
    flowEnabledDate,
    onClose,
    alreadyAddedDays,
    lastProfileAdditionDate,
  } = props;

  const [selectedCard, setSelectedCard] =
    useState<MaxDelayType>("at_the_start");

  const { maxDelay, isLoading, error, setError } = useGetMaxDelay(
    selectedCard,
    open,
    alreadyAddedDays
  );

  const maxDelayDays = maxDelay[selectedCard];
  const [inputValue, setInputValue] = useState(maxDelayDays ?? 0);
  const [inputState, setInputState] = useState<InputStateI>("active");
  const [showCountModal, setShowCountModal] = useState(false);

  const MAX_ALLOWED_DELAY_DAYS_VALUE = 30;

  const formattedFlowEnabledDate =
    formatDateForAddPastProfileModal(flowEnabledDate);

  const formattedLastProfileAdditionDate = formatDateForAddPastProfileModal(
    lastProfileAdditionDate
  );

  const validateInputAndShowCountModal = () => {
    if (
      selectedCard === "throughout_the_flow" &&
      inputValue > MAX_ALLOWED_DELAY_DAYS_VALUE
    ) {
      setError(
        `Flow cannot have more than ${MAX_ALLOWED_DELAY_DAYS_VALUE} days of delay. Currently flow has delay of ${inputValue} days`
      );
    }

    const isError = validateInput();
    if (isError) {
      setInputState("invalid");
      return;
    }
    setShowCountModal(true);
  };

  // while opening and closing, resetting the card to at_the-start
  useEffect(() => {
    setSelectedCard("at_the_start");
  }, [open]);

  useEffect(() => {
    setInputState("active");
    setInputValue(maxDelayDays ?? 0);
  }, [maxDelayDays]);

  const validateInput = () => {
    if (
      inputValue <= 0 ||
      inputValue > MAX_ALLOWED_DELAY_DAYS_VALUE ||
      inputValue % 1 !== 0
    ) {
      return true;
    }
    return false;
  };

  // When modal is opened and loading
  if (selectedCard === "at_the_start" && isLoading) {
    return (
      <StateModalComponent
        states={StateInterface.LOADING}
        heading={"Loading Past Profiles"}
        subline={"This might take a while..."}
        isButtonEnabled={false}
        width={400}
        onClose={onClose}
      />
    );
  }

  // There is an error in Max delay API
  if (error) {
    return (
      <StateModalComponent
        states={StateInterface.ERROR}
        heading={"Past Profiles can't be added"}
        subline={error}
        icon={
          <div
            style={{
              background: "#D53434",
              display: "flex",
              borderRadius: "32px",
              width: "64px",
              height: "64px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CrossTagIcon
              width={40}
              height={40}
              color={COLORS.content.primaryInverse}
            />
          </div>
        }
        isButtonEnabled={false}
        width={400}
        onClose={onClose}
      />
    );
  }

  // When user clicks on add past profile button
  if (showCountModal) {
    return (
      <CountModal
        open={showCountModal}
        maxDelayDays={inputValue}
        alreadyAddedDays={alreadyAddedDays}
        flowEnabledDate={flowEnabledDate}
        onEdit={() => {
          setShowCountModal(false);
          return;
        }}
        onClose={() => {
          setShowCountModal(false);
          onClose();
          return;
        }}
      />
    );
  }

  // User tries to add for second time but not eligible, displaying info modal.
  if (maxDelayDays === 0 && alreadyAddedDays) {
    return (
      <PastProfileAlreadyAddedModal
        open={open}
        onClose={onClose}
        formattedFlowEnabledDate={formattedFlowEnabledDate}
        formattedLastProfileAdditionDate={formattedLastProfileAdditionDate}
        alreadyAddedDays={alreadyAddedDays}
      />
    );
  }

  // User tries to add for first time but max delay is 0 in the flow, displaying the modal to input.
  if (maxDelayDays === 0 && !alreadyAddedDays) {
    return (
      <ZeroDelayModal
        open={open}
        onClick={validateInputAndShowCountModal}
        onClose={onClose}
        inputValue={inputValue}
        setInputValue={setInputValue}
        inputState={inputState}
        formattedFlowEnabledDate={formattedFlowEnabledDate}
        formattedLastProfileAdditionDate={formattedLastProfileAdditionDate}
      />
    );
  }

  // Displaying 'At the start' and 'Throughout the flow'.
  return (
    <NonZeroDelayModal
      open={open}
      formattedFlowEnabledDate={formattedFlowEnabledDate}
      formattedLastProfileAdditionDate={formattedLastProfileAdditionDate}
      selectedCard={selectedCard}
      setSelectedCard={setSelectedCard}
      isLoading={isLoading}
      daysToDisplay={maxDelayDays ?? 0}
      alreadyAddedDays={alreadyAddedDays}
      onClick={validateInputAndShowCountModal}
      onClose={onClose}
    />
  );
};

import React, {
  FunctionComponent,
  KeyboardEventHandler,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { InputDataProps } from "../input/InputModel";
import {
  DropdownMain,
  ErrorMain,
  ListWrapper,
  List,
  ListItem,
  InputWrapper,
  InputSVG,
  Input,
  PlaceHolder,
  InputValue,
  DropDownDiv,
  HeaderItem,
} from "./Dropdown.style";
import { Icon } from "../../icons";
import SearchInput from "../input/searchInput";
import DownArrow from "../../icons/downArrow";

export interface DATA_SET {
  id: any;
  header?: string;
  subHeader?: string;
  choice?: string;
  extraCharges?: number;
  isDisabled?: boolean;
  isHeader?: boolean;
  type?: string;
}

export interface DropDownDataProps {
  subText?: string;
  error?: boolean;
  placeHolder?: string;
  options: DATA_SET[];
  onChange?: (name: string, data: any, id?: string) => void;
  value?: any;
  id?: string;
  name?: string;
  width?: string;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  isRequired?: boolean;
  height?: string;
  valueColor?: string;
  valueFont?: string;
  showMore?: boolean;
  background?: string;
  maxHeight?: string;
  marginTop?: string;
  showMoreTitle?: string;
  showMoreType?: InputDataProps["type"];
  showMoreValidator?: (choice: string) => string | null;
  showMoreDefaultInputVal?: string;
  dropdataPosition?: "UP" | "DOWN";
  ArrowUp?: Icon;
  ArrowDown?: Icon;
  optionPadding?: string;
  dropDownUpInMobileView?: boolean;
  padding?: string;
  borderRadius?: string;
  searchFunction?: Function;
  isSearchFunctionRequired?: boolean;
  searchPlaceHolder?: string;
  isSearchRequired?: boolean;
  maxHeightInMobileView?: string /** For example: '56px' (type: string) */;
  hideName?: boolean;
  onClickDropDown?: () => void;
  onDropDownClose?: () => void;
  dropdownId?: string;
  lineHeight?: string;
  fontSize?: string;
  handleClick?: (e: any) => void;
}

export interface DropDownStyleDataProps extends Partial<DropDownDataProps> {
  listShow?: boolean;
}

type Props = DropDownDataProps;
const BotDropDown: FunctionComponent<Props> = (props) => {
  const [dropDownPosition, setDropDownPosition] = useState(
    props.dropdataPosition
  );
  const [showPlaceHolder, setShowPlaceHolder] = useState<boolean>(true);
  const [listShow, setListShow] = useState(false);
  const ref = useRef<{ contains: (e: MouseEvent["target"]) => boolean }>();
  const dropdownContainer = useRef<HTMLDivElement>();
  const optionsContainer = useRef<HTMLDivElement>();
  const [props_, setProps_] = useState<DropDownStyleDataProps>({
    ...props,
    value: props.value,
    listShow,
    dropdataPosition: dropDownPosition,
  });
  const [filteredData, setFilteredData] = useState<DATA_SET[]>([]);
  const [wordEntered, setWordEntered] = useState("");

  useEffect(() => {
    setFilteredData(props.options);
  }, [props.options]);

  const handleFilter = (event: any) => {
    const searchWord = event.target.value;

    if (props.searchFunction && props.isSearchFunctionRequired) {
      setWordEntered(searchWord);
      return;
    }
    setWordEntered(searchWord);
    const newFilter = props.options?.filter((value: any) => {
      return value.header.toLowerCase().includes(searchWord.toLowerCase());
    });
    setFilteredData(newFilter);
  };

  const { ArrowUp, ArrowDown } = props;

  useEffect(() => {
    if (props.error) {
      setListShow(false);
    }
  }, [props.error]);

  useLayoutEffect(() => {
    if (!listShow) return;

    if (props.dropDownUpInMobileView) {
      setDropDownPosition("UP");
      return;
    }

    const windowHeight = window.screen.height;
    const dropdownContainer_ = dropdownContainer?.current;
    const optionsContainer_ = optionsContainer?.current;
    if (dropdownContainer_ && optionsContainer_) {
      const dropDownDistanceFromTop =
        dropdownContainer_.getBoundingClientRect().bottom;
      const dropDownDistanceFromBottom = windowHeight - dropDownDistanceFromTop;
      if (
        optionsContainer_.getBoundingClientRect() &&
        optionsContainer_.getBoundingClientRect().height >
        dropDownDistanceFromBottom
      ) {
        setDropDownPosition("UP");
      } else {
        setDropDownPosition("DOWN");
      }
    }
  }, [listShow]);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (listShow && ref.current && !ref.current.contains(e.target)) {
        setListShow(false);
        props?.onDropDownClose?.();
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [listShow]);

  const onListItemClickHandle = (item: any) => {
    props?.onDropDownClose?.();
    setShowPlaceHolder(false);
    setListShow(!listShow);
    props.onChange?.(props.name || "", item, props.id || "");
  };

  const onToggleList = (e: any) => {
    setListShow(!listShow);
    e.stopPropagation();
  };

  return (
    <DropdownMain {...(props as any)}>
      {!props?.hideName && (
        <div
          className="label"
          style={{ color: `${listShow ? "#731DCF" : "#212121"}` }}
        >
          {props.name}
        </div>
      )}
      <InputWrapper
        {...props}
        onClick={(e: any) => {
          onToggleList(e);
          props?.onClickDropDown?.();
        }}
        ref={dropdownContainer}
      >
        <Input>
          <PlaceHolder {...props}>
            {showPlaceHolder && !props.value && props.placeHolder}
            {props.isRequired ? "*" : null}
          </PlaceHolder>
          <InputValue {...props}>{props.value}</InputValue>
        </Input>
        <InputSVG>
          {!listShow ? ArrowDown ? <DownArrow /> : <DownArrow /> : null}
          {listShow ? ArrowUp ? <DownArrow /> : <DownArrow /> : null}
        </InputSVG>
      </InputWrapper>
      <DropDownDiv ref={ref as unknown as React.Ref<HTMLDivElement>}>
        {props.subText && <ErrorMain {...props_}>{props.subText}</ErrorMain>}
        {listShow && (
          <ListWrapper {...props_} ref={optionsContainer}>
            {props?.isSearchRequired && (
              <div>
                <SearchInput
                  placeHolder={props.searchPlaceHolder}
                  onKeyDown={(e: any) => {
                    if (e.code === "Enter") {
                      if (
                        props.searchFunction &&
                        props.isSearchFunctionRequired
                      ) {
                        props.searchFunction(wordEntered);
                        return;
                      }
                    }
                  }}
                  onChangeHandle={handleFilter}
                  value={wordEntered}
                />
              </div>
            )}
            <div>
              {filteredData &&
                !!filteredData.length &&
                filteredData?.map((item) => (
                  <>
                    {item.isHeader ? (
                      <HeaderItem>{item.header}</HeaderItem>
                    ) : (
                      <List>
                        <ListItem
                          {...props_}
                          key={item.id}
                          className={`${item.isDisabled ? "disabled" : ""} ${item.isHeader ? "header" : ""
                            }`}
                          onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (item.isDisabled) {
                              return;
                            }
                            e.stopPropagation();
                            onListItemClickHandle(item);
                          }}
                        >
                          <p className="item-header">{item.header}</p>
                          {item.subHeader && (
                            <p className="item-subHeader">{item.subHeader}</p>
                          )}
                        </ListItem>
                      </List>
                    )}
                  </>
                ))}
            </div>
          </ListWrapper>
        )}
      </DropDownDiv>
    </DropdownMain>
  );
};
export default BotDropDown;

import { EntryPointStyle } from "./EntryPoint.style";
import BotInput from "../../ui-components/input";
import { useEffect, useState } from "react";
import { SuperAdminAccess } from "./SuperAdminAccess";
import { useAppSelector } from "../../state/store";
import { useDispatch } from "react-redux";
import { isStoreIdValid } from "../../services/helpers/StoreHelper";
import TemplateTable from "./TemplateTable";
import { customisationStateActions } from "../../state/customisationState";
import { publishRequestStateActions } from "../../state/publishState";
import { CustomizationTable } from "./Customisation";
import { homeDataAction } from "../../state/homeState";
import { PublishTable } from "./PublishRequest";
import { cloneDeep } from "lodash";
import { flowMetaStateActions } from "../../state/flowMetaState";
import { VariablesTable } from "./variables";
import {
  NotificationDataState,
  userDataState,
} from "../../state/storeDataStateType";
import { useNavigate } from "react-router-dom";
import { Toaster, Button } from "@bikdotai/bik-component-library";
import { notificationDataStateActions } from "../../state/notificationDataState";
import { Assistants } from "./assistants";

interface FlowProps {
  emitFlowName: (flowName: string) => void;
  userData: userDataState;
}

const EntryPoint = (props: FlowProps) => {
  const [flowData, setFlowData] = useState<{ [key: string]: any }>({});
  const [storeId, setStoreId] = useState("");
  const [flowId, setFlowId] = useState("");
  const superAdminFns = new SuperAdminAccess();
  const [errors, setErrors] = useState<{ [name: string]: string }>({});
  const [storeData, setStoreData] = useState<any>({});
  const homeState = useAppSelector((state) => state.homeState);
  const customisationRequests = useAppSelector<any>(
    (state) => state.customisationState?.customisations || {}
  );
  const publishRequests = useAppSelector<any>(
    (state) => state.publishRequestState?.publishRequests || {}
  );
  const dispatcher = useDispatch();
  const [openCustomisations, setOpenCustomisations] = useState(0);
  const [openPublishRequests, setOpenPublishRequests] = useState(0);
  const [tableToShow, setTableToShow] = useState("FLOWMETA");
  const navigate = useNavigate();
  const [toasterString, setToasterString] = useState("");
  const [showToaster, setShowToaster] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [flowLoading, setFlowLoading] = useState(false);
  const [toasterType, setToasterType] = useState<
    "error" | "info" | "default" | "success" | undefined
  >("info");
  const notificationDataState = useAppSelector(
    (state) => state.notificationDataState
  );

  const showNotification = (notificationDataState: NotificationDataState) => {
    if (notificationDataState.successMessage) {
      setToasterString(notificationDataState.successMessage);
      setToasterType("success");
      setShowToaster(true);
    }

    if (notificationDataState.errorMessage) {
      setToasterString(notificationDataState.errorMessage);
      setToasterType("error");
      setShowToaster(true);
    }
  };

  useEffect(() => {
    // gaurd to allow only SSO logged in user to visit entry point component
    if (!props.userData || Object.keys(props.userData).length === 0)
      navigate("/404");
    if (props.userData?.loginType !== "sso") navigate("/404");
    dispatcher(
      homeDataAction.addState({
        storeId: "",
        flowId: "",
        flowName: "",
        userData: props?.userData,
        journeyType: "utility",
      })
    );
  }, [Object.keys(props?.userData)?.length]);

  useEffect(() => {
    if (
      (notificationDataState?.errorMessage || "").length ||
      (notificationDataState?.successMessage || "").length
    ) {
      showNotification(notificationDataState);
      dispatcher(
        notificationDataStateActions.setNotifications({
          errorMessage: "",
          successMessage: "",
        })
      );
    }
  }, [
    notificationDataState.errorMessage,
    notificationDataState.successMessage,
  ]);

  useEffect(() => {
    setOpenCustomisations(0);
    if (Object.keys(customisationRequests).length) {
      const openReq = Object.keys(customisationRequests).filter((key) => {
        return customisationRequests[key]?.status === "requested";
      }).length;
      setOpenCustomisations(openReq);
    }
  }, [customisationRequests]);

  useEffect(() => {
    setOpenPublishRequests(0);
    if (Object.keys(publishRequests).length) {
      const openReq = Object.keys(publishRequests).filter((key) => {
        return publishRequests[key]?.status === "requested";
      }).length;
      setOpenPublishRequests(openReq);
    }
  }, [publishRequests]);

  const getFlows = async (storeId: string) => {
    setLoading(true);
    if (!storeId) {
      setErrors({
        ...errors,
        ["value1"]: "Is Required",
      });
      setStoreData({});
      setFlowData({});
      setLoading(false);
      return;
    } else {
      setErrors({});
    }
    const res = await isStoreIdValid(storeId);
    if (JSON.stringify(res) === "{}") {
      setErrors({
        ...errors,
        ["value1"]: "You are operating in an unrecognized store",
      });
      setStoreData({});
      setFlowData({});
    }
    setStoreData(res);
    const data = await superAdminFns.getFlowsForStore(storeId);
    setFlowData(data);
    dispatcher(
      homeDataAction.addState({
        storeId: storeId,
        flowName: "",
        flowId: "",
        userData: {} as userDataState,
        journeyType: "utility",
      })
    );
    dispatcher(
      flowMetaStateActions.setFlowMeta({
        flowMeta: data,
        updateFlowMeta: true,
      })
    );
    const customisationData = await superAdminFns.getCustomisationRequests(
      storeId
    );
    const publishRequestData = await superAdminFns.getPublishRequests(storeId);
    const sortedKey = Object.keys(customisationData).sort();
    const result: any = {};
    sortedKey.forEach((key) => {
      result[key] = customisationData[key];
    });
    if (result) {
      dispatcher(
        customisationStateActions.setCustomisations({
          customisations: result,
        })
      );
    }
    const sortedPublishKey = Object.keys(publishRequestData).sort();
    const publishSorted: any = {};
    sortedPublishKey.forEach((key) => {
      publishSorted[key] = publishRequestData[key];
    });
    if (publishSorted) {
      dispatcher(
        publishRequestStateActions.setPublishRequests({
          publishRequests: publishSorted,
        })
      );
    }
    setTableToShow("FLOWMETA");
    setLoading(false);
  };

  const openFlow = async (flowId: string) => {
    if (!storeId) {
      setErrors({
        ...errors,
        ["value1"]: "Is Required",
      });
      return;
    }

    if (!flowId) {
      setErrors({
        ...errors,
        ["value2"]: "Is Required",
      });
      return;
    }

    setFlowLoading(true);

    let channel = "";
    let flowName = "";
    let journeyType: "marketing" | "utility" = "utility";

    if (flowData[flowId]) {
      channel = flowData[flowId].channel.toLowerCase();
      flowName = flowData[flowId].flowName;
      journeyType = flowData[flowId]?.journeyType || "utility";
    } else {
      const res = await isStoreIdValid(storeId);
      if (JSON.stringify(res) === "{}") {
        setErrors({
          ...errors,
          ["value1"]: "You are operating in an unrecognized store",
        });
        setStoreData({});
        setFlowData({});
      }
      setStoreData(res);
      const data = await superAdminFns.getFlowsForStore(storeId);
      // User can enter any random flow Id which wont be in store
      if (!data?.[flowId]) {
        setFlowLoading(false);
        setErrors({
          ...errors,
          ["value2"]: "Incorrect flow",
        });
        return;
      }
      channel = data[flowId].channel.toLowerCase();
      flowName = data[flowId].flowName;
      journeyType = data[flowId]?.journeyType || "utility";
    }

    dispatcher(
      homeDataAction.addState({
        flowName: flowName,
        storeId: storeId,
        flowId: flowId,
        type: "",
        channel: channel,
        userData: {} as userDataState,
        journeyType: journeyType,
      })
    );

    if (channel) {
      setTimeout(() => {
        navigate(`/${storeId}/${flowId}/${channel}/edit`);
      }, 2000);
    } else navigate("/404");
  };

  useEffect(() => {
    if (homeState.storeId) {
      setStoreId(homeState.storeId);
      getFlows(homeState.storeId).then();
    }
  }, [homeState.storeId]);

  const updateFlowList = (flow: { [key: string]: any }) => {
    setFlowData(flow);
  };

  return (
    <>
      {showToaster && (
        <Toaster
          text={toasterString}
          width={"300px"}
          type={toasterType}
          autoClose={3000}
          onCloseToast={() => {
            setShowToaster(false);
          }}
        />
      )}
      <EntryPointStyle>
        <div className="grid__left">
          <div className="store__input">
            <BotInput
              name="value1"
              id={"value1"}
              height={"34px"}
              label={"Store Id"}
              type="text"
              error={!!errors["value1"]}
              subText={errors["value1"]}
              onChange={(event) => {
                setErrors({
                  ...errors,
                  ["value1"]: "",
                });
                setStoreId(event.target.value);
              }}
              value={storeId}
            />
            <div style={{ display: "flex", marginTop: 10 }}>
              <Button
                matchParentWidth
                size="large"
                isLoading={loading}
                buttonText="Search by store id"
                onClick={() => getFlows(storeId)}
              />
            </div>
          </div>
          <div className="store__input">
            <BotInput
              name="value2"
              id={"value2"}
              height={"34px"}
              label={"Flow Id"}
              type="text"
              error={!!errors["value2"]}
              subText={errors["value2"]}
              onChange={(event) => {
                setErrors({
                  ...errors,
                  ["value2"]: "",
                });
                setFlowId(event.target.value);
              }}
              value={flowId}
            />
            <div style={{ display: "flex", marginTop: 10 }}>
              <Button
                matchParentWidth
                size="large"
                isLoading={flowLoading}
                buttonText="Open Flow"
                onClick={() => openFlow(flowId)}
              />
            </div>
          </div>
          {storeData && !!Object.keys(storeData).length && (
            <div className="store-info">
              <div className="store-info-heading">About the store</div>
              <div className="store-info-content">
                <div className="store-info-left">Store name</div>
                <div className="store-info-right">
                  {storeData.storeName ? storeData.storeName : "NA"}
                </div>
              </div>
              <div className="store-info-content">
                <div className="store-info-left">Phone Number</div>
                <div className="store-info-right">
                  {storeData.phoneNumber ? storeData.phoneNumber : "NA"}
                </div>
              </div>
            </div>
          )}
          <div
            className={`store-info-content + ${
              tableToShow === "FLOWMETA" ? " active" : ""
            }`}
            onClick={() => setTableToShow("FLOWMETA")}
          >
            <div className="store-info-left">All</div>
          </div>
          <div
            className={`store-info-content + ${
              tableToShow === "PUBLISH" ? " active" : ""
            }`}
            onClick={() => setTableToShow("PUBLISH")}
          >
            <div className="store-info-left">Publish requests</div>
            {openPublishRequests && (
              <div className="notification">{openPublishRequests}</div>
            )}
          </div>
          <div
            className={`store-info-content + ${
              tableToShow === "CUSTOMISATION" ? " active" : ""
            }`}
            onClick={() => setTableToShow("CUSTOMISATION")}
          >
            <div className="store-info-left">Customization requests</div>
            {openCustomisations && (
              <div className="notification">{openCustomisations}</div>
            )}
          </div>
          <div
            className={`store-info-content + ${
              tableToShow === "VARIABLES" ? " active" : ""
            }`}
            onClick={() => setTableToShow("VARIABLES")}
          >
            <div className="store-info-left">Variables</div>
          </div>
          <div
            className={`store-info-content + ${
              tableToShow === "ASSISTANTS" ? " active" : ""
            }`}
            onClick={() => setTableToShow("ASSISTANTS")}
          >
            <div className="store-info-left">Assistants</div>
          </div>
        </div>
        <div className="grid__right">
          {tableToShow === "FLOWMETA" && (
            <TemplateTable
              items={cloneDeep(flowData)}
              emitFlowName={() => props.emitFlowName}
              emitUpdatedFlowList={updateFlowList}
            />
          )}
          {tableToShow === "CUSTOMISATION" && <CustomizationTable />}
          {tableToShow === "PUBLISH" && <PublishTable />}
          {tableToShow === "VARIABLES" && <VariablesTable storeId={storeId} />}
          {tableToShow === "ASSISTANTS" && (
            <Assistants storeId={storeId} showNotification={showNotification} />
          )}
        </div>
      </EntryPointStyle>
    </>
  );
};

export default EntryPoint;




import { Icon } from "./icons-props";

const GroupIcon: Icon = (props) => {
    const { width, height, color } = props;

    return (

        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="group">
                <g id="shape">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 1.66675C5.19881 1.66675 3.33333 3.53223 3.33333 5.83341C3.33333 8.1346 5.19881 10.0001 7.5 10.0001C9.80119 10.0001 11.6667 8.1346 11.6667 5.83341C11.6667 3.53223 9.80119 1.66675 7.5 1.66675ZM5 5.83341C5 4.4527 6.11929 3.33341 7.5 3.33341C8.88071 3.33341 10 4.4527 10 5.83341C10 7.21413 8.88071 8.33341 7.5 8.33341C6.11929 8.33341 5 7.21413 5 5.83341Z" fill="#616161" />
                    <path d="M4.16667 11.6667C3.0616 11.6667 2.00179 12.1057 1.22039 12.8871C0.438987 13.6685 0 14.7283 0 15.8334V17.5001C0 17.9603 0.373096 18.3334 0.833333 18.3334C1.29357 18.3334 1.66667 17.9603 1.66667 17.5001V15.8334C1.66667 15.1704 1.93006 14.5345 2.3989 14.0656C2.86774 13.5968 3.50363 13.3334 4.16667 13.3334H10.8333C11.4964 13.3334 12.1323 13.5968 12.6011 14.0656C13.0699 14.5345 13.3333 15.1704 13.3333 15.8334V17.5001C13.3333 17.9603 13.7064 18.3334 14.1667 18.3334C14.6269 18.3334 15 17.9603 15 17.5001V15.8334C15 14.7283 14.561 13.6685 13.7796 12.8871C12.9982 12.1057 11.9384 11.6667 10.8333 11.6667H4.16667Z" fill="#616161" />
                    <path d="M15.8598 12.4001C15.9749 11.9545 16.4294 11.6865 16.875 11.8015C17.7689 12.0323 18.5608 12.5535 19.1264 13.2831C19.692 14.0128 19.9993 14.9096 20 15.8328V17.5001C20 17.9603 19.6269 18.3334 19.1667 18.3334C18.7064 18.3334 18.3333 17.9603 18.3333 17.5001V15.834C18.3329 15.2802 18.1485 14.742 17.8092 14.3042C17.4698 13.8664 16.9947 13.5538 16.4583 13.4153C16.0127 13.3002 15.7447 12.8457 15.8598 12.4001Z" fill="#616161" />
                    <path d="M13.54 1.80112C13.0942 1.68697 12.6402 1.95586 12.526 2.40172C12.4119 2.84757 12.6808 3.30155 13.1266 3.41571C13.6644 3.5534 14.141 3.86615 14.4814 4.30465C14.8218 4.74316 15.0065 5.28248 15.0065 5.83758C15.0065 6.39269 14.8218 6.93201 14.4814 7.37051C14.141 7.80902 13.6644 8.12177 13.1266 8.25946C12.6808 8.37361 12.4119 8.82759 12.526 9.27345C12.6402 9.7193 13.0942 9.9882 13.54 9.87404C14.4363 9.64456 15.2307 9.12331 15.798 8.39247C16.3653 7.66162 16.6732 6.76276 16.6732 5.83758C16.6732 4.9124 16.3653 4.01354 15.798 3.2827C15.2307 2.55185 14.4363 2.0306 13.54 1.80112Z" fill="#616161" />
                </g>
            </g>
        </svg>

    );
};

export default GroupIcon;

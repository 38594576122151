import { Icon } from "./icons-props";

const ErrorReport: Icon = (props) => {
  const { width, height } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.75 14.5C15.75 14.0858 16.0858 13.75 16.5 13.75H20C20.4142 13.75 20.75 14.0858 20.75 14.5C20.75 14.9142 20.4142 15.25 20 15.25H16.5C16.0858 15.25 15.75 14.9142 15.75 14.5Z"
        fill="currentColor"
      />
      <path
        d="M16.5 16.25C16.0858 16.25 15.75 16.5858 15.75 17C15.75 17.4142 16.0858 17.75 16.5 17.75H18C18.4142 17.75 18.75 17.4142 18.75 17C18.75 16.5858 18.4142 16.25 18 16.25H16.5Z"
        fill="currentColor"
      />
      <path
        d="M15.75 19.5C15.75 19.0858 16.0858 18.75 16.5 18.75H18C18.4142 18.75 18.75 19.0858 18.75 19.5C18.75 19.9142 18.4142 20.25 18 20.25H16.5C16.0858 20.25 15.75 19.9142 15.75 19.5Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.5 12.75C13.5 11.7835 14.2835 11 15.25 11H21.25C22.2165 11 23 11.7835 23 12.75V21.25C23 22.2165 22.2165 23 21.25 23H15.25C14.2835 23 13.5 22.2165 13.5 21.25V12.75ZM15.25 12.5C15.1119 12.5 15 12.6119 15 12.75V21.25C15 21.3881 15.1119 21.5 15.25 21.5H21.25C21.3881 21.5 21.5 21.3881 21.5 21.25V12.75C21.5 12.6119 21.3881 12.5 21.25 12.5H15.25Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 2C10.5496 2 10.1068 2.11899 9.71437 2.34548C9.32191 2.57198 8.99302 2.89833 8.75944 3.29306L8.75693 3.29732L1.35827 15.9582L1.35118 15.9706C1.12236 16.3768 1.00129 16.8373 1.00001 17.3063C0.998729 17.7754 1.11728 18.2366 1.34387 18.6441C1.57047 19.0516 1.8972 19.3911 2.29157 19.629C2.68593 19.8669 3.13419 19.9948 3.59174 19.9999L13.5 20V18.2092H3.60693C3.45581 18.2067 3.30786 18.1642 3.17755 18.0856C3.04609 18.0063 2.93718 17.8931 2.86165 17.7573C2.78612 17.6214 2.7466 17.4677 2.74703 17.3114C2.74745 17.157 2.7868 17.0054 2.8612 16.8713L10.2542 4.22003C10.332 4.08926 10.4412 3.98111 10.5715 3.90596C10.7023 3.83046 10.8499 3.79079 11 3.79079C11.1501 3.79079 11.2977 3.83046 11.4285 3.90596C11.5588 3.98111 11.668 4.08926 11.7458 4.22003L11.7469 4.22181L15.7078 11H17.7443L13.2431 3.29731L13.2406 3.29306C13.007 2.89833 12.6781 2.57198 12.2856 2.34548C11.8932 2.11899 11.4504 2 11 2ZM11.8737 8.35979C11.8737 7.86527 11.4826 7.46439 11.0002 7.46439C10.5178 7.46439 10.1267 7.86527 10.1267 8.35979V11.9414C10.1267 12.4359 10.5178 12.8368 11.0002 12.8368C11.4826 12.8368 11.8737 12.4359 11.8737 11.9414V8.35979ZM10.1267 15.523C10.1267 15.0285 10.5178 14.6276 11.0002 14.6276H11.0089C11.4914 14.6276 11.8824 15.0285 11.8824 15.523C11.8824 16.0175 11.4914 16.4184 11.0089 16.4184H11.0002C10.5178 16.4184 10.1267 16.0175 10.1267 15.523Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ErrorReport;

import { Tooltip, BodyCaption, COLORS, Tag, IconButton } from "@bikdotai/bik-component-library"
import CrossTagIcon from "../../icons/crossTagIcon"
import DeleteButton from "../../icons/deleteButton"
import PlusIcon from "../../icons/plusIcon"
import { StyledLeftCell, StyledRightCell } from "./styles"

const Cell = (props: {
    isFirstCell: boolean,
    isLastCell: boolean,
    onLeftCellClick: () => void,
    onRightCellClick: () => void,
    onDelete: (cellId: string) => void,
    cellId: string,
    payloadVar: string,
    existingVar: string,
    onDeletePayload: (cellId: string) => void,
    onDeleteExisting: (cellId: string) => void,
    focusPlace: string,
    isError?: boolean
}) => {

    return (
        <tr style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        }}>
            <StyledLeftCell
                focusPlace={props.focusPlace}
                isFirstCell={props.isFirstCell}
                isLastCell={props.isLastCell}
                onClick={props.onLeftCellClick}
            >
                {
                    props.payloadVar ?
                        <Tooltip
                            body={''}
                            tooltipContent={
                                <BodyCaption
                                    color={COLORS.content.primaryInverse}
                                    style={{ whiteSpace: 'pre-line', padding: 8 }}
                                >
                                    {
                                        props.payloadVar
                                    }
                                </BodyCaption>
                            }
                            width={300}
                            delay={300}
                            placement={'bottom'}
                        >
                            <div>
                                <Tag tagText={props.payloadVar.length > 20 ? '{{' + props.payloadVar.replace('{{', '').replace('}}', '').substring(0, 20) + '...}}' : props.payloadVar} TrailingIcon={CrossTagIcon} type={props.isError ? 'negative' : "white"} theme="light" variant="regular" onTrailingIconClicked={() => {
                                    props.onDeletePayload(props.cellId)
                                }} />
                            </div>
                        </Tooltip>

                        : <PlusIcon width={20} height={20} color={props.focusPlace === 'left' ? COLORS.content.brand : COLORS.content.inactive} />
                }

            </StyledLeftCell>
            <StyledRightCell onClick={props.onRightCellClick} focusPlace={props.focusPlace}
                isFirstCell={props.isFirstCell}
                isLastCell={props.isLastCell}>
                {
                    props.existingVar ? <Tooltip
                        body={''}
                        tooltipContent={
                            <BodyCaption
                                color={COLORS.content.primaryInverse}
                                style={{ whiteSpace: 'pre-line', padding: 8 }}
                            >
                                {
                                    props.existingVar
                                }
                            </BodyCaption>
                        }
                        width={300}
                        delay={300}
                        placement={'bottom'}
                    >
                        <div>
                            <Tag tagText={props.existingVar.length > 20 ? '{{' + props.existingVar.replace('{{', '').replace('}}', '').substring(0, 20) + '...}}' : props.existingVar} TrailingIcon={CrossTagIcon} type={props.isError ? 'negative' : "white"} theme="light" variant="regular" onTrailingIconClicked={() => {
                                props.onDeleteExisting(props.cellId)
                            }} />
                        </div>
                    </Tooltip>
                        : <PlusIcon width={20} height={20} color={props.focusPlace === 'right' ? COLORS.content.brand : COLORS.content.inactive} />
                }
            </StyledRightCell>
            {!props.isFirstCell && <td>

                <div style={{ marginLeft: 8, width: 'fit-content' }} onClick={() => {
                    props.onDelete(props.cellId)
                }}>
                    <IconButton Icon={DeleteButton} />
                </div>
            </td>}
        </tr>
    )
}

export default Cell;
export enum NodeSubType {
  SINGLE_PRODUCT = "single_product",
  MESSAGE = "message",
  LIST = "list",
  CATALOG_LIST = "catalog_list",
  TEMPLATE = "template",
  WA_CAROUSEL = "wa_carousel",
  ORDER_LIST = "order_list",
  ASK_NAME = "ask_name",
  ASK_EMAIL = "ask_email",
  ASK_PHONE = "ask_phone",
  QUESTION = "question",
  CSAT_NPS = "CSAT/NPS",
  PRODUCT_CARD = "product_card",
  MULTI_PRODUCT = "multi_product",
  CART_INFO = "cart_info",
  BROWSE_COLLECTION = "browse_collection",
  ADD_EVENT = "add_event",
  BROADCAST_EVENTS = "broadcast_events",
  ASSIGN_TICKET = "assign_ticket",
  ADD_TICKET_LABEL = "add_ticket_label",
  CHANGE_TICKET_STAGE = "change_ticket_stage",
  CHECK_WORKING_HOURS = "check_working_hours",
  CALL_EXTERNAL_API = "call_external_api",
  REST_API = "rest_api",
  CALL_CUSTOM_API = "call_custom_api",
  STORE_VARIABLE = "store_variable",
  STORE_VALUE_EVALUATION = 'store_value_evaluation',
  CALL_API = "call_api",
  START_FLOW = "start_flow",
  SEND_SMS = "send_sms",
  SEND_EMAIL = "send_email",
  DELAY = "delay",
  FILTER = "filter",
  AB_TEST = "ab_test",
  ORDER_FILTER = "order_filter",
  CONDITION = "Condition",
  EVALUATE = "evaluate",
  SHOPIFY_ADD_TAGS = "shopify_add_tags",
  DISCOUNT_CODE = "discount_code",
  SHOPIFY_ADD_NOTES = "shopify_add_notes",
  ADD_CUSTOMER_TAGS = "add_customer_tags",
  CREATE_TICKET_ON_FRESHDESK = "create_ticket_on_freshdesk",
  CREATE_PAYMENT_LINK_ON_RAZORPAY = "create_payment_link_on_Razorpay",
  CREATE_PAYMENT_LINK_ON_GOSWIFT = "create_payment_link_on_Goswift",
  JUDGEME = "judgeme",
  GOOGLE_SHEET_ADD_ROW = "google_sheet_add_row",
  GOOGLE_SHEET_UPDATE_ROW = "google_sheet_update_row",
  GOOGLE_SHEET_GET_ROW_DATA = "google_sheet_get_row_data",
  INSTAGRAM_MEDIA = "instagram_media",
  INSTAGRAM_TEXT = "instagram_text",
  CAROUSEL = "carousel",
  PRODUCT_CAROUSEL = "product_carousel",
  AI_CLASSIFY = "ai_classify",
  AI_PRODUCT_RECOMMEND = "ai_product_recommend",
  AI_REPLY = "ai_reply",
  AI_GENERAL_PURPOSE_ASSISTANT = "ai_general_purpose_assistant",
  CSAT = "CSAT",
  TRIGGER_NODE = "trigger_node",
  GMAIL_REPLY = "gmail_reply",
  EMAIL_TEMP_NEW = "email_temp_new",
  EMAIL_CSAT = "email_csat",
  FETCH_PRODUCT = "fetch_product",
  FETCH_CATALOG = "fetch_catalog",
  ASSIGN_TO_AGENT= "assign_to_agent",
  PREVENT_BACKTRACKING = "prevent_backtracking",
  CHANNEL = "channel",
  STARTING_POINT = "starting_point",
  CATALOG = "catalog",
  STICKY_NOTES = "sticky_notes",
  SMS_BLOCK = "sms_block",

  // Manifest block types
  MANIFEST_SEARCH = "manifest_search",
  MANIFEST_LLM_CALL = "manifest_llm_call",
  MANIFEST_EXIT = "manifest_exit",
  MANIFEST_BUILD_LLM_INPUT = "manifest_build_llm_input",

}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {LocalVariablesState, PayloadVariableState} from "./storeDataStateType";

const initialState = {
    payload: {},
    webhookPayload: {}
}

const payloadVariablesDataSlice = createSlice({
    name: 'payloadVariableData',
    initialState: initialState,
    reducers: {
        addPayloadVariables: (state, action: PayloadAction<PayloadVariableState>) => {
            const { payload } = action.payload;
            // @ts-ignore
            state.payload = payload;
            return state;
        },
        setPayloadVariables: (state, action:PayloadAction<PayloadVariableState> ) => {
            const { payload } = action.payload;
            state.payload = payload || {};
            return state;
        },
        addWebhookVariables: (state, action: PayloadAction<PayloadVariableState>) => {
            const { webhookPayload } = action.payload;
            // @ts-ignore
            state.webhookPayload = webhookPayload;
            return state;
        },
        setWebhookVariables: (state, action:PayloadAction<PayloadVariableState> ) => {
            const { webhookPayload } = action.payload;
            state.webhookPayload = webhookPayload || {};
            return state;
        }
    }
});

export const payloadVariablesDataAction = payloadVariablesDataSlice.actions;
export default payloadVariablesDataSlice;

import {TextAreaDataProps} from "./BotTextArea";
import Styled from "styled-components";

export const TextAreaHeader = Styled.div`
   width: 100%;
   height: 36px;
   position: relative;
`;

export const TextAreaHeading = Styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 8px;
    
    
    color: #212121;

`

export const TextAreaLabel = Styled.label.attrs(() => {
    return { className: ["body-small"] };
})<TextAreaDataProps>`
   position: absolute;
   transform-origin: top left;
   transform: ${(props) =>
    !props.placeHolder && !props.value
        ? "translateY(14px) scale(1)"
        : "translateY(8px)"};
   transition: all 0.1s ease-in-out;
   text-overflow: ellipsis;
   cursor: text;
   font-size: ${(props) =>
    !props.value
        ? '14px'
        : "12px !important"};
`;


export const TextAreaWrapper = Styled.div<TextAreaDataProps>`
   width: 100%;
   height: 80px;
   border: 1px solid ${(props) =>
    !props.error ? "#d6dbe2" : 'red'};
   display: flex;
   flex-direction: column;
   padding: ${(props) =>
    props.isRequired || !!props.header ? "0" : "16px"} 17px;
   border-radius: 4px;
   margin-bottom: 4px;
   box-sizing: border-box;
   &:focus-within{
    border: 1px solid ${(props) =>
    !props.error ? 'purple' : 'red'};
   }
   &:focus-within ${TextAreaHeader} ${TextAreaLabel}{
    transform: translateY(8px);
    font-size: 12px;
    color: ${(props) =>
    !props.error ? 'purple' : 'red'};
   }
   &:not(:focus-within) ${TextAreaHeader} ${TextAreaLabel}{
    color: ${(props) =>
    props.error ? 'red' : 'purple'}
   }
   textarea{
   width: 100%;
   height: 100%;
   border: none;
   outline: none;
   resize: none;
   background-color: transparent;
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
   color: #212121;
   }
   @media (max-width: 768px) {
    height: '120px'};
   }
`;

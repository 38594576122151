import { Icon } from "./icons-props";

const CalendarIcon: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "25"}
      height={height || "24"}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5 2C17.5 1.44772 17.0523 1 16.5 1C15.9477 1 15.5 1.44772 15.5 2V3H9.5V2C9.5 1.44772 9.05228 1 8.5 1C7.94772 1 7.5 1.44772 7.5 2V3H5.5C3.84315 3 2.5 4.34315 2.5 6V20C2.5 21.6569 3.84315 23 5.5 23H19.5C21.1569 23 22.5 21.6569 22.5 20V6C22.5 4.34315 21.1569 3 19.5 3H17.5V2ZM20.5 9V6C20.5 5.44772 20.0523 5 19.5 5H17.5V6C17.5 6.55228 17.0523 7 16.5 7C15.9477 7 15.5 6.55228 15.5 6V5H9.5V6C9.5 6.55228 9.05228 7 8.5 7C7.94772 7 7.5 6.55228 7.5 6V5H5.5C4.94772 5 4.5 5.44772 4.5 6V9H20.5ZM4.5 11H20.5V20C20.5 20.5523 20.0523 21 19.5 21H5.5C4.94772 21 4.5 20.5523 4.5 20V11Z"
        fill={color ||"#212121"}
      />
    </svg>
  );
};

export default CalendarIcon;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { StoreInfoState } from "./storeDataStateType";

const initialState: StoreInfoState = {
  currency: "",
  countryCode: "",
  partnerStoreDomain: "",
  chatbotAttributionWindow: 0,
  productCount: 0,
  isNonShopifyStore: false,
};

const storeInfoSlice = createSlice({
  name: "StoreInfo",
  initialState: initialState,
  reducers: {
    addStoreInfoData: (state, action: PayloadAction<StoreInfoState>) => {
      const {
        currency,
        countryCode,
        partnerStoreDomain,
        chatbotAttributionWindow,
        productCount,
        isNonShopifyStore,
      } = action.payload;
      return {
        currency,
        countryCode,
        partnerStoreDomain,
        chatbotAttributionWindow,
        productCount,
        isNonShopifyStore,
      };
    },
  },
});

export const storeInfoStateActions = storeInfoSlice.actions;
export default storeInfoSlice;

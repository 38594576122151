import { Icon} from "./icons-props";

const SearchIcon: Icon = (props) => {
    const { height, width, color } = props;
    return (
        <>
            <svg width={width || "20"} height={ height || "20" } viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M14.6176 16.032C13.078 17.2635 11.125 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 11.125 17.2635 13.0781 16.0318 14.6178L19.707 18.2929C20.0975 18.6834 20.0975 19.3166 19.707 19.7071C19.3165 20.0977 18.6833 20.0977 18.2928 19.7071L14.6176 16.032ZM2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9C16 10.8858 15.2543 12.5975 14.0416 13.8562C14.0072 13.8826 13.9742 13.9115 13.9428 13.9429C13.9114 13.9744 13.8825 14.0074 13.8561 14.0417C12.5974 15.2543 10.8858 16 9 16C5.13401 16 2 12.866 2 9Z" fill={color || "#9E9E9E"}/>
            </svg>

        </>
    )
}

export default SearchIcon;

import styled from "styled-components";
import { backgroundBasedOnSubType } from "../util";

export const MessageNodeStyle = styled.div.attrs(
  (props: { subType: string; channel: string }) => props
)`
  .customNode {
    &__header {
      &__icon {
        background-color: ${(props) =>
          backgroundBasedOnSubType(props.subType, props.channel)};
      }
      &__menu {
        width: 10px;
      }
    }
    &__child-nodes {
      margin-top: 16px;
      border: 1px solid #e0e0e0;
      border-bottom: 0;
      border-radius: 4px;
      background: #fafafa;
    }
  }
`;

export const Header = styled.div.attrs(
  (props: { noButtons: boolean }) => props
)`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f0f0f0;
  gap: 8px;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  border: ${(props) => (props.noButtons ? "1px solid #e0e0e0" : "")};
  padding: 8px;
`;

export const ImgBg = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  // background: #f0f0f0;
`;

export const Title = styled.div`
  color: ${(props) => props.theme.colors.content.secondary};
  ${(props) => props.theme.fonts.buttonRegular};
`;

export const Caption = styled.div`
  color: ${(props) => props.theme.colors.content.secondary};
  ${(props) => props.theme.fonts.bodyCaption};
`;

export const Child = styled.div``;

export const Gap = styled.div`
  margin-top: 16px;
`;

export const Main = styled.div.attrs(
  (props: { idx: number; length: number }) => props
)`
  background: #fafafa;
  // border: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: ${(props) =>
    props.idx === props.length - 1 ? "1px solid #e0e0e0" : ""};
  border-top: 1px solid #e0e0e0;
`;

export const ProductCatalogHeader = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid #e0e0e0;
`;

export const TitleContainer = styled.div`
  background: #f0f0f0;
  padding: 12px 8px 12px 8px;
`;
export const ImageContainer = styled.div``;

export const TitleAndSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-itens: center;
`;

export const Wrapper = styled.div`
  background: #fafafa;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
`;

import styled from "styled-components";
import { backgroundBasedOnSubType } from "../util";

export const ActionNodeStyle = styled.div.attrs(
  (props: { subType: string; hasChildNodes: boolean }) => props
)`
  .customNode {
    &__header {
      &__icon {
        background-color: ${(props) => backgroundBasedOnSubType(props.subType)};
      }
      &__menu {
        width: 10px;
      }
    }
    &__body {
      &__description {
        min-height: 52px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .single-line {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          display: block;
        }
        .two-lines {
          display: -webkit-box;
          width: 100%;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .multi-line {
          display: -webkit-box;
          width: 100%;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
    &__footer {
      width: 100%;

      &__child-nodes {
        margin-top: 16px;
        gap: 16px;
      }
      &__next-step {
        margin-top: ${(props) => (props.hasChildNodes ? "20px" : "")};
        max-width: 65px;
        margin-left: auto;
      }
    }
  }
`;

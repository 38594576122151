import {
  BodySecondary,
  Button,
  COLORS,
  Dropdown,
  Input,
  TitleRegular,
  TitleSmall,
} from "@bikdotai/bik-component-library";
import React, { useEffect, useState } from "react";
import {
  AiAssistantI,
  AiAssistantType,
} from "../../../components/ai-assistant/models";
import { ManifestApiHelper } from "../../../services/helpers/ManifestApiHelpers";
import { SingleOption } from "@bikdotai/bik-component-library/dist/esm/components/dropdown/type";
import { NotificationDataState } from "../../../state/storeDataStateType";
import { generateTxt } from "../../../utilities/fileDownload";

const getAssistantTypeDropdownOption = (selectedType?: AiAssistantType) => {
  return [
    AiAssistantType.FAQ,
    AiAssistantType.PRODUCT_SEARCH,
    AiAssistantType.GENERAL_PURPOSE,
  ].map((type) => {
    return {
      label: type,
      value: type,
      selected: type === selectedType,
    };
  });
};

export const Assistants = (props: {
  storeId: string;
  showNotification: (notificationDataState: NotificationDataState) => void;
}) => {
  const [assistants, setAssistants] = useState<AiAssistantI[]>([]);
  const [assistantId, setAssistantId] = useState("");
  const [assistantTypeOptions, setassistantTypeOptions] = useState(
    getAssistantTypeDropdownOption
  );
  const selectedType = assistantTypeOptions.find((a) => a.selected);

  const manifestApiHelper = new ManifestApiHelper();

  useEffect(() => {
    if (!props.storeId) {
      return;
    }

    manifestApiHelper.getAssistants(props.storeId).then((data) => {
      setAssistants(data);
    });
  }, []);

  const handleLinkAssistant = () => {
    if (!assistantId || !selectedType) {
      return;
    }

    const newAssistant: AiAssistantI = {
      id: assistantId,
      name: "",
      type: selectedType.value,
      is_draft: false,
    };

    manifestApiHelper
      .linkAssistant(props.storeId, newAssistant)
      .then((data) => {
        if (data) {
          setAssistants(data);
          setAssistantId("");
          setassistantTypeOptions((prev) => {
            return prev.map((a) => {
              return {
                ...a,
                selected: false,
              };
            });
          });
          props.showNotification({
            successMessage: "Linked successfully!",
          });
        } else {
          props.showNotification({ errorMessage: "Invalid assistant Id!" });
        }
      });
  };

  const handleDelinkAssistant = (assistantId: string) => {
    manifestApiHelper
      .delinkAssistant(props.storeId, assistantId)
      .then((data) => {
        if (data) {
          setAssistants(data);
          props.showNotification({
            successMessage: "Delinked successfully!",
          });
        }
      });
  };

  const handleDraftPublish = (assistant: AiAssistantI) => {
    const newAssistant: AiAssistantI = {
      ...assistant,
      is_draft: !assistant.is_draft,
    };

    manifestApiHelper
      .linkAssistant(props.storeId, newAssistant)
      .then((data) => {
        if (data) {
          setAssistants(data);
          props.showNotification({
            successMessage: assistant.is_draft
              ? "Published successfully!"
              : "Marked as Draft!",
          });
        }
      });
  };

  const handleOpenAssistant = (assistantId: string) => {
    window.open(
      `https://platform.openai.com/playground/assistants?assistant=${assistantId}`,
      "_blank"
    );
  };

  const handleDownloadProductFile = () => {
    manifestApiHelper.downloadProducts(props.storeId).then((data) => {
      if (data) {
        generateTxt(JSON.stringify(data), `${props.storeId}_products`);
      }
    });
    return;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "24px",
        gap: "8px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <TitleSmall>List of Assistants</TitleSmall>
        <Button
          buttonText={"Download Product file"}
          onClick={handleDownloadProductFile}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid" + COLORS.content.primary,
          borderBottom: "none",
        }}
      >
        <AssistantRowHeader />
        {assistants.map((a) => {
          return (
            <AssistantRow
              assistant={a}
              handleDelinkAssistant={handleDelinkAssistant}
              handleOpenAssistant={handleOpenAssistant}
              handleDraftPublish={handleDraftPublish}
            />
          );
        })}
      </div>
      <TitleSmall>Add/Edit assistant</TitleSmall>
      <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
        <Input
          width={"50%"}
          value={assistantId}
          onChangeText={(input) => {
            setAssistantId(input);
          }}
        />
        <Dropdown
          options={assistantTypeOptions}
          placeHolder={"Assistant Type"}
          onDropdownItemClick={(option) => {
            setassistantTypeOptions(
              assistantTypeOptions.map((a) => {
                return {
                  ...a,
                  selected: (option as SingleOption).value === a.value,
                };
              })
            );
          }}
        />
        <Button
          size={"medium"}
          buttonText={"Link"}
          onClick={handleLinkAssistant}
        />
      </div>
    </div>
  );
};

export const AssistantRowHeader = () => {
  return (
    <div
      style={{
        display: "flex",
        gap: "8px",
        alignItems: "center",
        borderBottom: "1px solid " + COLORS.content.primary,
        padding: "8px",
      }}
    >
      <TitleRegular style={{ flex: 0.3 }}>Assistant Id</TitleRegular>
      <TitleRegular style={{ flex: 0.1 }}>Assistant Name</TitleRegular>
      <TitleRegular style={{ flex: 0.1 }}>Assistant Type</TitleRegular>
      <TitleRegular style={{ flex: 0.5 }}>Actions</TitleRegular>
    </div>
  );
};

export const AssistantRow = (props: {
  assistant: AiAssistantI;
  handleDelinkAssistant: (id: string) => void;
  handleOpenAssistant: (id: string) => void;
  handleDraftPublish: (assistant: AiAssistantI) => void;
}) => {
  const {
    assistant,
    handleDelinkAssistant,
    handleOpenAssistant,
    handleDraftPublish,
  } = props;
  return (
    <div
      style={{
        display: "flex",
        gap: "8px",
        alignItems: "center",
        borderBottom: "1px solid " + COLORS.content.primary,
        padding: "8px",
      }}
    >
      <BodySecondary style={{ flex: 0.3 }}>{assistant.id}</BodySecondary>
      <BodySecondary style={{ flex: 0.1 }}>{assistant.name}</BodySecondary>
      <BodySecondary style={{ flex: 0.1 }}>{assistant.type}</BodySecondary>
      <div style={{ flex: 0.5, display: "flex", gap: "8px" }}>
        <Button
          buttonText={"Delink"}
          buttonType={"destructive"}
          onClick={() => handleDelinkAssistant(assistant.id)}
        />
        <Button
          buttonText={"Open Asssitant"}
          onClick={() => handleOpenAssistant(assistant.id)}
        />
        <Button
          buttonText={!assistant.is_draft ? "Mark as draft" : "Publish"}
          onClick={() => handleDraftPublish(assistant)}
        />
      </div>
    </div>
  );
};

export interface FilterProps {
  nodeData: any;
  saveTo: string;
  storeId: string;
  setSaveTo: any;
  config: any;
  setLoading: Function;
}

export enum FilterActions {
  GET_EVENT_NAMES = "GET_EVENT_NAMES",
  GET_EVENT_PROPERTY_NAMES = "GET_EVENT_PROPERTY_NAMES",
  GET_EVENT_PROPERTY_VALUES = "GET_EVENT_PROPERTY_VALUES",
  GET_USER_PROPERTY_NAMES = "GET_USER_PROPERTY_NAMES",
  GET_USER_PROPERTY_VALUES = "GET_USER_PROPERTY_VALUES",
  GET_SEGMENT_NAMES = "GET_SEGMENT_NAMES",
  SEARCH_EVENT_NAMES = "SEARCH_EVENT_NAMES",
  SEARCH_EVENT_PROPERTY_NAMES = "SEARCH_EVENT_PROPERTY_NAMES",
  SEARCH_EVENT_PROPERTY_VALUES = "SEARCH_EVENT_PROPERTY_VALUES",
  SEARCH_USER_PROPERTY_NAMES = "SEARCH_USER_PROPERTY_NAMES",
  SEARCH_USER_PROPERTY_VALUES = "SEARCH_USER_PROPERTY_VALUES",
  SEARCH_SEGMENT_NAMES = "SEARCH_SEGMENT_NAMES",
}

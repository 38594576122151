import styled from "styled-components";
import { PopOverProps } from "./index";

const PopoverWrapper = styled.div`
  position: relative;
`;

const PopoverContent = styled.div<PopOverProps>`
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  padding: 10px;
  display: ${(props: PopOverProps) => (props.visible ? "block" : "none")};
  left: 440px;
  top: ${(props: PopOverProps) => `${props?.buttonRect?.top}px`};
  border-radius: 4px;
  width: ${(props: PopOverProps) => `${props.width}px`};

  /* Add a small triangle pointing to the button */
  &::before {
    content: "";
    position: absolute;
    border: 8px solid transparent;
    border-right-color: #fff;
    left: -16px;
    top: 10px;
  }
`;

export { PopoverWrapper, PopoverContent };

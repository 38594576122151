import { Icon } from "./icons-props";

const VideoIcon: Icon = (props) => {
    const { width, height, color } = props;
    return (
        <svg width={width || "28"} height={height || "28"} fill={color || '#9E9E9E'} viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_656_18256)">
                <path fillRule="evenodd" clipRule="evenodd" d="M14.8332 8.3806V5.83325C14.8332 4.45254 13.7139 3.33325 12.3332 3.33325H3.1665C1.78579 3.33325 0.666504 4.45254 0.666504 5.83325V14.1666C0.666504 15.5473 1.78579 16.6666 3.1665 16.6666H12.3332C13.7139 16.6666 14.8332 15.5473 14.8332 14.1666V11.6192L19.3488 14.8447C19.6028 15.0261 19.9369 15.0504 20.2145 14.9076C20.492 14.7647 20.6665 14.4787 20.6665 14.1666V5.83326C20.6665 5.5211 20.492 5.23512 20.2145 5.09228C19.9369 4.94945 19.6028 4.97371 19.3488 5.15514L14.8332 8.3806ZM2.33317 5.83325C2.33317 5.37301 2.70627 4.99992 3.1665 4.99992H12.3332C12.7934 4.99992 13.1665 5.37301 13.1665 5.83325V14.1666C13.1665 14.6268 12.7934 14.9999 12.3332 14.9999H3.1665C2.70627 14.9999 2.33317 14.6268 2.33317 14.1666V5.83325ZM18.9998 12.5473L15.4336 9.99992L18.9998 7.45258V12.5473Z"/>
            </g>
            <defs>
                <clipPath id="clip0_656_18256">
                    <rect width="20" height="20" fill="white" transform="translate(0.666504)"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default VideoIcon;
import { VariableInfo } from "../app/entry-point/variables/common";
import { globalVariablePattern } from "../ui-components/rich-text-editor/RichTextEditor";
import {VariableListInterfaceV2} from "../services/helpers/VariablesListHookV2";

type ObjectType = { [index: string]: string };
export const variableTypeMapper: ObjectType = {
  number: "Number",
  string: "String",
  boolean: "Boolean",
  list: "List",
  any: "Any"
};

export const reverseVariableTypeMapper: ObjectType = {
  Integer: "int",
  String: "str",
  Number: "float",
  Boolean: "bool",
  List: "list",
  response: "response",
  externalApiResponse: "externalApiResponse",
  payload: "payload",
};

export const parseVariables = (text: string): Array<string> => {
  const varList = text.match(globalVariablePattern) as string[];

  if (!varList) {
    return [];
  }
  return varList;
};

export function pythonTypesToJS(type: string) {
  switch(type){
      case 'str':
          return 'string'
      case 'int':
          return 'number'
      case 'bool':
          return 'boolean'
      case 'list':
          return 'list'
      case 'dict':
          return 'object'
      case 'float':
          return 'number'
      default:
          return 'any'
  }
}

export const getVariableType = (
  text: string,
  rawVariableList: any,
  globalVariableList: {
    [key: string]: VariableInfo;
  },
  localVariableList: { [index: string]: string }
): string => {
  const varList = parseVariables(text);
  if (varList.length) {
    const indexString = varList[0].replace("{{", "").replace("}}", "");

    let variableClass = indexString.split(".")[0];

    if (
      variableClass === "response" ||
      variableClass === "externalApiResponse" ||
      variableClass.endsWith("Event") ||
      variableClass === "payload" ||
      variableClass.startsWith('callCustomApi')
    ) {
      return variableClass;
    }

    // exception
    if (variableClass === "paymentLink") {
      variableClass = "payment";
    }

    let variableType =
      rawVariableList?.[variableClass]?.[indexString]?.variable_type;


    if (!variableType) {
      if (globalVariableList?.[indexString]) {
        const reverseType =
          reverseVariableTypeMapper[
            globalVariableList?.[indexString]?.["type"]
          ];
        if (reverseType) {
          variableType = reverseType;
        }
      }
    }

    if (!variableType) {
      if (
        Object.values(localVariableList).filter((item) => item === indexString)
          .length
      ) {
        variableType = "str";
      }
    }

    return variableType || "";
  }
  return "";
};

export const getVariableTypeV2 = (
    text: string,
    variableList: VariableListInterfaceV2[],
    withFetchingData?: boolean,
    missingCheck?:boolean
): string | [string, boolean] | null=> {
  const varList = parseVariables(text);
  if (varList.length) {
    const indexString = varList[0];
    const type = getVariableTypeByActualValue(variableList, indexString, withFetchingData)
    if(missingCheck && !type){
      return null
    }
    if(withFetchingData){
      return type || ['any', false]
    }
    return type || 'any';
  }

  if(withFetchingData){
    return ['any', false]
  }
  return 'any'
}

const getVariableTypeByActualValue = (variableLists: VariableListInterfaceV2[], textToSearch: string, withFetchingData?: boolean): string | null | [string, boolean] => {
  for (const variableList of variableLists) {
    for (const key in variableList.variables) {
      const variable = variableList.variables[key];
      if (variable.actualValue === textToSearch) {
        if(withFetchingData){
          return [variable.variableType, variable.needsFetching]
        }
        return variable.variableType;
      }
      if (variable.variables) {
        const nestedResult = getVariableTypeByActualValue([variable as any], textToSearch, withFetchingData);
        if (nestedResult) {
          return nestedResult;
        }
      }
    }
  }

  return null; // Return null if no match is found
}

export enum ValidationErrors {
  ONLY_VARIABLES = "Value should only contain variables",
  ONLY_ONE_VARIABLE = "Only one variable is allowed",
  INVALID_VARIABLE = "Please select/enter a valid variable",
  INVALID_VARIABLES = "One or more chosen variables are invalid",
  BOOL_REQUIRED = "Please enter either true/false",
  NULL_NOT_ALLOWED = "Value cannot be null",
  STRING_REQUIRED = "Please choose an string variable",
  FLOAT_REQUIRED = "Please choose an float/decimal variable",
  INTEGER_REQUIRED = "Please choose an integer variable",
  INVALID_EXPRESSION = "Invalid Expression",
  INVALID_EMAIL = "Please enter a valid email",
}

export function escapeHtml(text: string) {
  const doc = text.replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"')
      .replace(/&#039;/g, '\'');
  return doc
}

import { isProd } from "../../config";
import { NodeSubType } from "../../models/nodeSubType";

interface IHelpLink {
  [key: string]: any;
}

export const HelpLinks: IHelpLink = {
  ai_product_recommend: {
    visible: true,
    info: [
      "This block recommends a product based on customer message.",
      "It can also recommend similar products to a product through it’s product name",
    ],
    link: "",
  },
  ai_classify: {
    visible: true,
    info: [
      "This block classifies a customer message into categories using AI.",
      "Based on the topic, you can define the journey the customer will take.",
    ],
    link: "",
  },
  ai_reply: {
    visible: true,
    info: [
      "This block gives replies using AI for frequently asked questions by the customer",
      "Data sources need to be added in ",
      "Sometimes, the AI might give unexpected responses if it’s unable to find a response from data sources",
    ],
    linkMaps: {
      "1": ["settings", "https://dashboard.bik.ai/bik-ai/training"],
    },
    link: "",
  },
  [NodeSubType.AI_GENERAL_PURPOSE_ASSISTANT]: {
    visible: true,
    info: [
      "Select the AI Assisstant that will be used to respond",
      "Sometimes, the AI might give unexpected responses if it’s unable to find a response from data sources",
    ],
    linkMaps: {},
  },
  rest_api: {
    visible: true,
    info: [],
    link: "https://bikglobal.notion.site/Call-an-External-API-Block-558f0d592e5945d4b7ea27cea6fcd094",
  },
  wa_carousel: {
    visible: true,
    info: [
      "Send a set of up to 10 carousel cards that are horizontally scrollable, along with a text message.",
      "A Product Carousel allows you to add products to carousel cards.",
      "A Media and Description Carousel lets you to add image/video & description for carousel cards individually.",
    ],
    link: `https://${
      isProd ? "dashboard" : "staging.dashboard"
    }.bik.ai/template/create/whatsapp`,
  },
  google_sheet_add_row: {
    visible: true,
    info: [
      'Please give edit access to "googlesheet@bikayi-chat.iam.gserviceaccount.com" for this action to work.',
      "Don’t use special characters for value inputs.",
      "Value input example -{{customer.name}}, {{Order.ID}},...",
    ],
  },
  google_sheet_update_row: {
    visible: true,
    info: [
      'Please give edit access to "googlesheet@bikayi-chat.iam.gserviceaccount.com" for this action to work.',
    ],
  },
  google_sheet_get_row_data: {
    visible: true,
    info: [
      'Please give edit access to "googlesheet@bikayi-chat.iam.gserviceaccount.com" for this action to work.',
      "The data will be saved as variables under header “googleSheetGetRowData” with name corresponding to column.",
      "Use Store to Variable block in next step to map these to Existing Variables.",
    ],
  },
};

import {
  BodySecondary,
  COLORS,
  IconButton,
  TitleSmall,
  Tooltip,
} from "@bikdotai/bik-component-library";
import { AddPastProfileModalWrapper } from "./sub-components/AddPastProfileModalWrapper";
import { CardButton } from "./sub-components/CardButton";
import { MaxDelayType } from "./AddPastProfile";
import ConditionNodeIcon from "../../icons/conditionNodeIcon";
import ShimmerHelper from "../../ui-components/shimmer/shimmerHelper";
import InfoIcon from "../../icons/infoIcon";
import AtTheStartIcon from "../../icons/AtTheStartIcon";

export type NonZeroDelayModalPropsType = {
  open: boolean;
  formattedFlowEnabledDate: string;
  formattedLastProfileAdditionDate: string;
  selectedCard: MaxDelayType;
  setSelectedCard: (card: MaxDelayType) => void;
  isLoading: boolean;
  daysToDisplay: number;
  alreadyAddedDays: number;
  onClick: () => void;
  onClose: () => void;
};

/**
 * Modal which has card buttons, 'At the start' and 'Throughout the Flow'.
 * On change of button, Id gets changed and passed to parent component
 */
export const NonZeroDelayModal = (props: NonZeroDelayModalPropsType) => {
  const {
    open,
    formattedFlowEnabledDate,
    formattedLastProfileAdditionDate,
    selectedCard,
    setSelectedCard,
    isLoading,
    daysToDisplay,
    alreadyAddedDays,
    onClick,
    onClose,
  } = props;
  return (
    <AddPastProfileModalWrapper open={open} onClick={onClick} onClose={onClose}>
      <TitleSmall style={{ display: "flex", margin: "0px 25px" }}>
        Select how customers will get added
      </TitleSmall>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "0px 10px",
        }}
      >
        <CardButton
          id={"at_the_start"}
          LeadingIcon={AtTheStartIcon}
          title={"At the start"}
          subTitle={`Previous Customers' profiles will only be added at the start of the flow; if they have already passed the first communication point, they won't be added to the flow.`}
          selected={selectedCard === "at_the_start"}
          onClick={(id: MaxDelayType) => {
            setSelectedCard(id);
          }}
        />
        <CardButton
          id={"throughout_the_flow"}
          LeadingIcon={ConditionNodeIcon}
          title={"Throughout the flow"}
          subTitle={`Customers will get added to different points in the journey, based on when the trigger was performed.`}
          selected={selectedCard === "throughout_the_flow"}
          onClick={(id: MaxDelayType) => {
            setSelectedCard(id);
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "20px",
        }}
      >
        {isLoading ? (
          <ShimmerHelper boxes={[{ width: "703px", height: "16px" }]} />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flex: 1,
            }}
          >
            {alreadyAddedDays ? (
              <BodySecondary
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {`Customers will get added starting from `}
                <TitleSmall>&nbsp; {formattedFlowEnabledDate}</TitleSmall>
                {`, or `}
                <TitleSmall>&nbsp; {daysToDisplay} days &nbsp;</TitleSmall>
                {`  before profiles were previously added`}
              </BodySecondary>
            ) : (
              <BodySecondary
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                }}
              >
                {`Customers will get added starting from `}
                <TitleSmall>&nbsp; {formattedFlowEnabledDate}</TitleSmall>
                {`, or `}
                <TitleSmall>&nbsp; {daysToDisplay} days &nbsp;</TitleSmall>
                {`  before flow became active`}
              </BodySecondary>
            )}

            <Tooltip
              body="Calculated based on total delays added to journey"
              placement="bottom"
            >
              <IconButton
                style={{
                  display: "flex",
                  marginRight: "20px",
                  marginLeft: "2px",
                }}
                Icon={() => (
                  <InfoIcon
                    width={16}
                    height={16}
                    color={COLORS.content.placeholder}
                  />
                )}
              />
            </Tooltip>
          </div>
        )}
      </div>
    </AddPastProfileModalWrapper>
  );
};

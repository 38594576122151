import { ChildNodes } from "../customNode";
import {
  Caption,
  Child,
  ProductCatalogHeader,
  Title,
  TitleContainer,
  Wrapper,
} from "./style";
import Box from "../../../icons/box";
import { CURRENCY_SYMBOLS } from "../../../app/messageBlock/Constants";
import { useAppSelector } from "../../../state/store";
import {ResizableImage} from '@bikdotai/bik-component-library';

interface CarousalNodeProps {
  child: any;
  index: number;
  subType?: string;
  nodeId: string;
}

const ProductAndCatalogNode = (props: CarousalNodeProps) => {
  const { child, index, subType } = props;
  const storeInfoState = useAppSelector((state) => state.storeInfoState);
  const currency = CURRENCY_SYMBOLS[storeInfoState.currency];

  return (
    <>
      <>
        <ProductCatalogHeader>
          {child[0]?.image ? (
            <ResizableImage imageUrl={child[0].image} targetSize={120} alt="Product" style={{
              height: "120px",
              width: "100%",
              objectFit: "cover",
            }} />
          ) : (
            <div
              style={{
                width: "100%",
                height: "120px",
                display: "flex",
                flexDirection: "column",
                background: "#e0e0e0",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box height={24} width={24} color={"#616161"} />
              <div className="BodyCaption" style={{ marginTop: 8, width: 120 }}>
                Product will be displayed here
              </div>
            </div>
          )}

          <TitleContainer>
            <Title>{child[0].title || "Product Name"}</Title>
            <Caption style={{ marginTop: 4 }}>
              {child[0] && child[0].subTitle
                ? `${currency || ""}${child[0].subTitle}`
                : "Product Price"}
            </Caption>
          </TitleContainer>
        </ProductCatalogHeader>
      </>

      {child.map((ele: any) => {
        if(ele?.buttonId?.startsWith('drip-')){
          return <></>
        }
        return (
          <Wrapper>
            <div>
              <Child className="customNode__childNodes">
                <ChildNodes
                  key={ele.name}
                  name={ele.name}
                  buttonId={ele.buttonId}
                  index={index}
                  nodeType={"Message"}
                  count={1}
                  isConnectable={
                    ele.ctaValue && ele.ctaValue.length > 0
                      ? false
                      : ele.isConnectable
                  }
                  subType={subType}
                  ctaValue={ele.ctaValue}
                  nodeId={props.nodeId}
                />
              </Child>
            </div>
          </Wrapper>
        );
      })}
    </>
  );
};

export default ProductAndCatalogNode;

import { Icon } from "./icons-props";

const ProductCardIcon: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 21C16.4477 21 16 20.5523 16 20C16 19.4477 16.4477 19 17 19H22.5C23.0523 19 23.5 19.4477 23.5 20C23.5 20.5523 23.0523 21 22.5 21H17ZM16 17C16 16.4477 16.4477 16 17 16H19.75H22.5C23.0523 16 23.5 16.4477 23.5 17V17.0625C23.5 17.6148 23.0523 18.0625 22.5 18.0625H19.75H17C16.4477 18.0625 16 17.6148 16 17.0625V17ZM14 24C13.4477 24 13 23.5523 13 23C13 22.4477 13.4477 22 14 22C14.5523 22 15 22.4477 15 23C15 23.5523 14.5523 24 14 24ZM14 18C13.4477 18 13 17.5523 13 17C13 16.4477 13.4477 16 14 16C14.5523 16 15 16.4477 15 17C15 17.5523 14.5523 18 14 18ZM14 21C13.4477 21 13 20.5523 13 20C13 19.4477 13.4477 19 14 19C14.5523 19 15 19.4477 15 20C15 20.5523 14.5523 21 14 21ZM17 24C16.4477 24 16 23.5523 16 23C16 22.4477 16.4477 22 17 22H22.5C23.0523 22 23.5 22.4477 23.5 23C23.5 23.5523 23.0523 24 22.5 24H17Z"
        fill={color || "#1A872C"}
      />
      <path
        d="M11 11.5V21.382C11 22.1253 10.2177 22.6088 9.55279 22.2764L2.55279 18.7764C2.214 18.607 2 18.2607 2 17.882V7.5884C2 7.22524 2.19689 6.89062 2.51436 6.71425L10.5144 2.2698C10.8164 2.10201 11.1836 2.10201 11.4856 2.2698L19.4856 6.71425C19.8031 6.89062 20 7.22524 20 7.5884V14.5"
        stroke={color || "#1A872C"}
        stroke-width="2"
      />
      <path
        d="M19.5 6.5L11.4928 11.2101C11.1875 11.3897 10.8099 11.3944 10.5002 11.2223L2 6.5"
        stroke={color || "#1A872C"}
        stroke-width="2"
      />
    </svg>
  );
};

export default ProductCardIcon;

import { Icon } from "./icons-props";

const Person: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.66669C7.69881 1.66669 5.83333 3.53217 5.83333 5.83335C5.83333 8.13454 7.69881 10 10 10C12.3012 10 14.1667 8.13454 14.1667 5.83335C14.1667 3.53217 12.3012 1.66669 10 1.66669ZM7.5 5.83335C7.5 4.45264 8.61929 3.33335 10 3.33335C11.3807 3.33335 12.5 4.45264 12.5 5.83335C12.5 7.21407 11.3807 8.33335 10 8.33335C8.61929 8.33335 7.5 7.21407 7.5 5.83335Z"
        fill={color || "#212121"}
      />
      <path
        d="M6.66667 11.6667C5.5616 11.6667 4.50179 12.1057 3.72039 12.8871C2.93899 13.6685 2.5 14.7283 2.5 15.8334V17.5C2.5 17.9603 2.8731 18.3334 3.33333 18.3334C3.79357 18.3334 4.16667 17.9603 4.16667 17.5V15.8334C4.16667 15.1703 4.43006 14.5344 4.8989 14.0656C5.36774 13.5967 6.00363 13.3334 6.66667 13.3334H13.3333C13.9964 13.3334 14.6323 13.5967 15.1011 14.0656C15.5699 14.5344 15.8333 15.1703 15.8333 15.8334V17.5C15.8333 17.9603 16.2064 18.3334 16.6667 18.3334C17.1269 18.3334 17.5 17.9603 17.5 17.5V15.8334C17.5 14.7283 17.061 13.6685 16.2796 12.8871C15.4982 12.1057 14.4384 11.6667 13.3333 11.6667H6.66667Z"
        fill={color || "#212121"}
      />
    </svg>
  );
};

export default Person;

import { Icon } from "./icons-props";

const InstagramSkeletonIcon: Icon = (props) => {
    const { width, height, color } = props;
    return (
        <svg width={width || "20"} height={height || "20"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.11722 6.26524C8.89513 5.86159 9.78052 5.71352 10.6474 5.84207C11.5317 5.9732 12.3504 6.38526 12.9825 7.01739C13.6147 7.64952 14.0267 8.46819 14.1579 9.35249C14.2864 10.2194 14.1383 11.1048 13.7347 11.8827C13.331 12.6606 12.6924 13.2915 11.9095 13.6855C11.1267 14.0795 10.2396 14.2166 9.3743 14.0774C8.50903 13.9382 7.70969 13.5297 7.08998 12.9099C6.47027 12.2902 6.06174 11.4909 5.92251 10.6256C5.78328 9.76035 5.92042 8.87321 6.31445 8.09038C6.70847 7.30754 7.3393 6.66888 8.11722 6.26524ZM10.403 7.49071C9.88281 7.41358 9.35158 7.50242 8.88483 7.74461C8.41808 7.9868 8.03958 8.36999 7.80317 8.83969C7.56676 9.30939 7.48447 9.84168 7.56801 10.3608C7.65155 10.88 7.89667 11.3596 8.26849 11.7314C8.64032 12.1033 9.11992 12.3484 9.63908 12.4319C10.1582 12.5155 10.6905 12.4332 11.1602 12.1968C11.6299 11.9603 12.0131 11.5818 12.2553 11.1151C12.4975 10.6483 12.5863 10.1171 12.5092 9.59696C12.4305 9.06638 12.1833 8.57518 11.804 8.1959C11.4247 7.81662 10.9335 7.56939 10.403 7.49071Z" fill="#731DCF" />
            <path d="M15 5.83301C15.4603 5.83301 15.8334 5.45991 15.8334 4.99967C15.8334 4.53944 15.4603 4.16634 15 4.16634C14.5398 4.16634 14.1667 4.53944 14.1667 4.99967C14.1667 5.45991 14.5398 5.83301 15 5.83301Z" fill="#731DCF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.833374 5.83301C0.833374 3.07158 3.07195 0.833008 5.83337 0.833008H14.1667C16.9281 0.833008 19.1667 3.07158 19.1667 5.83301V14.1663C19.1667 16.9278 16.9281 19.1663 14.1667 19.1663H5.83337C3.07195 19.1663 0.833374 16.9278 0.833374 14.1663V5.83301ZM5.83337 2.49967C3.99242 2.49967 2.50004 3.99206 2.50004 5.83301V14.1663C2.50004 16.0073 3.99242 17.4997 5.83337 17.4997H14.1667C16.0077 17.4997 17.5 16.0073 17.5 14.1663V5.83301C17.5 3.99206 16.0077 2.49967 14.1667 2.49967H5.83337Z" fill="#731DCF" />
        </svg>
    );
}

export default InstagramSkeletonIcon;
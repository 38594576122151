import { Icon } from "./icons-props";

const GoogleSheetGet: Icon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.08335 0.740723L13.6667 5.32406L11.375 5.74072L9.08335 5.32406L8.66669 3.03239L9.08335 0.740723Z"
        fill="#188038"
      />
      <path
        d="M9.08334 5.32406V0.740723H1.58334C0.892718 0.740723 0.333344 1.3001 0.333344 1.99072V17.8241C0.333344 18.5147 0.892718 19.0741 1.58334 19.0741H12.4167C13.1073 19.0741 13.6667 18.5147 13.6667 17.8241V5.32406H9.08334Z"
        fill="#34A853"
      />
      <path
        d="M2.83337 7.82397V13.8656H11.1667V7.82397H2.83337ZM6.47921 12.824H3.87504V11.3656H6.47921V12.824ZM6.47921 10.324H3.87504V8.86564H6.47921V10.324ZM10.125 12.824H7.52087V11.3656H10.125V12.824ZM10.125 10.324H7.52087V8.86564H10.125V10.324Z"
        fill="white"
      />
      <rect width="15.75" height="21" fill="url(#pattern0)" />
      <rect x="8" y="8" width="16" height="16" rx="8" fill="white" />
      <path
        d="M11.4283 21.7147H20.5712V20.3702H11.4283V21.7147ZM20.5712 14.3197H17.959V10.2861H14.0406V14.3197H11.4283L15.9998 19.0256L20.5712 14.3197Z"
        fill="#34A853"
      />
    </svg>
  );
};

export default GoogleSheetGet;

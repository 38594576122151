import styled from "styled-components";
import ActionNodeIcon from "../../icons/actionNodeIcon";
import ConditionNodeIcon from "../../icons/conditionNodeIcon";
import StartFlowIcon from "../../icons/startFlow";
import InstagramIcon from "../../icons/instagramIcon";
import WhatsappIcon from "../../icons/whatsappIcon";
import StickyNoteIcon from "../../icons/stickyNotesIcon";
import Email from "../../icons/email";
import { BASE_COLORS, COLORS } from "@bikdotai/bik-component-library";
import SparkleIcon from "../../icons/SparkleIcon";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../state/store";
import { Icon } from "../../icons";
const BLACK_COLOR = BASE_COLORS.grayscale["900"];

interface AddNodePopupProps {
  click: (nodeName: string) => void;
  isActive: boolean;
  activeNode?: string;
  channel: string;
}

const AddNodePopupStyle = styled.div<{ isActive: boolean }>`
  z-index: 5;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  width: 72px;
  height: fit-content;
  border-radius: 4px;
  right: ${(props) => (props.isActive ? "408px" : "2%")};
  top: 0;
  bottom: 0;
  margin: auto;
  transition: right 0.5s ease;
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.12));
  .add_node_block:not(:last-child) {
    border-bottom: 1px solid #e0e0e0;
  }
  .add_node_block {
    height: 72px;
    width: 72px;
    align-items: center;
    justify-content: center;
    padding: 10px 5px;
    &:hover {
      background-color: #f9f9f9 !important;
      cursor: pointer;
    }
    &__icon {
      background-color: #f0f0f0;
    }
    &__name {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #212121;
      margin-top: 4px;
    }
  }
`;

interface NodeType {
  icon?: Icon;
  name: string;
  activeIcon?: Icon;
  type: string;
}

const AddNodePopup = (props: AddNodePopupProps) => {
  const { click, isActive, activeNode, channel } = props;
  const homeState = useAppSelector((state) => state.homeState);
  const [nodeTypes, setNodeTypes] = useState(
    channel === "whatsapp"
      ? WHATSAPP
      : channel === "instagram"
        ? INSTAGRAM
        : EMAIL)

  const [isSSO, setIsSSO] = useState<boolean>(false);

  useEffect(() => {
    if (homeState.userData && homeState.userData?.loginType === "sso") {
      setIsSSO(true);
    }

    setNodeTypes(channel === "whatsapp"
      ? WHATSAPP
      : channel === "instagram"
        ? INSTAGRAM
        : EMAIL)
  }, [homeState?.flowId]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: "4px",
        position: "absolute",
        right: "2%",
        marginBottom: "100px"
      }}
      id="node-popup"
    >
      <AddNodePopupStyle isActive={isActive}>
        {nodeTypes.map((el: any, index: number) => {
          return (
            <div
              className="add_node_block flex--column"
              key={index}
              onClick={() => click(el.name)}
              style={{
                backgroundColor:
                  isActive && activeNode === el.type
                    ? COLORS.background.brandLight
                    : "unset",
              }}
            >
              <div
                className="add_node_block__icon icon-wrapper"
                style={{
                  backgroundColor:
                    isActive && activeNode === el.type ? "#4B1583" : undefined,
                }}
              >
                {isActive && activeNode === el.type ? el.activeIcon : el.icon}
              </div>
              <div className="add_node_block__name">{el.name}</div>
            </div>
          );
        })}
      </AddNodePopupStyle>
    </div>
  );
};

const WHATSAPP = [
  {
    icon: <WhatsappIcon color={BLACK_COLOR} />,
    name: "Whatsapp",
    activeIcon: <WhatsappIcon color={BLACK_COLOR} />,
    type: "Message",
  },
  {
    icon: <ActionNodeIcon color={BLACK_COLOR} />,
    name: "Action",
    activeIcon: <ActionNodeIcon color="#FFFFFF" />,
    type: "Action",
  },
  {
    icon: <ConditionNodeIcon color={BLACK_COLOR} />,
    name: "Flow control",
    type: "Condition",
    activeIcon: <ConditionNodeIcon color="#FFFFFF" />,
  },
  {
    icon: <SparkleIcon color={BLACK_COLOR} />,
    name: "Bik AI",
    type: "AI",
    activeIcon: <SparkleIcon color="#FFFFFF" />,
  },
  {
    icon: <StartFlowIcon color={BLACK_COLOR} />,
    name: "Start flow",
    type: "Start flow",
  },
  {
    icon: <StickyNoteIcon color={BLACK_COLOR} />,
    name: "Sticky notes",
    type: "Sticky notes",
  },
];

const INSTAGRAM = [
  {
    icon: <InstagramIcon color={BLACK_COLOR} />,
    name: "Instagram",
    activeIcon: <InstagramIcon color={BLACK_COLOR} />,
    type: "Message",
  },
  {
    icon: <ActionNodeIcon color={BLACK_COLOR} />,
    name: "Action",
    activeIcon: <ActionNodeIcon color="#FFFFFF" />,
    type: "Action",
  },
  {
    icon: <ConditionNodeIcon color={BLACK_COLOR} />,
    name: "Flow control",
    type: "Condition",
    activeIcon: <ConditionNodeIcon color="#FFFFFF" />,
  },
  {
    icon: <SparkleIcon color={BLACK_COLOR} />,
    name: "Bik AI",
    type: "AI",
    activeIcon: <SparkleIcon color="#FFFFFF" />,
  },
  { icon: <StartFlowIcon color={BLACK_COLOR} />, name: "Start flow" },
  { icon: <StickyNoteIcon color={BLACK_COLOR} />, name: "Sticky notes" },
  // { icon: <TagNodeIcon color={BLACK_COLOR}/>, name: 'Tags' },
  // { icon: <Email color={BLACK_COLOR}/>, name: 'Email', activeIcon: <Email color={BLACK_COLOR}/>},
  // { icon: <SmsIcon color={BLACK_COLOR}/>, name: 'SMS', activeIcon: <SmsIcon color={BLACK_COLOR}/>},
];

const EMAIL = [
  {
    icon: <Email color={BLACK_COLOR} />,
    name: "Email",
    activeIcon: <Email color={"#FFFFFF"} />,
    type: "Message",
  },
  {
    icon: <ActionNodeIcon color={BLACK_COLOR} />,
    name: "Action",
    activeIcon: <ActionNodeIcon color="#FFFFFF" />,
    type: "Action",
  },
  {
    icon: <ConditionNodeIcon color={BLACK_COLOR} />,
    name: "Flow control",
    type: "Condition",
    activeIcon: <ConditionNodeIcon color="#FFFFFF" />,
  },
  {
    icon: <SparkleIcon color={BLACK_COLOR} />,
    name: "Bik AI",
    type: "AI",
    activeIcon: <SparkleIcon color="#FFFFFF" />,
  },
  { icon: <StartFlowIcon color={BLACK_COLOR} />, name: "Start flow" },
  { icon: <StickyNoteIcon color={BLACK_COLOR} />, name: "Sticky notes" },
];

export default AddNodePopup;

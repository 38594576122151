import { Icon } from "./icons-props";

const AiReplyIcon: Icon = (props) => {
  const { width, height } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.39306 6.27337C6.75754 6.63786 6.75754 7.22881 6.39306 7.5933L5.18636 8.8H13.1998C16.2925 8.8 18.7998 11.3072 18.7998 14.4C18.7998 17.4928 16.2925 20 13.1998 20H6.66642C6.15096 20 5.73309 19.5821 5.73309 19.0667C5.73309 18.5512 6.15096 18.1333 6.66642 18.1333H13.1998C15.2616 18.1333 16.9331 16.4619 16.9331 14.4C16.9331 12.3381 15.2616 10.6667 13.1998 10.6667H5.18635L6.39306 11.8734C6.75754 12.2379 6.75754 12.8288 6.39306 13.1933C6.02857 13.5578 5.43761 13.5578 5.07312 13.1933L2.27312 10.3933C1.90863 10.0288 1.90863 9.43786 2.27312 9.07337L5.07312 6.27337C5.43761 5.90888 6.02857 5.90888 6.39306 6.27337Z"
        fill="url(#paint0_linear_318_1683)"
      />
      <path
        d="M20.0097 4.94845L19.2097 3.44836C19.1204 3.28081 18.8802 3.28081 18.7908 3.44836L17.9907 4.94846C17.9686 4.99001 17.9346 5.02403 17.893 5.04619L16.3929 5.84624C16.2254 5.9356 16.2254 6.17576 16.3929 6.26512L17.893 7.06517C17.9346 7.08733 17.9686 7.12135 17.9908 7.1629L18.7908 8.663C18.8802 8.83055 19.1204 8.83055 19.2097 8.663L20.0097 7.16291C20.0319 7.12135 20.0659 7.08733 20.1075 7.06517L21.6076 6.26512C21.7751 6.17576 21.7751 5.9356 21.6076 5.84624L20.1075 5.04619C20.0659 5.02403 20.0319 4.99001 20.0097 4.94845Z"
        fill="url(#paint1_linear_318_1683)"
      />
      <path
        d="M12.6732 3.26186L12.1399 2.2618C12.0803 2.1501 11.9202 2.1501 11.8606 2.26179L11.3272 3.26186C11.3125 3.28956 11.2898 3.31224 11.2621 3.32702L10.262 3.86039C10.1503 3.91996 10.1503 4.08006 10.262 4.13964L11.2621 4.673C11.2898 4.68778 11.3125 4.71046 11.3272 4.73816L11.8606 5.73823C11.9202 5.84993 12.0803 5.84992 12.1399 5.73822L12.6732 4.73816C12.688 4.71046 12.7107 4.68778 12.7384 4.673L13.7384 4.13964C13.8501 4.08006 13.8501 3.91996 13.7384 3.86039L12.7384 3.32702C12.7107 3.31225 12.688 3.28956 12.6732 3.26186Z"
        fill="url(#paint2_linear_318_1683)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_318_1683"
          x1="1.99976"
          y1="7.16666"
          x2="19.4679"
          y2="8.15169"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF166A" />
          <stop offset="1" stop-color="#FB5F08" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_318_1683"
          x1="16.0002"
          y1="3.55567"
          x2="22.2449"
          y2="3.84911"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF166A" />
          <stop offset="1" stop-color="#FB5F08" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_318_1683"
          x1="10.0002"
          y1="2.33333"
          x2="14.1634"
          y2="2.52897"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF166A" />
          <stop offset="1" stop-color="#FB5F08" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AiReplyIcon;

const TearFaceEmoji = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path d="M16.0112 29.5978C9.14578 29.5978 1.73901 25.2915 1.73901 15.8423C1.73901 6.39318 9.14578 2.08691 16.0112 2.08691C19.8253 2.08691 23.3441 3.34188 25.9525 5.63035C28.7823 8.14029 30.2834 11.6837 30.2834 15.8423C30.2834 20.001 28.7823 23.5198 25.9525 26.0297C23.3441 28.3182 19.8007 29.5978 16.0112 29.5978Z" fill="url(#paint0_radial_1584_28498)" />
            <path d="M27.7956 7.66504C29.1072 9.78126 29.7913 12.301 29.7913 15.1038C29.7913 19.2624 28.2902 22.7812 25.4604 25.2912C22.852 27.5797 19.3086 28.8592 15.5191 28.8592C11.075 28.8592 6.41442 27.0506 3.66333 23.2143C6.31107 27.56 11.2916 29.5974 16.0112 29.5974C19.8007 29.5974 23.3442 28.3179 25.9525 26.0294C28.7824 23.5195 30.2834 20.0006 30.2834 15.842C30.2834 12.7071 29.4295 9.92152 27.7956 7.66504Z" fill="#EB8F00" />
            <path d="M16.1097 19.9023C18.7673 19.9023 20.6374 21.2803 21.2034 22.1416C21.4199 22.4147 21.4667 22.7838 21.3264 23.1013C21.2182 23.3006 21.0115 23.4236 20.7851 23.4212C20.7014 23.4163 20.6177 23.399 20.539 23.372C19.0995 22.961 17.6083 22.7543 16.1097 22.7568C14.6185 22.7469 13.1322 22.9635 11.705 23.3966C11.6263 23.4236 11.5426 23.4409 11.4589 23.4458C11.2325 23.4532 11.0209 23.3301 10.9176 23.1259C10.7749 22.8085 10.8241 22.4369 11.0406 22.1662C11.582 21.2803 13.4767 19.9023 16.1097 19.9023Z" fill="#422B0D" />
            <path d="M9.46557 26.0791C7.8661 26.0791 6.63574 24.5289 6.63574 23.2001C6.63574 22.265 7.05406 21.1823 7.64464 19.6813C7.71846 19.4598 7.81689 19.2383 7.91532 18.9923C8.25982 18.1015 8.65353 17.2304 9.09646 16.3839C9.18505 16.2141 9.38683 16.1403 9.564 16.2117C9.63782 16.2486 9.69934 16.3101 9.73625 16.3839C10.1054 17.1467 10.5237 18.0818 10.942 19.0415C11.9755 21.3546 12.2708 22.2896 12.2708 23.2247C12.2954 24.5289 11.0404 26.0791 9.46557 26.0791Z" fill="url(#paint1_radial_1584_28498)" />
            <path d="M11.1882 24.3044C10.8265 24.863 10.0071 24.7572 10.0071 23.8295C10.0071 23.2365 10.1277 20.1901 10.637 20.6158C11.4737 21.3097 11.7099 23.5146 11.1882 24.3044Z" fill="#81D4FA" />
            <path d="M7.74313 11.856C7.43554 12.3112 6.70471 12.0184 6.91879 11.4672C7.42324 10.2688 8.36569 9.30912 9.55668 8.78744C9.99223 8.58074 10.4622 8.45033 10.9421 8.39865C11.1857 8.34944 11.4244 8.50692 11.4736 8.75053C11.5228 8.99415 11.3653 9.23284 11.1192 9.28205H11.0872C9.71417 9.73728 8.53548 10.6453 7.74313 11.856Z" fill="#422B0D" />
            <path d="M24.3013 11.856C24.6089 12.3112 25.3397 12.0184 25.1256 11.4672C24.6187 10.2663 23.6713 9.30666 22.4754 8.78744C22.0399 8.58074 21.5748 8.45033 21.0949 8.39865C20.8513 8.34944 20.6126 8.50692 20.5659 8.75053C20.5167 8.99415 20.6742 9.23284 20.9178 9.28205H20.9498C22.3253 9.73482 23.5089 10.6428 24.3013 11.856Z" fill="#422B0D" />
            <path d="M11.3531 11.915C10.3221 11.915 9.38452 12.7861 9.38452 14.233C9.38452 15.6799 10.3221 16.5486 11.3531 16.5486C12.3841 16.5486 13.3217 15.6775 13.3217 14.233C13.3217 12.7886 12.3841 11.915 11.3531 11.915Z" fill="#422B0D" />
            <path d="M11.2571 12.8824C10.9077 12.7151 10.4869 12.8628 10.3171 13.2122C10.1867 13.4853 10.2458 13.8126 10.4648 14.0242C10.8142 14.1915 11.235 14.0439 11.4048 13.6945C11.5352 13.4213 11.4761 13.0941 11.2571 12.8824Z" fill="#896024" />
            <path d="M20.785 11.915C19.7539 11.915 18.8164 12.7861 18.8164 14.233C18.8164 15.6799 19.7539 16.5486 20.785 16.5486C21.816 16.5486 22.7536 15.6775 22.7536 14.233C22.7536 12.7886 21.8209 11.915 20.785 11.915Z" fill="#422B0D" />
            <path d="M20.6939 12.8824C20.3445 12.7151 19.9237 12.8628 19.7539 13.2122C19.6235 13.4853 19.6825 13.8126 19.9015 14.0242C20.251 14.1915 20.6717 14.0439 20.8415 13.6945C20.9719 13.4213 20.9129 13.0941 20.6939 12.8824Z" fill="#896024" />
            <defs>
                <radialGradient id="paint0_radial_1584_28498" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.0112 15.8423) scale(14.0162)">
                    <stop offset="0.5" stop-color="#FDE030" />
                    <stop offset="0.92" stop-color="#F7C02B" />
                    <stop offset="1" stop-color="#F4A223" />
                </radialGradient>
                <radialGradient id="paint1_radial_1584_28498" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(9.66735 17.4403) scale(6.50122 10.0119)">
                    <stop offset="0.46" stop-color="#29B6F6" />
                    <stop offset="1" stop-color="#1E88E5" />
                </radialGradient>
            </defs>
        </svg>
    )
}

export default TearFaceEmoji;
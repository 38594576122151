import styled from "styled-components";
import { SaveFlowModalProps } from "./saveFlowModal";

const SaveFlowModalStyle = styled.div<SaveFlowModalProps>`
  width: auto;
  max-width: 720px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  .success__modal {
    position: relative;
    width: 352px;
    max-height: 280px;
    .cross__icon {
      position: absolute;
      right: 0;
    }
    .green__tick {
      display: flex;
      justify-content: center;
      background: ${(props) => (props.error ? "#FFEBEF" : "#1A872C")};
      border-radius: 40px;
      width: 64px;
      height: 64px;
      margin: 0px auto;
      align-items: center;
    }
    .title {
      text-align: center;
      padding: 24px 0 8px 0;
    }
    .description {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;

      color: #616161;
    }
    .button__bar {
      padding-top: 24px;
      display: flex;
      flex: 1 1 0;
      gap: 12px;
    }
  }
  .check__error {
    width: 352px;
    height: 160px;
    padding: 20px 60px;
    .loader {
      border: 4px solid #f3f3f3;
      border-radius: 50%;
      border-top: 4px solid #731dcf;
      max-width: 40px;
      height: 40px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
      margin: auto;
    }

    .error {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #212121;
      padding: 28px 0 8px 0;
    }

    .wait {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;

      color: #616161;
    }

    /* Safari */
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .body {
    background: #f0f0f0;
    border-left: 4px solid #616161;
    border-radius: 4px;
    width: 412px;
    padding: 8px 16px;
    margin-right: 40px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #616161;
  }

  .heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 24px;
  }
  .title {
    flex: 1;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #212121;
  }
  .footer {
    display: flex;
    flex-direction: row-reverse;
    gap: 20px;
    padding-top: 24px;
    align-items: center;
    .save__button {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
    }
  }
  .discard-text {
    text-align: right;
  }
`;

export default SaveFlowModalStyle;

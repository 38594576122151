import {retryFromNode} from "../../services/helpers/AnalyticsHelper";
import {useAppSelector} from "../../state/store";

const useRetryHook = () => {
    const homeState = useAppSelector ((state) => state.homeState);
    const flowState = useAppSelector ((state) => state.flowMetaState);

    const retry = async (nodeData: any) => {
        const conditions: { [key: string]: string } = {};
        nodeData?.childNodes?.forEach((item: any) => {
            conditions[item?.buttonId] = item?.name;
        });
        let payload = {
            "storeId": homeState.storeId,
            "flowId": homeState.flowId,
            "startDate": homeState.analyticsData?.startDate,
            "endDate": homeState.analyticsData?.endDate,
            "channel": homeState.channel,
            "nodeId": nodeData.nodeId,
            "nodeSubType": nodeData.subType,
            "nodeType": nodeData.type,
            "automationsType": null
        }
        // @ts-ignore
        if (flowState && flowState.flowMeta && flowState.flowMeta[homeState.flowId]){
            // @ts-ignore
            const triggers = flowState.flowMeta[homeState.flowId].triggers;
            if (triggers && triggers.length) {
                payload.automationsType = triggers[0].header
            }
        }
        return await retryFromNode(payload)
    }

    return {retry};
}

export default useRetryHook;

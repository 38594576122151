const HeartEyesEmoji = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path d="M16.0112 29.5978C9.14578 29.5978 1.73901 25.2915 1.73901 15.8423C1.73901 6.39318 9.14578 2.08691 16.0112 2.08691C19.8253 2.08691 23.3441 3.34188 25.9525 5.63035C28.7823 8.14029 30.2834 11.6837 30.2834 15.8423C30.2834 20.001 28.7823 23.5198 25.9525 26.0297C23.3441 28.3182 19.8007 29.5978 16.0112 29.5978Z" fill="url(#paint0_radial_1584_28477)" />
            <path d="M27.7956 7.66504C29.1072 9.78126 29.7913 12.301 29.7913 15.1038C29.7913 19.2624 28.2902 22.7812 25.4604 25.2912C22.852 27.5797 19.3086 28.8592 15.5191 28.8592C11.075 28.8592 6.41442 27.0506 3.66333 23.2143C6.31107 27.56 11.2916 29.5974 16.0112 29.5974C19.8007 29.5974 23.3442 28.3179 25.9525 26.0294C28.7824 23.5195 30.2834 20.0006 30.2834 15.842C30.2834 12.7071 29.4295 9.92152 27.7956 7.66504Z" fill="#EB8F00" />
            <path d="M16.2081 20.8618C15.3272 20.8618 14.4537 20.7142 13.6244 20.4189C12.6032 20.067 11.4934 20.6108 11.1415 21.6295C10.9422 22.2054 11.0234 22.8402 11.3605 23.3471C12.4186 24.9712 14.0919 25.8571 16.2081 25.8571C18.3244 25.8571 19.9977 24.9712 21.0558 23.3471C21.6537 22.449 21.4077 21.2358 20.5095 20.6379C20.0026 20.3008 19.3677 20.2196 18.7919 20.4189C17.9626 20.7142 17.0891 20.8618 16.2081 20.8618Z" fill="#422B0D" />
            <path opacity="0.8" d="M8.77672 22.3137C11.155 22.3137 13.083 20.4959 13.083 18.2535C13.083 16.0112 11.155 14.1934 8.77672 14.1934C6.39844 14.1934 4.47046 16.0112 4.47046 18.2535C4.47046 20.4959 6.39844 22.3137 8.77672 22.3137Z" fill="url(#paint1_radial_1584_28477)" />
            <path opacity="0.8" d="M23.6395 22.3137C26.0178 22.3137 27.9458 20.4959 27.9458 18.2535C27.9458 16.0112 26.0178 14.1934 23.6395 14.1934C21.2612 14.1934 19.3333 16.0112 19.3333 18.2535C19.3333 20.4959 21.2612 22.3137 23.6395 22.3137Z" fill="url(#paint2_radial_1584_28477)" />
            <path d="M26.8359 8.82236C24.8156 8.25394 23.3318 9.90508 23.3318 9.90508C23.3318 9.90508 23.1595 8.03247 21.4321 7.44436C19.3602 6.73321 17.1726 7.86514 17.0422 10.8008C16.8945 14.1449 21.3189 18.5348 21.3189 18.5348C21.3189 18.5348 26.8112 17.0584 28.61 13.9284C30.0742 11.3815 28.364 9.25053 26.8359 8.82236Z" fill="#F44336" />
            <path d="M26.836 8.82204C26.5874 8.7556 26.3315 8.72607 26.0756 8.731C26.7991 9.21576 27.3724 9.89245 27.7292 10.6848C28.0762 11.519 28.1672 12.5968 27.5078 13.8837C26.0781 16.6816 21.6759 18.4139 21.356 18.5345C21.7571 18.4213 26.8901 16.9449 28.62 13.9354C30.0743 11.3812 28.3641 9.25021 26.836 8.82204Z" fill="#C62828" />
            <path d="M23.3293 10.2074C23.3146 9.95144 23.2752 9.69553 23.2162 9.44453C23.0734 8.72108 22.6256 8.0936 21.9858 7.72203C21.5478 7.46611 21.063 7.30371 20.561 7.24219C20.561 7.24219 21.2993 7.46611 21.8258 8.48731C22.0941 9.02375 22.254 9.6094 22.2934 10.2074C22.2909 10.4264 22.3303 10.6404 22.409 10.8447C22.4976 11.0489 22.7142 11.1695 22.9356 11.135C23.2727 11.0489 23.3466 10.6281 23.3293 10.2074Z" fill="#C62828" />
            <path d="M18.5458 9.03345C19.0059 8.46256 19.7614 7.91874 20.4848 8.42073C20.8687 8.68895 20.8736 9.44931 20.4036 9.79135C19.6112 10.3721 19.5743 10.7535 19.4858 11.0734C19.3775 11.4548 19.3086 11.91 18.9616 12.102C18.6147 12.2939 18.3095 12.102 18.1274 11.5532C17.8174 10.6969 17.9749 9.74214 18.5458 9.03345Z" fill="#FF847A" />
            <path d="M10.3811 7.39481C8.38547 8.04198 8.06558 10.2394 8.06558 10.2394C8.06558 10.2394 6.88443 8.76297 5.11271 9.24035C2.99895 9.79647 1.80551 11.9521 3.31393 14.4645C5.06104 17.3435 11.1735 18.537 11.1735 18.537C11.1735 18.537 14.9261 14.2651 14.6898 10.6626C14.4954 7.72947 11.8895 6.90513 10.3811 7.39481Z" fill="#F44336" />
            <path d="M10.3812 7.39482C10.1375 7.47357 9.90378 7.58676 9.6897 7.72948C10.5608 7.73194 11.4122 7.97801 12.1504 8.44063C12.9034 8.93277 13.5752 9.7891 13.7425 11.2286C14.1042 14.3513 11.3974 18.2318 11.1883 18.5148C11.4614 18.1998 14.9138 14.1249 14.6874 10.6602C14.4955 7.72948 11.8896 6.90514 10.3812 7.39482Z" fill="#C62828" />
            <path d="M8.23525 10.4981C8.08268 10.2889 7.91043 10.097 7.7185 9.92472C7.20175 9.39812 6.48568 9.1176 5.74992 9.15697C5.24547 9.1865 4.75087 9.31692 4.2981 9.54577C4.2981 9.54577 5.03631 9.32184 6.04029 9.88288C6.56442 10.1856 7.01965 10.5916 7.38384 11.0739C7.50195 11.2584 7.65452 11.4159 7.83415 11.5414C8.02117 11.662 8.26724 11.6398 8.43211 11.4897C8.65111 11.224 8.48132 10.8352 8.23525 10.4981Z" fill="#C62828" />
            <path d="M3.60926 12.1755C3.61911 11.9688 3.65602 11.7646 3.71754 11.5677C3.81843 11.1543 4.11125 10.8123 4.50251 10.6474C4.879 10.5293 5.40067 10.613 5.60245 10.9895C5.78208 11.3241 5.65166 11.7277 5.55323 12.0968C5.46465 12.52 5.41543 12.9531 5.40559 13.3862C5.40805 13.7406 5.12507 14.0285 4.77072 14.0334C4.43607 14.0334 4.17523 13.743 3.98822 13.465C3.72738 13.086 3.5945 12.6357 3.60926 12.1755Z" fill="#FF847A" />
            <defs>
                <radialGradient id="paint0_radial_1584_28477" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.0112 15.8423) scale(14.0162)">
                    <stop offset="0.5" stop-color="#FDE030" />
                    <stop offset="0.92" stop-color="#F7C02B" />
                    <stop offset="1" stop-color="#F4A223" />
                </radialGradient>
                <radialGradient id="paint1_radial_1584_28477" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(8.77672 16.386) scale(4.69306 4.21417)">
                    <stop stop-color="#ED7770" />
                    <stop offset="0.9" stop-color="#ED7770" stop-opacity="0" />
                </radialGradient>
                <radialGradient id="paint2_radial_1584_28477" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30.6575 622.333) scale(4.78856)">
                    <stop stop-color="#ED7770" />
                    <stop offset="0.9" stop-color="#ED7770" stop-opacity="0" />
                </radialGradient>
            </defs>
        </svg>
    )
}

export default HeartEyesEmoji;
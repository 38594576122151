import { useEffect, useState } from "react";
import { RunsService } from "./RunsService";
import { DebugRuns, DebugRunSetup } from "./RunsModel";
import { useAppSelector } from "../../../state/store";
import { useDispatch } from "react-redux";
import { debugRunDataAction } from "../../../state/debugRunState";

export interface DebugNodeInfo {
    nodeExecuted: boolean,
    nodeRuns: number,
    totalRuns: number,
}

export const useDebugRuns = () => {
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const debugRunState = useAppSelector(
        (state) => state.debugRunState
    );
    const dispatcher = useDispatch();
    const [isDebugAnalyticsSetup, setIsDebugAnalyticsSetup] = useState<boolean>(false);

    useEffect(() => {
        setIsDebugAnalyticsSetup(!!debugRunState.debugRunStep)
    }, [debugRunState.debugRunStep])

    const setUpWithRunsLoaded = (setUpConfig: DebugRunSetup, runs: DebugRuns | undefined) => {
        console.log('addDebugRunState : inside eventually for ', setUpConfig, runs);
        if (!runs) {
            dispatcher(debugRunDataAction.addDebugRunState(undefined));
            return
        }

        const {
            sessionId,
            flowId,
            showCompleteAnalytics
        } = setUpConfig;

        if (showCompleteAnalytics) {
            fetchAnalytics(runs, flowId);
            dispatcher(debugRunDataAction.addDebugRunState(setUpConfig));
        } else if (sessionId) {
            fetchRunsForSession(runs, sessionId);
            dispatcher(debugRunDataAction.addDebugRunState(setUpConfig));
        } else {
            dispatcher(debugRunDataAction.addDebugRunState(undefined));
        }
    }

    const setUp = (setUpConfig: DebugRunSetup) => {
        const {
            storeId,
            phoneNumber,
        } = setUpConfig;

        if (!storeId) {
            return;
        }

        if (debugRunState.runs?.runs?.length) {
            if (debugRunState?.storeId === storeId || debugRunState?.phoneNumber === phoneNumber) {
                setUpWithRunsLoaded(setUpConfig, debugRunState.runs);
                return;
            }
        }

        const runsService = new RunsService();
        setIsSearching(true);
        runsService.fetchData(storeId, phoneNumber).then((runData) => {
            setIsSearching(false);
            dispatcher(
                debugRunDataAction.addRuns({
                    runs: runData,
                    storeId: storeId,
                    phoneNumber: phoneNumber
                })
            );
            setUpWithRunsLoaded(setUpConfig, runData);
        })
    }

    const fetchRunsForSession = (runs: DebugRuns, sessionId: string) => {
        const currentRun = runs.runs.find((run) => run.sessionId === sessionId);
        dispatcher(
            debugRunDataAction.addRun(currentRun!)
        );
        return currentRun;
    }

    const fetchAnalytics = (runs: DebugRuns, flowId: string | undefined) => {
        if (!flowId) {
            return;
        }
        const runsService = new RunsService();
        const analytics = runsService.getDebugAnalayticsForFlow(flowId, runs);
        dispatcher(debugRunDataAction.addDebugAnalytics(analytics));
    }


    const hasRunForNode = (nodeId: string) => {
        const currentRun = debugRunState.currentRun;
        if (!currentRun) {
            return false;
        }
        return !!currentRun!.nodes.find((node) => node.nodeId === nodeId);
    }

    const analyticsForNode = (nodeId: string): DebugNodeInfo => {
        const nodeRuns = debugRunState.analytics?.analytics[nodeId] || 0;
        const totalRuns = debugRunState.analytics?.totalRuns || 0;
        return {
            nodeExecuted: hasRunForNode(nodeId),
            nodeRuns,
            totalRuns
        }
    }

    const setUpFromParams = (storeId: string, flowId: string, searchParams: URLSearchParams): DebugRunSetup | undefined => {
        const showAnalytics = searchParams.get('analytics');
        if (!showAnalytics) {
           return undefined
        }

        const isCompleteAnalytics = searchParams.get('complete');
        if (isCompleteAnalytics) {
            return {
                storeId: storeId,
                flowId: flowId,
                showCompleteAnalytics: true
            }
        }

        const debugPayload = {
            phoneNumber: searchParams.get('phoneNumber') || undefined,
            storeId: storeId,
            flowId: flowId,
            sessionId: searchParams.get('sessionId') || undefined,
            showCompleteAnalytics: false
        }
        if (debugPayload.sessionId) {
            return debugPayload;
        }
    }

    const analyticsType = (): 'node' | 'complete' => {
        if (debugRunState.debugRunStep?.showCompleteAnalytics) {
            return 'complete';
        }
        return 'node';
    }

    return {
        runs: debugRunState?.runs,
        fetchRunsForSession,
        setUp,
        isSearching,
        analyticsForNode,
        setUpFromParams,
        isDebugAnalyticsSetup,
        analyticsType
    }
}

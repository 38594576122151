import { StyledModal, TitleMedium, TitleRegular, BodyPrimary, ButtonGroup, CheckBox, BodySecondary, COLORS, Button, BikShimmer } from "@bikdotai/bik-component-library"
import HeartIcon from "../../icons/heartIcon"
import { Container, Header, Body, ModalTitle, CommentAction, ActionStrip, InlineText, VerticalSeparator, KeywordContainer, BtnGrpSelector, ExcludeStrip, CheckBoxBar, HeartReaction, Footer } from "./style"
import { ActionEventsList } from "./models"
import { useEffect, useRef, useState } from "react"
import { generateUniqueId } from "../../app/action-block/Util"
import InstaIdsDropdown from "./instaDropdown"
import ErrScreen from "./errScreen"
import { v4 as uuidv4 } from 'uuid';


const Mentions = (props: {
    instaId: string;
    storeId: string;
    navigateBack: React.Dispatch<React.SetStateAction<ActionEventsList | "default">>;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    fetchedConf: any;
    onSave: (data: any) => void;
}) => {
    const { fetchedConf } = props;
    const [heart, setHeart] = useState<boolean>(false)
    const [selectedInsta, setSelectedInsta] = useState<{
        label: string,
        value: string,
        selected: boolean
    }>();
    const prevSelectedInsta = useRef<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [showErrorScreen, setShowErrorScreen] = useState<boolean>(false)


    useEffect(() => {
        if (Object.keys(fetchedConf).length) {
            setHeart(fetchedConf.heartReaction)
        }
    }, [])


    useEffect(() => {

        if (selectedInsta?.value) {
            setIsLoading(false)
        }

        if (selectedInsta?.value === 'ERROR') {
            setShowErrorScreen(true)
            return
        }

        if (!prevSelectedInsta.current || !selectedInsta?.value) {
            prevSelectedInsta.current = selectedInsta?.value || ''
            return
        }

        if (prevSelectedInsta.current && prevSelectedInsta.current !== selectedInsta.value) {
            setHeart(false)
            prevSelectedInsta.current = selectedInsta.value
        }

    }, [selectedInsta])

    const generateConfig = () => {
        const eventProperties = [
            {
                condition: "in",
                firstOperand: "anyStory",
                secondOperand: []
            }
        ]
        const eventConnector = "AND"
        const eventSelected = "Mentions"
        const trigger = {
            eventProperties,
            eventSelected,
            eventConnector
        }
        const mediaType = ""
        const media: [] = []

        const header = 'Mentioned in a story'
        const subHeader = ``
        const id = fetchedConf?.id ? fetchedConf.id : uuidv4();
        const type = 'ig'
        const tag = 'Any Story'
        const instaId = selectedInsta?.value || ''
        const operator = 'AND'

        props.onSave({
            trigger,
            mediaType,
            media,
            header,
            subHeader,
            id,
            type,
            tag,
            instaId,
            operator,
            commentReplies: [],
            heartReaction: heart
        })
        props.setShowModal(false)
    }

    return (
        <StyledModal open={true} zIndex={200} centralContainerStyles={{ width: "1032px", height: "560px" }} onClose={() => props.setShowModal(false)}>
            <Container>
                <Header>
                    <TitleMedium>{'Mentioned in story'}</TitleMedium>
                    <InstaIdsDropdown storeId={props.storeId} instaId={props.instaId} setSelectedInsta={setSelectedInsta} />
                </Header>

                {
                    showErrorScreen ? (<Body>
                        <div style={{
                            minHeight: '90%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <ErrScreen
                                body={'Could not find instagram account'}
                                title="No account found"
                            />
                        </div>
                    </Body>) : isLoading ? <Body>
                        <BikShimmer boxes={
                            [{ width: "968px", height: "350px" }]
                        } />
                    </Body> :
                        <Body>
                            <ModalTitle>
                                <TitleRegular>Journey will be triggered...</TitleRegular>
                            </ModalTitle>
                            <CommentAction>
                                <ActionStrip>
                                    <InlineText>
                                        <BodyPrimary>
                                            {'Whenever someone mentions you in a story'}
                                        </BodyPrimary>
                                    </InlineText>
                                </ActionStrip>
                            </CommentAction>

                            <HeartReaction>
                                <CheckBox isChecked={heart} onValueChange={function (checked: boolean, value?: string | undefined): void {
                                    checked ? setHeart(true) : setHeart(false)
                                }} />
                                <InlineText>
                                    <BodySecondary>React with</BodySecondary>
                                    <HeartIcon />
                                    <BodySecondary>in response</BodySecondary>
                                </InlineText>
                            </HeartReaction>

                        </Body>
                }


                <Footer>
                    <div></div>
                    <Button buttonText="Create" size={'medium'} onClick={() => {
                        generateConfig()
                    }} />
                </Footer>

            </Container>
        </StyledModal>
    )
}

export default Mentions;
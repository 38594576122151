import { FunctionComponent, useEffect, useRef, useState } from "react";
import { SuperAdminAccess } from "./SuperAdminAccess";
import Modal from "../../ui-components/modal";
import {
  NewFlowModal,
  PodEnableModal,
  PublishModal,
  TriggerModal,
  UploadFile,
} from "./modals";
import BotPopup, { PopUpOptions } from "../../ui-components/popup";
import { FilePreviewStyle, NoFlow, TableStyle } from "./EntryPoint.style";
import TagManager from "./tag-manager";
import OptionsIcon from "../../icons/options";
import PlusIcon from "../../icons/Plus";
import { homeDataAction } from "../../state/homeState";
import { useDispatch } from "react-redux";
import BotDropDown, { DATA_SET } from "../../ui-components/dropdown";
import DropdownIcon from "../../icons/dropdownIcon";
import { useNavigate } from "react-router-dom";
import { flowMetaStateActions } from "../../state/flowMetaState";
import DeleteIcon from "../../icons/DeleteIcon";
import { SlackNotification } from "../../utilities/slackNotification";
import { useAppSelector } from "../../state/store";
import { channelTypeList } from "./Constants";
import { userDataState } from "../../state/storeDataStateType";
import {
  createNewFlow,
  updateKeywordCache,
} from "../../services/helpers/StoreHelper";
import { notificationDataStateActions } from "../../state/notificationDataState";
import { useFirebaseHelperHook } from "../../utilities/firebaseHelperHook";
import { Button } from "@bikdotai/bik-component-library";
import { getLast7DaysStartAndEndDate } from "../../utilities/dateUtils";

interface TableProps {
  items: { [key: string]: any };
  emitFlowName: (flowName: string) => void;
  emitUpdatedFlowList?: (flowList: { [key: string]: any }) => void;
}

const TemplateTable: FunctionComponent<TableProps> = (props) => {
  const [showPopover, setShowPopover] = useState<string>("");
  const [itemList, setItemList] = useState<{ [key: string]: any }>(props.items);
  const [currentId, setCurrentId] = useState<any>();
  const [showModal, setShowModal] = useState<boolean>();
  const [type, setType] = useState<string>("");
  const [errors, setErrors] = useState<{ [name: string]: string }>({});
  const [showNewFlowModal, setShowNewFlowModal] = useState(false);
  const [showEditFlowModal, setShowEditFlowModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [flowLoading, setFlowLoading] = useState<boolean>(false);
  const [selectedFlows, setSelectedFlows] = useState<{
    [key: string]: boolean;
  }>({});
  const [currentSelectedFlows, setCurrentSelectedFlows] = useState<string[]>(
    []
  );
  const [flowIdExistingMap, setFlowIdExistingMap] = useState<{
    [key: string]: string;
  }>({});
  const storeId = useAppSelector((state) => state.homeState?.storeId);
  const superAdminFns = new SuperAdminAccess();
  let flowData = props.items;
  const navigate = useNavigate();
  const dispatcher = useDispatch();
  const [keywordsList, setKeywordsList] = useState({} as any);
  const slackNotification = new SlackNotification();

  const { getFirebaseObject } = useFirebaseHelperHook();
  const firebaseService = getFirebaseObject();

  useEffect(() => {
    const sortedKey = Object.keys(props.items).sort();
    const res: any = {};
    sortedKey.forEach((key) => {
      res[key] = props.items[key];
    });
    const existingFLows = { ...flowIdExistingMap };
    let tempKeywordsList = { ...keywordsList } as any;
    Object.keys(res).forEach((key) => {
      let flowData = res[key];
      tempKeywordsList[key] = flowData?.keywordsNew
        ? []
            .concat(flowData?.keywordsNew?.EXACT || [])
            .concat(flowData?.keywordsNew?.BEGINS_WITH || [])
            .concat(flowData?.keywordsNew?.CONTAINS || [])
            .concat(flowData?.keywordsNew?.INCLUDES || [])
        : flowData?.keywords || [];
      existingFLows[key] = "existing";
      setFlowIdExistingMap(existingFLows);
      selectedFlows[key] = false;
    });
    setKeywordsList({ ...tempKeywordsList } as any);
    setItemList(res);
  }, [props.items, showModal]);

  const onClickRename = async (val: string) => {
    itemList[currentId].flowName = val;
    await superAdminFns.saveFlowMeta(storeId, currentId, itemList);
    setModalTypeAndValue();
  };

  const onClickSaveTrigger = async (val: string) => {
    itemList[currentId].trigger = val;
    await superAdminFns.saveFlowMeta(storeId, currentId, itemList);
    setModalTypeAndValue();
  };

  const onClickSaveEnablements = async (val: string[]) => {
    itemList[currentId].enabledPods = val;
    await superAdminFns.saveFlowMeta(storeId, currentId, itemList);
    setModalTypeAndValue();
  };

  const onClickUpdateKeywordsList = async (keywordsNewObj: string[]) => {
    itemList[currentId].keywordsNew = keywordsNewObj;
    itemList[currentId].keywords = [];
    await superAdminFns.saveFlowMeta(storeId, currentId, itemList);
    setModalTypeAndValue();
  };

  const onClickHandle = (id: string, hidePopover = false) => {
    setCurrentId(id);
    if (hidePopover) {
      return;
    }
    if (showPopover === id) {
      setShowPopover("");
    } else {
      setShowPopover(id);
    }
  };

  const onClickDelete = async () => {
    const flowData = await superAdminFns.deleteFlows(storeId, currentId);
    setItemList(flowData);
    props?.emitUpdatedFlowList?.(flowData);
    setModalTypeAndValue();
    await firebaseService.deleteFlowsById(storeId, [currentId]);
    await updateKeywordCache(storeId, {}, true);
  };

  const onClickEnable = async () => {
    if (!itemList[currentId].status) {
      // const response = await firebaseService.canPublishFlow(storeId, currentId);
      // if (response && response.status === 200) {
      //     if (!response.data.can_publish_flow) {
      //         dispatcher (notificationDataStateActions.setNotifications({
      //             successMessage: '',
      //             errorMessage: response.data.reason
      //         }));
      //         return;
      //     }
      // }
    }
    itemList[currentId].status = !itemList[currentId].status;
    itemList[currentId].isComingSoon = false;
    await superAdminFns.saveFlowMeta(storeId, currentId, itemList);
  };

  const markComingSoon = async () => {
    itemList[currentId].isComingSoon = true;
    await superAdminFns.saveFlowMeta(storeId, currentId, itemList);
  };

  const onTypeChange = async (
    name: string,
    type: DATA_SET,
    currentId: string,
    fieldName: string
  ) => {
    const newItems = { ...itemList };
    newItems[currentId][fieldName] = type.header;
    await superAdminFns.saveFlowMeta(storeId, currentId, newItems);
    setItemList(newItems);
    props?.emitUpdatedFlowList?.(newItems);
    dispatcher(
      flowMetaStateActions.setFlowMeta({
        flowMeta: newItems,
        updateFlowMeta: true,
      })
    );
  };

  const setModalTypeAndValue = (val = "") => {
    setShowModal(!showModal);
    setType(val);
  };

  const createFlow = () => {
    if (!storeId) {
      setErrors({
        ...errors,
        ["value1"]: "Is Required",
      });
      setItemList({});
      return;
    } else {
      setErrors({});
    }
    setShowNewFlowModal(true);
  };

  const redirectToFlow = (
    flowId: string,
    flowName: string,
    flowData: { [x: string]: any } | undefined
  ) => {
    const clickedFlow = flowData || {};
    const channel = clickedFlow.channel?.toLowerCase();
    const journeyType = clickedFlow.journeyType || "utility";

    const { startDate, endDate } = getLast7DaysStartAndEndDate();

    dispatcher(
      homeDataAction.addState({
        flowName: flowName,
        storeId: storeId,
        flowId: flowId,
        type: type,
        channel: channel,
        userData: {} as userDataState,
        journeyType,
        analyticsData: {
          startDate,
          endDate,
        },
      })
    );
    if (channel) {
      navigate(`/${storeId}/${flowId}/${channel}/analytics`);
    } else navigate("/404");
  };

  const addFlow = async (
    flowName: string,
    description: string,
    channel: string,
    journeyType: string,
    themes: string[],
    subThemes: string[],
    otherCategories: string[]
  ) => {
    setFlowLoading(true);
    const dndStatus = journeyType === "marketing" ? true : false;
    const flowData: any = {
      flowName: flowName,
      description: description,
      categoryTheme: themes,
      categorySubTheme: subThemes,
      categoryType: otherCategories,
      channel: channel,
      journeyType: journeyType,
      dndEnabled: dndStatus,
    };
    const resp = await createNewFlow(storeId, flowData);
    setFlowLoading(false);
    if (!resp) return;
    const flowId = resp.flow_id;
    flowIdExistingMap[flowId] = "new";
    redirectToFlow(flowId, flowName, flowData);
    setShowNewFlowModal(false);
    props.emitFlowName(flowName);
    dispatcher(
      homeDataAction.addState({
        storeId: storeId,
        flowName: flowName,
        flowId: flowId,
        type: type,
        channel: channel,
        userData: {} as userDataState,
        journeyType: journeyType as "marketing" | "utility",
      })
    );
  };

  const onClickEdit = async (
    flowName: string,
    description: string,
    channel: string,
    journeyType: string,
    themes: string[],
    subThemes: string[],
    otherCategories: string[]
  ) => {
    const dndStatus = journeyType === "marketing" ? true : false;
    itemList[currentId].flowName = flowName;
    itemList[currentId].description = description;
    itemList[currentId].journeyType = journeyType;
    itemList[currentId].categoryTheme = themes;
    itemList[currentId].categorySubTheme = subThemes;
    itemList[currentId].categoryType = otherCategories;
    itemList[currentId].dndEnabled = dndStatus;
    await superAdminFns.saveFlowMeta(storeId, currentId, itemList);
    setShowEditFlowModal(false);
  };

  const selectFlows = (flowId: string) => {
    let selectedFlowsUpdated = { ...selectedFlows };
    selectedFlowsUpdated[flowId] = !selectedFlowsUpdated[flowId] || false;
    setSelectedFlows(selectedFlowsUpdated);
    const selectedFlowIds = Object.keys(selectedFlowsUpdated).filter(
      (flowId) => {
        return selectedFlowsUpdated[flowId];
      }
    );
    setCurrentSelectedFlows(selectedFlowIds);
  };

  const clickPublish = () => {
    const selectedFlowIds = Object.keys(selectedFlows).filter((flowId) => {
      return selectedFlows[flowId];
    });
    if (selectedFlowIds.length === 0) return;
    setShowPublishModal(true);
  };

  const onPublishToStores = async (storeIds: string[]) => {
    setLoading(true);
    const errorString = await superAdminFns.duplicateToStores(
      storeIds,
      currentSelectedFlows,
      storeId
    );
    if (errorString) {
      dispatcher(
        notificationDataStateActions.setNotifications({
          successMessage: "",
          errorMessage: errorString,
        })
      );
      setLoading(false);
      return;
    }
    dispatcher(
      notificationDataStateActions.setNotifications({
        successMessage: "Copied successfully",
        errorMessage: "",
      })
    );
    setLoading(false);
    setShowPublishModal(false);
  };

  const onFileUpload = async (fileMetaData: any) => {
    itemList[currentId].mediaName = fileMetaData.mediaName;
    itemList[currentId].mediaType = fileMetaData.mediaType;
    itemList[currentId].mediaSize = fileMetaData.mediaSize;
    itemList[currentId].mediaUrl = fileMetaData.mediaUrl;
    await superAdminFns.saveFlowMeta(storeId, currentId, itemList);
    setItemList(flowData);
    props?.emitUpdatedFlowList?.(flowData);
    setModalTypeAndValue();
  };

  const modalSelected = () => {
    switch (type) {
      // case "DELETE":
      //   return (
      //     <Modal>
      //       <DeleteModal
      //         flowName={itemList[currentId].flowName}
      //         onClickDelete={onClickDelete}
      //         onClickCancel={setModalTypeAndValue}
      //       />
      //     </Modal>
      //   );
      // case "RENAME":
      //   return (
      //     <Modal>
      //       <RenameModal
      //         onClickCancel={setModalTypeAndValue}
      //         onClickSave={onClickRename}
      //       />
      //     </Modal>
      //   );
      // case "KEYWORDS":
      //   return (
      //     <Modal>
      //       <KeywordsModal
      //         keywordsNewArray={
      //           itemList[currentId]?.keywordsNew || emptyKeywordsNewObject
      //         }
      //         onClickCancel={setModalTypeAndValue}
      //         onClickSave={onClickUpdateKeywordsList}
      //       />
      //     </Modal>
      //   );

      case "TRIGGER":
        return (
          <Modal>
            <TriggerModal
              onClickCancel={setModalTypeAndValue}
              onClickSave={onClickSaveTrigger}
            />
          </Modal>
        );

      case "UPLOAD":
        return (
          <Modal>
            <UploadFile
              flowId={currentId}
              onClickCancel={setModalTypeAndValue}
              onFileUpload={onFileUpload}
            />
          </Modal>
        );

      case "ENABLEPODS":
        return (
          <Modal>
            <PodEnableModal
              onClickCancel={setModalTypeAndValue}
              enabledPods={itemList[currentId]?.enabledPods}
              onClickSave={onClickSaveEnablements}
            />
          </Modal>
        );
    }
  };

  const actionsList: PopUpOptions[] = [
    {
      eventName: "Edit",
      eventHandler: () =>
        redirectToFlow(
          currentId,
          itemList?.[currentId]?.flowName,
          itemList?.[currentId]
        ),
    },
    // {
    //   eventName: "Add Keywords",
    //   eventHandler: () => setModalTypeAndValue("KEYWORDS"),
    // },
    // {
    //   eventName: "Delete",
    //   eventHandler: () => setModalTypeAndValue("DELETE"),
    // },
    // {
    //   eventName: "Rename",
    //   eventHandler: () => setModalTypeAndValue("RENAME"),
    // },
  ];

  const filterFileName = (fileName: string) => {
    let name = fileName;
    try {
      const n = name.split(".")[0];
      const extension = name.split(".").pop();
      name = name.substring(0, 15) + "..." + n[n.length - 1] + "." + extension;
      return name;
    } catch (error: any) {
      return fileName;
    }
  };

  const deleteMedia = async (flowId: string) => {
    await superAdminFns
      .deleteMedia({ ...itemList }, storeId, flowId)
      .catch((error: any) => {
        slackNotification.reportError("Delete Media Error: ", error?.message);
        return;
      });
    delete itemList[flowId].mediaName;
    delete itemList[flowId].mediaSize;
    delete itemList[flowId].mediaType;
    delete itemList[flowId].mediaUrl;
    setItemList(itemList);
    props?.emitUpdatedFlowList?.(itemList);
    dispatcher(
      flowMetaStateActions.setFlowMeta({
        flowMeta: itemList,
        updateFlowMeta: true,
      })
    );
  };

  return (
    <>
      {showNewFlowModal && (
        <Modal>
          <NewFlowModal
            loading={flowLoading}
            addFlow={addFlow}
            cancel={() => setShowNewFlowModal(false)}
            isNewFlow={true}
            storeId={storeId}
          />
        </Modal>
      )}
      {showEditFlowModal && (
        <Modal>
          <NewFlowModal
            loading={false}
            addFlow={onClickEdit}
            cancel={() => setShowEditFlowModal(false)}
            flowName={itemList[currentId].flowName}
            description={itemList[currentId].description}
            journeyType={itemList[currentId].journeyType}
            storeId={storeId}
            themes={itemList[currentId].categoryTheme ?? []}
            otherCategories={itemList[currentId].categoryType ?? []}
            subThemes={itemList[currentId].categorySubTheme ?? []}
          />
        </Modal>
      )}
      {showPublishModal && (
        <Modal>
          <PublishModal
            flowIds={currentSelectedFlows}
            storeIdsArray={[]}
            onClickCancel={() => setShowPublishModal(false)}
            onClickPublish={onPublishToStores}
            loading={loading}
          />
        </Modal>
      )}
      <div className="right__header">
        <div className="header__input"></div>
        <div className="header__buttons">
          {currentSelectedFlows.length > 0 && (
            <Button
              buttonText="Publish to Store"
              buttonType="secondary"
              onClick={clickPublish}
            />
          )}
          <Button
            buttonText="Create new Flow"
            buttonType="secondary"
            onClick={createFlow}
            LeadingIcon={PlusIcon}
          />
        </div>
      </div>
      {itemList && !!Object.keys(itemList).length ? (
        <TableStyle>
          {showModal && modalSelected()}
          <div className="table-wrapper">
            <table className="flow-table">
              <colgroup>
                <col style={{ minWidth: "48px" }} />
                <col style={{ minWidth: "270px", maxWidth: "270px" }} />
                <col style={{ minWidth: "124px" }} />
                <col style={{ minWidth: "232px" }} />
                <col style={{ minWidth: "124px" }} />
                <col style={{ minWidth: "176px" }} />
                <col style={{ minWidth: "50px", maxWidth: "160px" }} />
                <col style={{ minWidth: "150px" }} />
                <col style={{ minWidth: "80px" }} />
                <col style={{ minWidth: "80px" }} />
              </colgroup>
              <thead>
                <tr>
                  <th></th>
                  <th>Flow name</th>
                  <th>Category</th>
                  <th>Trigger</th>
                  <th>Type</th>
                  <th style={{ textAlign: "center" }}>Keywords</th>
                  <th>Status</th>
                  <th>Thumbnail</th>
                  <th>Channel type</th>
                  <th></th>
                </tr>
              </thead>

              {Object.keys(itemList)!.map((key, index) => {
                const value = itemList[key];
                return (
                  <tbody>
                    <tr
                      onClick={(event) => {
                        redirectToFlow(key, value?.flowName, value);
                        event.stopPropagation();
                      }}
                    >
                      <td className="checkbox-length">
                        <input
                          type="checkbox"
                          value={selectedFlows[key] as any}
                          onClick={(event) => {
                            selectFlows(key);
                            event.stopPropagation();
                          }}
                        />
                      </td>
                      <td
                        className="description-length"
                        onClick={(event) => {
                          onClickHandle(key, true);
                          setShowEditFlowModal(true);
                          event.stopPropagation();
                        }}
                      >
                        <div>{value.flowName}</div>
                        <div className="description-text">
                          {value.description}
                        </div>
                      </td>
                      <td
                        onClick={(event) => {
                          onClickHandle(key, true);
                          setShowEditFlowModal(true);
                          event.stopPropagation();
                        }}
                      >
                        <div className="category-style">
                          <div className="category-style__text">
                            {value.categoryName}
                          </div>
                          {!!value.categoryName && (
                            <div className="category-style__icon">
                              <DropdownIcon />
                            </div>
                          )}
                        </div>
                      </td>
                      <td
                        onClick={(event) => {
                          onClickHandle(key, true);
                          setModalTypeAndValue("TRIGGER");
                          event.stopPropagation();
                        }}
                      >
                        {value.triggerType}
                      </td>
                      <td onClick={(event) => event.stopPropagation()}>
                        {/* <BotDropDown
                          options={typeDataSet}
                          marginTop={"0"}
                          height={"40px"}
                          value={value.type || "Select"}
                          onChange={(name, data) =>
                            onTypeChange(name, data, key, "type")
                          }
                        /> */}
                      </td>
                      <td
                        className="keywords-list"
                        onClick={(event) => {
                          onClickHandle(key, true);
                          setModalTypeAndValue("KEYWORDS");
                          event.stopPropagation();
                        }}
                      >
                        <DynamicKeywords
                          width={160}
                          items={
                            keywordsList && keywordsList[key]
                              ? keywordsList[key]
                              : []
                          }
                        />
                      </td>
                      <td>
                        {!value.isComingSoon && (
                          <TagManager
                            textColor={value.status ? "#00580E" : "#B92321"}
                            tagColor={value.status ? "#E6F4E7" : "#FFEBEF"}
                            text={value.status ? "Enabled" : "Disabled"}
                            borderColor={value.status ? "#C3E4C4" : "#FFCED4"}
                          />
                        )}
                        {value.isComingSoon && (
                          <TagManager
                            textColor="#99631B"
                            tagColor="#FFEABE"
                            text={"Coming soon"}
                            borderColor="#FFEABE"
                          />
                        )}
                      </td>
                      <td className="upload-media">
                        {value.mediaName ? (
                          <FilePreviewStyle className="file">
                            <div
                              className="file__left"
                              onClick={(event) => {
                                event.stopPropagation();
                                onClickHandle(key, true);
                                setModalTypeAndValue("UPLOAD");
                              }}
                            >
                              <p className="file__text">
                                {filterFileName(value.mediaName)}
                              </p>
                              <p className="file__size">{value.mediaSize}</p>
                            </div>
                            <div
                              className="file__right"
                              onClick={async (event) => {
                                event.stopPropagation();
                                await deleteMedia(key);
                              }}
                            >
                              <DeleteIcon width={24} height={24} />
                            </div>
                          </FilePreviewStyle>
                        ) : (
                          <p
                            className="file__upload-text"
                            onClick={(event) => {
                              onClickHandle(key, true);
                              setModalTypeAndValue("UPLOAD");
                              event.stopPropagation();
                            }}
                          >
                            Upload
                          </p>
                        )}
                      </td>
                      <td onClick={(event) => event.stopPropagation()}>
                       {value.channel}
                      </td>

                      <td className="more-length">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{ display: "flex", width: 24 }}
                            onClick={(event) => {
                              onClickHandle(key);
                              event.stopPropagation();
                            }}
                          >
                            <span style={{ margin: "auto", display: "flex" }}>
                              <OptionsIcon />
                            </span>
                            {showPopover === key && (
                              <div>
                                <BotPopup
                                  list={actionsList}
                                  rightPosition={"80px"}
                                />
                              </div>
                            )}
                          </div>
                          {/* <div
                            style={{
                              marginLeft: "16px",
                              display: "flex",
                              width: 24,
                            }}
                            onClick={(event) => {
                              setCurrentId(key);
                              setModalTypeAndValue("ENABLEPODS");
                              event.stopPropagation();
                            }}
                          >
                            <span style={{ margin: "auto", display: "flex" }}>
                              <EyeIcon />
                            </span>
                          </div> */}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </TableStyle>
      ) : (
        <NoFlow>
          <p>Click on create new flow button to start building flow!</p>
        </NoFlow>
      )}
    </>
  );
};

interface DynamicKeywordsProps {
  width: number;
  items: Array<string>;
}

const DynamicKeywords = (props: DynamicKeywordsProps) => {
  const { width, items } = props;
  const [keywords, setKeywords] = useState<Array<string>>(props.items || []);
  const maxWidth = useRef(width - 40);

  useEffect(() => {
    setKeywords((prevState) => []);

    let index = 0;
    let totalLength = (items || []).length;

    for (const item of items || []) {
      const width = getTextWidth(item);
      maxWidth.current -= width;
      if (maxWidth.current <= 0 || index >= totalLength) {
        const remaining_keywords = `+${totalLength - index}`;
        setKeywords((prevState) => {
          return [...prevState, remaining_keywords];
        });
        break;
      }
      index++;
      setKeywords((prevState) => {
        return [...prevState, item];
      });
    }

    return () => {
      maxWidth.current = width - 40;
    };
  }, [items?.length, items]);

  const getTextWidth = (word: string) => {
    if (!word) return 0;
    const text = document.createElement("span");
    document.body.appendChild(text);
    text.style.font = "times new roman";
    text.style.fontSize = 12 + "px";
    text.style.height = "auto";
    text.style.width = "auto";
    text.style.position = "absolute";
    text.style.whiteSpace = "no-wrap";
    text.innerHTML = word;
    const width = Math.ceil(text.clientWidth);
    document.body.removeChild(text);
    return width;
  };

  return (
    <>
      {keywords.map((word: string, index: number) => {
        return (
          <div key={index}>
            <TagManager
              textColor={"#616161"}
              tagColor={"#FAFAFA"}
              text={word}
              borderColor={"#E0E0E0"}
            />
          </div>
        );
      })}
    </>
  );
};

export default TemplateTable;

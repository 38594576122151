import React from "react";
import ShimmerHelper from "../../ui-components/shimmer/shimmerHelper";

const AutomationShimmer = () => {
  const box = [
    {
      width: "auto",
      height: "24px",
    },
    {
      width: "auto",
      height: "40px",
    },
    {
      width: "auto",
      height: "48px",
    },
  ];
  return (
    <>
      <ShimmerHelper boxes={box as any} />
    </>
  );
};

export default AutomationShimmer;

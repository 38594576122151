import { Icon } from "./icons-props";

const OrdersList: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.0011 14C22.5525 14 22.9995 13.5522 22.9995 13.0008C22.9995 12.4485 22.5518 12 21.9995 12H21.554C20.8606 12 20.2636 12.4894 20.1277 13.1693L19.907 14.2727H13.554C12.6361 14.2727 11.9477 15.1125 12.1277 16.0125L12.8549 19.6489C12.9909 20.3288 13.5879 20.8182 14.2812 20.8182H19.4451C20.1384 20.8182 20.7354 20.3288 20.8714 19.6489L22.0011 14ZM19.507 16.2727L18.9979 18.8182H14.7284L14.2193 16.2727H19.507Z"
        fill={color || "#212121"}
      />
      <circle cx="14.9234" cy="22.6158" r="1.15384" fill={color || "#212121"} />
      <circle cx="19.5386" cy="22.6158" r="1.15384" fill={color || "#212121"} />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.99951 3.00049C1.99951 1.89592 2.89494 1.00049 3.99951 1.00049H17.9995C19.1041 1.00049 19.9995 1.89592 19.9995 3.00049V9.50049C19.9995 10.0528 19.5518 10.5005 18.9995 10.5005C18.4472 10.5005 17.9995 10.0528 17.9995 9.50049V3.00049L3.99951 3.00049V19.0005H9.99951C10.5518 19.0005 10.9995 19.4482 10.9995 20.0005C10.9995 20.5528 10.5518 21.0005 9.99951 21.0005H3.99951C2.89494 21.0005 1.99951 20.1051 1.99951 19.0005V3.00049Z"
        fill={color || "#212121"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.00001 6.5C7.55229 6.5 8.00001 6.05228 8.00001 5.5C8.00001 4.94772 7.55229 4.5 7.00001 4.5C6.44772 4.5 6.00001 4.94772 6.00001 5.5C6.00001 6.05228 6.44772 6.5 7.00001 6.5ZM7.00001 9.49999C7.55229 9.49999 8.00001 9.05227 8.00001 8.49999C8.00001 7.9477 7.55229 7.49999 7.00001 7.49999C6.44772 7.49999 6.00001 7.9477 6.00001 8.49999C6.00001 9.05227 6.44772 9.49999 7.00001 9.49999ZM8 11.5C8 12.0523 7.55228 12.5 7 12.5C6.44772 12.5 6 12.0523 6 11.5C6 10.9477 6.44772 10.5 7 10.5C7.55228 10.5 8 10.9477 8 11.5ZM10.0004 4.50034C9.44807 4.50034 9.00036 4.94806 9.00036 5.50034C9.00036 6.05263 9.44807 6.50034 10.0004 6.50034H15.0004C15.5526 6.50034 16.0004 6.05263 16.0004 5.50034C16.0004 4.94806 15.5526 4.50034 15.0004 4.50034H10.0004ZM9.00036 8.50034C9.00036 7.94806 9.44807 7.50034 10.0004 7.50034H15.0004C15.5526 7.50034 16.0004 7.94806 16.0004 8.50034C16.0004 9.05263 15.5526 9.50034 15.0004 9.50034H10.0004C9.44807 9.50034 9.00036 9.05263 9.00036 8.50034ZM10.0004 10.5003C9.44807 10.5003 9.00036 10.9481 9.00036 11.5003C9.00036 12.0526 9.44807 12.5003 10.0004 12.5003H15.0004C15.5526 12.5003 16.0004 12.0526 16.0004 11.5003C16.0004 10.9481 15.5526 10.5003 15.0004 10.5003H10.0004Z"
        fill={color || "#212121"}
      />
    </svg>
  );
};

export default OrdersList;

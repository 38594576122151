import { Icon } from "./icons-props";

const AlertIcon: Icon = (props) => {
    const { width, height, color } = props;
    return (
        <svg width={width || "20"} height={height || "20"} viewBox="0 0 32 32" fill={color || '#B92321'} xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.0373 3.04424C14.6364 2.70697 15.3122 2.52979 15.9997 2.52979C16.6872 2.52979 17.3631 2.70697 17.9621 3.04424C18.5612 3.38151 19.0632 3.86749 19.4197 4.45527L19.4236 4.46161L30.7169 23.3149L30.7277 23.3334C31.077 23.9382 31.2618 24.624 31.2637 25.3224C31.2657 26.0208 31.0847 26.7076 30.7388 27.3144C30.393 27.9212 29.8942 28.4269 29.2923 28.7811C28.6903 29.1353 28.0061 29.3257 27.3077 29.3334L27.293 29.3335L4.69172 29.3334C3.99332 29.3258 3.3091 29.1353 2.70714 28.7811C2.10517 28.4269 1.60645 27.9212 1.26058 27.3144C0.914707 26.7076 0.733747 26.0208 0.735703 25.3224C0.737659 24.624 0.922462 23.9382 1.27173 23.3334L1.28256 23.3149L12.5759 4.46162L12.5797 4.45527C12.9363 3.86749 13.4383 3.38151 14.0373 3.04424ZM14.8613 5.83563L3.57663 24.6745C3.46306 24.8743 3.403 25.1 3.40236 25.3299C3.40171 25.5627 3.46203 25.7916 3.57732 25.9939C3.69261 26.1961 3.85885 26.3647 4.0595 26.4828C4.2584 26.5998 4.48424 26.6632 4.71491 26.6668H27.2845C27.5152 26.6632 27.741 26.5998 27.9399 26.4828C28.1406 26.3647 28.3068 26.1961 28.4221 25.9939C28.5374 25.7916 28.5977 25.5627 28.5971 25.3299C28.5964 25.1 28.5364 24.8743 28.4228 24.6746L17.1397 5.83828L17.1381 5.83563C17.0194 5.64089 16.8526 5.47985 16.6538 5.36794C16.4542 5.25551 16.2289 5.19645 15.9997 5.19645C15.7706 5.19645 15.5453 5.25551 15.3456 5.36794C15.1468 5.47985 14.9801 5.64089 14.8613 5.83563ZM16 10.6668C16.7364 10.6668 17.3333 11.2637 17.3333 12.0001V17.3335C17.3333 18.0698 16.7364 18.6668 16 18.6668C15.2636 18.6668 14.6667 18.0698 14.6667 17.3335V12.0001C14.6667 11.2637 15.2636 10.6668 16 10.6668ZM16 21.3335C15.2636 21.3335 14.6667 21.9304 14.6667 22.6668C14.6667 23.4032 15.2636 24.0001 16 24.0001H16.0133C16.7497 24.0001 17.3467 23.4032 17.3467 22.6668C17.3467 21.9304 16.7497 21.3335 16.0133 21.3335H16Z"/>
        </svg>
    );
}

export default AlertIcon;
import { CustomNodeIconProps } from "../utilities/flowBuilder.utility";

const TicketIcon = (props: CustomNodeIconProps) => {
    const {backgroundColor, color, height, width, style, showBorder} = props;
    return (
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 1V3M13 7V9M13 13V15M3 1H17C17.5304 1 18.0391 1.21071 18.4142 1.58579C18.7893 1.96086 19 2.46957 19 3V6C18.4696 6 17.9609 6.21071 17.5858 6.58579C17.2107 6.96086 17 7.46957 17 8C17 8.53043 17.2107 9.03914 17.5858 9.41421C17.9609 9.78929 18.4696 10 19 10V13C19 13.5304 18.7893 14.0391 18.4142 14.4142C18.0391 14.7893 17.5304 15 17 15H3C2.46957 15 1.96086 14.7893 1.58579 14.4142C1.21071 14.0391 1 13.5304 1 13V10C1.53043 10 2.03914 9.78929 2.41421 9.41421C2.78929 9.03914 3 8.53043 3 8C3 7.46957 2.78929 6.96086 2.41421 6.58579C2.03914 6.21071 1.53043 6 1 6V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1Z" stroke={color ?? "#212121"} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default TicketIcon;

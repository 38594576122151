import { Icon } from "./icons-props";

const EditIcon: Icon = (props) => {
  const { width, height, color, backgroundColor } = props;
  return (
    <svg
      width={width || "32"}
      height={height || "32"}
      viewBox="0 0 24 24"
      fill={color || "#616161"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7177 4.99994C17.5493 4.99994 17.3826 5.03311 17.227 5.09755C17.0714 5.16199 16.93 5.25645 16.811 5.37552L6.1053 16.0812L5.42524 18.5747L7.91877 17.8947L18.6244 7.189C18.7435 7.06992 18.838 6.92856 18.9024 6.77298C18.9669 6.61741 19 6.45066 19 6.28226C19 6.11386 18.9669 5.94711 18.9024 5.79154C18.838 5.63596 18.7435 5.4946 18.6244 5.37552C18.5054 5.25645 18.364 5.16199 18.2084 5.09755C18.0528 5.03311 17.8861 4.99994 17.7177 4.99994ZM16.4616 3.24979C16.8598 3.08484 17.2867 2.99994 17.7177 2.99994C18.1487 2.99994 18.5756 3.08484 18.9738 3.24979C19.372 3.41474 19.7339 3.65652 20.0387 3.96131C20.3434 4.2661 20.5852 4.62794 20.7502 5.02617C20.9151 5.4244 21 5.85122 21 6.28226C21 6.7133 20.9151 7.14012 20.7502 7.53835C20.5852 7.93658 20.3434 8.29842 20.0387 8.60321L9.1452 19.4967C9.02215 19.6197 8.86911 19.7085 8.70121 19.7543L4.26314 20.9647C3.91693 21.0591 3.54667 20.9608 3.29292 20.707C3.03917 20.4533 2.94084 20.083 3.03526 19.7368L4.24564 15.2987C4.29143 15.1309 4.38024 14.9778 4.5033 14.8548L15.3968 3.96131C15.7015 3.65652 16.0634 3.41474 16.4616 3.24979Z"
        fill={color || "#616161"}
      />
    </svg>
  );
};

export default EditIcon;

import {
  HTTPMethods,
  RequestExecutor,
} from "../../api-helpers/RequestExecutor";
import { getCampaignEndPoints, getIntegrationsEndpoints } from "../../config";
import { LogsSearchRequest, LogsSearchResponse } from "./LogSearchModel";
import { FirebaseService } from "../../services/firebase/FirebaseService";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  startAfter,
} from "firebase/firestore";

export class IntegrationService {
  lastDoc = undefined;
  async startBulkMigration(storeId: string, identifier: string) {}

  async findBulkMigrationStatus(storeId: string, identifier: string) {}

  async findTotalProductsOnShopify(storeId: string) {}

  async findLogsById(request: LogsSearchRequest) {
    const path = `${getIntegrationsEndpoints()}/loggerAPIFunctions-searchLogs`;
    const res = (await RequestExecutor.fetch({
      apiPath: path,
      apiMethod: HTTPMethods.POST,
      requestBody: request,
    })) as any;
    return res.data.data as LogsSearchResponse;
  }

  hasInvalidScopes(scopes: string) {
    const can_disrupt = [
      "read_price_rules",
      "write_price_rules",
      "read_discounts",
      "write_discounts",
      "read_draft_orders",
      "write_draft_orders",
      "write_script_tags",
    ];
    return can_disrupt.filter((scope: string) => !scopes.includes(scope));
  }

  async fetchScopes(storeId: string) {
    const path = `${getIntegrationsEndpoints()}/integrationsApiFunctions-executeIntegrationApi`;
    const res = (await RequestExecutor.fetch({
      apiPath: path,
      apiMethod: HTTPMethods.POST,
      requestBody: {
        storeId: storeId,
        code: "fetchAccessScopes",
        partner: "SHOPIFY",
        payload: {},
      },
    })) as any;
    const scopes = res.data.data.accessScopes.map((scope: any) => scope.handle);
    const mismatch = this.hasInvalidScopes(scopes);
    return {
      scopes: scopes,
      mismatch: mismatch.length ? mismatch : "all good",
    };
  }

  async findLinkForScopes(storeId: string, scopes: string[]) {
    const path = `${getIntegrationsEndpoints()}/integrationsApiFunctions-executeIntegrationApi`;
    const res = (await RequestExecutor.fetch({
      apiPath: path,
      apiMethod: HTTPMethods.POST,
      requestBody: {
        storeId: storeId,
        code: "updateAccessScopes",
        partner: "SHOPIFY",
        payload: {
          scopes: scopes,
        },
      },
    })) as any;
    return JSON.stringify(res);
  }

  async fetchProductsErrors(
    storeId: string,
    catalogId: string,
    productIds: string[]
  ) {
    const path = `${getIntegrationsEndpoints()}/integrationsApiFunctions-executeIntegrationApi`;
    const response = (await RequestExecutor.fetch({
      apiPath: path,
      apiMethod: HTTPMethods.POST,
      requestBody: {
        storeId: storeId,
        partner: "FACEBOOK",
        code: "fetchFbItemErrors",
        payload: {
          catalogId: catalogId,
          productIds: productIds,
        },
      },
    })) as any;

    return response?.data?.data;
  }

  async enableWhatsappCatalog(storeId: string) {
    const path = `${getCampaignEndPoints()}/whatsappApiFunctions-whatsappCommerceSettings`;
    const response = (await RequestExecutor.fetch({
      apiPath: path,
      apiMethod: HTTPMethods.POST,
      requestBody: {
        storeId: storeId,
        isCartEnabled: true,
        isCatalogVisible: true,
      },
    })) as any;

    if (response?.status !== 200 || !response?.data?.response?.success) {
      if (response?.error) {
        return response?.error.message;
      } else {
        return "something went wrong, please try again";
      }
    } else {
      return "catalog connect successfully";
    }
  }

  async fetchProducts(storeId: string) {
    const firebaseService = new FirebaseService(
      () => {},
      () => {}
    );
    const database = firebaseService.getDatabase();
    const colRef = collection(database, "store-new", storeId, "items");
    let querySnapshot;
    if (this.lastDoc) {
      querySnapshot = await getDocs(
        query(colRef, startAfter(this.lastDoc), limit(25))
      );
    } else {
      querySnapshot = await getDocs(query(colRef, limit(500)));
    }

    const productList = querySnapshot.docs.map((document) => {
      return document.data();
    });

    return productList ?? [];
  }

  async fetchFbProductErrors(storeId: string, productList: any[]) {
    const catalogId = await this.fetchCatalogId(storeId);

    const productIds = productList.map((item) => {
      return item.combinations[0].id;
    });

    const productErrors: any = await this.fetchProductsErrors(
      storeId,
      catalogId,
      productIds
    );
    const newProductsList = productList.map((product) => {
      const error = productErrors?.find((err: any) => {
        return product.combinations[0].id === err.retailer_id;
      });
      if (error && error?.errors) {
        const errors = error?.errors?.map((err: any) => {
          return err.title;
        });
        return {
          ...product,
          error: errors.join(", "),
        };
      } else {
        return {
          ...product,
          error: "Product does not exist",
        };
      }
    });

    return newProductsList ?? [];
  }

  async fetchCatalogId(storeId: string) {
    const firebaseService = new FirebaseService(
      () => {},
      () => {}
    );
    const database = firebaseService.getDatabase();
    const docsRef = doc(database, "store-meta", storeId);
    const docsSnapshot = await getDoc(docsRef);
    const docs = docsSnapshot.data();
    return docs?.whatsappIntegrationInfo?.catalogId;
  }

  async importCatalogs(storeId: string) {
    if (!storeId) {
      return "No store id found.";
    }
    const catalogId = await this.fetchCatalogId(storeId);
    if (!catalogId) {
      return "No catalog id found.";
    }
    const path = `${getIntegrationsEndpoints()}/integrationsApiFunctions-executeIntegrationApi`;
    const response = (await RequestExecutor.fetch({
      apiPath: path,
      apiMethod: HTTPMethods.POST,
      requestBody: {
        storeId: storeId,
        partner: "FACEBOOK",
        code: "importFbCatalogs",
        payload: {
          catalogId: catalogId,
        },
      },
    })) as any;
    if (response.error || response.status !== 200) {
      return "something went wrong, please try again";
    } else {
      return "catalogs import successfully";
    }
  }

  async fetchShopifyProducts(storeId: string) {
    const path = `${getIntegrationsEndpoints()}/integrationsApiFunctions-executeIntegrationApi`;
    const response = (await RequestExecutor.fetch({
      apiPath: path,
      apiMethod: HTTPMethods.POST,
      requestBody: {
        storeId: storeId,
        partner: "SHOPIFY",
        code: "fetchAllProducts",
      },
    })) as any;
    return response?.data?.data?.products ?? [];
  }

  async fetchProductById(storeId: string, productId: string) {
    const path = `${getIntegrationsEndpoints()}/integrationsApiFunctions-executeIntegrationApi`;
    const response = (await RequestExecutor.fetch({
      apiPath: path,
      apiMethod: HTTPMethods.POST,
      requestBody: {
        storeId: storeId,
        partner: "SHOPIFY",
        code: "fetchProductById",
        payload: {
          productId: productId,
        },
      },
    })) as any;
    
    return response?.data?.data?.product[productId] ?? {};
  }

  async triggerShopifyProductsSync(storeId: string) {
    const triggerWorkflowRequest = {
      apiPath: `${getCampaignEndPoints()}/workflowApiFunctions-resumeWorkflow`,
      apiMethod: HTTPMethods.POST,
      requestBody: {
        processName: "DATA_MIGRATION",
        payload: {
          storeId,
          identifier: "PRODUCTS",
          baseUrl: getIntegrationsEndpoints(),
          force: true,
        },
        storeId,
        isTrigger: true,
      },
    } as any;
    const response: any = await RequestExecutor.fetch(triggerWorkflowRequest);
    if (response?.status !== 200 || response?.data?.error) {
      return "something went wrong, please try again";
    } else {
      return "product sync successfully";
    }
  }

  async fetchData(storeId: string) {
    const products = await this.fetchProducts(storeId);

    const shopifyProducts = await this.fetchShopifyProducts(storeId);
    const absentProducts = this.filterProduct(products, shopifyProducts);

    return {
      products,
      absentProducts,
    };
  }

  filterProduct(internalProducts: any[], products: any[]) {
    const notPresentItems = products?.filter((shopifyProduct) => {
      return !internalProducts?.some(
        (internalProduct) => internalProduct.id === shopifyProduct.id
      );
    });
    return notPresentItems;
  }
}

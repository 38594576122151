import React, {FunctionComponent} from 'react';
import {CustomNodeProps} from "../../utilities/flowBuilder.utility";
import styled from "styled-components";
import { useDebugRuns } from './runs/useDebugRuns';

interface OwnProps {
    LocalComponent: React.FunctionComponentElement<CustomNodeProps>;
    nodeId: string;
}

type Props = OwnProps;

const AnalyticsNodeWrapperStyled = styled.div`
  color: green;
  position: absolute;
  top: -50px;
  display: flex;
  
  h3 {
    margin-left: 16px;
  }
`

const AnalyticsNodeWrapper: FunctionComponent<Props> = (props) => {
    const useRuns = useDebugRuns();
    const nodeLevelData = useRuns.analyticsForNode(props.nodeId);

    const percentage = () => {
        if (nodeLevelData.totalRuns === 0) {
            return 0
        }
        return parseInt(((nodeLevelData.nodeRuns * 100) / nodeLevelData.totalRuns).toString())
    }

    const showCompleteAnalytics = useRuns.analyticsType() === 'complete';
    
    return (
        <>
            {<AnalyticsNodeWrapperStyled>
                <div>
                    {!showCompleteAnalytics && <h3>{nodeLevelData.nodeExecuted ? 'Successful Execution' : ''}</h3>}
                    {showCompleteAnalytics && <h3>Total : {nodeLevelData.nodeRuns} / {nodeLevelData.totalRuns} (%{percentage()})</h3>}
                </div>
            </AnalyticsNodeWrapperStyled>}
            {props.LocalComponent}
        </>
    )
};

export default AnalyticsNodeWrapper;

import React, { useEffect } from "react";
import { CSSProperties } from "styled-components";
import { Backdrop } from "./Modal.style";

interface Props {
    onBackdropPress?: () => void;
    backdropId?: string
    children: React.ReactNode;
    childrenStyle?: CSSProperties;
    handleClick?: (e: any) => void,
    overflowY?: string,
}

export interface ModalStyleProps {
    minWidth?: number
    maxWidth?: number,
}

export const Modal: React.FC<Props> = (props) => {
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "";
        };
    }, []);

    const handleBackDropClick = () => {
        if (props.onBackdropPress) {
            props.onBackdropPress();
        }
    };

    return (
        <Backdrop overflowY={props.overflowY} onClick={handleBackDropClick} id={props.backdropId ? props.backdropId : ''}>
            <div id={"chatbot-builder-modal"} onClick={(e) => props.handleClick ? props.handleClick(e) : e.stopPropagation()} style={props?.childrenStyle || {display: 'flex'}}>
                {props.children}
            </div>
        </Backdrop>
    );
};

export default Modal;

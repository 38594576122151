import { BikShimmer, BodyCaption, BodyPrimary, BodySecondary, Button, ButtonGroup, COLORS, CheckBox, DynamicTabs, DynamicTabsDetails, DynamicTabsStyleDetails, StyledModal, TitleMedium, TitleRegular } from "@bikdotai/bik-component-library";
import { ActionStrip, Body, BtnGrpSelector, CommentAction, Container, Footer, Header, HeartReaction, InlineText, KeywordContainer, ModalTitle, VerticalSeparator } from "./style";
import HeartIcon from "../../icons/heartIcon";
import TearsOfJoy from "../../icons/TearsOfJoy";
import AstonishedEmoji from "../../icons/AstonishedEmoji";
import ClappedEmoji from "../../icons/clapEmoji";
import HeartEyesEmoji from "../../icons/HeartEyesEmoji";
import FireEmoji from "../../icons/FireEmoji";
import TearFaceEmoji from "../../icons/TearFaceEmoji";
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { generateUniqueId } from "../../app/action-block/Util";
import { ActionEventsList } from "./models";
import { ErrorIndicator } from "./comments";
import InstaIdsDropdown from "./instaDropdown";
import ErrScreen from "./errScreen";
import { v4 as uuidv4 } from 'uuid';


const Reactions = (props: {
    instaId: string;
    storeId: string;
    navigateBack: React.Dispatch<React.SetStateAction<ActionEventsList | "default">>;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    fetchedConf: any;
    onSave: (data: any) => void;
}) => {

    const { fetchedConf } = props

    const [anyOrSpecific, setAnyOrSpecific] = useState<string>('Any')
    const [heart, setHeart] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const prevSelectedInsta = useRef<string>('')

    const [emojiList, setEmojiList] = useState<{ [key: string]: boolean }>({
        'joy': false,
        'astonished': false,
        'clap': false,
        'heartEyes': false,
        'fire': false,
        'tear': false
    })

    const emojiMapper: { [key: string]: string } = {
        'joy': "😂",
        'astonished': "😮",
        'clap': "👏",
        'heartEyes': "😍",
        'fire': "🔥",
        'tear': "🥲"
    }

    const reverseEmojiMapper = Object.entries(emojiMapper).reduce((acc, item) => {
        return {
            ...acc,
            [item[1]]: item[0]
        }
    }, {})
    const [showErrorScreen, setShowErrorScreen] = useState<boolean>(false)


    const [selectedInsta, setSelectedInsta] = useState<{
        label: string,
        value: string,
        selected: boolean
    }>();


    useEffect(() => {

        if (selectedInsta?.value) {
            setIsLoading(false)
        }

        if (selectedInsta?.value === 'ERROR') {
            setShowErrorScreen(true)
            return
        }

        if (!prevSelectedInsta.current || !selectedInsta?.value) {
            prevSelectedInsta.current = selectedInsta?.value || ''
            return
        }

        if (prevSelectedInsta.current && prevSelectedInsta.current !== selectedInsta.value) {
            setHeart(false)
            setAnyOrSpecific('Any')
            prevSelectedInsta.current = selectedInsta.value
        }

    }, [selectedInsta])


    useEffect(() => {
        if (Object.keys(fetchedConf).length) {
            setHeart(fetchedConf.heartReaction)
            const emojis: string[] = ((fetchedConf.trigger.eventProperties.filter((item: any) => item.firstOperand === 'Reaction'))[0]).secondOperand
            let isAny = false
            if (fetchedConf.subHeader === 'Reaction is anything'){
                isAny = true
            }
            setAnyOrSpecific(!isAny ? 'Specific': 'Any')
            setEmojiList((prev) => {
                if(isAny){
                    return prev
                }
                const cur = { ...prev }
                Object.keys(prev).forEach(k => {
                    if (emojis.includes(emojiMapper[k])) {
                        cur[k] = true
                    }
                })
                return cur;
            })
        }
    }, [])


    useEffect(() => {
        error && Object.values(emojiList).filter(item => item).length && setError(false)
    }, [emojiList])


    const tabStyleInfo: DynamicTabsStyleDetails = {
        type: 'SQUARE',
    };

    const tabsInfo: DynamicTabsDetails[] = [
        {
            id: 0,
            text: 'Any',
            action: (tabName: string) => {
                if (tabName !== anyOrSpecific) {
                    setEmojiList(prev => {
                        return {
                            'joy': false,
                            'astonished': false,
                            'clap': false,
                            'heartEyes': false,
                            'fire': false,
                            'tear': false
                        }
                    })
                }
                setAnyOrSpecific('Any')
            },
        },
        {
            id: 1,
            text: 'Specific',
            action: (tabName: string) => {
                if (tabName !== anyOrSpecific) {
                    setEmojiList(prev => {
                        return {
                            'joy': false,
                            'astonished': false,
                            'clap': false,
                            'heartEyes': false,
                            'fire': false,
                            'tear': false
                        }
                    })
                }
                setAnyOrSpecific('Specific')
            },
        },
    ];


    const generateConf = () => {


        if (anyOrSpecific === 'Specific' && !Object.values(emojiList).filter(item => item).length) {
            setError(true)
            return
        } else {
            setError(false)
        }

        const eventProperties = [
            {
                condition: "in",
                firstOperand: "anyStory",
                secondOperand: []
            },
            {
                condition: anyOrSpecific === 'Any' ? 'any' : 'contains',
                firstOperand: "Reaction",
                secondOperand: anyOrSpecific === 'Any' ? [] : Object.keys(emojiList).filter(k => emojiList[k]).map(i => emojiMapper[i])
            }
        ]
        const eventConnector = "AND"
        const eventSelected = "Reacts"
        const trigger = {
            eventProperties,
            eventSelected,
            eventConnector
        }
        const mediaType = ""
        const media: [] = []

        const header = 'Reacts on story'
        const selectedReactions = Object.keys(emojiList).filter(k => {
            return anyOrSpecific === 'Any' ? k : emojiList[k]
        })
        const subHeader = anyOrSpecific === 'Any' ? 'Reaction is anything' : selectedReactions.length > 1 ? `Reactions are ${selectedReactions.map(i => emojiMapper[i]).join(', ')}` : `Reaction is ${selectedReactions.map(i => emojiMapper[i]).join(', ')}`
        const id = fetchedConf?.id ? fetchedConf.id : uuidv4();
        const type = 'ig'
        const tag = 'Any Story'
        const instaId = selectedInsta?.value
        const operator = 'AND'

        props.onSave({
            trigger,
            mediaType,
            media,
            header,
            subHeader,
            id,
            type,
            tag,
            instaId,
            operator,
            commentReplies: [],
            heartReaction: heart
        })
        props.setShowModal(false)
    }


    const onClickEmoji = (type: string) => {
        setEmojiList(prev => {
            return {
                ...prev,
                [type]: !prev[type]
            }
        })
    }


    return (
        <StyledModal open={true} zIndex={200} centralContainerStyles={{ width: "1032px", height: "560px" }} onClose={() => props.setShowModal(false)}>
            <Container>
                <Header>
                    <TitleMedium>{'Reacts on story'}</TitleMedium>
                    <InstaIdsDropdown storeId={props.storeId} instaId={props.instaId} setSelectedInsta={setSelectedInsta} />
                </Header>
                {
                    showErrorScreen  ? (
                        <Body>
                        <div style={{
                            minHeight: '90%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <ErrScreen
                                body={'Could not find instagram account'}
                                title="No account found"
                            />
                        </div>
                    </Body>
                    )
                    : isLoading ? <Body>
                        <BikShimmer boxes={
                            [{ width: "968px", height: "350px" }]
                        } />
                    </Body>
                        : <Body>
                            <ModalTitle>
                                <TitleRegular>Journey will be triggered...</TitleRegular>
                            </ModalTitle>

                            <CommentAction>
                                <ActionStrip>
                                    <InlineText>
                                        <BodyPrimary>
                                            {'Whenever someone reacts on'}
                                        </BodyPrimary>
                                        <BodyPrimary>
                                            <b>any</b>
                                        </BodyPrimary>
                                        <BodyPrimary>
                                            {'story'}
                                        </BodyPrimary>
                                    </InlineText>
                                </ActionStrip>
                            </CommentAction>
                            <VerticalSeparator />
                            <CommentAction>
                                <KeywordContainer>
                                    <BtnGrpSelector>
                                        <BodyPrimary>
                                            and reaction is
                                        </BodyPrimary>
                                        <DynamicTabs
                                            tabsInfo={tabsInfo}
                                            tabStyleInfo={tabStyleInfo}
                                            selectedTabId={anyOrSpecific === 'Any' ? 0 : 1}
                                        />
                                        <BodyPrimary>
                                            reaction
                                        </BodyPrimary>
                                    </BtnGrpSelector>
                                    {anyOrSpecific === 'Specific' ? <EmojiContainer>
                                        <EmojiBox onClick={() => onClickEmoji('joy')} selected={emojiList['joy']}>
                                            <TearsOfJoy />
                                        </EmojiBox>
                                        <EmojiBox onClick={() => onClickEmoji('astonished')} selected={emojiList['astonished']}>
                                            <AstonishedEmoji />
                                        </EmojiBox>
                                        <EmojiBox onClick={() => onClickEmoji('clap')} selected={emojiList['clap']}>
                                            <ClappedEmoji />
                                        </EmojiBox>
                                        <EmojiBox onClick={() => onClickEmoji('heartEyes')} selected={emojiList['heartEyes']}>
                                            <HeartEyesEmoji />
                                        </EmojiBox>
                                        <EmojiBox onClick={() => onClickEmoji('fire')} selected={emojiList['fire']}>
                                            <FireEmoji />
                                        </EmojiBox>
                                        <EmojiBox onClick={() => onClickEmoji('tear')} selected={emojiList['tear']}>
                                            <TearFaceEmoji />
                                        </EmojiBox>
                                    </EmojiContainer> : <></>}
                                    {error ?
                                        <div style={{
                                            marginTop: 8
                                        }}> <BodyCaption color={COLORS.content.negative}>Please select any reaction</BodyCaption> </div>
                                        :
                                        <></>}
                                </KeywordContainer>
                            </CommentAction>
                            <HeartReaction>
                                <CheckBox isChecked={heart} onValueChange={function (checked: boolean, value?: string | undefined): void {
                                    checked ? setHeart(true) : setHeart(false)
                                    return;
                                }} />
                                <InlineText>
                                    <BodySecondary>React with</BodySecondary>
                                    <HeartIcon />
                                    <BodySecondary>in response</BodySecondary>
                                </InlineText>
                            </HeartReaction>
                        </Body>
                }

                <Footer>
                    {
                        error ? <ErrorIndicator /> : <div></div>
                    }
                    <Button buttonText="Create" size={'medium'} onClick={() => { generateConf() }} />
                </Footer>
            </Container>
        </StyledModal>
    )
}

export default Reactions;

const EmojiBox = styled.div<{ selected: boolean }>`
    display: flex;
    width: 48px;
    height: 48px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: ${(props) => props.selected ? `1px solid ${COLORS.stroke.brand}` : "none"};
    background: ${(props) => props.selected ? COLORS.background.brandLight : COLORS.surface.hovered}
`

const EmojiContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-top: 18px;
`
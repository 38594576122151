import { useEffect } from 'react';
import {keyboardEventDataActions} from "../state/keyboardEventState";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../state/store";

/**
 * Custom hook for global keyboard event listener
 */
const useKeyboardEventListener = () =>  {

    const dispatcher = useDispatch();
    const keyboardEventDataState = useAppSelector((state) => state.keyboardEventState)

    useEffect(() => {

        // When key is pressed
        const handleKeyDown = (event: KeyboardEvent) => {

            // Copy is pressed | Cmd+C | Ctrl+C
            if (event.key === 'c' && (event.ctrlKey || event.metaKey)) {
                dispatcher(
                    keyboardEventDataActions.keyPressed({
                        ...keyboardEventDataState,
                        keyPressAction: 'copy'
                    }));
            }

            // Paste is pressed | Cmd+V | Ctrl+V
            if (event.key === 'v' && (event.ctrlKey || event.metaKey)) {
                dispatcher(
                    keyboardEventDataActions.keyPressed({
                        ...keyboardEventDataState,
                        keyPressAction: 'paste'
                    }));
            }

            // Escape key is pressed
            if (event.key === 'Escape'){
                //Prevents OS default action.
                event.preventDefault();

                dispatcher(
                    keyboardEventDataActions.keyPressed({
                        ...keyboardEventDataState,
                        keyPressAction: 'escape'
                    }));


                // Resets keyboard event state | When escape key is pressed, state will change and after 200ms the state will reset.
                // Handled so that when user presses escape first and opens side modal, state would be consistent.
                setTimeout(() => {
                    dispatcher(keyboardEventDataActions.keyPressed({
                        ...keyboardEventDataState,
                        keyPressAction: '',
                    }))
                }, 200);
            }


            // Delete key is pressed
            if (event.key === 'Delete' || event.key === 'Backspace'){

                dispatcher(
                    keyboardEventDataActions.keyPressed({
                        ...keyboardEventDataState,
                        keyPressAction: 'delete'
                    }));
            }


            //Shift key is pressed
            if (event.key === 'Shift') {
                dispatcher(
                    keyboardEventDataActions.keyPressed({
                        ...keyboardEventDataState,
                        keyPressAction: 'shift-down'
                    }));
            }

        };

        // When key is released
        const handleKeyUp = (event: KeyboardEvent) => {

            // Shift key is released | Handled because user will click the node when holding shift key
            if (event.key === 'Shift') {
                dispatcher(
                    keyboardEventDataActions.keyPressed({
                        ...keyboardEventDataState,
                        keyPressAction: 'shift-up'
                    }));
            }

        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.addEventListener('keyup', handleKeyUp);

        };
    }, []);
}

export default useKeyboardEventListener;

import ImageIcon from "../../../icons/img";
import {
  ChildNodeProps,
} from "../../../utilities/flowBuilder.utility";
import { ChildNodes } from "../customNode";
import {
  Child,
  Header,
  ImgBg,
  Main,
} from "./style";
import {BodySecondary, ResizableImage} from "@bikdotai/bik-component-library";

interface CarousalNodeProps {
  nodesArray: any;
  nodeData: any;
  nodesArrayCount: number;
  parentIndex: number;
}

const CarousalNode = (props: CarousalNodeProps) => {
  const { nodesArray, nodeData, nodesArrayCount, parentIndex } = props;

  const addEllipsis = (text: string) => {
    if (text.length > 14) {
      return text.slice(0, 18) + "...";
    }
    return text;
  };

  return nodesArray?.map((childNode: ChildNodeProps, index: number) => {
    return (
      <>
        {index === 0 && (
          <>
            <Header noButtons={childNode.buttonId ? false : true}>
              {childNode.imageUrl && !childNode.imageUrl?.includes('{{') ? (
                <ImgBg>
                  <ResizableImage imageUrl={childNode.imageUrl} targetSize={40} alt="Product" style={{borderRadius: 4, objectFit: 'cover'}} />
                </ImgBg>
              ) : (
                <ImgBg style={{ backgroundColor: "#FAFAFA" }}>
                  <ImageIcon color="#9E9E9E" height={28} width={28} />
                </ImgBg>
              )}
              <BodySecondary numberOfLines={2} >
                {childNode.title ? childNode.title : ""}
              </BodySecondary>
            </Header>
          </>
        )}
        {childNode.buttonId && (
          <Main idx={index} length={nodesArray.length}>
            <div>
              <Child className="customNode__childNodes">
                <ChildNodes
                  key={childNode.name}
                  name={childNode.name}
                  buttonId={childNode.buttonId}
                  index={index}
                  connectorColor={nodeData?.connectorColor}
                  nodeType={"Message"}
                  count={nodesArrayCount}
                  isConnectable={
                    childNode.ctaValue.length > 0
                      ? false
                      : childNode.isConnectable
                  }
                  subType={nodeData.subType}
                  childNodeCount={nodesArray.length}
                  parentIndex={parentIndex}
                  ctaValue={childNode.ctaValue}
                  noButtons={!childNode.buttonId ? true : false}
                  nodeId={nodeData.nodeId}
                  type={childNode.type}
                />
              </Child>
            </div>
          </Main>
        )}
      </>
    );
  });
};

export default CarousalNode;

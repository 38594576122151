export const Separator = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1"
      height="16"
      viewBox="0 0 1 16"
      fill="none"
    >
      <rect width="1" height="16" fill="#616161" />
    </svg>
  );
};

import { Icon } from "./icons-props";

const JourneysIcon: Icon = (props) => {
    const { width, height, color, backgroundColor } = props;
    return (
        <svg
            width={width || "24"}
            height={height || "24"}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_547_7686)">
                <path d="M7.5 12H11.25M15 17.25H13.5C13.2042 17.2513 12.9111 17.1939 12.6375 17.0813C12.364 16.9686 12.1154 16.803 11.9063 16.5938C11.6971 16.3846 11.5314 16.1361 11.4188 15.8625C11.3061 15.589 11.2488 15.2958 11.25 15V9.00002C11.2488 8.7042 11.3061 8.41106 11.4188 8.13752C11.5314 7.86397 11.6971 7.61544 11.9063 7.40626C12.1154 7.19708 12.364 7.0314 12.6375 6.91876C12.9111 6.80613 13.2042 6.74878 13.5 6.75002H15M3 9.375H6.75C7.16421 9.375 7.5 9.71079 7.5 10.125V13.875C7.5 14.2892 7.16421 14.625 6.75 14.625H3C2.58579 14.625 2.25 14.2892 2.25 13.875V10.125C2.25 9.71079 2.58579 9.375 3 9.375ZM15.75 3.75H20.25C20.6642 3.75 21 4.08579 21 4.5V9C21 9.41421 20.6642 9.75 20.25 9.75H15.75C15.3358 9.75 15 9.41421 15 9V4.5C15 4.08579 15.3358 3.75 15.75 3.75ZM15.75 14.25H20.25C20.6642 14.25 21 14.5858 21 15V19.5C21 19.9142 20.6642 20.25 20.25 20.25H15.75C15.3358 20.25 15 19.9142 15 19.5V15C15 14.5858 15.3358 14.25 15.75 14.25Z" stroke="#9E9E9E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_547_7686">
                    <rect width={width || "24"} height={height || "24"} fill={color || "white"}/>
                </clipPath>
            </defs>
        </svg>
   );
};

export default JourneysIcon;

const FireEmoji = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path d="M9.10409 10.2672C8.96209 11.7819 8.86244 14.4625 9.75681 15.6035C9.75681 15.6035 9.33578 12.6588 13.1101 8.96422C14.6298 7.47693 14.981 5.454 14.4504 3.93681C14.1489 3.07731 13.5984 2.3673 13.12 1.87153C12.841 1.58005 13.0553 1.09923 13.4614 1.11667C15.9178 1.22629 19.8988 1.9089 21.5904 6.15405C22.3328 8.01754 22.3876 9.9433 22.0339 11.9015C21.8097 13.1521 21.0124 15.9324 22.8311 16.2737C24.129 16.5178 24.7568 15.4864 25.0384 14.744C25.1554 14.4351 25.5615 14.3579 25.7808 14.6045C27.9731 17.0983 28.1599 20.0355 27.7065 22.5642C26.8296 27.4521 21.8794 31.0096 16.9616 31.0096C10.8181 31.0096 5.9277 27.4944 4.65964 21.1317C4.14892 18.5632 4.40802 13.4809 8.36916 9.89348C8.66313 9.62442 9.14395 9.86358 9.10409 10.2672Z" fill="url(#paint0_radial_1584_28493)" />
            <path d="M19.2063 19.408C16.9417 16.4932 17.9557 13.1673 18.5112 11.8419C18.5859 11.6675 18.3866 11.5031 18.2297 11.6102C17.2556 12.2729 15.2601 13.8325 14.3308 16.0273C13.0727 18.9944 13.1624 20.4468 13.9073 22.2206C14.3557 23.2894 13.8351 23.5161 13.5735 23.556C13.3194 23.5958 13.0852 23.4264 12.8983 23.2495C12.3627 22.7388 11.9766 22.0736 11.7922 21.3561C11.7523 21.2017 11.5506 21.1593 11.4584 21.2864C10.7608 22.2505 10.3996 23.7976 10.3821 24.8913C10.3273 28.272 13.1201 31.0124 16.4982 31.0124C20.7559 31.0124 23.8575 26.3038 21.4111 22.3676C20.701 21.2216 20.0334 20.4717 19.2063 19.408Z" fill="url(#paint1_radial_1584_28493)" />
            <defs>
                <radialGradient id="paint0_radial_1584_28493" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(15.7449 31.0871) rotate(-179.751) scale(17.5854 28.8541)">
                    <stop offset="0.3144" stop-color="#FF9800" />
                    <stop offset="0.6616" stop-color="#FF6D00" />
                    <stop offset="0.9715" stop-color="#F44336" />
                </radialGradient>
                <radialGradient id="paint1_radial_1584_28493" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.7324 13.588) rotate(90.5787) scale(18.3997 13.8472)">
                    <stop offset="0.2141" stop-color="#FFF176" />
                    <stop offset="0.3275" stop-color="#FFF27D" />
                    <stop offset="0.4868" stop-color="#FFF48F" />
                    <stop offset="0.6722" stop-color="#FFF7AD" />
                    <stop offset="0.7931" stop-color="#FFF9C4" />
                    <stop offset="0.8221" stop-color="#FFF8BD" stop-opacity="0.804" />
                    <stop offset="0.8627" stop-color="#FFF6AB" stop-opacity="0.529" />
                    <stop offset="0.9101" stop-color="#FFF38D" stop-opacity="0.2088" />
                    <stop offset="0.9409" stop-color="#FFF176" stop-opacity="0" />
                </radialGradient>
            </defs>
        </svg>
    )
}

export default FireEmoji;
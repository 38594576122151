import { Icon } from "./icons-props";

const CallApi: Icon = (props) => {
    const { width, height, color, backgroundColor } = props;
    return (
      <svg width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2132_61094)">
          <path d="M7.0001 7L2.41794 11.5822C2.18717 11.8129 2.18717 12.1871 2.41794 12.4178L7.0001 17" stroke={color || "#212121"} stroke-width="2" stroke-linecap="round"/>
          <path d="M16.9999 7L21.5821 11.5822C21.8128 11.8129 21.8128 12.1871 21.5821 12.4178L16.9999 17" stroke={color || "#212121"} stroke-width="2" stroke-linecap="round"/>
          <path d="M15 2L9 22" stroke={color || "#212121"} stroke-width="2" stroke-linecap="round"/>
        </g>
        <defs>
          <clipPath id="clip0_2132_61094">
            <rect width="24" height="24" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    );
}

export default CallApi;

import { CustomNodeIconProps } from "../utilities/flowBuilder.utility";

const ABTestingIcon = (props: CustomNodeIconProps) => {
    const {backgroundColor, color, height, width} = props;
    return (
        <svg width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 12H7.597C8.34683 12.0001 9.08704 12.1689 9.76282 12.4938C10.4386 12.8187 11.0327 13.2914 11.501 13.877L12.499 15.123C12.9673 15.7086 13.5614 16.1813 14.2372 16.5062C14.913 16.8311 15.6532 16.9999 16.403 17H21M21 17L18 14M21 17L18 20M21 7H15.922C15.3224 7.00019 14.7305 7.13519 14.1901 7.39501C13.6497 7.65482 13.1746 8.03281 12.8 8.501L11.201 10.5C10.8262 10.9681 10.3509 11.346 9.81033 11.6057C9.26975 11.8653 8.6777 12.0001 8.078 12H6M21 7L18 4M21 7L18 10" stroke={color || "#212121"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
}

export default ABTestingIcon;
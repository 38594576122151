import Modal from "../modal";

const ChatbotLoader = () => {
  return (
    <Modal>
      <div
        style={{
          width: 400,
          height: 180,
          background: "white",
          display: "flex",
          borderRadius: 4,
        }}
      >
        <div className="m-auto">
          <div className="d-flex" style={{ marginBottom: 8 }}>
            <img
              className="m-auto"
              width={72}
              height={72}
              src={require("../../assets/chatbot-loader.gif")}
              alt="Chatbot Loader"
            />
          </div>
          <div className="body-secondary" style={{ color: "#616161" }}>
            Wait while we are loading...
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChatbotLoader;

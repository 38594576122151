import { Icon } from "./icons-props";

const PlusIcon: Icon = (props) => {
    const { width, height, color } = props;
    return (
        <svg
            width={width || "14"}
            height= {height || "14"}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M7.83337 1.16667C7.83337 0.70643 7.46028 0.333334 7.00004 0.333334C6.5398 0.333334 6.16671 0.70643 6.16671 1.16667V6.16667H1.16671C0.70647 6.16667 0.333374 6.53976 0.333374 7C0.333374 7.46024 0.70647 7.83333 1.16671 7.83333H6.16671V12.8333C6.16671 13.2936 6.5398 13.6667 7.00004 13.6667C7.46028 13.6667 7.83337 13.2936 7.83337 12.8333V7.83333H12.8334C13.2936 7.83333 13.6667 7.46024 13.6667 7C13.6667 6.53976 13.2936 6.16667 12.8334 6.16667H7.83337V1.16667Z" fill={color || "#731DCF"}/>
        </svg>
    );
}

export default PlusIcon

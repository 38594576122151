import { Icon } from "./icons-props";

const OtherActionIcon: Icon = (props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="11"
        y="11"
        width="16"
        height="16"
        rx="1.17646"
        stroke="#D53434"
        stroke-width="2"
        mask="url(#path-2-inside-1_131_65)"
      />
      <circle cx="16" cy="19" r="1" fill="#D53434" />
      <circle cx="19" cy="19" r="1" fill="#D53434" />
      <circle cx="22" cy="19" r="1" fill="#D53434" />
      <path
        d="M8.85714 20H8.17646C7.52672 20 7 19.4733 7 18.8235V8.17646C7 7.52672 7.52672 7 8.17646 7H18.8235C19.4733 7 20 7.52672 20 8.17646V9.36364"
        stroke="#D53434"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default OtherActionIcon;

import styled from "styled-components";

export const ErrorBarStyled = styled.div`
  position: relative;
  .error__bar {
    position: absolute;
    z-index: 5;
    width: 100%;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    .alert__icon {
      display: flex;
      align-items: center;
    }
    .error__message {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      margin-left: 8px;
      margin-right: 16px;
    }
    .error__color {
      color: #b92321;
    }
    .warning__color {
      color: #99631b;
    }
    .view {
      cursor: pointer;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-decoration-line: underline;

      color: #731dcf;
    }
  }
  .error__background {
    background: #ffebef;
    border-bottom: 1px solid #ffced4;
  }
  .warning__background {
    background: #fff7e5;
    border-bottom: 1px solid #99631b;
  }
`;




import { Icon } from "./icons-props";

const PromocodeIcon: Icon = (props) => {
    const { width, height, color } = props;
    return (
        <svg width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z" fill={color || "white"} />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2 1C1.44772 1 1 1.44772 1 2V12C1 12.2654 1.10552 12.5199 1.29331 12.7075L9.88271 21.2869C10.1613 21.5658 10.4921 21.787 10.8562 21.9379C11.2204 22.0889 11.6108 22.1666 12.005 22.1666C12.3992 22.1666 12.7896 22.0889 13.1538 21.9379C13.5178 21.787 13.849 21.5655 14.1275 21.2867L21.2971 14.1171L21.2992 14.115C21.858 13.5529 22.1716 12.7926 22.1716 12C22.1716 11.2074 21.858 10.4471 21.2992 9.885L12.7071 1.29289C12.5196 1.10536 12.2652 1 12 1H2ZM19.8817 12.7041L19.8808 12.705L12.7125 19.8733C12.6196 19.9663 12.5093 20.04 12.3879 20.0903C12.2665 20.1407 12.1364 20.1666 12.005 20.1666C11.8736 20.1666 11.7435 20.1407 11.6221 20.0903C11.5007 20.04 11.3904 19.9663 11.2975 19.8733L11.2967 19.8725L3 11.5854V3H11.5858L19.8808 11.295L19.8817 11.2959C20.0674 11.4832 20.1716 11.7362 20.1716 12C20.1716 12.2638 20.0674 12.5168 19.8817 12.7041Z" fill={color || "white"} />
        </svg>
    );
};

export default PromocodeIcon;
import { Icon } from "./icons-props";

const FreshdeskCreateTicketIcon: Icon = (props) => {
    const { width, height, color, backgroundColor } = props;
    return (
        <svg width={width || "20"} height={height || "20"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2132_61122)">
                <path d="M9.96875 -0.000163292H17.48C17.8119 -0.00283259 18.141 0.0606201 18.4482 0.186493C18.7553 0.312366 19.0343 0.498139 19.2689 0.732975C19.5034 0.967811 19.6889 1.24701 19.8144 1.55428C19.94 1.86155 20.003 2.19074 20 2.52265V10.0311C20 15.538 15.5381 19.9998 10.0312 19.9998H9.97437C8.66491 20.001 7.36805 19.7441 6.15794 19.2437C4.94782 18.7434 3.84817 18.0095 2.92185 17.084C1.99552 16.1584 1.26068 15.0594 0.759335 13.8497C0.257986 12.64 -4.15617e-05 11.3434 5.02131e-09 10.0339C5.02132e-09 4.5114 4.45437 0.0567117 9.96875 -0.000163292Z" fill="#25C16F"/>
                <path d="M9.96875 4.45459C7.43969 4.45459 5.38937 6.50459 5.38937 9.03397V12.1477C5.4075 12.9812 6.07875 13.6524 6.91219 13.6705H8.2075V10.0937H6.4575V9.09365C6.56375 7.21053 8.12219 5.7374 10.0087 5.7374C11.8953 5.7374 13.45 7.20772 13.5562 9.09365V10.0937H11.7812V13.673H12.9516V13.7299C12.9391 14.503 12.3156 15.1268 11.5453 15.1362H10.1475C10.0337 15.1362 9.90875 15.193 9.90875 15.3065C9.9115 15.3689 9.93754 15.428 9.98173 15.4722C10.0259 15.5164 10.0851 15.5425 10.1475 15.5452H11.5537C12.5553 15.539 13.3656 14.7287 13.3719 13.7271V13.6133C13.7064 13.538 14.005 13.3503 14.218 13.0815C14.4309 12.8127 14.5454 12.4791 14.5422 12.1362V9.09365C14.5991 6.51428 12.5534 4.4574 9.96281 4.4574L9.96875 4.45459Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_2132_61122">
                    <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default FreshdeskCreateTicketIcon;
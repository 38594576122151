import { COLORS } from "@bikdotai/bik-component-library";
import styled from "styled-components";

export const ErrorsModalStyle = styled.div`
  height: fit-content;
  //max-height: 70vh;
  padding: 0 24px;
  
    .modal {
      &__heading {
        font-family: 'Inter',serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #212121;
        padding-bottom: 8px;
      }
      &__text {
        font-family: 'Inter',serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #616161;
      }
      &__footer {
        display: flex;
        flex-direction: row;
        padding-top: 8px;
        margin-left: auto;
      }
    }
  .discard__text {
    font-family: 'Inter',serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #616161;
    padding-right: 12px;
    padding-top: 10px;
    cursor: pointer;
  }
  a {
    font-size: 12px;
    color: ${COLORS.text.brand} !important;
    font-weight: 500;
    &:hover {
      text-decoration: underline !important;
    }
  }
`

import { COLORS } from "@bikdotai/bik-component-library";
import styled from "styled-components";

export const AiNodeStyle = styled.div`
  .customNode {
    &__header {
      &__icon {
        background: linear-gradient(93deg, #ffe6ee 0.37%, #fff0e7 100%);
      }
      &__menu {
        width: 10px;
      }
    }
    &__body {
      &__description {
        min-height: 52px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }
      &__no-content {
        text-align: center;
        line-height: 24px;
      }
    }
    &__child-nodes {
      gap: 16px;
    }
  }
`;

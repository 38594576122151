import { Icon } from "./icons-props";

const ChevronDownIcon: Icon = (props) => {
  const { color } = props;
  return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.70701 10.7071C7.07704 10.0771 7.52321 9 8.41411 9H15.5857C16.4766 9 16.9228 10.0771 16.2928 10.7071L12.707 14.2929C12.3165 14.6834 11.6833 14.6834 11.2928 14.2929L7.70701 10.7071Z" fill={color || "#212121"}/>
      </svg>
  );
};

export default ChevronDownIcon;

import {useEffect, useRef, useState} from 'react';
import {FirebaseService, PAGES, PRODUCT_TOUR_META} from "../services/firebase/FirebaseService";
import {useAppSelector} from "../state/store";

export const useProductTourMeta = () => {
  const [productTourMeta, setProductTourMeta] = useState<PRODUCT_TOUR_META>();
  const fireBaseService = new FirebaseService(() => {}, () => {});
  const storeId = useAppSelector((state) => state.homeState?.storeId);
  const productTourMetaRef = useRef<PRODUCT_TOUR_META | undefined>();

  useEffect(() => {
    if (!localStorage) {
      return
    }
    const user = localStorage.getItem('journey_login_uid');
    let currentUser = undefined;
    if (user) {
        currentUser = JSON.parse(user);
    }
    if (!productTourMeta && storeId && currentUser) {
      fetchProductTourMeta(storeId, currentUser).then();
    }
  }, [storeId, localStorage]);

  useEffect(() => {
    productTourMetaRef.current = productTourMeta;
  }, [productTourMeta]);

  const fetchProductTourMeta = async (storeId: string, agentId: string) => {
    const productTourMeta = await fireBaseService.fetchProductTourMeta(
      storeId,
      agentId,
    );
    setProductTourMeta(productTourMeta);
  };

  const fetchPageTourStatus = (page: PAGES) => {
    return productTourMetaRef.current?.productTourCompletion?.[page] ?? false;
  };

  const updatePageTourStatus = async (
    storeId: string,
    agentId: string,
    page: PAGES,
  ) => {
    await fireBaseService.setProductTour(storeId, agentId, page);
  };

  return {
    productTourMeta,
    fetchProductTourMeta,
    updatePageTourStatus,
    fetchPageTourStatus,
  };
};
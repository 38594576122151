import {CheckBox, StyledModal} from "@bikdotai/bik-component-library";
import React from "react";

type EscapeConfirmationModalProps = {
    onPositiveButtonClick: () => void;
    onNegativeButtonClick: () => void;
    isChecked: boolean;
    onCheckedChange: () => void;
}


export const EscapeClickConfirmationModal =
    ({
        onPositiveButtonClick,
        onNegativeButtonClick,
        isChecked,
        onCheckedChange,
     }: EscapeConfirmationModalProps
    ) => <StyledModal
    onClose={onNegativeButtonClick}
    zIndex={200}
    open={true}
    headingTitle="The changes made will not be saved. Are you sure?"
    primaryButton={{
        buttonType: "primary",
        buttonText: "Yes",
        onClick: onPositiveButtonClick,
    }}
    secondaryButton={{
        buttonText: "Discard",
        buttonType: "tertiaryGray",
        onClick: onNegativeButtonClick
    }}
    centralContainerStyles={{
        width: '450px'
    }}>
    <div style={{margin: "10px", paddingLeft: "10px"}}>
        <CheckBox
            label={"Don't remind me again"}
            isChecked={isChecked}
            onValueChange={onCheckedChange}/>
    </div>
</StyledModal>;

import { Icon } from "./icons-props";

const SendIcon: Icon = (props) => {
    const { width, height, color } = props;
    return (
        <svg width={width || "20"} height={height || "20"} viewBox="0 0 20 20" fill={color || "none"} xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1782_41160)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.9395 1.09481C18.934 1.08892 18.9283 1.0831 18.9226 1.07735C18.776 0.930829 18.5885 0.850277 18.3969 0.835695C18.3448 0.831697 18.2922 0.832584 18.2397 0.838515C18.1721 0.846116 18.1052 0.861991 18.0406 0.886141L1.39136 6.71339C1.06886 6.82627 0.847938 7.12457 0.83401 7.46597C0.820082 7.80737 1.01597 8.12268 1.3282 8.26145L8.53531 11.4646L11.7385 18.6717C11.8772 18.984 12.1926 19.1798 12.534 19.1659C12.8754 19.152 13.1737 18.9311 13.2865 18.6086L19.1138 1.95913C19.1375 1.89594 19.1532 1.83046 19.1609 1.76422C19.1895 1.522 19.1105 1.27602 18.9395 1.09481ZM15.1619 3.6595L3.92588 7.59211L8.98209 9.83932L15.1619 3.6595ZM10.1606 11.0178L16.3404 4.83801L12.4078 16.074L10.1606 11.0178Z" fill={color || "#9E9E9E"}/>
            </g>
            <defs>
                <clipPath id="clip0_1782_41160">
                    <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default SendIcon;
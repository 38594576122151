const PasteIcon = () => {
    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="action icon">
            <path id="Subtract" fill-rule="evenodd" clip-rule="evenodd" d="M4.99935 2.91667C4.99935 1.99619 5.74554 1.25 6.66602 1.25H11.666C12.5865 1.25 13.3327 1.99619 13.3327 2.91667H14.166C14.8291 2.91667 15.4649 3.18006 15.9338 3.6489C16.4026 4.11774 16.666 4.75362 16.666 5.41667V10.4443C16.5576 10.4261 16.4463 10.4167 16.3327 10.4167H14.9993V5.41667C14.9993 5.19565 14.9116 4.98369 14.7553 4.82741C14.599 4.67113 14.387 4.58333 14.166 4.58333H13.3327C13.3327 5.50381 12.5865 6.25 11.666 6.25H6.66602C5.74554 6.25 4.99935 5.50381 4.99935 4.58333H4.16602C3.945 4.58333 3.73304 4.67113 3.57676 4.82741C3.42048 4.98369 3.33268 5.19565 3.33268 5.41667V15.4167C3.33268 15.6377 3.42048 15.8496 3.57676 16.0059C3.73304 16.1622 3.945 16.25 4.16602 16.25H9.16602V17.5833C9.16602 17.6969 9.17548 17.8083 9.19367 17.9167H4.16602C3.50297 17.9167 2.86709 17.6533 2.39825 17.1844C1.92941 16.7156 1.66602 16.0797 1.66602 15.4167V5.41667C1.66602 4.75363 1.92941 4.11774 2.39825 3.6489C2.86709 3.18006 3.50297 2.91667 4.16602 2.91667H4.99935ZM11.666 4.58333H6.66602V2.91667H11.666V4.58333Z" fill="#212121" />
            <path id="Rectangle 506" d="M10.166 12.4167C10.166 11.8644 10.6137 11.4167 11.166 11.4167H16.3327C16.885 11.4167 17.3327 11.8644 17.3327 12.4167V17.5834C17.3327 18.1356 16.885 18.5834 16.3327 18.5834H11.166C10.6137 18.5834 10.166 18.1356 10.166 17.5834V12.4167Z" stroke="#212121" stroke-width="2" />
        </g>
    </svg>
}

export default PasteIcon;

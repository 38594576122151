import { Icon } from "./icons-props";

const BrowsingFlowIcon: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5485 1.55975C11.4401 1.06441 12.5188 1.04097 13.431 1.49712L15.4875 2.52535C16.0259 2.79456 16.2442 3.4493 15.975 3.98773C15.7057 4.52617 15.051 4.74441 14.5126 4.4752L12.4561 3.44697C12.1875 3.31264 11.8698 3.31954 11.6072 3.46542L5.24449 7.00027L12.2824 10.9102H12.5C13.102 10.9102 13.59 11.3982 13.59 12.0002C13.59 12.3848 13.3909 12.7229 13.09 12.9169V20.1365L18.4462 17.1237C18.7327 16.9625 18.91 16.6593 18.91 16.3306V15.5003C18.91 14.8983 19.398 14.4103 20 14.4103C20.602 14.4103 21.09 14.8983 21.09 15.5003V16.3306C21.09 17.4469 20.4879 18.4764 19.5149 19.0237L13.5089 22.4022C12.5733 22.9284 11.4317 22.9314 10.4933 22.4101L3.4994 18.5246C2.51843 17.9796 1.91003 16.9457 1.91003 15.8235V8.17708C1.91003 7.05489 2.51843 6.02091 3.4994 5.47593L10.5485 1.55975ZM4.09003 15.8235V8.85273L10.91 12.6416V20.1478L4.5581 16.6189C4.26921 16.4585 4.09003 16.1539 4.09003 15.8235Z"
        fill={color || "#1A872C"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.1162 11.6112C19.5773 12.0423 18.8938 12.3 18.15 12.3C16.4103 12.3 15 10.8897 15 9.15002C15 7.41031 16.4103 6 18.15 6C19.8897 6 21.3 7.41031 21.3 9.15002C21.3 9.89378 21.0423 10.5773 20.6112 11.1162L21.8975 12.4026C22.0342 12.5392 22.0342 12.7608 21.8975 12.8975C21.7608 13.0342 21.5392 13.0342 21.4025 12.8975L20.1162 11.6112ZM15.7 9.15002C15.7 7.79691 16.7969 6.7 18.15 6.7C19.5031 6.7 20.6 7.79691 20.6 9.15002C20.6 9.81007 20.339 10.4092 19.9146 10.8497C19.9026 10.8589 19.891 10.869 19.88 10.88C19.869 10.891 19.8589 10.9026 19.8497 10.9146C19.4091 11.339 18.81 11.6 18.15 11.6C16.7969 11.6 15.7 10.5031 15.7 9.15002Z"
        fill={color || "#1A872C"}
        stroke={color || "#1A872C"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default BrowsingFlowIcon;

import {
  DeleteModalStyle,
  DeleteVariablesStyle,
  EnablePodsModalStyle,
  KeywordsModalStyle,
  NewFlowModalStyle,
  RenameModalStyle,
} from "./EntryModal.style";
import UploadFileStyle from "./EntryModal.style";
import { CrossIcon } from "../../../icons";
import BotInput from "../../../ui-components/input";
import BikayiButton from "../../../ui-components/button";
import React, { FunctionComponent, useEffect, useState } from "react";
import TagManager from "../tag-manager";
import { isStoreIdValid } from "../../../services/helpers/StoreHelper";
import UploadMediaIcon from "../../../icons/uploadMediaIcon";
import { FirebaseService } from "../../../services/firebase/FirebaseService";
import { getDownloadURL } from "firebase/storage";
import { useAppSelector } from "../../../state/store";
import { cloneDeep } from "lodash";
import LoaderIcon from "../../../icons/loaderIcon";
import BotDropDown, { DATA_SET } from "../../../ui-components/dropdown";
import BotTextArea from "../../../ui-components/textarea/BotTextArea";
import { getCategories } from "../../../services/helpers/CategoryHelper";
import { categoryDataAction } from "../../../state/categoryState";
import { useDispatch } from "react-redux";
import { flowMetaStateActions } from "../../../state/flowMetaState";
import { voidFunction } from "../../messageBlock/Constants";
import {
  BodySecondary,
  Button,
  Dropdown,
} from "@bikdotai/bik-component-library";
import { captureErrorToSentry } from "../../../utilities/sentryHelper";
import { DropdownOption } from "../../../components/condition-block/types";

export interface JourneyCategoryI {
  id: string;
  name: string;
}

export interface JourneyCategoriesState {
  themes: {
    id: string;
    name: string;
    description: string;
    show_new_tag: boolean;
    sub_themes: JourneyCategoryI[];
  }[];
  other_categories: JourneyCategoryI[];
}

interface NewFlowModalProps {
  addFlow: (
    flowName: string,
    description: string,
    channel: string,
    journeyType: string,
    themes: string[],
    subThemes: string[],
    otherCategories: string[]
  ) => void;
  cancel: () => void;
  isNewFlow?: boolean;
  flowName?: string;
  description?: string;
  storeId: string;
  journeyType?: string;
  themes?: string[];
  subThemes?: string[];
  otherCategories?: string[];
  loading: boolean;
}

export const NewFlowModal = (props: NewFlowModalProps) => {
  const [flowName, setFlowName] = useState("");
  const [errors, setErrors] = useState<{ [name: string]: string }>({});
  const [channel, setChannel] = useState({
    id: "1",
    header: "whatsapp",
  } as DATA_SET);
  const [journeyType, setJourneyType] = useState({
    id: "1",
    header: "marketing",
  } as DATA_SET);
  const [description, setDescription] = useState<string>("");
  const categoryData = useAppSelector<any>((state) => state.categoryState);
  const [themeOptions, setThemeOptions] = useState<DropdownOption[]>([]);
  const [otherCategoriesOptions, setOtherCategoriesOptions] = useState<
    DropdownOption[]
  >([]);

  const [subThemeOptions, setSubThemeOptions] = useState<DropdownOption[]>([]);
  const dispatcher = useDispatch();

  const channels: DATA_SET[] = [
    {
      id: "1",
      header: "whatsapp",
    },
    {
      id: "2",
      header: "instagram",
    },
    {
      id: "3",
      header: "email",
    },
  ];

  const journeyTypes: DATA_SET[] = [
    {
      id: "1",
      header: "marketing",
    },
    {
      id: "2",
      header: "utility",
    },
  ];

  const fetchData = async () => {
    const categories = (await getCategories()) as JourneyCategoriesState;
    dispatcher(categoryDataAction.setJourneyCategories(categories));

    if (!props.isNewFlow) {
      return;
    }

    if (categories?.themes?.length) {
      const tempThemeOptions = categories.themes.map((theme) => {
        return {
          label: theme.name,
          value: theme.id,
          selected: false,
        } as DropdownOption;
      });
      setThemeOptions(tempThemeOptions);
    }

    if (categories?.other_categories?.length) {
      const tempOtherCategoryOptions = categories.other_categories.map(
        (other_category) => {
          return {
            label: other_category.name,
            value: other_category.id,
            selected: false,
          } as DropdownOption;
        }
      );
      setOtherCategoriesOptions(tempOtherCategoryOptions);
    }
  };

  useEffect(() => {
    const selectedThemeIds = themeOptions
      .filter((theme) => theme.selected)
      .map((theme) => theme.value);
    const tempSubThemeOptions = categoryData?.themes
      ?.filter(
        (theme: any) =>
          selectedThemeIds.includes(theme.id) && theme?.sub_themes?.length
      )
      .map((theme: any) => {
        return {
          label: theme.name,
          value: theme.id,
          options: theme?.sub_themes?.map((subTheme: any) => {
            return {
              label: subTheme.name,
              value: subTheme.id,
              selected: false,
            };
          }),
        };
      });
    setSubThemeOptions(tempSubThemeOptions);
  }, [themeOptions]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!props.isNewFlow && categoryData?.themes?.length) {
      setFlowName(props.flowName!);
      setDescription(props.description!);
      const initialJourneyType: DATA_SET = {
        id: props.journeyType && props.journeyType === "marketing" ? "1" : "2",
        header:
          props.journeyType && props.journeyType === "marketing"
            ? "marketing"
            : "utility",
      };
      setJourneyType(initialJourneyType);

      setThemeOptions(
        categoryData?.themes?.map((theme: any) => {
          return {
            label: theme.name,
            value: theme.id,
            selected: props.themes?.includes(theme.id) ?? false,
          };
        }) ?? []
      );
      setOtherCategoriesOptions(
        categoryData?.otherCategories?.map((oth: any) => {
          return {
            label: oth.name,
            value: oth.id,
            selected: props.otherCategories?.includes(oth.id) ?? false,
          };
        }) ?? []
      );
      const tempSubThemeOptions =
        categoryData?.themes
          ?.filter(
            (theme: any) =>
              props.themes?.includes(theme.id) && theme?.sub_themes?.length
          )
          ?.map((theme: any) => {
            return {
              label: theme.name,
              value: theme.id,
              options: theme?.sub_themes?.map((subTheme: any) => {
                return {
                  label: subTheme.name,
                  value: subTheme.id,
                  selected: props.subThemes?.includes(subTheme.id) ?? false,
                };
              }),
            };
          }) ?? [];
      setTimeout(() => {
        setSubThemeOptions(tempSubThemeOptions ?? []);
      }, 500);
    }
  }, [props.isNewFlow, categoryData]);

  const handleChannelChangeDropdown = async (name: string, data: DATA_SET) => {
    setChannel(data as DATA_SET);
  };

  const handleTypeChangeDropdown = async (name: string, data: DATA_SET) => {
    setJourneyType(data as DATA_SET);
  };

  const clickSave = () => {
    if (!flowName) {
      setErrors({
        ...errors,
        ["newFlowName"]: "Flow name is required!",
      });
      return;
    }

    const selectedThemes = themeOptions
      .filter((theme) => theme.selected)
      .map((theme) => theme.value);
    const selectedOtherCategories = otherCategoriesOptions
      .filter((oth) => oth.selected)
      .map((oth) => oth.value);

    const selectedSubThemes = subThemeOptions.flatMap((option) =>
      //@ts-ignore
      option.options
        .filter((subTheme: any) => subTheme.selected)
        .map((subTheme: any) => subTheme.value)
    );

    props.addFlow(
      flowName,
      description,
      channel.header!,
      journeyType.header!,
      selectedThemes,
      selectedSubThemes,
      selectedOtherCategories
    );
  };

  return (
    <NewFlowModalStyle>
      <div className="heading">
        <div className="title">
          {props.isNewFlow ? "New Flow" : "Edit Flow Info"}
        </div>
        <div className="header-icon" onClick={props.cancel}>
          <CrossIcon />
        </div>
      </div>
      <div className="body">
        <BotInput
          name="newFlowText"
          height={"34px"}
          wordCount={40}
          value={flowName}
          label={"Flow Name"}
          error={!!errors["newFlowName"]}
          subText={errors["newFlowName"]}
          type="text"
          onChange={(event) => {
            setErrors({
              ...errors,
              ["newFlowName"]: "",
            });
            setFlowName(event.target.value);
          }}
        />
      </div>
      <div className="body">
        <BotTextArea
          name={"description"}
          isRequired={false}
          id={"description"}
          label={"Short Description"}
          value={description}
          onChange={(event) => {
            setDescription(event.target.value);
          }}
        />
      </div>

      <div style={{ display: "flex", gap: "10px", marginTop: 4 }}>
        <div style={{ flex: 1 }}>
          <BotDropDown
            options={channels!}
            name={"Channel"}
            value={channel.header}
            onChange={handleChannelChangeDropdown}
          />
        </div>
        {channel.header !== "instagram" && (
          <div style={{ flex: 1 }}>
            <BotDropDown
              options={journeyTypes!}
              name={"Journey Type"}
              value={journeyType.header}
              onChange={handleTypeChangeDropdown}
            />
          </div>
        )}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          marginTop: "4px",
        }}
      >
        <BodySecondary>Other Categories</BodySecondary>
        <Dropdown
          isMultiSelect={true}
          options={otherCategoriesOptions}
          onSelect={(options) => {
            const selectedIds = (options as DropdownOption[]).map(
              (option) => option.value
            );
            setOtherCategoriesOptions(
              otherCategoriesOptions.map((theme) => {
                return {
                  ...theme,
                  selected: selectedIds.includes(theme.value),
                };
              })
            );
            return;
          }}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <BodySecondary>Themes</BodySecondary>
        <Dropdown
          isMultiSelect={true}
          options={themeOptions}
          onSelect={(options) => {
            const selectedIds = (options as DropdownOption[]).map(
              (option) => option.value
            );
            setThemeOptions(
              themeOptions.map((theme) => {
                return {
                  ...theme,
                  selected: selectedIds.includes(theme.value),
                };
              })
            );
            return;
          }}
        />
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <BodySecondary>Sub Themes</BodySecondary>
        <Dropdown
          isMultiSelect={true}
          options={subThemeOptions}
          onSelect={(options) => {
            const selectedThemeIds = (options as DropdownOption[]).map(
              (option) => option.value
            );

            const temp = subThemeOptions.map((option) => {
              if (!selectedThemeIds.includes(option.value)) {
                return { ...option };
              }
              const selectedSubThemeIds = options
                //@ts-ignore
                .filter((opt: any) => opt.value === option.value)
                .flatMap((option: any) =>
                  option.options.flatMap((subTheme: any) => subTheme.value)
                );

              return {
                ...option,
                //@ts-ignore
                options: option.options.map((subOption: any) => {
                  return {
                    ...subOption,
                    selected: selectedSubThemeIds.includes(subOption.value),
                  };
                }),
              };
            });

            setSubThemeOptions(temp);
            return;
          }}
        />
      </div>

      <div className="footer">
        <Button
          matchParentWidth
          size="medium"
          isLoading={props.loading}
          buttonText={props.isNewFlow ? "Save and Start" : "Save"}
          onClick={() => {
            clickSave();
            return;
          }}
        />
      </div>
    </NewFlowModalStyle>
  );
};

interface PublishProps {
  storeIdsArray: string[];
  onClickCancel: () => void;
  onClickPublish: (storeIds: string[]) => void;
  flowIds: string[];
  loading: boolean;
}

export const PublishModal: FunctionComponent<PublishProps> = (props) => {
  const [storeIds, setStoreIds] = useState<string[]>(props.storeIdsArray);
  const [errors, setErrors] = useState<{ [name: string]: string }>({});

  useEffect(() => {
    setStoreIds(props.storeIdsArray);
  }, [props.storeIdsArray]);

  const onclickSave = () => {
    if (storeIds.length === 0) {
      setErrors({
        ...errors,
        ["value1"]: "enter storeIds first",
      });
      return;
    }
    props.onClickPublish(storeIds);
  };

  const checkKeyPress = async (e: any) => {
    const input = e.target.value;
    if (e.key === "Enter") {
      if (input === "") {
        return;
      }
      const res = await isStoreIdValid(input);
      if (JSON.stringify(res) === "{}") {
        setErrors({
          ...errors,
          ["value1"]: "You are operating in a unrecognised store",
        });
      }
      const copyArray = [...storeIds];
      copyArray.push(input);
      setStoreIds(copyArray);
      e.target.value = "";
    }
  };

  const onClickRemove = (index: number) => {
    let copyArray = [...storeIds];
    copyArray.splice(index, 1);
    setStoreIds(copyArray);
  };
  return (
    <KeywordsModalStyle>
      <div className="heading">
        <div className="title">Publishing {props.flowIds?.length} flows to</div>
        <div className="cross-icon" onClick={props.onClickCancel}>
          <CrossIcon />
        </div>
      </div>
      <div className="body">
        <div className="body-used">
          <div className="used-label">Store ids list</div>
          <div className="used-keywords" style={{ width: 400 }}>
            {storeIds?.map((val, index) => {
              return (
                <div key={index}>
                  <TagManager
                    textColor={"#616161"}
                    tagColor={"#FAFAFA"}
                    text={val}
                    borderColor={"#E0E0E0"}
                    showCross={true}
                    ClickCross={() => onClickRemove(index)}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="body-add">
          <div className="add-label">Add store ids</div>
          <div className="add-keywords">
            <BotInput
              name=""
              height={"34px"}
              type="text"
              onKeyDown={async (e) => await checkKeyPress(e)}
              error={!!errors["value1"]}
              subText={errors["value1"]}
              onChange={() => {
                setErrors({
                  ...errors,
                  ["value1"]: "",
                });
              }}
            />
          </div>
          <div className="add-footnote">press ENTER to add keywords</div>
        </div>
      </div>
      <div className="footer">
        <Button
          size="medium"
          isLoading={props.loading}
          buttonText="Publish"
          onClick={onclickSave}
        />
        <Button
          buttonText="Cancel"
          buttonType="tertiaryGray"
          size="medium"
          onClick={props.onClickCancel}
        />
      </div>
    </KeywordsModalStyle>
  );
};

interface PasswordModalProps {
  onSubmit: (passKey: string) => void;
  onClickCancel: () => void;
}

export const PasswordModal: FunctionComponent<PasswordModalProps> = (props) => {
  const [password, setPassword] = useState<string>("");

  return (
    <KeywordsModalStyle>
      <div className="heading">
        <div className="title">Enter Password</div>
        <div className="cross-icon" onClick={props.onClickCancel}>
          <CrossIcon />
        </div>
      </div>
      <div className="body">
        <div className="body-add">
          <div className="add-keywords">
            <BotInput
              name=""
              height={"34px"}
              type="password"
              onKeyDown={(e) => setPassword(e.target.value)}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="footer">
        <Button
          buttonText="Submit"
          buttonType="primary"
          size="medium"
          onClick={() => props.onSubmit(password)}
        />
      </div>
    </KeywordsModalStyle>
  );
};

interface keywordsNewArray {
  BEGINS_WITH?: any;
  EXACT?: any;
  CONTAINS?: any;
  INCLUDES?: any;
}
interface KeywordsProps {
  keywordsNewArray?: keywordsNewArray;
  onClickCancel: () => void;
  onClickSave: (val: string[]) => void;
  hideExtras?: boolean;
  saveTo?: string;
}

export const KeywordsModal: FunctionComponent<KeywordsProps> = (props) => {
  const [keywordsListObj, setKeywordsListObj] = useState<any>(
    props?.keywordsNewArray || ({} as keywordsNewArray)
  );

  useEffect(() => {
    if (props?.saveTo && props?.saveTo === "Trigger") {
      onclickSave();
    }
  }, [props?.saveTo]);

  const onclickSave = () => {
    props.onClickSave(keywordsListObj);
  };

  const keywordComponent = (title: string, type: string) => {
    return (
      <div className="body body-div">
        <div className="body-used">
          <div className="used-label">{title}</div>
          <div className="used-keywords">
            {keywordsListObj &&
              keywordsListObj[type] &&
              keywordsListObj[type]?.map((val: any, index: any) => {
                return (
                  <div key={index}>
                    <TagManager
                      textColor={"#616161"}
                      tagColor={"#FAFAFA"}
                      text={val}
                      borderColor={"#E0E0E0"}
                      showCross={true}
                      ClickCross={() => onClickRemove(index, type)}
                    />
                  </div>
                );
              })}
          </div>
        </div>
        <div className="body-add">
          <div className="add-keywords">
            <BotInput
              name=""
              height={"34px"}
              type="text"
              onKeyDown={(e) => checkKeyPress(e, type)}
            />
          </div>
          <div className="add-footnote">press ENTER to add keywords</div>
        </div>
      </div>
    );
  };

  const checkKeyPress = (e: any, type: string) => {
    if (e.key === "Enter") {
      if (e.target.value === "") {
        return;
      }
      let tempkeywordsListObj = { ...keywordsListObj };
      const copyArray = tempkeywordsListObj?.[type]
        ? [...tempkeywordsListObj?.[type]]
        : [];
      copyArray.push(e.target.value);
      tempkeywordsListObj[type] = copyArray;
      setKeywordsListObj(tempkeywordsListObj);
      e.target.value = "";
    }
  };

  const onClickRemove = (index: number, type: string) => {
    let tempkeywordsListObj = { ...keywordsListObj };
    let copyArray = [...tempkeywordsListObj[type]];
    copyArray.splice(index, 1);
    tempkeywordsListObj[type] = copyArray;
    setKeywordsListObj(tempkeywordsListObj);
  };
  return (
    <KeywordsModalStyle
      hideExtras={props.hideExtras || false}
      style={{
        width: props?.hideExtras ? "100%" : "568px",
        height: "calc(100% - 172px)",
        background: props?.hideExtras ? "none" : "#FFFFFF",
        boxShadow: props?.hideExtras
          ? "none"
          : "0px 4px 12px rgba(0, 0, 0, 0.12)",
        borderRadius: "4px",
      }}
    >
      <div className="heading">
        <div className="title">Add key words to trigger the flow</div>
        {!props?.hideExtras && (
          <div className="cross-icon" onClick={props.onClickCancel}>
            <CrossIcon />
          </div>
        )}
      </div>
      <div className="subHeading">
        Chatflow will be triggered whenever customers text these keywords.
      </div>
      {keywordComponent("Message is", "EXACT")}
      {keywordComponent("Message begins with", "BEGINS_WITH")}
      {keywordComponent("Message contains the whole word", "CONTAINS")}
      {keywordComponent("Message includes", "INCLUDES")}

      {!props?.hideExtras && (
        <div className="footer">
          <BikayiButton
            buttonName="Save"
            buttonStyle="primary"
            buttonWidth="62px"
            buttonHeight="40px"
            click={onclickSave}
          />
          <div className="discard-text" onClick={props.onClickCancel}>
            Cancel
          </div>
        </div>
      )}
    </KeywordsModalStyle>
  );
};

interface DeleteProps {
  onClickDelete: () => void;
  onClickCancel: () => void;
  flowName: string;
}

export const DeleteModal: FunctionComponent<DeleteProps> = (props) => {
  return (
    <DeleteModalStyle>
      <div className="heading">
        <div className="title">Delete Flow?</div>
        <div className="cross-icon" onClick={props.onClickCancel}>
          <CrossIcon />
        </div>
      </div>
      <div className="body">
        Are you sure you want to delete {props.flowName} flow? Once deleted,
        they cannot be restored.
      </div>
      <div></div>
      <div className="footer">
        <BikayiButton
          buttonName="Delete flow"
          buttonStyle="primary"
          buttonWidth="102px"
          buttonHeight="40px"
          click={props.onClickDelete}
        />
        <div className="discard-text" onClick={props.onClickCancel}>
          Cancel
        </div>
      </div>
    </DeleteModalStyle>
  );
};

interface TriggerProps {
  onClickCancel: () => void;
  onClickSave: (val: string) => void;
}

export const TriggerModal: FunctionComponent<TriggerProps> = (props) => {
  const [userInput, setUserInput] = useState<string>();
  return (
    <RenameModalStyle>
      <div className="heading">
        <div className="title">Edit trigger</div>
        <div className="cross-icon" onClick={props.onClickCancel}>
          <CrossIcon />
        </div>
      </div>
      <div className="body">
        <BotInput
          name="renameText"
          height={"34px"}
          label={"Trigger Name"}
          type="text"
          value={userInput}
          onChange={(e) => {
            setUserInput(e.target.value.toString());
          }}
        />
      </div>
      <div className="footer">
        <BikayiButton
          buttonName="Save"
          buttonStyle="primary"
          buttonWidth="62px"
          buttonHeight="40px"
          click={() => props.onClickSave(userInput || "")}
        />
        <div className="discard-text" onClick={props.onClickCancel}>
          Cancel
        </div>
      </div>
    </RenameModalStyle>
  );
};

interface RenameProps {
  onClickCancel: () => void;
  onClickSave: (val: string) => void;
}

const RenameModal: FunctionComponent<RenameProps> = (props) => {
  const [userInput, setUserInput] = useState<string>();
  return (
    <RenameModalStyle>
      <div className="heading">
        <div className="title">Rename Flow</div>
        <div className="cross-icon" onClick={props.onClickCancel}>
          <CrossIcon />
        </div>
      </div>
      <div className="body">
        <BotInput
          name="renameText"
          height={"34px"}
          label={"Flow Name"}
          type="text"
          value={userInput}
          onChange={(e) => {
            setUserInput(e.target.value.toString());
          }}
        />
      </div>
      <div className="footer">
        <BikayiButton
          buttonName="Save"
          buttonStyle="primary"
          buttonWidth="62px"
          buttonHeight="40px"
          click={() => props.onClickSave(userInput || "")}
        />
        <div className="discard-text" onClick={props.onClickCancel}>
          Cancel
        </div>
      </div>
    </RenameModalStyle>
  );
};

export default RenameModal;

interface FileUploadProps {
  flowId: string;
  onClickCancel: () => void;
  onFileUpload: (fileMetaData: any) => void;
}

export const UploadFile = (props: FileUploadProps) => {
  const firebaseService = new FirebaseService(voidFunction, voidFunction);
  const [error, setError] = useState<string>("");
  const homeState = useAppSelector((state) => state.homeState);
  const flowMetaState = useAppSelector((state) => state.flowMetaState);
  const [uploadState, setUploadState] = useState<string>("");
  const dispatcher = useDispatch();

  const handleFileUpload = (target: any) => {
    setError("");
    const file = target?.files?.[0];
    const error = validateFile(file);
    setError(error);
    if (!error) uploadMedia(file);
  };

  const validateFile = (file: File) => {
    if (!file) return "Please upload an video before saving!";
    else if (!!!file.type.match("video.*") && !!!file.type.match("image.*"))
      return "Only video and image are allowed!";
    return "";
  };

  const getFileName = (fileName: string) => {
    const extension = fileName.split(".").pop();
    const date = new Date();
    const randomString = Math.floor(10000 + Math.random() * 90000);
    return date.getTime().toString() + randomString + "." + extension;
  };

  const getMediaPath = (path: string) => {
    return `chatbot/${homeState.storeId}/${path}`;
  };

  const formatFileSize = (bytes: number) => {
    if (!bytes) return "0 Bytes";
    const k = 1024;
    const dm = 2;
    const sizes = ["Bytes", "KB", "MB"];
    const power = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, power)).toFixed(dm))} ${
      sizes[power]
    }`;
  };

  const getMetaData = (file: File, url: string, fileName: string) => {
    return {
      mediaType: file?.type?.split("/")[0] || "",
      mediaUrl: url,
      mediaSize: formatFileSize(file.size) || "0 Bytes",
      mediaName: fileName,
    };
  };

  const uploadMedia = async (file: File) => {
    if (!error) {
      setUploadState("uploading");
      const fileName = getFileName(file!.name);
      const uploadRef = firebaseService.getUploadRef(
        file!,
        getMediaPath(fileName)
      );
      uploadRef.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          if (percent === 100) {
            setUploadState("uploaded");
          }
        },
        (error) => {
          captureErrorToSentry(error, `Error in uploading media modals`);
        },
        () => {
          getDownloadURL(uploadRef.snapshot.ref).then((url) => {
            const meta = getMetaData(file!, url, fileName);
            const flow_metaData: any = cloneDeep(flowMetaState.flowMeta);
            flow_metaData[props.flowId] = {
              ...flow_metaData[props.flowId],
              ...meta,
            };
            firebaseService
              .saveMediaMetaData(
                flow_metaData[props.flowId],
                props.flowId,
                homeState.storeId
              )
              .then(
                (data) => {
                  dispatcher(
                    flowMetaStateActions.setFlowMeta({
                      flowMeta: flow_metaData,
                      updateFlowMeta: true,
                    })
                  );
                  setUploadState("");
                  props.onFileUpload(meta);
                  props.onClickCancel();
                },
                (err) => {
                  setUploadState("");
                  props.onClickCancel();
                }
              );
          });
        }
      );
    }
  };

  return (
    <UploadFileStyle>
      <div className="heading">
        <div className="title">
          <p>Upload media</p>
        </div>
        <div className="cross-icon" onClick={props.onClickCancel}>
          <CrossIcon />
        </div>
      </div>
      <div className="body">
        {!uploadState && (
          <div className="upload__section">
            <label htmlFor="file_upload">
              <UploadMediaIcon />
            </label>
            <input
              id="file_upload"
              type="file"
              accept="video/*, image/*"
              onChange={(event: React.FormEvent) =>
                handleFileUpload(event.target)
              }
            />
          </div>
        )}
        {uploadState && uploadState === "uploading" && <UploadingMedia />}
        {uploadState && uploadState === "uploaded" && <UploadedMedia />}
        {error && (
          <div className="upload__error">
            <p className="error-text">{error}</p>
          </div>
        )}
      </div>
    </UploadFileStyle>
  );
};

const UploadingMedia = () => {
  return (
    <div className="uploading__section">
      <LoaderIcon />
      <h2>Uploading Media...</h2>
      <p>Please wait, this might take a few seconds.</p>
    </div>
  );
};

const UploadedMedia = () => {
  return (
    <div className="uploaded__section">
      <h2>Media Uploaded Successfully!</h2>
    </div>
  );
};

interface DeleteVairiableProps {
  onClickCancel: any;
  onClickDelete: any;
}

export const DeleteVariablesModal: FunctionComponent<DeleteVairiableProps> = (
  props
) => {
  return (
    <DeleteVariablesStyle>
      <div className="header">
        <div className="header__text">Delete variable?</div>
        <div className="header__cross_icon" onClick={props.onClickCancel}>
          <CrossIcon />
        </div>
      </div>
      <div className="body">
        Are you sure you want to delete this variable? This variable might be in
        use in the flows.
      </div>
      <div className="footer">
        <div className={"footer__cancel_text"} onClick={props.onClickCancel}>
          Cancel
        </div>
        <div>
          <BikayiButton
            buttonName="Delete"
            buttonStyle="primary"
            backgroundColor="#D53434"
            buttonWidth="62px"
            buttonHeight="40px"
            click={props.onClickDelete}
          />
        </div>
      </div>
    </DeleteVariablesStyle>
  );
};
interface PodEnablementProps {
  enabledPods: string[];
  onClickCancel: () => void;
  onClickSave: (val: string[]) => void;
}

export enum PODS {
  STORE = "STORE",
  CAMPAIGN = "CAMPAIGN",
  DM = "DM",
  CRM = "CRM",
}

export const PodEnableModal: FunctionComponent<PodEnablementProps> = (
  props
) => {
  const [pods, setPods] = useState(props.enabledPods || []);
  const addToPods = (podName: string) => {
    if (pods.indexOf(podName) === -1) {
      const temp = [...pods, podName];
      setPods(temp);
    } else {
      const temp = [...pods];
      temp.splice(pods.indexOf(podName), 1);
      setPods(temp);
    }
  };
  return (
    <RenameModalStyle>
      <div className="heading">
        <div className="title">Enable</div>
        <div className="cross-icon" onClick={props.onClickCancel}>
          <CrossIcon />
        </div>
      </div>

      <div className="body">
        <EnablePodsModalStyle>
          {Object.keys(PODS).map((key) => {
            return (
              <div className="checkbox-length">
                <input
                  type="checkbox"
                  value={key}
                  onClick={(event) => {
                    addToPods(key);
                    event.stopPropagation();
                  }}
                  checked={pods.indexOf(key) !== -1}
                />
                {key}
              </div>
            );
          })}
        </EnablePodsModalStyle>
      </div>
      <div className="footer" style={{ marginTop: "auto" }}>
        <BikayiButton
          buttonName="Save"
          buttonStyle="primary"
          buttonWidth="62px"
          buttonHeight="40px"
          click={() => props.onClickSave(pods)}
        />
        <div className="discard-text" onClick={props.onClickCancel}>
          Cancel
        </div>
      </div>
    </RenameModalStyle>
  );
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotAnEvent } from "../app/entry-point/Constants";
import { EventPropertyState } from "./storeDataStateType";

const initialState: EventPropertyState = {
    data: {},
}

const EventPropertySlice = createSlice({
    name: 'EventProperty',
    initialState: initialState,
    reducers: {
        insertProperty: (state, action: PayloadAction<EventPropertyState>) => {
            const {data} = action.payload;
            if (data && Object.keys(data).length) {
                for (const [key, value] of Object.entries(data)) {
                    if (value && Array.isArray(value) && value.length) {
                        state.data[key] = value;
                    }
                }
            }
            return state;
        },
        deleteProperty: (state, action: PayloadAction<EventPropertyState>) => {
            const {data} = action.payload;
            if (data && data.length) {
                for (const key of (data as Array<string>)) {
                    delete state.data[key];
                }
            }
            return state;
        }
    }
});

export const EventPropertyActions = EventPropertySlice.actions;
export default EventPropertySlice;

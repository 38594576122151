import { Icon } from "./icons-props";

const GoswiftIcon: Icon = (props) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_5925_40180" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                <path d="M19.879 0.5H0V19.9118H19.879V0.5Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_5925_40180)">
                <path d="M13.7915 2.51875L19.2236 18.5L0.5 16.7725V2L13.7915 2.51875Z" fill="#4EAC5B"/>
                <path d="M7.91267 13.5302C7.48227 13.5311 7.05322 13.4804 6.63422 13.3791C6.25122 13.2918 5.88537 13.1385 5.55207 12.9256C5.40152 12.8246 5.31012 12.7002 5.2779 12.5526C5.24434 12.4129 5.25637 12.2657 5.31212 12.1338C5.33607 12.0724 5.37183 12.0167 5.41726 11.9699C5.46269 11.9231 5.51682 11.8863 5.57647 11.8616C5.69707 11.8112 5.83577 11.8364 5.99262 11.9374C6.27632 12.1113 6.58347 12.241 6.90412 12.3223C7.23447 12.4042 7.57327 12.4449 7.91312 12.4434C8.42837 12.4434 8.80377 12.3543 9.03932 12.1761C9.27482 11.9979 9.39232 11.7676 9.39172 11.4851C9.39172 11.2503 9.30847 11.0655 9.14197 10.9307C8.97542 10.796 8.69027 10.685 8.28652 10.5978L7.20907 10.3657C5.97922 10.0956 5.3643 9.44625 5.3643 8.41755C5.3643 7.97365 5.47836 7.587 5.70647 7.2576C5.93462 6.92825 6.25127 6.67282 6.65647 6.49139C7.06167 6.30996 7.52857 6.2191 8.05727 6.2188C8.43447 6.21689 8.81017 6.26786 9.17402 6.37029C9.51192 6.46435 9.83277 6.61426 10.124 6.81415C10.1836 6.8533 10.235 6.9042 10.2754 6.9639C10.3158 7.0236 10.3443 7.09095 10.3593 7.16205C10.3861 7.2918 10.3687 7.42705 10.3101 7.5452C10.2817 7.60275 10.2424 7.65385 10.1944 7.6954C10.1464 7.73695 10.0907 7.7681 10.0308 7.78695C9.90357 7.83095 9.75502 7.8025 9.58507 7.7015C9.35557 7.5651 9.10822 7.4632 8.85067 7.399C8.58727 7.3366 8.31767 7.30615 8.04742 7.30825C7.59717 7.30825 7.24457 7.40575 6.98967 7.60065C6.73472 7.7956 6.60742 8.051 6.60767 8.3669C6.60767 8.6091 6.68597 8.8024 6.84252 8.94685C6.99907 9.0913 7.26752 9.2036 7.64792 9.28375L8.71722 9.5158C9.36392 9.6567 9.84557 9.8785 10.1621 10.1812C10.4786 10.4839 10.637 10.8873 10.6373 11.3913C10.6442 11.7914 10.5276 12.1834 10.3041 12.5107C10.082 12.8337 9.76687 13.0841 9.35882 13.262C8.95077 13.4399 8.46872 13.5293 7.91267 13.5302Z" fill="white"/>
            </g>
        </svg>
    );
}

export default GoswiftIcon;

import styled from "styled-components";

export const PickerStyle = styled.div<{
  width: string;
  height: string;
}>`
  width: ${(props) => props.width};
  .screen {
    max-height: ${(props) => props.height};
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 0 !important;
    }
    min-height: ${(props) => props.height};
  }
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 4px;

  .search-bar {
    padding: 12px 16px 12px 16px;
    background: #ffffff;
    position: sticky;
    top: 56px;
    height: 76px;
  }
`;

export const ErrorScreen = styled.div`
  .err-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 300px;
    min-height: 300px;
    background: white;
    row-gap: 16px;
    text-align: center;

    &__icon_holder {
      width: 88px;
      height: 88px;
      background: #f0f0f0;
      border-radius: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__desc {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 320px;
      row-gap: 4px;

      &__title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #212121;
      }

      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #616161;
    }
  }
`;

export const LimitExceedWarning = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 28px;
  align-items: center;
  background: #ffebef;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  .text {
    ${(props) => props.theme.fonts.bodySecondary};
    color: ${(props) => props.theme.colors.content.primary};
  }
`;

export const RowCardStyle = styled.div<{
  width: string;
  height: string;
  paddingRight?: string;
  paddingLeft?: string;
  paddingBottom?: string;
  paddingTop?: string;
  backgroundColor?: string;
  alignItems?: string;
  justifyContent?: string;
  border?: boolean;
}>`
  min-width: ${(props) => props.width};
  max-width: ${(props) => props.width};
  height: ${(props) => props.height};
  ${(props) =>
    props.paddingBottom ? `padding-bottom: ${props.paddingBottom}` : "0px"};
  ${(props) =>
    props.paddingRight ? `padding-right: ${props.paddingRight}` : "0px"};
  ${(props) =>
    props.paddingLeft ? `padding-left: ${props.paddingLeft}` : "0px"};
  ${(props) => (props.paddingTop ? `padding-top: ${props.paddingTop}` : "0px")};
  ${(props) =>
    props.backgroundColor
      ? `background-color: ${props.backgroundColor}`
      : "#FFFFFF"};
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  align-items: ${(props) =>
    props.alignItems ? props.alignItems : "flex-start"};
  border: ${(props) => (props.border ? "1px solid #e0e0e0" : "")};
  border-radius: 4px;
  #right {
    margin-left: auto;
  }
`;

export const ColumnCardStyle = styled.div<{
  height: string;
  paddingRight?: string;
  paddingLeft?: string;
  paddingBottom?: string;
  paddingTop?: string;
}>`
  max-height: ${(props) => props.height};
  width: fit-content;
  height: fit-content;
  ${(props) =>
    props.paddingBottom ? `padding-bottom: ${props.paddingBottom}` : ""};
  ${(props) =>
    props.paddingRight ? `padding-right: ${props.paddingRight}` : ""};
  ${(props) => (props.paddingLeft ? `padding-left: ${props.paddingLeft}` : "")};
  ${(props) => (props.paddingTop ? `padding-top: ${props.paddingTop}` : "")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-overflow: ellipsis;
`;

export const CheckBoxStyle = styled.div<{
  selected: boolean;
}>`
  height: 18px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(props) => (!props.selected ? "2px solid #212121" : "")};
  border-radius: 4px;
  margin-left: auto;
  margin-right: 16px;
  background: ${(props) => (props.selected ? "#1A872C" : "#FFFFFF")};
`;

import * as React from "react";
import { memo } from "react";
import { Icon } from "./icons-props";

const SvgComponent: Icon = ({color}) => {
    const isMobile = true;
    return (
        <>
            {isMobile ? (
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.41107 6.91083C9.73651 6.58539 10.2641 6.58539 10.5896 6.91083L15.5896 11.9108C15.915 12.2363 15.915 12.7639 15.5896 13.0893C15.2641 13.4148 14.7365 13.4148 14.4111 13.0893L10.0003 8.67859L5.58958 13.0893C5.26414 13.4148 4.73651 13.4148 4.41107 13.0893C4.08563 12.7639 4.08563 12.2363 4.41107 11.9108L9.41107 6.91083Z"
                        fill={color ? color : "#474B52"}
                    />
                </svg>
            ) : (
                <svg
                    id='test2'
                    width="24"
                    height="24"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.41107 6.91083C9.73651 6.58539 10.2641 6.58539 10.5896 6.91083L15.5896 11.9108C15.915 12.2363 15.915 12.7639 15.5896 13.0893C15.2641 13.4148 14.7365 13.4148 14.4111 13.0893L10.0003 8.67859L5.58958 13.0893C5.26414 13.4148 4.73651 13.4148 4.41107 13.0893C4.08563 12.7639 4.08563 12.2363 4.41107 11.9108L9.41107 6.91083Z"
                        fill={color ? color : "#474B52"}
                    />
                </svg>
            )}
        </>
    );
};

const ArrowUp = memo(SvgComponent);
export default ArrowUp;

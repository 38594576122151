const DownloadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        d="M10.8334 2.49996C10.8334 2.03972 10.4603 1.66663 10 1.66663C9.53978 1.66663 9.16669 2.03972 9.16669 2.49996V10.4881L6.42261 7.74404C6.09717 7.4186 5.56954 7.4186 5.2441 7.74404C4.91866 8.06947 4.91866 8.59711 5.2441 8.92255L9.41017 13.0886C9.41218 13.0906 9.4142 13.0926 9.41623 13.0946C9.49485 13.1718 9.58498 13.2303 9.68103 13.2701C9.77856 13.3105 9.88544 13.333 9.99752 13.3333L10 13.3333L10.0025 13.3333C10.2289 13.3326 10.434 13.2417 10.5838 13.0946C10.5858 13.0926 10.5879 13.0906 10.5899 13.0886L14.7559 8.92255C15.0814 8.59711 15.0814 8.06947 14.7559 7.74404C14.4305 7.4186 13.9029 7.4186 13.5774 7.74404L10.8334 10.4881V2.49996Z"
        fill="currentColor"
      />
      <path
        d="M2.50002 11.6666C2.96026 11.6666 3.33335 12.0397 3.33335 12.5V15.8333C3.33335 16.0543 3.42115 16.2663 3.57743 16.4225C3.73371 16.5788 3.94567 16.6666 4.16669 16.6666H15.8334C16.0544 16.6666 16.2663 16.5788 16.4226 16.4225C16.5789 16.2663 16.6667 16.0543 16.6667 15.8333V12.5C16.6667 12.0397 17.0398 11.6666 17.5 11.6666C17.9603 11.6666 18.3334 12.0397 18.3334 12.5V15.8333C18.3334 16.4963 18.07 17.1322 17.6011 17.6011C17.1323 18.0699 16.4964 18.3333 15.8334 18.3333H4.16669C3.50365 18.3333 2.86776 18.0699 2.39892 17.6011C1.93008 17.1322 1.66669 16.4963 1.66669 15.8333V12.5C1.66669 12.0397 2.03978 11.6666 2.50002 11.6666Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DownloadIcon;

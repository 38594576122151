import { useEffect } from "react";

export function useOutside(
  ref: any,
  cb: (e: any) => void,
  excludeRefs: any[] = []
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      let isOutside = true;
      if (ref.current && ref.current.contains(event.target)) {
        isOutside = false;
      }
      excludeRefs.forEach((excludeRef) => {
        if (
          excludeRef.current &&
          excludeRef.current.contains(event.target as Node)
        ) {
          isOutside = false;
        }
      });

      if (isOutside) {
        cb(event.target);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref, excludeRefs]);
}

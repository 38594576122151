import styled from "styled-components";

export const EditorHeaderStyled = styled.div<{
  isCarousel?: boolean;
}>`
  .tool__box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    position: absolute;
    right: ${(props) => (props.isCarousel ? "76px" : 0)};
    .ql-toolbar {
      padding: 0px;
    }
  }

  .add_variable {
    position: absolute;
    ${(props) => props.theme.fonts.bodyCaption};
    color: ${(props) => props.theme.colors.content.secondary};
    line-height: 16px !important;
    margin-top: 4px;
  }

  .subText {
    position: absolute;
    ${(props) => props.theme.fonts.bodyCaption};
    color: grey;
    padding-top: 4px;
  }

  .error__texty {
    position: absolute;
    padding-top: 2px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #b92321 !important;
  }

  .errorText {
    color: #b92321 !important;
  }

  .toolBar {
    padding: 4px;
    display: flex;
    max-width: 320px;
    margin-left: auto;
    align-items: center;
    .insertStar {
      display: flex;
      align-items: center;
      margin: 4px 6px;
      justify-content: center;
      img {
        width: 15px;
      }
    }
    .ql-bold {
      padding: 0 6px;
      display: flex;
      align-items: center;
      margin: 4px 6px;
      svg {
        .ql-stroke {
          stroke: #ffffff !important;
        }
      }
    }

    .ql-italic {
      margin: 4px 6px;
      padding: 0 6px;
      display: flex;
      align-items: center;
      svg {
        .ql-stroke {
          stroke: #ffffff !important;
        }
      }
    }
    .ql-strike {
      margin: 4px 6px;
      svg {
        .ql-stroke {
          stroke: #ffffff !important;
        }
        .ql-fill {
          fill: #ffffff !important;
        }
      }
    }
    .addVariable {
      display: flex;
      grid-gap: 8px;
      color: white;

      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;

      padding: 2px 6px;
      margin: 6px;

      cursor: pointer;
      .add {
        display: flex;
        align-items: center;
        img {
          width: 20px;
        }
      }
    }
    .highlight {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 4px;
    }

    .ql-picker {
      color: white !important;
      .ql-active {
        color: ${(props) => props.theme.colors.background.content};
        svg {
          .ql-stroke {
            stroke: ${(props) => props.theme.colors.background.content} !important;
          }
        }
      }
      .ql-picker-label:hover:before {
        color: ${(props) => props.theme.colors.background.content};
      }
      .ql-picker-label:hover svg {
        .ql-stroke {
          stroke: ${(props) => props.theme.colors.background.content} !important;
        }
      }
    }

    .ql-expanded {
      .ql-picker-label {
        color: #FFFFFF;
        border: none;
        svg {
          .ql-stroke {
            stroke: #ffffff !important;
          }
        }
      }
      .ql-picker-options {
        background-color: #212121;
        border: none;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 12px;
        border-radius: 6px;
        span.ql-picker-item::before {
          color: #FFFFFF;
        }
        .ql-picker-item:hover:before {
          color: ${(props) => props.theme.colors.background.brand};
        }
      }
      .ql-expanded .ql-picker-label {
        border: none;
      }
    }
  }
`;

import styled from "styled-components";

export const TableStyle = styled.div`
  .description-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #616161;
    white-space: initial;
    word-wrap: break-word;
    padding-right: 4px;
  }
  .category-style {
    display: flex;
    flex-direction: row;
    gap: 16px;
    &__text {
      flex: 1;
    }
    &__span {
      flex: 0;
    }
  }
    table {
      border-spacing: unset;
      width: 100%;
      height: 100%;
      
      thead {
        height: 40px;
        background-color: #E0E0E0;
        
        td {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  .table-wrapper {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
  .flow-table {
    
    td:first-child, th:first-child {
      position: sticky;
      left: 0;
      z-index: 1;
      //background-color: white;
    }

    td:nth-child(2), th:nth-child(2) {
      position: sticky;
      left: 48px;
      z-index: 1;
    }
    
    td:nth-child(10), th:nth-child(11) {
      position: sticky;
      right: 0;
      z-index: 1;
    }
  }
  table > thead > tr {
    gap: 12px;
    border-bottom: 1px solid grey;
  }
  table > thead > tr {
    background: #FAFAFA;
  }
  table > thead > tr > th {
    background: #FAFAFA;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: black;
    height: 40px;
    text-align: left;
    padding-left: 8px;
  }
  table > thead > td {
    padding-left: 8px;
  }
  table > tbody > tr > td {
    height: 56px;
    border-bottom: 1px solid #E0E0E0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #212121;
    padding-left: 8px;
  }
  
  .keywords-list {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .checkbox-length {
    background-color: white;
  }
  .description-length {
    background-color: white;
    box-shadow: 4px 0px 8px -2px #0000001f;
  }
  .more-length {
    background-color: white;
    box-shadow: 0px 4px 8px 2px #0000001f;
  }
  .archive-button {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #731DCF;
    cursor: pointer;
  }
`

export const EntryPointStyle = styled.div `
  display: flex;
  flex-direction: row;
  height: calc(100vh - 60px);
  .grid {
    &__left {
      height: 100%;
      height: inherit;
      flex: .25;
      border-right: 1px solid #E0E0E0;
      padding: 24px;
    }
    &__right {
      flex: 1;
      height: fit-content;
      max-height: calc(100% - 50px);
      margin: 24px;
      border: 1px solid #E0E0E0;
      border-radius: 6px;
      overflow-x: auto;
    }
  }
  .store__input {
    padding-bottom: 35px;
  }
  .right {
    &__header {
      padding: 12px 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    &__footer {
      height: 50px;
      display: flex;
      flex-direction: row;
      padding: 0 16px;
      justify-content: space-between;
      &__left {
        flex: .20;
      }
      &__right {
        padding-top: 8px;
      }
    }
  }
  .header {
    &__input {
      width: 280px;
    }
    &__buttons {
      display: flex;
      flex-direction: row;
      gap: 8px;
      padding-top: 5px;
    }
  }
  
  .header {
    &__tabs {
      display: flex;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      cursor: pointer;
    }
    
    &__tab-elements {
      padding: 18px 24px;
    }

    &__tab-elements-selected {
      color: #731DCF;
      padding: 18px 24px;
      border-bottom: 1px solid #731DCF;
    }
  }
  
  .notification {
    height: 20px;
    background-color: #731DCF;
    color: white;
    min-width: 20px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    font-size: 10px;
    font-weight: 700;
    padding: 3px 2px;
  }
  
  .store-info {
    &-heading {
      padding-top: 32px;
      padding-bottom: 16px;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #212121;
    }
    &-content {
      display: flex;
      flex-direction: row;
      padding: 0 5px 8px 5px;
      align-items: center;
    }
    &-left {
      flex: 1;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #212121;
    }
    &-right {
      flex: 1;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #212121;
    }
  }
  
  .active {
    background: #EFE6F9;
    border-radius: 5px;
  }
  
`

export const FilePreviewStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .file {
      &__text {
        color: #731DCF;
        font-size: 14px;
        line-height: 20px;
        text-decoration: underline;
      }
      &__size {
        color: #616161;
        font-size: 12px;
        line-height: 16px;
      }
      &__right {
        margin: 3px 10px 0 0;
      }
  }
`;

export const NoFlow = styled.div`
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 20px;
  padding: 24px;
  p {
    color: #616161;
  }
`;

// @ts-nocheck
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {AutomationTriggerState } from "./storeDataStateType";

const initialState:AutomationTriggerState = {
  triggers: [],
};

const AutomationTriggerSlice = createSlice({
  name: "AutomationTrigger",
  initialState: initialState,
  reducers: {
    insertProperty: (state, action: PayloadAction<AutomationTriggerState>) => {
      let isUpdate = false;
      if (state.triggers.length && state.triggers[0].type === 'keyword') {
        state.triggers.forEach((item, i) => {
          if(item.channelId === action.payload.channelId) {
            isUpdate = true;
            state.triggers[i] = {...state.triggers[i], ...action.payload}
            return state;
          }
        });
      } else {
        state.triggers.forEach((item, i) => {
          if(item.id === action.payload.id) {
            isUpdate = true;
            state.triggers[i] = {...state.triggers[i], ...action.payload}
            return state;
          }
        });
      }
      if(!isUpdate) {
        state.triggers.push(action.payload);
        return state;
      }
    },
    updateProperty: (state, action: any) => {
      state.triggers = action.payload
      return state;
    },
    deleteProperty: (state, action: PayloadAction<{ trigger: any }>) => {
      const { trigger } = action.payload;
      if (trigger.type === "keyword") {
        state.triggers = state.triggers.filter((item) => item.channelId !== trigger.channelId)
      } else {
        const index = state.triggers.findIndex((t) => t.id === trigger.id);
        if (index !== -1) {
          state.triggers.splice(index, 1);
        }
      }
      return state;
    },
    resetProperty: (state) => {
      state.triggers = []
      return state;
    }
  },
});

export const AutomationTriggerActions = AutomationTriggerSlice.actions;
export default AutomationTriggerSlice;

import { StyledModal } from "@bikdotai/bik-component-library";
import ShimmerHelper from "../../../ui-components/shimmer/shimmerHelper";
import { FirebaseService } from "../../../services/firebase/FirebaseService";
import { subDays } from "date-fns";

/**
 * Returns seconds to days ignoring the decimals
 */
export const secondsToDays = (seconds: number): number => {
  const days = seconds / (60 * 60 * 24);
  return Math.floor(days);
};

/**
 * Takes in Iso string as param, returns date in below format
 * 23rd Feb '24
 * 2nd Apr '24
 */
export function formatDateForAddPastProfileModal(isoString: string): string {
  const date = new Date(isoString);

  if (isNaN(date.getTime())) {
    return "";
  }

  const day = new Intl.DateTimeFormat("en", { day: "numeric" }).format(date);
  const month = new Intl.DateTimeFormat("en", { month: "long" }).format(date);
  const year = new Intl.DateTimeFormat("en", { year: "2-digit" }).format(date);

  return `${day}${getDaySuffix(Number(day))} ${month.slice(0, 3)} '${year}`;
}

/**
 * Takes in day as param and returns the suffix.
 * E.g st for 1, nd for 2.
 *
 */
function getDaySuffix(day: number): string {
  if (!day) return "";

  // Only for 11, 12, 13 because for every other 2 digit number, suffix ends with st, nd, rd.
  //That is handled in switch case
  // E.g. 11 -> eleventh, 21 -> twenty first
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

/**
 * Shimmer helper to show while count modal is loading
 */
export const CountModalShimmer = () => {
  return (
    <StyledModal open={true} width={"494px"} wrapperStyle={{ margin: "40px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <ShimmerHelper boxes={[{ width: "234px", height: "40px" }]} />
        <ShimmerHelper boxes={[{ width: "405px", height: "18px" }]} />
        <ShimmerHelper boxes={[{ width: "88px", height: "18px" }]} />

        <ShimmerHelper boxes={[{ width: "450px", height: "60px" }]} />
        <ShimmerHelper boxes={[{ width: "450px", height: "60px" }]} />
        <ShimmerHelper
          style={{ marginTop: "50px" }}
          boxes={[{ width: "450px", height: "60px" }]}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignSelf: "flex-end",
            marginRight: "40px",
            marginTop: "20px",
          }}
        >
          <ShimmerHelper boxes={[{ width: "63px", height: "32px" }]} />
          <ShimmerHelper boxes={[{ width: "141px", height: "32px" }]} />
        </div>
      </div>
    </StyledModal>
  );
};

/**
 *
 * @param channel eligible channel is whatsapp and email
 * @param flowKeyword used to get the type from flowkeyword. type should be event for eligibility
 * @param flowStatus should be true
 * @param isTestMode should be false
 * @returns boolean
 */
export const isPastProfileEligible = (
  channel: string,
  flowKeyword: any,
  flowStatus: boolean,
  isTestMode: boolean
) => {
  if (channel === "instagram") {
    return false;
  }

  if (isTestMode) {
    return false;
  }

  const flowEnabledDate = getFlowEnabledOnDate(flowKeyword);
  const triggerType = flowKeyword?.triggerType === "event";
  const isFlowActive = flowStatus;

  return triggerType && isFlowActive && flowEnabledDate;
};

/**
 * Takes in flowkeyword, returns date in ISO string format
 */
export const getFlowEnabledOnDate = (flowKeyword: any) => {
  const flowEnabledDate = flowKeyword?.firstEnabledOn;
  if (flowEnabledDate) {
    return new Date(flowEnabledDate * 1000).toISOString();
  }
  return "";
};

/**
 * Queries DB for already added past profile details and returns number of days.
 * @param storeId
 * @param flowId
 * @returns number of days which is already added from firestore
 */
export const getAlreadyAddedDays = async (storeId: string, flowId: string) => {
  const firebaseService = new FirebaseService("", "");
  const pastProfileDetails = await firebaseService.getPastProfileDetails(
    storeId,
    flowId
  );

  return pastProfileDetails?.numberOfDays;
};

/**
 *  Queries DB for already added past profile details and returns number of days.
 * @param storeId
 * @param flowId
 * @returns last profile addition date in
 */
export const getLastprofileAdditionDate = async (
  storeId: string,
  flowId: string
) => {
  const firebaseService = new FirebaseService("", "");
  const pastProfileDetails = await firebaseService.getPastProfileDetails(
    storeId,
    flowId
  );
  if (pastProfileDetails) {
    return new Date(
      pastProfileDetails?.addPastProfileTriggeredDate * 1000
    ).toISOString();
  }
};

/**
 * Logic to calculate from date  -
 * Used to get customer count and adding past profiles
 * Logic: Flow enabled date - (current delay days + already added days)
 * If user is adding for fist time, this will be flow enabled date - current delay days
 */
export const calculateFromDate = (
  flowEnabledDate: string,
  maxdelayDays: number,
  alreadyAddedDays: number
) => {
  const fromDate = subDays(
    new Date(flowEnabledDate),
    maxdelayDays + alreadyAddedDays
  );
  return fromDate.toISOString();
};

/**
 * Logic to calculate to date  -
 * Used to get customer count and adding past profiles
 * Logic: Flow enabled date -  already added days.
 * If user is adding for first time, this will be flow enabled date.
 */
export const calculateToDate = (
  flowEnabledDate: string,
  alreadyAddedDays: number
) => {
  const toDate = subDays(new Date(flowEnabledDate), alreadyAddedDays);
  return toDate.toISOString();
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {FlowMetaState} from "./storeDataStateType";

const initialState: FlowMetaState = {
    flowMeta: {},
    updateFlowMeta: false,
    channels: {}
}

const flowMetaStateSlice = createSlice({
    name: 'Flow meta',
    initialState: initialState,
    reducers: {
        setFlowMeta: (state, action: PayloadAction<FlowMetaState>) => {
            return {
                ...state,
                ...action.payload
            };
        },
        setChannels: (state, action: any) => {
            state.channels = {
                ...action.payload
            }
            return state;
        },
        updateFlowMetaState: (state, action: any) => {
            state.updateFlowMeta = action.payload.updateFlowMeta;
            return state;
        },
    }
});

export const flowMetaStateActions = flowMetaStateSlice.actions;
export default flowMetaStateSlice;

import {
  TitleRegular,
  Switch,
  BodySecondary,
  COLORS,
} from "@bikdotai/bik-component-library";
import AlertIcon from "../../icons/alertIcon";
import { TooltipContainer, WarningContainer } from "./style";
import { dasboardUrl } from "../../config";

interface ITestModeTooltip {
  showAlert: boolean;
  channel: string;
  value: boolean;
  onValueChange: (value: boolean) => void;
}

const renderTextContent = (channel: string) => {
  let text = "";
  switch (channel) {
    case "whatsapp":
      text = "No Whatsapp numbers added in test mode settings to test.";
      break;
    case "instagram":
      text = "No Instagram account added in test mode settings to test.";
      break;
    case "email":
      text = "No Email added in test mode settings to test.";
      break;

    default:
      break;
  }
  return text;
};

const TestModeTooltip = ({ channel, onValueChange, value, showAlert }: ITestModeTooltip) => {
  return (
    <TooltipContainer>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TitleRegular>Test mode</TitleRegular>
        <Switch
          value={value}
          type="brand"
          onValueChange={async (value: boolean) => {
            return onValueChange(value)
          }}
        />
      </div>
      <BodySecondary
        style={{ width: 379, color: COLORS.content.secondary, marginBottom: 8 }}
      >
        Test mode will run the journey for the customers added in the test mode
        settings on Journey dashboard.
      </BodySecondary>
      {showAlert && (
        <WarningContainer>
        <AlertIcon width={20} height={20} color={COLORS.content.warning} />
        <div>
          <BodySecondary>
            {renderTextContent(channel)}{" "}
            <a href={`${dasboardUrl}/journeys/settings/test-mode`} target="_blank" rel="noreferrer">
              Go to test settings
            </a>
          </BodySecondary>
        </div>
      </WarningContainer>
      )}
      
    </TooltipContainer>
  );
};

export default TestModeTooltip;

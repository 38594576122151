import { Icon } from "./icons-props";

export const WarnIcon: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "32"}
      height={height || "28"}
      fill={color || "#99631B"}
      viewBox="0 0 32 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0373 1.04424C14.6364 0.706969 15.3122 0.529785 15.9997 0.529785C16.6872 0.529785 17.3631 0.706969 17.9621 1.04424C18.5612 1.38151 19.0632 1.86749 19.4197 2.45527L19.4236 2.46161L30.7169 21.3149L30.7277 21.3334C31.077 21.9382 31.2618 22.624 31.2637 23.3224C31.2657 24.0208 31.0847 24.7076 30.7388 25.3144C30.393 25.9212 29.8942 26.4269 29.2923 26.7811C28.6903 27.1353 28.0061 27.3257 27.3077 27.3334L27.293 27.3335L4.69172 27.3334C3.99332 27.3258 3.3091 27.1353 2.70714 26.7811C2.10517 26.4269 1.60645 25.9212 1.26058 25.3144C0.914707 24.7076 0.733747 24.0208 0.735703 23.3224C0.737659 22.624 0.922462 21.9382 1.27173 21.3334L1.28256 21.3149L12.5759 2.46162L12.5797 2.45527C12.9363 1.86749 13.4383 1.38151 14.0373 1.04424ZM14.8613 3.83563L3.57663 22.6745C3.46306 22.8743 3.403 23.1 3.40236 23.3299C3.40171 23.5627 3.46203 23.7916 3.57732 23.9939C3.69261 24.1961 3.85885 24.3647 4.0595 24.4828C4.2584 24.5998 4.48424 24.6632 4.71491 24.6668H27.2845C27.5152 24.6632 27.741 24.5998 27.9399 24.4828C28.1406 24.3647 28.3068 24.1961 28.4221 23.9939C28.5374 23.7916 28.5977 23.5627 28.5971 23.3299C28.5964 23.1 28.5364 22.8743 28.4228 22.6746L17.1397 3.83828L17.1381 3.83563C17.0194 3.64089 16.8526 3.47985 16.6538 3.36794C16.4542 3.25551 16.2289 3.19645 15.9997 3.19645C15.7706 3.19645 15.5453 3.25551 15.3456 3.36794C15.1468 3.47985 14.9801 3.64089 14.8613 3.83563ZM16 8.66679C16.7364 8.66679 17.3333 9.26374 17.3333 10.0001V15.3335C17.3333 16.0698 16.7364 16.6668 16 16.6668C15.2636 16.6668 14.6667 16.0698 14.6667 15.3335V10.0001C14.6667 9.26374 15.2636 8.66679 16 8.66679ZM16 19.3335C15.2636 19.3335 14.6667 19.9304 14.6667 20.6668C14.6667 21.4032 15.2636 22.0001 16 22.0001H16.0133C16.7497 22.0001 17.3467 21.4032 17.3467 20.6668C17.3467 19.9304 16.7497 19.3335 16.0133 19.3335H16Z"
      />
    </svg>
  );
};

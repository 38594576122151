import React, {FunctionComponent, useState} from 'react';
import {AdminTableStyles} from "../core/TableStyles";

interface OwnProps {
    productList: any[]
}

type Props = OwnProps;

const InternalProductsDashboard: FunctionComponent<Props> = (props) => {

  return (
      <div>
          <div>
              <AdminTableStyles style={{ padding: 16 }}>
                  <h2>Internal Products</h2>
                  <p>Total {props.productList.length} products found.</p>
              </AdminTableStyles>
          </div>
      </div>
  );
};

export default InternalProductsDashboard;

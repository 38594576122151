import {
  AmplitudeKeys,
  BikEvents,
  OrderEvents,
  RecordableEvents,
  TargetPlatform,
} from "./AnalyticsTypes";
import { isProd } from "../config";
import { BikStore } from "@bikdotai/bik-models/growth";
import { AmplitudeEvent } from "@bikdotai/bik-component-library";

const AmplitudeApiKeys = {
  BIK: "a8dc6ea9258a7f40e8eb98900945827f",
  GLOBAL: "284617317bbd02714b2ace1a527727e7",
  isLocal: "",
};

export class RecordEventHelper {
  private static instance: RecordEventHelper;
  static getInstance(): RecordEventHelper {
    if (!RecordEventHelper.instance) {
      RecordEventHelper.instance = new RecordEventHelper();
    }
    return RecordEventHelper.instance;
  }

  init(store: BikStore, roleData?: UserI) {
    if (!isProd) {
      return;
    }

    const eventHelper = AmplitudeEvent.getInstance();
    if (eventHelper.isHelperInitialized()) return;
    const currentUser = roleData
      ? roleData
      : (JSON.parse(localStorage.getItem("Bik/CurrentUser") || "{}") as UserI);
    const amplitudeKey = isProd ? AmplitudeApiKeys.BIK : "";
    eventHelper.init(store, currentUser, amplitudeKey);
  }

  trackEvent(
    target: TargetPlatform,
    eventKey:
      | AmplitudeKeys
      | RecordableEvents
      | OrderEvents
      | BikEvents
      | string,
    properties?: any
  ) {
    const currentUser = JSON.parse(
      localStorage.getItem("Bik/CurrentUser") || "{}"
    );

    const eventPropsWithUserProps = {
      ...properties,
      userId: currentUser.id,
      userRole: currentUser.type,
      storeId: currentUser.storeId,
    };
    const emailRegex = /@(bik\.ai|bikayi\.com)$/;

    if (!isProd || emailRegex.test(currentUser.email ?? "")) {
      return;
    }

    if (target === TargetPlatform.Amplitude) {
      const amplitudeInstance = AmplitudeEvent.getInstance();
      amplitudeInstance.trackEvent(eventKey, eventPropsWithUserProps);
    }
  }
}

export interface UserI {
  id: number;
  name: string;
  photoUrl: string;
  roleId: number;
  type: string;
  storeId?: string;
  teams: TeamI[];
  isAvailable?: boolean;
  email?: string;
  phoneNumber?: string;
  exotelNumber?: string;
  storeName?: string;
  isInternal?: boolean;
}

export interface TeamI {
  id: number;
  name: string;
  members: TeamMemberI[];
  description?: string;
  isDefault?: boolean;
  isAvailable?: boolean;
  storeId: string;
  labels: number[];
  createdAt?: Date;
  updatedAt?: Date;
  workingHours: Record<string, string>;
}

export interface TeamMemberI {
  id: number;
  name: string;
}

import { Icon } from "./icons-props";

const Box: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75162 1.16893C9.13124 0.950176 9.56168 0.835022 9.99984 0.835022C10.438 0.835022 10.8685 0.950186 11.2481 1.16896L11.2498 1.16996L17.0832 4.50328C17.3915 4.68128 17.6574 4.92257 17.8639 5.21021C17.9142 5.26054 17.9589 5.31818 17.9962 5.38271C18.0283 5.43829 18.0534 5.49605 18.0715 5.55491C18.2429 5.89944 18.3328 6.27958 18.3332 6.66579V13.3342C18.3327 13.7726 18.217 14.2032 17.9976 14.5827C17.7782 14.9623 17.4628 15.2775 17.0832 15.4967L17.08 15.4985L11.2498 18.83L11.2483 18.8309C10.984 18.9832 10.695 19.0854 10.3959 19.1334C10.2781 19.1971 10.1432 19.2333 9.99984 19.2333C9.85648 19.2333 9.72159 19.1971 9.60379 19.1334C9.3047 19.0854 9.01571 18.9833 8.75138 18.8309L8.74984 18.83L2.91972 15.4985L2.9165 15.4967C2.53683 15.2775 2.22148 14.9623 2.00208 14.5827C1.78268 14.2032 1.66695 13.7726 1.6665 13.3342V6.66579C1.6669 6.27952 1.75679 5.89932 1.92823 5.55474C1.94639 5.49594 1.97139 5.43824 2.00351 5.38271C2.04081 5.31823 2.08543 5.26062 2.1357 5.21033C2.34225 4.92263 2.60817 4.68131 2.9165 4.50329L2.91972 4.50144L8.75162 1.16893ZM10.8332 17.1485L16.2498 14.0533L16.2512 14.0525C16.3771 13.9795 16.4818 13.8747 16.5546 13.7486C16.6278 13.6221 16.6664 13.4786 16.6665 13.3325V7.1146L10.8332 10.489V17.1485ZM9.1665 10.489V17.1485L3.74984 14.0533L3.74852 14.0525C3.62255 13.9795 3.51791 13.8747 3.44503 13.7486C3.37196 13.6222 3.33338 13.4788 3.33317 13.3328V7.11458L9.1665 10.489ZM10.4197 2.61518L15.8007 5.69001L9.99985 9.0456L4.199 5.69L9.57995 2.61518L9.58317 2.61333C9.70985 2.54019 9.85356 2.50169 9.99984 2.50169C10.1461 2.50169 10.2898 2.54019 10.4165 2.61333L10.4197 2.61518Z"
        fill={color || "#212121"}
      />
    </svg>
  );
};

export default Box;

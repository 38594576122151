import { Icon } from "./icons-props";

const OptionsIcon: Icon = (props) => {
    const { width, height, color } = props;
    return (
        <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.66659 2.16667C3.66659 3.08714 2.92039 3.83333 1.99992 3.83333C1.07944 3.83333 0.333252 3.08714 0.333252 2.16667C0.333252 1.24619 1.07944 0.5 1.99992 0.5C2.92039 0.5 3.66659 1.24619 3.66659 2.16667Z" fill="#212121"/>
            <path d="M3.66659 8C3.66659 8.92047 2.92039 9.66667 1.99992 9.66667C1.07944 9.66667 0.333252 8.92047 0.333252 8C0.333252 7.07953 1.07944 6.33333 1.99992 6.33333C2.92039 6.33333 3.66659 7.07953 3.66659 8Z" fill="#212121"/>
            <path d="M1.99992 15.5C2.92039 15.5 3.66659 14.7538 3.66659 13.8333C3.66659 12.9129 2.92039 12.1667 1.99992 12.1667C1.07944 12.1667 0.333252 12.9129 0.333252 13.8333C0.333252 14.7538 1.07944 15.5 1.99992 15.5Z" fill="#212121"/>
        </svg>

    );
}
 export default OptionsIcon

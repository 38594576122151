import ChatbotBuilder from "./components/chatbot-builder";
import Header from "./components/header";
import { GlobalStyle } from "./global-style";
import { FirebaseService } from "./services/firebase/FirebaseService";
import { Navigate, Route, Routes, useMatch } from "react-router-dom";
import EntryPoint from "./app/entry-point";
import React, { useEffect, useState } from "react";
import Login from "./app/entry-point/loginModule";
import Component404 from "./404";
import { userDataState } from "./state/storeDataStateType";
import InternalAdminDashboardRouter from "./admin/core/InternalAdminDashboardRouter";
import { dasboardUrl, isProd } from "./config";
import { FirebaseAuthHelper } from "./services/firebase/auth/firebaseAuth";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-calendar/dist/Calendar.css";
import "reactflow/dist/style.css";
import AppProviders from "./app-providers";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import { captureErrorToSentry } from "./utilities/sentryHelper";
import {
  StateInterface,
  StateModalComponent,
} from "@bikdotai/bik-component-library";
import { useTimer } from "./utilities/useTimer";

FirebaseService.initializeApp();

const App = () => {
  const [flowName, setFlowName] = useState<string>("");
  const [userData, setUserData] = useState<userDataState>({} as userDataState);
  const [loggedIn, setLoggedIn] = useState(false);
  const [redirectTo404, setRedirectTo404] = useState(false);
  const [validatingUser, setValidatingUser] = useState<boolean>(true);
  const [forceFetch, setForceFetch] = useState<boolean>(false);
  const [showTour, setShowTour] = useState<number>(0);
  const [showRedirectionPopup, setShowRedirectionPopup] = useState(false);
  const [emptyString, storeId, flowId, channel, mode, loginText, tokenValue] =
    window.location.pathname.split("/");

  const { time, startTimer, stopTimer } = useTimer();

  const onSetFlowName = (flowName: string) => {
    setFlowName(flowName);
  };

  const getLoginType = (email: string) => {
    if (!email) return "token";
    const domain = email.split("@")?.[1];
    if (!domain || !["bik.ai", "bikayi.com", "bikhq.co"].includes(domain))
      return "token";
    return "sso";
  };

  useEffect(() => {
    async function getUser() {
      return new Promise(async (resolve, reject) => {
        const user = await FirebaseService.fetchCurrentUser();
        if (!user) {
          setLoggedIn(false);
          setValidatingUser(false);
          reject("User not found");
        } else {
          const authHelper = new FirebaseAuthHelper();

          const projectId = (user as any)?.auth.app._options.projectId;
          if (
            (isProd && projectId === "staging-bikayi") ||
            (!isProd && projectId === "bikayi-chat")
          ) {
            authHelper.signOut();
            setLoggedIn(false);
            reject("Logging out because auth issue");
          }

          setLoggedIn(true);
          const userDetails = {
            uid: user.uid || "",
            email: user.email || "",
            loginType: getLoginType(user.email!),
            agentId: NaN
          };
          setUserData(userDetails);
          setValidatingUser(false);
          localStorage.setItem(`journey_login_uid`, JSON.stringify(user.uid!));
          resolve(true);
        }
      });
    }

    if (!loggedIn && !tokenValue)
      getUser()
        .then()
        .catch((error) => {
          captureErrorToSentry(error, `Error in getUser`);
          handleRedirectionWhenNotAuthenticated();
        });
  }, []);

  /**
   * Redirects to journey dashboard when unauthenticated or token invalid.
   * A set of urls are excluded from this.
   * State of redirectionPopup is handled here.
   */
  const handleRedirectionWhenNotAuthenticated = () => {
    const REDIRECTION_TIMEOUT_MS = 5000;
    const relativeUrlsToBeExcluded = ["/v2/bik-chatbot/flows"];

    if (
      relativeUrlsToBeExcluded.some((url) =>
        window.location.pathname.includes(url)
      )
    ) {
      return;
    }

    if (showRedirectionPopup) return;

    setShowRedirectionPopup(true);
    startTimer(REDIRECTION_TIMEOUT_MS / 1000);

    setTimeout(() => {
      setShowRedirectionPopup(false);
      stopTimer();
      window.location.href = `${dasboardUrl}/journeys/home`;
    }, REDIRECTION_TIMEOUT_MS);
  };

  function onLogin(val: boolean) {
    setLoggedIn(val);
  }

  const onRedirectTo404 = (val: boolean) => {
    setRedirectTo404(val);
  };

  return (
    <AppProviders>
      <GlobalStyle />
      <div className="App">
        <Header
          title={flowName || "Journey Builder"}
          redirect404={redirectTo404}
          onEditClick={() => setForceFetch(true)}
          onAnalyticsClick={() => setForceFetch(true)}
          onShowTourClick={() => {
            setShowTour((prev) => prev + 1);
          }}
        />
        {redirectTo404 && <Component404 />}
        {!redirectTo404 && (
          <Routes>
            <Route path="/:storeId/:flowId">
              <Route
                index
                element={
                  loggedIn && (
                    <ChatbotBuilder userData={userData} showTour={showTour} />
                  )
                }
              />
              <Route
                path="/:storeId/:flowId/:channel/analytics"
                element={
                  loggedIn && (
                    <ChatbotBuilder
                      userData={userData}
                      showTour={showTour}
                      forceFetch={forceFetch}
                    />
                  )
                }
              />
              <Route
                path="/:storeId/:flowId/:channel/edit"
                element={
                  loggedIn && (
                    <ChatbotBuilder
                      userData={userData}
                      showTour={showTour}
                      forceFetch={forceFetch}
                    />
                  )
                }
              />

              <Route
                path="/:storeId/:flowId/:channel/edit/:nodeId"
                element={
                  loggedIn && (
                    <ValidateNodeRoute>
                      <ChatbotBuilder
                        userData={userData}
                        showTour={showTour}
                        forceFetch={forceFetch}
                      />
                    </ValidateNodeRoute>
                  )
                }
              />

              <Route
                path="/:storeId/:flowId/:channel/analytics/login/:token"
                element={
                  loggedIn ? (
                    <ChatbotBuilder showTour={showTour} userData={userData} />
                  ) : (
                    <Login
                      onLogin={onLogin}
                      redirectTo404={onRedirectTo404}
                      validatingUser={validatingUser}
                      mode={"analytics"}
                      handleRedirectionWhenNotAuthenticated={
                        handleRedirectionWhenNotAuthenticated
                      }
                      setUserData={setUserData}
                    />
                  )
                }
              />
              <Route
                path="/:storeId/:flowId/:channel/edit/login/:token"
                element={
                  loggedIn ? (
                    <ChatbotBuilder showTour={showTour} userData={userData} />
                  ) : (
                    <Login
                      onLogin={onLogin}
                      redirectTo404={onRedirectTo404}
                      validatingUser={validatingUser}
                      mode={"edit"}
                      handleRedirectionWhenNotAuthenticated={
                        handleRedirectionWhenNotAuthenticated
                      }
                      setUserData={setUserData}
                    />
                  )
                }
              />
              <Route
                path="/:storeId/:flowId/:channel/edit/login/:token/:nodeId"
                element={
                  loggedIn ? (
                    <ValidateNodeRoute>
                      <ChatbotBuilder showTour={showTour} userData={userData} />
                    </ValidateNodeRoute>
                  ) : (
                    <Login
                      onLogin={onLogin}
                      redirectTo404={onRedirectTo404}
                      validatingUser={validatingUser}
                      mode={"edit"}
                      handleRedirectionWhenNotAuthenticated={
                        handleRedirectionWhenNotAuthenticated
                      }
                      setUserData={setUserData}
                    />
                  )
                }
              />
            </Route>
            <Route path="/v2/bik-chatbot/flows">
              <Route
                index
                element={
                  loggedIn ? (
                    <EntryPoint
                      userData={userData}
                      emitFlowName={onSetFlowName}
                    />
                  ) : (
                    <Login
                      requestingEntryPath={true}
                      onLogin={onLogin}
                      redirectTo404={onRedirectTo404}
                      validatingUser={validatingUser}
                      setUserData={setUserData}
                    />
                  )
                }
              />
            </Route>
            <Route path="/401">
              <Route
                index
                element={
                  <RedirectComponent
                    handleRedirectionWhenNotAuthenticated={
                      handleRedirectionWhenNotAuthenticated
                    }
                  />
                }
              />
            </Route>
            <Route
              path="v2/admin/*"
              element={
                loggedIn ? (
                  <InternalAdminDashboardRouter loggedIn={loggedIn} />
                ) : (
                  <Login
                    requestingEntryPath={true}
                    onLogin={onLogin}
                    redirectTo404={onRedirectTo404}
                    validatingUser={validatingUser}
                  />
                )
              }
            />
            <Route path="*" element={<Component404 />}></Route>
          </Routes>
        )}
        {(showRedirectionPopup || window.location.href.includes("/401")) && (
          <StateModalComponent
            states={StateInterface.ERROR}
            heading={`We encountered an issue while authenticating`}
            subline={`Redirecting in ${time} seconds`}
            width={400}
            isButtonEnabled={false}
            onClose={() => {
              stopTimer();
              window.location.href = `${dasboardUrl}/journeys/home`;
            }}
          />
        )}
      </div>
    </AppProviders>
  );
};

export default App;

function ValidateNodeRoute({ children }: { children: any }) {
  const match = useMatch("/:storeId/:flowId/:channel/edit/:nodeId");
  const nodeId = match && match.params.nodeId;

  if (nodeId && nodeId.startsWith("N")) {
    return children;
  } else {
    return <Navigate to="/404" />;
  }
}

const RedirectComponent = ({
  handleRedirectionWhenNotAuthenticated,
}: {
  handleRedirectionWhenNotAuthenticated: () => void;
}) => {
  handleRedirectionWhenNotAuthenticated();
  return <></>;
};

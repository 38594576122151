export const ExtraDelay = [
    {
        label: 'Time of',
        value: "0",
        selected: false
    },
    {
        label: '5 minutes',
        value: "300",
        selected: false
    },
    {
        label: '15 minutes',
        value: "900",
        selected: false
    },
    {
        label: '30 minutes',
        value: "1800",
        selected: false
    },
    {
        label: '1 hour',
        value: "3600",
        selected: false
    },
    {
        label: '1 day',
        value: "86400",
        selected: false
    },
    {
        label: '1 week',
        value: "604800",
        selected: false
    },
]

export const ExtraDelayOptions = [
    {
        label: 'After',
        value: 'After',
        selected: false,
    },
    {
        label: 'Before',
        value: 'Before',
        selected: false,
    },
]

export const MIN_DURATION = 1;
export const MAX_DURATION = 720;
export const MAX_DURATION_DAY = 30;


export const tillData = [
    {
        "label": "Anyday",
        "value": "Anyday",
        "key": 0,
        "noOfDays": 0
    },
    {
        "label": "next Monday",
        "value": "next Monday",
        "key": 1,
        "noOfDays": 1
    },
    {
        "label": "next Tuesday",
        "value": "next Tuesday",
        "key": 2,
        "noOfDays": 2
    },
    {
        "label": "next Wednesday",
        "value": "next Wednesday",
        "key": 3,
        "noOfDays": 3
    },
    {
        "label": "next Thursday",
        "value": "next Thursday",
        "key": 4,
        "noOfDays": 4
    },
    {
        "label": "next Friday",
        "value": "next Friday",
        "key": 5,
        "noOfDays": 5
    },
    {
        "label": "next Saturday",
        "value": "next Saturday",
        "key": 6,
        "noOfDays": 6
    },
    {
        "label": "next Sunday",
        "value": "next Sunday",
        "key": 7,
        "noOfDays": 7
    },
    {
        "label": "Weekdays",
        "value": "Weekdays",
        "key": 8,
        "noOfDays": 8
    },
    {
        "label": "Weekends",
        "value": "Weekends",
        "key": 9,
        "noOfDays": 9
    },
    {
        "label": "Specific Date",
        "value": "calender",
        "key": "custom",
        "isCustom": true,
        "dateRangeSelectionOptions": {
            "selectRange": false,
            "minDate": new Date(),
            "maxDate": new Date(new Date().setDate(new Date().getDate() + 7))
        }
    }
]

export const forData = [
    { label: "mins", value: "mins", selected: false },
    { label: "hrs", value: "hrs", selected: false },
    { label: "days", value: "days", selected: false },
]

export const timeData = [
    {
        label: '00:00',
        value: '00:00',
        selected: false
    },
    {
        label: '00:30',
        value: '00:30',
        selected: false
    }, {
        label: '01:00',
        value: '01:00',
        selected: false
    }, {
        label: '01:30',
        value: '01:30',
        selected: false
    }, {
        label: '02:00',
        value: '02:00',
        selected: false
    }, {
        label: '02:30',
        value: '02:30',
        selected: false
    }, {
        label: '03:00',
        value: '03:00',
        selected: false
    }, {
        label: '03:30',
        value: '03:30',
        selected: false
    }, {
        label: '04:00',
        value: '04:00',
        selected: false
    }, {
        label: '04:30',
        value: '04:30',
        selected: false
    }, {
        label: '05:00',
        value: '05:00',
        selected: false
    }, {
        label: '05:30',
        value: '05:30',
        selected: false
    }, {
        label: '06:00',
        value: '06:00',
        selected: false
    }, {
        label: '06:30',
        value: '06:30',
        selected: false
    }, {
        label: '07:00',
        value: '07:00',
        selected: false
    }, {
        label: '07:30',
        value: '07:30',
        selected: false
    }, {
        label: '08:00',
        value: '08:00',
        selected: false
    }, {
        label: '08:30',
        value: '08:30',
        selected: false
    }, {
        label: '09:00',
        value: '09:00',
        selected: false
    }, {
        label: '09:30',
        value: '09:30',
        selected: false
    }, {
        label: '10:00',
        value: '10:00',
        selected: false
    }, {
        label: '10:30',
        value: '10:30',
        selected: false
    }, {
        label: '11:00',
        value: '11:00',
        selected: false
    }, {
        label: '11:30',
        value: '11:30',
        selected: false
    }, {
        label: '12:00',
        value: '12:00',
        selected: false
    }, {
        label: '12:30',
        value: '12:30',
        selected: false
    }, {
        label: '13:00',
        value: '13:00',
        selected: false
    }, {
        label: '13:30',
        value: '13:30',
        selected: false
    }, {
        label: '14:00',
        value: '14:00',
        selected: false
    }, {
        label: '14:30',
        value: '14:30',
        selected: false
    }, {
        label: '15:00',
        value: '15:00',
        selected: false
    }, {
        label: '15:30',
        value: '15:30',
        selected: false
    }, {
        label: '16:00',
        value: '16:00',
        selected: false
    }, {
        label: '16:30',
        value: '16:30',
        selected: false
    }, {
        label: '17:00',
        value: '17:00',
        selected: false
    }, {
        label: '17:30',
        value: '17:30',
        selected: false
    }, {
        label: '18:00',
        value: '18:00',
        selected: true
    }, {
        label: '18:30',
        value: '18:30',
        selected: false
    }, {
        label: '19:00',
        value: '19:00',
        selected: false
    }, {
        label: '19:30',
        value: '19:30',
        selected: false
    }, {
        label: '20:00',
        value: '20:00',
        selected: false
    }, {
        label: '20:30',
        value: '20:30',
        selected: false
    }, {
        label: '21:00',
        value: '21:00',
        selected: false
    }, {
        label: '21:30',
        value: '21:30',
        selected: false
    }, {
        label: '22:00',
        value: '22:00',
        selected: false
    }, {
        label: '22:30',
        value: '22:30',
        selected: false
    }, {
        label: '23:00',
        value: '23:00',
        selected: false
    }, {
        label: '23:30',
        value: '23:30',
        selected: false
    },
]
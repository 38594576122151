import { Icon } from "./icons-props";

const AddEvent: Icon = (props) => {
    const { width, height, color, backgroundColor } = props;
    return (
        <svg width={width || "22"} height={height || "22"} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.49997 0.5C1.84312 0.5 0.499969 1.84315 0.499969 3.5V18.5C0.499969 20.1569 1.84312 21.5 3.49997 21.5H18.5C20.1568 21.5 21.5 20.1569 21.5 18.5V13.5C21.5 12.9477 21.0523 12.5 20.5 12.5C19.9477 12.5 19.5 12.9477 19.5 13.5V18.5C19.5 19.0523 19.0523 19.5 18.5 19.5H3.49997C2.94769 19.5 2.49997 19.0523 2.49997 18.5V3.5C2.49997 2.94772 2.94768 2.5 3.49997 2.5H17.5C18.0523 2.5 18.5 2.05228 18.5 1.5C18.5 0.947715 18.0523 0.5 17.5 0.5H3.49997ZM21.7071 5.5001C22.0977 5.10957 22.0977 4.47641 21.7071 4.08589C21.3166 3.69536 20.6834 3.69536 20.2929 4.08589L11 13.3788L7.70714 10.0859C7.31662 9.69536 6.68345 9.69536 6.29293 10.0859C5.9024 10.4764 5.9024 11.1096 6.29293 11.5001L10.2929 15.5001C10.6835 15.8906 11.3166 15.8906 11.7071 15.5001L21.7071 5.5001Z" fill={color || "#212121"}/>
        </svg>
    );
}

export default AddEvent;

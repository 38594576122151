import { COLORS } from "@bikdotai/bik-component-library";
import styled from "styled-components";

export const Backdrop = styled.div<{overflowY?: string}>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  overflow-y: ${(props) => props.overflowY || 'auto'};
`;

export const CloseButtonContainer = styled.div`
	height: 36px;
	width: 36px;
	border-radius: 50%;
	background-color: ${COLORS.surface.standard};
  outline: 1px solid ${COLORS.stroke.primary};
	cursor: pointer;
  margin-left: auto;
  margin-bottom: -18px;
  margin-right: -18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  z-index: 100;
`;

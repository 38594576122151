import { Icon } from "./icons-props";

const WorkingHourIcon: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1981 2.30566C15.5136 1.40244 13.6228 0.953737 11.7121 1.00377C9.80134 1.0538 7.93663 1.60085 6.3017 2.59099C4.66678 3.58114 3.31808 4.9802 2.38851 6.65031C1.63979 7.99549 1.18379 9.47912 1.04516 11.0042C1.04363 11.0166 1.04231 11.0291 1.04123 11.0417C1.01392 11.3577 1 11.6773 1 12C1 12.3226 1.01392 12.6423 1.04123 12.9583C1.08877 13.5086 1.57337 13.9161 2.1236 13.8685C2.67384 13.821 3.08135 13.3364 3.0338 12.7862C3.01409 12.558 3.00288 12.3273 3.00049 12.0942C2.98412 10.5305 3.37549 8.98943 4.13605 7.62298C4.89661 6.25653 6.00009 5.11184 7.33776 4.30172C8.67542 3.4916 10.2011 3.04402 11.7644 3.00308C13.3277 2.96215 14.8747 3.32927 16.253 4.06827C17.6312 4.80727 18.7931 5.89264 19.6241 7.21741C20.4551 8.54219 20.9266 10.0606 20.9921 11.6231C21.0576 13.1856 20.7148 14.7382 19.9976 16.1278C19.7307 16.6449 19.4153 17.1328 19.057 17.5856L18.3757 16.4055C18.187 16.0786 17.8303 15.8859 17.4534 15.9071C17.0765 15.9284 16.7437 16.16 16.5929 16.5061L14.6916 20.8698C14.5649 21.1607 14.5837 21.4944 14.7424 21.7692C14.901 22.044 15.1807 22.2272 15.4959 22.2629L20.2257 22.7982C20.6008 22.8406 20.9678 22.6682 21.1746 22.3525C21.3815 22.0367 21.3929 21.6314 21.2042 21.3045L20.1178 19.423C20.7699 18.7099 21.3279 17.911 21.7748 17.0451C22.6514 15.3467 23.0704 13.4491 22.9904 11.5394C22.9103 9.62967 22.334 7.77379 21.3183 6.15462C20.3026 4.53545 18.8826 3.20889 17.1981 2.30566Z"
        fill={color || "#D53434"}
      />
      <path
        d="M2.51156 15.3212C3.01195 15.0875 3.60707 15.3036 3.84081 15.804C4.06346 16.2807 4.32678 16.7348 4.62638 17.1619C4.94355 17.614 4.83414 18.2377 4.38201 18.5548C3.92987 18.872 3.30623 18.7626 2.98907 18.3105C2.62309 17.7887 2.30115 17.2336 2.02875 16.6504C1.79501 16.15 2.01117 15.5549 2.51156 15.3212Z"
        fill={color || "#D53434"}
      />
      <path
        d="M6.83811 19.3736C6.38597 19.0564 5.76233 19.1659 5.44517 19.618C5.128 20.0701 5.23741 20.6938 5.68954 21.0109C6.21125 21.3769 6.76639 21.6988 7.34956 21.9712C7.84995 22.205 8.44507 21.9888 8.67881 21.4884C8.91254 20.988 8.69638 20.3929 8.19599 20.1592C7.71933 19.9365 7.2652 19.6732 6.83811 19.3736Z"
        fill={color || "#D53434"}
      />
      <path
        d="M10.1315 21.8764C10.179 21.3262 10.6636 20.9186 11.2138 20.9662C11.4727 20.9886 11.7349 21 12 21C12.2651 21 12.5273 20.9886 12.7862 20.9662C13.3364 20.9186 13.821 21.3262 13.8685 21.8764C13.9161 22.4266 13.5086 22.9112 12.9583 22.9588C12.6423 22.9861 12.3227 23 12 23C11.6773 23 11.3577 22.9861 11.0417 22.9588C10.4914 22.9112 10.0839 22.4266 10.1315 21.8764Z"
        fill={color || "#D53434"}
      />
      <path
        d="M13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7V11H7C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13H12C12.5523 13 13 12.5523 13 12V7Z"
        fill={color || "#D53434"}
      />
    </svg>
  );
};

export default WorkingHourIcon;

import { FirebaseService } from "../services/firebase/FirebaseService";

export enum HTTPMethods {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE",
}

export type FetchOption = {
  apiPath: string;
  apiMethod?: HTTPMethods;
  requestBody?: any;
  requestHeader?: { [key: string]: string };
};

export enum HTTP_CODE {
  OK = 200,
  REQUEST_TIMOUT = 408,
  INTERNAL_SERVER_ERROR = 500,
}

export interface Response<T> {
  status: HTTP_CODE;
  data?: T;
  error?: any;
}

export class RequestExecutor {
  /**
   * Wrapper for the fetch API. It normalize the input based on the type of the request and adds few header
   *
   * @example
   *
   * ```
   * fetch({
   *    apiPath: '/get/user/12',
   *    requestBody: {
   *      label: 'active'
   *   }
   * }).then(res => {
   *  const { data } = res
   *  // data will have the list of users
   * })
   *
   * This request will get converted to
   *
   * HTTP Method : GET
   * URL: /get/user/12?label=active
   * ```
   * @param fetchOption
   * @returns
   */
  static async fetch(fetchOption: FetchOption) {
    if (
      !fetchOption.requestHeader ||
      !fetchOption.requestHeader["Authorization"]
    ) {
      await this.addAuthorizationHeader(fetchOption);
    }

    if (!fetchOption.apiMethod) {
      fetchOption.apiMethod = HTTPMethods.GET;
    }

    if (!fetchOption.requestHeader) {
      fetchOption.requestHeader = {};
    }
    if (!fetchOption.requestBody) {
      fetchOption.requestBody = {};
    }
    fetchOption.requestHeader["Content-Type"] = "application/json";
    // fetchOption.requestHeader['bik-referer'] = 'bik';

    if (fetchOption.requestBody) {
      fetchOption.requestHeader["Content-Length"] =
        typeof fetchOption.requestBody === "object"
          ? JSON.stringify(fetchOption.requestBody).length.toString()
          : fetchOption.requestBody.length.toString();
    }

    let url = fetchOption.apiPath;
    const options: RequestInit = {
      method: fetchOption.apiMethod,
      headers: fetchOption.requestHeader,
      body: JSON.stringify(fetchOption.requestBody),
    };
    if (fetchOption.apiMethod === HTTPMethods.GET) {
      const requestParams =
        typeof options.body === "string"
          ? JSON.parse(options.body)
          : options.body;
      url =
        url + "?" + RequestExecutor.getQueryParamsFromJsonObj(requestParams);
      delete options.body;
      delete fetchOption.requestHeader["Content-Length"];
    }

    return new Promise((res, rej) => {
      try {
        res(
          fetch(url, options)
            .then((r) => {
              // Response object in Javasript has status property, so we are checking 401 in response before converting to JSON.
              if (RequestExecutor.handleError(r.status)) {
                return;
              }
              return r.json();
            })
            .then((r) => {
              // If status from API is 200 but in response json status is 401, it will be caught here.
              if (RequestExecutor.handleError(r?.status)) {
                return;
              }
              if (r?.error && r?.status) {
                return r;
              }
              return { data: r, status: HTTP_CODE.OK };
            })
            .catch((error) => ({
              error,
              status: HTTP_CODE.INTERNAL_SERVER_ERROR,
            }))
        );
      } catch (error) {
        rej({ error, status: HTTP_CODE.INTERNAL_SERVER_ERROR });
      }
    });
  }

  /**
   *
   * Method to get data from an API. It uses GET Http Method
   *
   * @example
   *
   * ```
   * getData({
   *    apiPath: '/get/users',
   *    requestHeader: { JWTTOKEN: 'bearer 0239u203ndwioue23' };
   * }).then(res => {
   *  const { data } = res
   *  // data will have the list of users
   * })
   *
   * ```
   * @param fetchOption
   * @returns
   */
  static async getData<T>(fetchOption: FetchOption): Promise<Response<T>> {
    await this.addAuthorizationHeader(fetchOption);

    if (!fetchOption.requestHeader) {
      fetchOption.requestHeader = {};
    }
    if (!fetchOption.requestBody) {
      fetchOption.requestBody = {};
    }
    fetchOption.requestHeader["Content-Type"] = "application/json";
    // fetchOption.requestHeader['bik-referer'] = 'bik';

    let url = fetchOption.apiPath;

    const requestParams =
      typeof fetchOption.requestBody === "string"
        ? JSON.parse(fetchOption.requestBody)
        : fetchOption.requestBody;

    url = url + "?" + RequestExecutor.getQueryParamsFromJsonObj(requestParams);

    const options: RequestInit = {
      method: HTTPMethods.GET,
      headers: fetchOption.requestHeader,
    };

    return new Promise((res, rej) => {
      try {
        res(
          fetch(url, options)
            .then((r) => r.json())
            .then((r) => {
              if (r?.error && r?.status) {
                return r;
              }
              return { data: r, status: HTTP_CODE.OK };
            })
            .catch((error) => ({
              error,
              status: HTTP_CODE.INTERNAL_SERVER_ERROR,
            }))
        );
      } catch (error) {
        rej({ error, status: HTTP_CODE.INTERNAL_SERVER_ERROR });
      }
    });
  }

  /**
   * This is to send data to an url. It used POST method.
   * includes sentry track
   *
   * @example
   *
   * ```
   * sendData({
   *    apiPath: '/add/user',
   *    requestBody: { name: 'Joe', age: 14 },
   *    requestHeader: { JWTTOKEN: 'bearer 0239u203ndwioue23' };
   * }).then(res => {
   *  // work with the response `res` here
   * })
   *
   * ```
   * @param fetchOption
   * @returns
   */

  static async sendData<T>(fetchOption: FetchOption): Promise<Response<T>> {
    if (
      !fetchOption.requestHeader ||
      !fetchOption.requestHeader["Authorization"]
    ) {
      await this.addAuthorizationHeader(fetchOption);
    }

    if (!fetchOption.requestHeader) {
      fetchOption.requestHeader = {};
    }
    if (!fetchOption.requestBody) {
      fetchOption.requestBody = {};
    }

    fetchOption.requestHeader["Content-Type"] = "application/json";
    // fetchOption.requestHeader['bik-referer'] = 'bik';

    if (fetchOption.requestBody) {
      fetchOption.requestHeader["Content-Length"] =
        typeof fetchOption.requestBody === "object"
          ? JSON.stringify(fetchOption.requestBody).length.toString()
          : fetchOption.requestBody.length.toString();
    }

    const requestBodyJson = JSON.stringify(fetchOption.requestBody);
    const url = fetchOption.apiPath;
    const options: RequestInit = {
      method: HTTPMethods.POST,
      headers: fetchOption.requestHeader,
      body: requestBodyJson,
    };

    return new Promise((res) => {
      try {
        res(
          fetch(url, options)
            .then((r) => {
              // Response object in Javasript has status property, so we are checking 401 in response before converting to JSON.
              if (RequestExecutor.handleError(r.status)) {
                return;
              }
              const contentType = r.headers.get("content-type")!;
              if (contentType.includes("text")) {
                return r.text();
              } else {
                return r.json();
              }
            })
            .then((r) => {
              // If status from API is 200 but in response json status is 401, it will be caught here.
              if (RequestExecutor.handleError(r?.status)) {
                return;
              }

              if (r?.error) {
                return r;
              }

              if (
                r?.status &&
                /** Some APIs returns status as boolean eg `ananlyzeWhatsappCredits` */
                typeof r.status !== "boolean" &&
                r.status !== 200
              ) {
             
              }

              if (typeof r?.response !== "undefined" && r?.status) {
                return {
                  data: r.response,
                  status: r.status,
                  error: r.error,
                };
              }

              return { data: r, status: HTTP_CODE.OK };
            })
            .catch((error) => {
              return {
                error,
                status: HTTP_CODE.INTERNAL_SERVER_ERROR,
              };
            })
        );
      } catch (error) {    
        res({ error, status: HTTP_CODE.INTERNAL_SERVER_ERROR });
      }
    });
  }

  static getQueryParamsFromJsonObj(jsonObject: any) {
    return Object.keys(jsonObject)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(jsonObject[key])}`
      )
      .join("&");
  }

  static async addAuthorizationHeader(fetchOption: FetchOption) {
    const UNATHENTICATED_APIS = [
      "generateOTP",
      "verifyOTP",
      "userApiFunctions-getUsers",
      "shopifyApiFunctions-verifySession",
      "shopifyApiFunctions-storeShopifyDetails",
      "shopifyApiFunctions-onboardShopifyStore",
    ];
    const isPublicApi = UNATHENTICATED_APIS.find((api) =>
      fetchOption.apiPath.includes(api)
    );
    if (isPublicApi) {
      return;
    }

    const idToken = await FirebaseService.fetchIdToken();
    if (!idToken) {
      return;
    }

    fetchOption.requestHeader = {
      ...(fetchOption.requestHeader || {}),
      Authorization: idToken,
    };
  }

  static handleError(status?: number | string) {
    const rootUrl = window.location.href.split("/")[0];
    if (
      (status === 401 || status === "401") &&
      !window.location.href.includes("/401")
    ) {
      window.location.href = `${rootUrl}/401`;
      return true;
    }

    return false;
  }
}

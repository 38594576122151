import { CustomNodeIconProps } from "../utilities/flowBuilder.utility";

const DocIcon = (props: CustomNodeIconProps) => {
    const {backgroundColor, color, height, width} = props;
    return (
        <svg width={width || "24"} height={height || "30"} viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 3.00004C3.26362 3.00004 2.66667 3.59699 2.66667 4.33337V25.6667C2.66667 26.4031 3.26362 27 4 27H20C20.7364 27 21.3333 26.4031 21.3333 25.6667V12.3334H16C13.7909 12.3334 12 10.5425 12 8.33337V3.00004H4ZM14.6667 4.88566L19.4477 9.66671H16C15.2636 9.66671 14.6667 9.06975 14.6667 8.33337V4.88566ZM0 4.33337C0 2.12424 1.79086 0.333374 4 0.333374H13.8856L24 10.4478V25.6667C24 27.8758 22.2091 29.6667 20 29.6667H4C1.79086 29.6667 0 27.8758 0 25.6667V4.33337Z" fill={color || "#9E9E9E"}/>
        </svg>
    );
}

export default DocIcon;
import {
  HTTPMethods,
  RequestExecutor,
} from "../../api-helpers/RequestExecutor";
import { getChatbotEndpoints, getIntegrationsEndpoints } from "../../config";

export async function getStoreWidgetStatus(
  storeId: string,
  widgetType: string
) {
  const path = `${getChatbotEndpoints()}/get-widget-status`;
  const response = (await RequestExecutor.fetch({
    apiPath: path,
    apiMethod: HTTPMethods.POST,
    requestBody: {
      storeId: storeId,
      widget: widgetType,
    },
  })) as any;

  return response?.data?.data;
}

export async function createSlots(storeId: string){
  const path = `${getChatbotEndpoints()}/create-business-events`;
  try{
    const response = (await RequestExecutor.fetch({
      apiPath: path,
      apiMethod: HTTPMethods.POST,
      requestBody: {
        storeId: storeId,
      },
    })) as any;
    return response?.data?.data;
  } catch(err){
    throw Error('Could not create slot')
  }

}

export async function getTrackerStatus(storeId: string) {
  const path = `${getIntegrationsEndpoints()}/integrationsApiFunctions-executeIntegrationApi`;
  const response = (await RequestExecutor.fetch({
    apiPath: path,
    apiMethod: HTTPMethods.POST,
    requestBody: {
      storeId: storeId,
      partner: "SHOPIFY",
      code: "getWebPixelId",
      payload: {},
    },
  })) as any;

  return response?.data?.data;
}

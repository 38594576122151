import styled from "styled-components";

export const RichTextEditorStyled = styled.div<{
  customHeight?: string;
  headerTextColor?: string;
}>`
  width: 100%;
  height: 100%;
  padding-bottom: 5px;

  .textEditor {
    .quill {
      min-height: ${(props) =>
        props.customHeight
          ? `${props.customHeight} !important`
          : "120px !important" };
    }
  }
  *::-webkit-scrollbar {
    display: none;
  }
  * {
    -ms-overflow-style: none;
    /* scrollbar-width: none; */
  }

  .headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${(props) =>
      props.headerTextColor ? `${props.headerTextColor}` : "#731dcf"};
    padding: 8px 0;
  }
  .headerError {
    color: #b92321 !important;
  }
  .ql-editor {
    background: white;
    border-radius: 4px;
    white-space: pre-wrap !important;
    min-height: ${(props) =>
        props.customHeight
          ? `${props.customHeight} !important`
          : "120px !important" };
  }

  .ql-clipboard {
    left: -100000px;
    height: 1px;
    overflow-y: hidden;
    position: absolute;
    top: 50%;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .ql-editor.ql-blank::before {
    font-style: normal !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #9e9e9e;
  }

  .EmojiPickerReact {
    margin-top: 32px;
    position: absolute;
  }

  .variableStyle {
    color: ${(props) => props.theme.colors.background.brand};
    border: 1px solid ${(props) => props.theme.colors.background.brand};
    border-radius: 4px;
  }

  .ql-container {
    max-height: 350px;
    border-radius: 4px;
    font-size: 16px;
    line-height: 24px;
  }

  .onFocusOut {
    border: 1px solid #e0e0e0 !important;
    border-radius: 4px;
  }

  .error {
    border: 1px solid #b92321 !important;
    outline: 1px solid #b92321 !important;
    border-radius: 4px;
  }

  .onFocusIn {
    border: 1px solid ${(props) => props.theme.colors.background.brand} !important;
    outline: 1px solid ${(props) => props.theme.colors.background.brand} !important;
    border-radius: 4px;
  }
  .toolBar {
    background: #212121;
    border-radius: 4px;
    height: 32px;
  }
  .hide__toolbar {
    position: relative;
    top: -10000px;
    right: -10000px;
  }
`;

export const isProd = process.env.REACT_APP_IS_PROD === "true";

export const RetryPw = "botminz";
export const editorApiKey = "60vzh21dotl068vgifkmq9gf4uiq939ucih5gx6qakt2i5og";
export const dasboardUrl = isProd
  ? "https://dashboard.bik.ai"
  : "https://staging.dashboard.bik.ai/";

export const baseUrlProd = "https://api.bik.ai/chatbot";
export const baseUrlStaging = "https://api.staging.bik.ai/chatbot";

export const enabledStoreIds = ["YRvGEcthQnWFkN5IGnwJkSD9bob2"];

export const ADMIN_STORE_ID = isProd
  ? "TKhBIxzXBygjxiQVJjgHHBqKsIW2"
  : "isaFXAV7JOcaMM5aN2ofNMo5f7w1";

export const TEST_STORE_ID = isProd
  ? "R5XFG5W7C2WcWYOdCqIbgprpTGF3"
  : "p60HYv5wjgQaOrPdqT5NjbpkroD2";

export const getCampaignEndPoints = () => {
  return isProd
    ? "https://bikapi.bikayi.app/campaign"
    : "https://api.staging.bik.ai/campaign";
};

export const getCRMEndpoints = (): string => {
  return isProd
    ? "https://bikapi.bikayi.app/crm"
    : "https://api.staging.bik.ai/crm";
};

export const getDMEndpoints = (): string => {
  return isProd
    ? "https://bikapi.bikayi.app/dm"
    : "https://api.staging.bik.ai/dm";
};

export const getChatbotEndpoints = () => {
  return isProd
    ? "https://bikapi.bikayi.app/chatbot"
    : "https://api.staging.bik.ai/chatbot";
};

export const getGrowthEndpoints = (): string => {
  return isProd
    ? "https://bikapi.bikayi.app/growth"
    : "https://api.staging.bik.ai/growth";
};

export const getIntegrationsEndpoints = () => {
  return isProd
    ? "https://bikapi.bikayi.app/integrations"
    : "https://api.staging.bik.ai/integrations";
};

export const getFastifyEndpoints = () => {
  return isProd
    ? "https://fastify-6nwaonxexq-el.a.run.app"
    : "https://fastify-6nwaonxexq-el.a.run.app";
};

export const getCloudFunctionEndpoints = () => {
  return isProd
    ? "https://asia-south1-bikai-d5ee5.cloudfunctions.net"
    : "https://asia-south1-staging-bikayi.cloudfunctions.net";
};

export const getManifestEndpoints = () => {
  return isProd ? "https://bikapi.bikayi.app/uat/gpt" : "https://api.staging.bik.ai/gpt"
}

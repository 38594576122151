import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AvailableFeatureInteface {
  [featureName: string]: { storeIds: string[] };
}

export interface AvailableFeaturesInteface {
  availableFeatures: AvailableFeatureInteface;
  isVersionLoaded: boolean;
}

const initialVersions: AvailableFeaturesInteface = {
  availableFeatures: {} as AvailableFeatureInteface,
  isVersionLoaded: false,
};

const versionSlice = createSlice({
  name: 'versionContext',
  initialState: initialVersions,
  reducers: {
    setAvailaleVersions(
      state,
      action: PayloadAction<AvailableFeatureInteface>,
    ) {
      state.availableFeatures = action.payload;
    },
    setIsVersionLoaded(state, action: PayloadAction<boolean>) {
      state.isVersionLoaded = action.payload;
    },
  },
});

export const { setAvailaleVersions, setIsVersionLoaded } = versionSlice.actions;

export default versionSlice;
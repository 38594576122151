import { HTTPMethods, RequestExecutor } from "../api-helpers/RequestExecutor";

export class SlackNotification {

    private endpoint = "https://hooks.slack.com/services/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";

    public async reportError (name: string, msg: string) {
        try {
            await RequestExecutor.fetch({
                apiMethod: HTTPMethods.POST,
                apiPath: this.endpoint,
                requestBody: { 'username': name, 'text': msg }
            })
        } catch (error: any) {
        }
    }
}
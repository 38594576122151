export const JudgeMe = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_131_1383"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <path d="M0 0H24V24H0V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_131_1383)">
        <path d="M24 0H0V24H24V0Z" fill="url(#paint0_linear_131_1383)" />
        <path
          d="M14.3197 16.7825V3.56543H15.7883L22.3971 24.1256H12.8511L7.71094 19.7197L14.3197 16.7825Z"
          fill="url(#paint1_radial_131_1383)"
        />
        <g filter="url(#filter0_d_131_1383)">
          <path
            d="M15.9535 3.55957V14.985C15.9535 17.9863 13.5337 20.4394 10.5314 20.4394C7.52907 20.4394 5.10922 17.9863 5.10922 14.985H8.23984C8.23984 16.2436 9.27276 17.2765 10.5314 17.2765C11.79 17.2765 12.8229 16.2436 12.8229 14.985V6.69065H10.5314V3.55957H15.9535Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_131_1383"
          x="-5.9677"
          y="-3.82504"
          width="29.3058"
          height="35.3414"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1.84615" dy="1.84615" />
          <feGaussianBlur stdDeviation="4.61538" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_131_1383"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_131_1383"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_131_1383"
          x1="12"
          y1="0"
          x2="12"
          y2="24"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#339999" />
          <stop offset="1" stop-color="#2D8396" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_131_1383"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15.0538 13.8455) rotate(63.8254) scale(19.537 13.955)"
        >
          <stop stop-color="#395F5F" />
          <stop offset="1" stop-color="#278888" stop-opacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};

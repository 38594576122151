import {DefaultTheme} from "styled-components";

interface FontStyleI {
    fontWeight: number,
    fontSize: string,
    lineHeight?: string,
}

const getTypography = (theme: DefaultTheme): any => {
    type FontKey = keyof typeof theme.fonts;
    let fontClasses: string[] = [];
    fontClasses = Object.keys(theme.fonts).filter((each) => !each.includes('Mobile'))

    /*
      converts camelcase string to '-' seperated lowercase class name.
      eg: bodyRegularMobile -> body-regular-mobile
     */
    const getClassName = (classKey: string): string => {
        return `.${(classKey.split(/(?=[A-Z])/).map((each) => each.toLowerCase()).join('-'))}`;
    }

    const getDesktopClassFromMobileClass = (classKey: string): string => {
        return `, ${getClassName(classKey).split('-').slice(0, -1).join('-')}`;
    }

    return fontClasses.map((each) => {
        return (`${getClassName(each)} {
          font-weight: ${(theme.fonts[each as FontKey] as FontStyleI).fontWeight};
          font-size: ${(theme.fonts[each as FontKey] as FontStyleI).fontSize};
          line-height: ${(theme.fonts[each as FontKey] as FontStyleI).lineHeight || 'inherit'};
        }`);
    })
}

export default (props: any) => (`
  ${getTypography(props.theme)};
`);

import { Icon } from "./icons-props";

const RedirectIcon: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 21 20"
      fill={color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.16667 4.66667C2.16667 3.28595 3.28595 2.16667 4.66667 2.16667H6.33333C6.79357 2.16667 7.16667 1.79357 7.16667 1.33333C7.16667 0.873096 6.79357 0.5 6.33333 0.5H4.66667C2.36548 0.5 0.5 2.36548 0.5 4.66667V11.3333C0.5 13.6345 2.36548 15.5 4.66667 15.5H11.3333C13.6345 15.5 15.5 13.6345 15.5 11.3333V9.66667C15.5 9.20643 15.1269 8.83333 14.6667 8.83333C14.2064 8.83333 13.8333 9.20643 13.8333 9.66667V11.3333C13.8333 12.714 12.714 13.8333 11.3333 13.8333H4.66667C3.28595 13.8333 2.16667 12.714 2.16667 11.3333V4.66667Z"
        fill="#9E9E9E"
      />
      <path
        d="M10.5 0.5C10.0398 0.5 9.66667 0.873096 9.66667 1.33333C9.66667 1.79357 10.0398 2.16667 10.5 2.16667H12.6548L7.41074 7.41074C7.08531 7.73618 7.08531 8.26382 7.41074 8.58926C7.73618 8.91469 8.26382 8.91469 8.58926 8.58926L13.8333 3.34518V5.5C13.8333 5.96024 14.2064 6.33333 14.6667 6.33333C15.1269 6.33333 15.5 5.96024 15.5 5.5V1.33333C15.5 0.873096 15.1269 0.5 14.6667 0.5H10.5Z"
        fill="#9E9E9E"
      />
    </svg>
  );
};

export default RedirectIcon;

import { CrossIcon } from "../../../icons";
import { TickIcon } from "../../../icons/tickIcon";
import AlertIcon from "../../../icons/alertIcon";
import { useAppSelector } from "../../../state/store";
import SaveFlowModalStyle from "./saveFlowModal.style";
import { Button } from "@bikdotai/bik-component-library";

export interface SaveFlowModalProps {
  flowEditHistory: any;
  oldNodeCount: number;
  newNodeCount: number;
  onClickCancel: () => void;
  onClickSave: () => void;
  saveType: string;
  checkError: boolean;
  showSuccess: boolean;
  error: string;
  heading?: string;
  subheading?: string;
  onActivate: () => void;
  showButtons: boolean;
  buttonLoader: boolean;
}

export const Loader = (saveType: string) => {
  return (
    <div className="check__error">
      <div className="loader"></div>
      <div className="error">
        {saveType === "Save" ? "Saving your flow..." : "Checking for errors..."}
      </div>
      <div className="wait">This might take a while...</div>
    </div>
  );
};

const SaveFlowModal: React.FC<SaveFlowModalProps> = (props) => {
  const { error } = props;
  const flowType = useAppSelector((state) => state.homeState.type);

  const convertTimestampToReadableFormat = (timestamp: string) => {
    if (!timestamp) return "NOT_AVAILABLE";
    const date = new Date(timestamp);
    return (
      date.toDateString() + ", " + date.getHours() + ":" + date.getMinutes()
    );
  };

  return (
    <SaveFlowModalStyle {...props}>
      {!(props.showSuccess || props.checkError) && (
        <>
          <div className="heading">
            <div className="title">
              <p>
                {props.saveType} {flowType}
              </p>
            </div>
            <div
              className="cross-icon"
              onClick={() => {
                props.onClickCancel();
              }}
            >
              <CrossIcon />
            </div>
          </div>
          <div className="body">
            <div className="edit-history">
              <p>
                Last edited by :{" "}
                <b>{props.flowEditHistory?.email || "NOT_AVAILABLE"}</b>{" "}
              </p>
              <p>
                At :{" "}
                <b>
                  {convertTimestampToReadableFormat(
                    props.flowEditHistory?.lastUpdatedTime
                  )}
                </b>
              </p>
            </div>
            <div className="flow-history">
              <p>
                Previous block count : <b>{props.oldNodeCount}</b>
              </p>
              <p>
                New block count : <b>{props.newNodeCount}</b>
              </p>
            </div>
          </div>
          <div className="footer">
            <div className="save__button">
              <Button
                buttonType="primary"
                size="small"
                buttonText={props.saveType}
                onClick={() => {
                  props.onClickSave();
                }}
                matchParentWidth={true}
                isLoading={props.buttonLoader}
              />
            </div>
            <div className="discard-text">
              <Button
                buttonType="tertiaryGray"
                size="medium"
                buttonText="Cancel"
                onClick={() => {
                  props.onClickCancel();
                }}
                matchParentWidth={true}
              />
            </div>
          </div>
        </>
      )}
      {props.checkError && Loader(props.saveType)}
      {props.showSuccess && (
        <div className="success__modal">
          <div
            className="cross__icon"
            onClick={() => {
              props.onClickCancel();
            }}
          >
            <CrossIcon />
          </div>
          <div className="green__tick">
            {error ? (
              <AlertIcon width={32} height={32} />
            ) : (
              <TickIcon width={32} height={32} color="#FFFFFF" />
            )}
          </div>
          <div className="title">{props.heading}</div>
          <div className="description">{props.subheading}</div>
          {props.showButtons && (
            <div className="button__bar">
              <Button
                buttonType="secondary"
                size="small"
                buttonText="Cancel"
                onClick={() => {
                  props.onClickCancel();
                }}
                matchParentWidth={true}
              />
              <Button
                buttonType="primary"
                size="small"
                buttonText="Activate"
                onClick={() => {
                  props.onClickCancel();
                  props.onActivate();
                }}
                matchParentWidth={true}
              />
            </div>
          )}
        </div>
      )}
    </SaveFlowModalStyle>
  );
};

export default SaveFlowModal;

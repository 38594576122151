import { Icon } from "./icons-props";

const EvaluateIcon: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 17C12 16.4477 12.4477 16 13 16H21C21.5523 16 22 16.4477 22 17C22 17.5523 21.5523 18 21 18H13C12.4477 18 12 17.5523 12 17Z"
        fill={color || "#99631B"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.6705 3.3295C21.1098 3.76884 21.1098 4.48116 20.6705 4.9205L4.9205 20.6705C4.48116 21.1098 3.76884 21.1098 3.3295 20.6705C2.89017 20.2312 2.89017 19.5188 3.3295 19.0795L19.0795 3.3295C19.5188 2.89017 20.2312 2.89017 20.6705 3.3295Z"
        fill={color || "#99631B"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 3C8 2.44772 7.55228 2 7 2C6.44772 2 6 2.44772 6 3V6H3C2.44772 6 2 6.44772 2 7C2 7.55228 2.44772 8 3 8H6V11C6 11.5523 6.44772 12 7 12C7.55228 12 8 11.5523 8 11V8H11C11.5523 8 12 7.55228 12 7C12 6.44772 11.5523 6 11 6H8V3Z"
        fill={color || "#99631B"}
      />
    </svg>
  );
};

export default EvaluateIcon;

import { COLORS } from "@bikdotai/bik-component-library";
import styled from "styled-components";

export const TriggerContainer = styled.div`
  padding: 24px;
  border: 1px solid ${COLORS.stroke.primary};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const CustomersNotifyContainer = styled.div`
  padding: 24px;
  border: 1px solid ${COLORS.stroke.primary};
  border-radius: 8px;
`;

export const ExcludeProductsContainer = styled.div`
  padding: 12px 24px;
  border: 1px solid ${COLORS.stroke.primary};
  border-top: 0px solid ${COLORS.stroke.primary};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: ${COLORS.surface.subdued};
`;

export const ParamContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;
  align-items: center;
  justify-content: space-between;
`;

export const InfoContainer = styled.div`
  background-color: ${COLORS.surface.hovered};
  border-radius: 4px;
  border: 1px solid ${COLORS.stroke.primary};
  display: flex;
  flex-direction: row;
  gap: 18px;
  align-items: center;
  padding: 12px;
`;

export const TriggerBody = styled.div`
  margin-left: 46px;
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

export const CustomerNotifyBody = styled.div`
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;

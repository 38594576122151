import { COLORS, Input, InputStateI } from "@bikdotai/bik-component-library";
import { useState } from "react";
import styled from "styled-components";

export type MaxDelayInputComponentProps = {
  inputValue: number;
  setInputValue: (input: number) => void;
  inputState: InputStateI;
};

/**
 * Handles input related logic. Type conversion of
 * string and number is handled here so that outside component can only use number.
 * Error message and error state also handled here.
 */
export const MaxDelayInputComponent = (props: MaxDelayInputComponentProps) => {
  const { inputState, inputValue, setInputValue } = props;

  const [errorMessage, setErrorMessage] = useState("");

  const getErrorMessage = (input: number) => {
    if (input < 0) {
      return "Enter a valid number!";
    }

    if (input % 1 !== 0) {
      return "Decimals not allowed!";
    }

    if (input > 30) {
      return "Max. 30 days are allowed";
    }

    return "";
  };

  return (
    <Input
      type={"number"}
      value={inputValue <= 0 ? "" : inputValue.toString()}
      onChangeText={(input: string, event?: Event | undefined) => {
        if (input === "") {
          setInputValue(-1);
        } else {
          setInputValue(Number(input));
        }
        setErrorMessage(getErrorMessage(Number(input)));
        return;
      }}
      variant={"small"}
      width={"170px"}
      height={"48px"}
      placeholder={"Eg. 20 "}
      hintText="Max. 30 days are allowed"
      errorMessage={errorMessage ? errorMessage : ""}
      suffixText="days"
      state={inputState}
    />
  );
};

export const StyledInputLoader = styled.div`
  width: 80px;
  height: 32px;
  background: ${COLORS.stroke.primary};
  border: 1px solid ${COLORS.surface.hovered};
  border-radius: 4px;
  pointer-events: none;
`;

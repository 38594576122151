const SmsIcon = (props: any) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_131_2125)">
        <path
          d="M7.44383 20.4414C7.21868 20.4402 6.99558 20.4835 6.78726 20.5686L6.76732 20.5764L3.52487 21.7478L3.50048 21.7566L3.47659 21.7667C3.43603 21.7838 3.39307 21.7943 3.34931 21.798C3.29284 21.7937 3.23956 21.7692 3.19939 21.7288C3.15747 21.6866 3.13306 21.6302 3.1308 21.571C3.13245 21.5392 3.13787 21.5077 3.14694 21.4772L3.14969 21.468L3.15226 21.4587L4.03734 18.2571C4.18504 17.7566 3.99357 17.3402 3.88944 17.1566L3.88266 17.1442L3.87087 17.1228L3.85804 17.102C3.84705 17.0841 3.83758 17.07 3.83478 17.0658L3.82418 17.0502L3.81043 17.0305L3.79335 17.0062C3.78302 16.9913 3.77231 16.9762 3.76649 16.9681L3.76465 16.9655L3.75749 16.9555C3.75623 16.9537 3.75562 16.9528 3.75548 16.9526L3.75557 16.9525L3.74748 16.941L3.74718 16.9406C2.73903 15.4074 2.20181 13.6125 2.20201 11.7773L2.202 11.774C2.19416 9.26714 3.17274 6.89842 4.96933 5.0942C6.83019 3.23005 9.31739 2.2022 11.9915 2.2022L11.992 2.2022C14.2556 2.20108 16.4521 2.97086 18.2197 4.38476C19.9452 5.77389 21.1398 7.70647 21.5893 9.82291L21.5899 9.82556C21.7284 10.4692 21.7982 11.1257 21.798 11.7841V11.7844C21.798 14.3642 20.8088 16.7791 19.0132 18.5952L19.0128 18.5956C17.2039 20.4272 14.7763 21.4346 12.1642 21.4346C11.2742 21.4346 10.0749 21.1988 9.50622 21.0383L9.50395 21.0377C8.85813 20.8571 8.23986 20.6246 8.07775 20.5637C8.06689 20.5596 8.05807 20.5563 8.05143 20.5538C7.85731 20.4802 7.65149 20.4421 7.44383 20.4414ZM7.44383 20.4414C7.44443 20.4414 7.44503 20.4414 7.44563 20.4414L7.43954 21.4434L7.44202 20.4414C7.44262 20.4414 7.44322 20.4414 7.44383 20.4414Z"
          stroke="#28458F"
          stroke-width="2.004"
        />
        <path
          d="M8 10H17"
          stroke="#28458F"
          stroke-width="1.66667"
          stroke-linecap="round"
        />
        <path
          d="M8 14H14"
          stroke="#28458F"
          stroke-width="1.66667"
          stroke-linecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_131_2125">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SmsIcon;

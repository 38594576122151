import {
  BodyCaption,
  BodyPrimary,
  BodySecondary,
  COLORS,
  TitleSmall,
} from "@bikdotai/bik-component-library";
import { unCamelCase } from "../../../utilities/flowBuilder.utility";
import AddEvent from "../../../icons/addEvent";
import styled from "styled-components";

/**
 * Count card used to display in Count Modal
 * Channel is required for icon color.
 */
export const CountCard = (props: {
  header: string;
  subHeader: string;
  count: number;
  channel?: string;
}) => {
  return (
    <StyledCountCard>
      <div className="countcard__icon">
        <AddEvent
          width={16}
          height={16}
          color={props.channel === "whatsapp" ? "#1A872C" : "grey"}
        />
      </div>
      <div className="countcard__body">
        <BodyPrimary>{unCamelCase(props.header)}</BodyPrimary>
        <BodySecondary
          className="countcard__body_sub_header"
          color={COLORS.content.secondary}
          numberOfLines={1}
        >
          {props.subHeader}
        </BodySecondary>
      </div>
      <div className="countcard__count">
        <TitleSmall>{props.count} </TitleSmall>
        <BodyCaption color={COLORS.content.secondary}>Profiles</BodyCaption>
      </div>
    </StyledCountCard>
  );
};

export const StyledCountCard = styled.div`
  display: flex;
  border: 1px solid ${COLORS.stroke.primary};
  background: ${COLORS.surface.subdued};
  border-radius: 4px;
  padding: 5px;
  justify-content: center;
  align-items: center;

  .countcard__icon {
    width: 32px;
    height: 32px;
    display: flex;
    flex: 0.5;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
  }

  .countcard__body {
    display: flex;
    flex: 5;
    align-items: flex-start;
    flex-direction: column;
  }

  .countcard__body_sub_header {
    text-align: start;
  }

  .countcard__count {
    display: flex;
    flex: 1;
    border-left: 1px solid ${COLORS.stroke.primary};
    padding: 5px;
    flex-direction: column;
    align-items: center;
  }
`;

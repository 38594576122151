import { COLORS } from "@bikdotai/bik-component-library";
import styled from "styled-components";

export const BuilderContainer = styled.div`
  overflow-y: auto;
`;

export const InstaDropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 12px;
  .dropdown-text {
    margin-left: 12px;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const InstaDropdownContainer = styled.div`
  border-radius: 4px;
  border: 1px solid ${COLORS.stroke.primary};
  cursor: pointer;
  background-color: #fff;
  &:hover {
    background-color: ${COLORS.surface.hovered};
  }
`;

import React, { useEffect, useRef, useState } from "react";
import AutomationShimmer from "./components/AutomationTrigger/AutomationShimmer";
import ShowNudgeModal from "./components/AutomationTrigger/ShowNudgeModal";
import { useAppSelector } from "./state/store";

const LazyLoad = (props: any) => {
  const [InnerComponent, setInnerComponent] = useState<JSX.Element>();
  const { getComponent, ...rest } = props;
  const LOCALCOMPONENT =
    useRef<React.SetStateAction<JSX.Element | undefined>>();
  const builderState = useAppSelector((state) => state.builderState);

  useEffect(() => {
    props
      .getComponent()
      .then((res: any) => {
        const Comp = res.default;
        LOCALCOMPONENT.current = <Comp {...rest} />;
        return setInnerComponent(LOCALCOMPONENT.current);
      })
      .catch(() => {
        const doc = document.getElementById("cmpModal");
        if (doc) {
          doc.style.display = "none";
        }
        return setInnerComponent(
          <ShowNudgeModal isSaveStateEnabled={builderState.save ?? false} />
        );
      });
  }, [props]);

  if (!InnerComponent) {
    return <AutomationShimmer />;
  }
  return InnerComponent;
};

export default LazyLoad;

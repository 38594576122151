import { VariableListInterfaceV2 } from "@bikdotai/bik-component-library";

export function processJson(obj: object) {
  // Process the JSON object recursively
  function processObject(data: any): any {
    if (Array.isArray(data)) {
      return data.map((item) => processObject(item));
    } else if (typeof data === "object" && data !== null) {
      const processedObject: any = {};
      for (const [key, value] of Object.entries(data)) {
        processedObject[key] = processObject(value);
      }
      return processedObject;
    } else {
      // For raw values, replace with their types
      return typeof data;
    }
  }

  return processObject(obj);
}

export function updateSelectedVariables(
    payloadList: VariableListInterfaceV2[],
    selectedVariables: any
  ) {
    const firstPayloadCopy = {
      ...payloadList[0],
      variables: recursiveUpdateVariables(
        payloadList[0].variables,
        selectedVariables
      ),
    };  
    return [firstPayloadCopy, ...payloadList.slice(1)];  
  }

function recursiveUpdateVariables(payloadMap: any, selectedVariables: any) {
  const updatedPayloadMap: any = {};
  Object.keys(payloadMap).forEach((key) => {
    if (
      typeof payloadMap[key].variables !== "undefined" &&
      Object.keys(payloadMap[key].variables).length > 0
    ) {
      updatedPayloadMap[key] = {
        ...payloadMap[key],
        variables: recursiveUpdateVariables(
          payloadMap[key].variables,
          selectedVariables
        ),
      };
    } else {
      updatedPayloadMap[key] = {
        ...payloadMap[key],
        selected: selectedVariables.includes(payloadMap[key].actualValue),
      };
    }
  });
  return updatedPayloadMap;
}

export function createList(operateJson: any, parentKey = "", isArray = false) {
  const finalJson: any = {};
  Object.keys(operateJson).forEach((key, index) => {
    if (typeof operateJson[key] === "object") {
      if (isArray) {
        const newKey = parentKey ? parentKey + "[" + key + "]" : key;
        finalJson[newKey] = {
          description: "",
          displayName: "[" + key + "]",
          actualValue: `{{${newKey}}}`,
          variableType: "any",
          isAvailable: true,
          isSubHeader: true,
          variables: createList(
            operateJson[key],
            newKey,
            Array.isArray(operateJson[key])
          ),
        };
      } else {
        let newKey = "";
        if (key.startsWith("[") && key.endsWith("]")) {
          newKey = parentKey ? parentKey + key : key;
        } else {
          newKey = parentKey ? parentKey + "." + key : key;
        }
        finalJson[newKey] = {
          description: "",
          displayName: key,
          actualValue: `{{${newKey}}}`,
          variableType: "any",
          isAvailable: true,
          isSubHeader: true,
          variables: createList(
            operateJson[key],
            newKey,
            Array.isArray(operateJson[key])
          ),
        };
      }
    } else {
      if (isArray) {
        const newKey = parentKey ? parentKey + "[" + key + "]" : key;
        finalJson[newKey] = {
          description: undefined,
          displayName: "[" + key + "]",
          actualValue: `{{${newKey}}}`,
          variableType: operateJson[key],
          isAvailable: true,
        };
      } else {
        let newKey = "";
        if (key.startsWith("[") && key.endsWith("]")) {
          newKey = parentKey ? parentKey + key : key;
        } else {
          newKey = parentKey ? parentKey + "." + key : key;
        }
        finalJson[newKey] = {
          description: undefined,
          displayName: key,
          actualValue: `{{${newKey}}}`,
          variableType: operateJson[key],
          isAvailable: true,
        };
      }
    }
  });
  return finalJson;
}

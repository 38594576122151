import {
  BodyPrimaryLink,
  COLORS,
  StyledModal,
} from "@bikdotai/bik-component-library";
import ArrowRightIcon from "../../../icons/ArrowRightIcon";

/**
 * Styled modal wrapper for past profiles.
 * Used in Zero Delay Modal and Non zero delay modal
 */
export const AddPastProfileModalWrapper = (props: {
  children?: React.ReactNode;
  onClick: () => void;
  onClose: () => void;
  open: boolean;
}) => (
  <StyledModal
    open={props.open}
    headingTitle="Add past profiles"
    //@ts-ignore
    headingSubtitle={
      <>
        Add customers to the journey for whom the flow would have triggered
        before it turned active.{" "}
      </>
    }
    primaryButton={{
      buttonText: "Add Past Profiles",
      style: {
        display: "flex",
        justifyItems: "center",
        alignItems: "center",
      },
      TrailingIcon: () => (
        <div style={{ display: "flex" }}>
          <ArrowRightIcon
            width={20}
            height={20}
            color={COLORS.content.primaryInverse}
          />
        </div>
      ),
      onClick: props.onClick,
    }}
    secondaryButton={{
      buttonText: "Cancel",
      buttonType: "tertiaryGray",
      onClick: props.onClose,
    }}
    width={"761px"}
    onClose={props.onClose}
  >
    {props.children}
  </StyledModal>
);

import React, { FunctionComponent, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { BASE_ROUTES, FeatureProps, FEATURE_BOXES, LINK_FEATURE_MAP } from './FeatureProps';

interface OwnProps {
    loggedIn: boolean;
}

type Props = OwnProps;


const TopNavigationBar = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [showSecondaryNav, setshowSecondaryNav] = React.useState(false);
    const [secondaryNavItems, setSecondaryNavItems] = React.useState<FeatureProps[]>([]);


    useEffect(() => {
        const pathTokens = location.pathname.split('/admin/')
        if (pathTokens.length === 1) {
            setshowSecondaryNav(false);
            return
        }
        const podTokens = pathTokens[1].split('/');
        const podName = podTokens[0];
        setSecondaryNavItems(LINK_FEATURE_MAP[podName as keyof typeof LINK_FEATURE_MAP]);
        setshowSecondaryNav(true);
        const secondaryFeature = podTokens[1];
    }, [location]);


    const navigateToLink = (link: string) => {
        navigate(link);
    }

    const featureBoxes = BASE_ROUTES.map((podBox) => {
        return (<b style={{ color: 'black', cursor: 'pointer' }} onClick={() => navigateToLink(podBox.redirectLink)}>{podBox.podName}</b>)
    })

    const secondarNavBoxes = (secondaryNavItems: FeatureProps[]) => {
        return secondaryNavItems.map((podBox) => {
            return (<p style={{ color: 'black', cursor: 'pointer', fontSize: 12 }} onClick={() => navigateToLink(podBox.redirectLink)}>{podBox.podName}</p>)
        })
    }

    return (
        <>
            <div style={{ padding: 16, width: '100%', backgroundColor: '#e7e7e7', display: 'flex', columnGap: 40 }} >
                <b style={{ color: 'black', cursor: 'pointer', marginLeft: 16 }} onClick={() => navigateToLink('/v2/admin')}>Home</b>
                {featureBoxes}
            </div>
            {secondaryNavItems && <div style={{ paddingLeft: 32, paddingTop: 8, paddingBottom: 8, width: '100%', backgroundColor: '#bcbcbc', display: 'flex', columnGap: 40 }} >
                {secondarNavBoxes(secondaryNavItems)}
            </div>}
        </>
    )
}

const InternalAdminDashboardRouter: FunctionComponent<Props> = (props) => {
    return (
        <>
            <TopNavigationBar />
            <Routes>
                {
                    FEATURE_BOXES.map((featureBox) => {
                        const relativeLink = featureBox.redirectLink.split('/v2/admin')[1];
                        if (featureBox.POD_COMPONENT) {
                            return <Route path={relativeLink} element={<featureBox.POD_COMPONENT/>} />
                        } else {
                            return <></>
                        }
                    })
                }
            </Routes>
        </>
    )
};

export default InternalAdminDashboardRouter;

import {
  COLORS,
  TitleSmall,
  BodyCaption,
} from "@bikdotai/bik-component-library";
import styled from "styled-components";
import { MaxDelayType } from "../AddPastProfile";

/**
 * Card Button for 'At the start' and 'Throughout the flow'.
 * On click, returns the id passed as prop
 */
export const CardButton = (props: {
  id: MaxDelayType;
  LeadingIcon: any;
  title: string;
  subTitle: string;
  selected?: boolean;
  onClick?: (id: MaxDelayType) => void;
}) => {
  const { LeadingIcon, title, subTitle, selected, id } = props;

  return (
    <StyledCardButton
      onClick={(e) => {
        props.onClick?.(id);
      }}
      selected={selected}
    >
      <div
        style={{
          display: "flex",
        }}
      >
        <LeadingIcon
          width={24}
          height={24}
          style={{ display: "flex" }}
          color={selected ? COLORS.content.brand : COLORS.content.secondary}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: "10px",
        }}
      >
        <TitleSmall
          color={selected ? COLORS.content.brand : COLORS.content.secondary}
        >
          {title}
        </TitleSmall>
        <BodyCaption
          color={selected ? COLORS.content.brand : COLORS.content.secondary}
        >
          {subTitle}
        </BodyCaption>
      </div>
    </StyledCardButton>
  );
};

export const StyledCardButton = styled.div<{ selected?: boolean }>`
  margin: 10px;
  display: flex;
  flex-direction: row;
  padding: 10px;
  width: 343px;
  border: 1px solid
    ${(props) =>
      props.selected ? COLORS.content.brand : COLORS.content.inactive};
  background: ${(props) =>
    props.selected ? COLORS.background.brandLight : COLORS.surface.subdued};
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    background: ${(props) =>
      props.selected ? COLORS.background.brandLight : COLORS.surface.hovered};
  }
`;

import {
  HTTPMethods,
  RequestExecutor,
} from "../../../api-helpers/RequestExecutor";
import { getChatbotEndpoints } from "../../../config";
import { MaxDelayType } from "../AddPastProfile";
import { EventTriggerCountType } from "../CountModal";

/**
 * Calls get-max-delay API with payload and returns typed response.
 */
export const getMaxDelayApi = async (payload: any) => {
  const response: any = await RequestExecutor.fetch({
    apiPath: `${getChatbotEndpoints()}/get-max-delay`,
    apiMethod: HTTPMethods.POST,
    requestBody: payload,
  });
  if (response?.data?.data) {
    return {
      data: response.data.data as {
        type: MaxDelayType;
        maxDelay: number;
      },
    };
  }
  return {
    errors: response?.data?.errors,
  };
};

/**
 * Calls get-customer-count-event-triggers API with payload and returns typed response.
 */
export const getCustomerCount = async (payload: any) => {
  const response: any = await RequestExecutor.fetch({
    apiPath: `${getChatbotEndpoints()}/get-customer-count-event-triggers`,
    apiMethod: HTTPMethods.POST,
    requestBody: payload,
  });
  if (response?.data?.data) {
    return {
      data: response.data.data as {
        totalCustomerCount: number;
        customerCountList: EventTriggerCountType[];
      },
    };
  }
  return {
    errors: response?.data?.errors,
  };
};

/**
 * Calls add-past-profiles API with payload.
 * Response doesn't contain data.
 */
export const addPastProfiles = async (payload: any) => {
  const response: any = await RequestExecutor.fetch({
    apiPath: `${getChatbotEndpoints()}/add-past-profiles`,
    apiMethod: HTTPMethods.POST,
    requestBody: payload,
  });
  if (response?.data?.errors) {
    return {
      errors: response?.data?.errors,
    };
  }
  return {
    success: response?.data?.success,
  };
};

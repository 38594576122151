export interface BackInStockParams {
  minInventory: number | string;
  noOfCustomerToNotify: number | string;
  gapBetweenBatches: number | string;
  customersEngagementGap: number | string;
  eventsSubscribedTo: {
    leadGenerated: boolean;
    productViewed: boolean;
  };
}

export interface PriceDropParams {
  amount: number | string;
  amountType: "percentage" | "value";
  eventsSubscribedTo: {
    leadGenerated: boolean;
    productViewed: boolean;
  };
  customersEngagementGap: number | string;
}

export interface PriceDropErrors {
  amountError: boolean;
  customersEngagementGapError: boolean;
  eventsSubscribedToError: boolean;
  excludedProductsError: boolean;
}

export interface BackInStockErrors {
  minInventoryError: boolean;
  noOfCustomerToNotifyError: boolean;
  gapBetweenBatchesError: boolean;
  customersEngagementGapError: boolean;
  eventsSubscribedToError: boolean;
  excludedProductsError: boolean;
}

export interface ExcludedProduct {
  productId: string;
  variantId: string;
  collectionId: string;
}

export interface BackInStockConf {
  minInventory: number;
  noOfCustomerToNotify: number;
  gapBetweenBatches: number;
  customersEngagementGap: number;
  eventsSubscribedTo: {
    leadGenerated: boolean;
    productViewed: boolean;
  };
  excludedProducts: ExcludedProduct[];
  type: string;
  businessEventType: string;
  id: string;
  header: string;
  channelId: string;
  subHeader: string;
}

export interface PriceDropConf {
  amount: number;
  amountType: "percentage" | "value";
  customersEngagementGap: number;
  eventsSubscribedTo: {
    leadGenerated: boolean;
    productViewed: boolean;
  };
  excludedProducts: ExcludedProduct[];
  type: string;
  businessEventType: string;
  id: string;
  header: string;
  channelId: string;
  subHeader: string;
}

export enum BUSINESS_EVENTS {
  BACK_IN_STOCK = "backInStock",
  PRICE_DROP = "priceDrop",
}

// @ts-nocheck

import { HTTPMethods, RequestExecutor } from "../../api-helpers/RequestExecutor"
import { getChatbotEndpoints } from "../../config";


export const getAutomations = async (payload) => {
  const url = getChatbotEndpoints() + '/get-trigger';
  // const url = 'http://localhost:5000/get-trigger'

  const response = await RequestExecutor.sendData<{
    data: any;
  }>({
    apiPath: url,
    apiMethod: HTTPMethods.POST,
    requestBody: payload,
  });

  return response.data;
};

export const saveStartingPoint = async (payload) => {
  const url =  getChatbotEndpoints() + '/add-trigger';
  // const url = 'http://localhost:5000/add-trigger'
  const response = await RequestExecutor.sendData<{
    data: any;
  }>({
    apiPath: url,
    apiMethod: HTTPMethods.POST,
    requestBody: payload,
  });

  return response.data;
};
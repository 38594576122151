const AstonishedEmoji = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path d="M16.0112 29.5978C9.14578 29.5978 1.73901 25.2915 1.73901 15.8423C1.73901 6.39318 9.14578 2.08691 16.0112 2.08691C19.8253 2.08691 23.3441 3.34188 25.9525 5.63035C28.7823 8.14029 30.2834 11.6837 30.2834 15.8423C30.2834 20.001 28.7823 23.5198 25.9525 26.0297C23.3441 28.3182 19.8007 29.5978 16.0112 29.5978Z" fill="url(#paint0_radial_1584_28451)" />
        <path d="M27.7956 7.66504C29.1072 9.78126 29.7913 12.301 29.7913 15.1038C29.7913 19.2624 28.2902 22.7812 25.4604 25.2912C22.852 27.5797 19.3086 28.8592 15.5191 28.8592C11.075 28.8592 6.41442 27.0506 3.66333 23.2143C6.31107 27.56 11.2916 29.5974 16.0112 29.5974C19.8007 29.5974 23.3442 28.3179 25.9525 26.0294C28.7824 23.5195 30.2834 20.0006 30.2834 15.842C30.2834 12.7071 29.4295 9.92152 27.7956 7.66504Z" fill="#EB8F00" />
        <path d="M16.2572 21.4279C17.1948 21.4303 18.1299 21.3811 19.0625 21.2802C19.0748 19.6488 17.7607 18.3175 16.1293 18.3077C14.4978 18.2978 13.169 19.6069 13.1567 21.2384C14.1853 21.3663 15.2213 21.4303 16.2572 21.4279Z" fill="white" />
        <path d="M13.1567 21.2393V21.4287V24.3324C13.1567 25.9638 14.4781 27.2852 16.1096 27.2852C17.7411 27.2852 19.0625 25.9638 19.0625 24.3324V21.4287C19.0625 21.3771 19.0625 21.3254 19.0625 21.2737C18.1323 21.3771 17.1997 21.4287 16.2646 21.4287C15.2262 21.4312 14.1878 21.3672 13.1567 21.2393Z" fill="#422B0D" />
        <path d="M20.9572 10.3555C21.8923 10.3555 22.7535 11.4382 22.7535 13.2591C22.7535 15.0801 21.8923 16.1628 20.9572 16.1628C20.0221 16.1628 19.1609 15.0801 19.1609 13.2591C19.1609 11.4382 19.9975 10.3555 20.9572 10.3555Z" fill="#422B0D" />
        <path d="M11.2619 10.3555C10.253 10.3555 9.36719 11.4382 9.36719 13.2591C9.36719 15.0801 10.2777 16.1628 11.2619 16.1628C12.2462 16.1628 13.1567 15.0801 13.1567 13.2591C13.1567 11.4382 12.2462 10.3555 11.2619 10.3555Z" fill="#422B0D" />
        <path d="M11.5819 7.47625C12.1478 7.52547 12.3693 6.76264 11.7787 6.59039C11.3137 6.4649 10.8314 6.41568 10.3515 6.44275C9.0498 6.49196 7.81698 7.03824 6.90652 7.9684C6.48819 8.41133 7.07877 8.95268 7.5217 8.63279C8.70776 7.80353 10.1374 7.39505 11.5819 7.47625Z" fill="#422B0D" />
        <path d="M20.5882 7.47625C22.0401 7.39259 23.4796 7.79861 24.673 8.63279C25.116 8.95268 25.7065 8.41133 25.2882 7.9684C24.3753 7.04317 23.1425 6.49689 21.8432 6.44275C21.3634 6.41568 20.8811 6.4649 20.416 6.59039C20.1748 6.65929 20.0346 6.90783 20.1035 7.14898C20.1625 7.36306 20.3692 7.50086 20.5882 7.47625Z" fill="#422B0D" />
        <path d="M11.2571 11.4059C10.9077 11.2385 10.4869 11.3862 10.3171 11.7356C10.1867 12.0088 10.2458 12.336 10.4648 12.5476C10.8142 12.715 11.235 12.5673 11.4048 12.2179C11.5352 11.9448 11.4761 11.6175 11.2571 11.4059Z" fill="#896024" />
        <path d="M20.8614 11.4058C20.5095 11.2409 20.0912 11.391 19.9238 11.7429C19.7959 12.016 19.8549 12.3384 20.0715 12.5476C20.4234 12.7124 20.8417 12.5623 21.009 12.2104C21.137 11.9398 21.0779 11.6149 20.8614 11.4058Z" fill="#896024" />
        <path d="M19.0624 21.4283C18.9197 20.7048 18.5407 20.0478 17.9871 19.5581C16.9117 18.6206 15.3073 18.6206 14.232 19.5581C13.6783 20.0478 13.3018 20.7048 13.1567 21.4283C13.0189 20.6039 13.2649 19.7624 13.8235 19.1423C14.9284 17.8799 16.8502 17.752 18.1126 18.8593C18.2134 18.9479 18.307 19.0414 18.3955 19.1423C18.6736 19.4572 18.8778 19.8288 18.996 20.2324C19.1091 20.6212 19.1313 21.0296 19.0624 21.4283Z" fill="#EB8F00" />
        <defs>
            <radialGradient id="paint0_radial_1584_28451" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.0112 15.8423) scale(14.0162)">
                <stop offset="0.5" stop-color="#FDE030" />
                <stop offset="0.92" stop-color="#F7C02B" />
                <stop offset="1" stop-color="#F4A223" />
            </radialGradient>
        </defs>
    </svg>
}

export default AstonishedEmoji;
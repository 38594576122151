import React, {FunctionComponent, useEffect, useState} from "react";
import SearchBarStyle, {ListItemStyle} from "./SearchBar.style";
import SearchInput from "../input/searchInput";


export interface ListItemProps {
    isHeading?: boolean;
    text: string;
    id?: string;
    type?: string;
    partner?: string;
    dataType?: string
}

const ListItem = (props: ListItemProps) => {
    return (
        <ListItemStyle>
            <div className={`${props.isHeading ? 'heading-item': props.type === 'type' ? 'type-item' : 'list-item'}`}>
                {props.text}
            </div>
        </ListItemStyle>
    )
}

interface SearchBarProps {
    data:  ListItemProps[],
    placeholder: string,
    onClickHandle: (value: ListItemProps)=> void,
    isSearchRequired?: boolean;
}

type Props = SearchBarProps

const  SearchBar: FunctionComponent<Props> = (props) => {
    const [filteredData, setFilteredData] = useState<ListItemProps[]>([]);
    const [wordEntered, setWordEntered] = useState("");

    useEffect(()=>{
        setFilteredData(props.data)
    },[]);
    const onClickItem = (value: ListItemProps) => {
        const textValue = value
        props.onClickHandle(textValue);
        setWordEntered(textValue.text)
    }

    const handleFilter = (event: any) => {
        const searchWord = event.target.value;
        setWordEntered(searchWord);
        const newFilter = props.data.filter((value) => {
            return value.text.toLowerCase().includes(searchWord.toLowerCase());
        });

        setFilteredData(newFilter);
    };


    return (
        <SearchBarStyle onClick = {(e)=>{e.stopPropagation()}}>
            <div className="search pointer">
                {props.isSearchRequired && <div className="searchInputs">
                    <SearchInput onChangeHandle={handleFilter} value={wordEntered}/>
                </div>}
                {props.data.length > 0 && filteredData.length > 0 && (
                    <div>
                        {filteredData?.map((value, key) => {
                            return (
                                <div onClick={
                                    ()=>{
                                        if(value.isHeading)
                                            return;
                                        onClickItem(value)
                                    }
                                }>
                                    <div style={{width: "100%", background: value?.isHeading ? "#F0F0F0" : "#FFFFFF" ,
                                     borderBottom: "1px solid #E0E0E0", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                        <ListItem text={value?.text} isHeading={value?.isHeading}/>
                                        {value?.dataType && <ListItem text={value?.dataType} isHeading={value?.isHeading} type={'type'}/>}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </SearchBarStyle>
    );
}

export default SearchBar;

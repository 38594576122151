import { generateRandomNumber } from "../app/action-block/ActionBlockHelper";
import { getIntegrationsEndpoints } from "../config";
import { DATA_SET } from "../ui-components/dropdown";
import { MIN_DURATION } from "../app/action-block/delay/constants";

export class CallApiDataSet {
  apiName: string = "";
  apiId: string = "";
  apiPartner: string = "";
  actionType: string = "";
  apiEndpoint: string =
    getIntegrationsEndpoints() +
    "/integrationsApiFunctions-executeIntegrationApi";
  apiParams: Array<{ [key: string]: any }> = [
    {
      id: generateRandomNumber(),
      key: "",
      value: "",
      value_type: "",
      is_required: false,
    },
  ];
  apiHeaders: Array<{ [key: string]: any }> = [
    {
      id: generateRandomNumber(),
      key: "content-type",
      value: "application/json",
    },
  ];
  apiBodyForm: Array<{ [key: string]: any }> = [];
}

export class DelayDataSet {
  duration: number = MIN_DURATION;
  unit: string = "mins";
}

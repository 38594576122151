import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  VariableData,
  VariableInfo,
} from "./common";
import { globalVariablesDataAction } from "../../../state/globalVariablesState";
import { useAppSelector } from "../../../state/store";
import { FirebaseService } from "../../../services/firebase/FirebaseService";
import { voidFunction } from "../../messageBlock/Constants";

const useGlobalVariableHook = (storeId: string) => {
  const [globalVariableList, setGlobalVariableList] = useState(
    convertToList(
      useAppSelector((state) => state.globalVariablesState.globalVariablesList)
    )
  );

  useEffect(() => {
    const fetchFromDb = async () => {
      const data = await new FirebaseService(voidFunction, voidFunction).getGlobalVariablesV2(
        storeId
      );
      return data;
    };

    fetchFromDb().then((existingVars: { [key: string]: VariableInfo }) => {
      const existingVarsList: VariableData[] = [];
      Object.entries(existingVars).forEach(([k, v]) => {
        existingVarsList.push({
          name: k,
          type: v.type,
          defaultValue: v.defaultValue,
        });
      });
      setGlobalVariableList(existingVarsList);
    });
  }, [storeId]);

  const dispatcher = useDispatch();

  const updateGlobalVariableList = async (newList: VariableData[]) => {
    setGlobalVariableList(newList);

    const globalVariableMap: { [key: string]: VariableInfo } = {};
    newList.forEach((item) => {
      globalVariableMap[item.name] = {
        type: item.type,
        defaultValue: item.defaultValue,
      };
    });

    dispatcher(
      globalVariablesDataAction.setGlobalVariablesList({
        globalVariablesList: globalVariableMap,
      })
    );

    await new FirebaseService(()=>{}, ()=>{}).setGlobalVariablesV2(
      storeId,
      globalVariableMap
    );
  };

  return {
    globalVariableList,
    setGlobalVariableList,
    updateGlobalVariableList,
  };
};

const convertToList = (existingVars: { [key: string]: VariableInfo }) => {
  const existingVarsList: VariableData[] = [];
  Object.entries(existingVars).forEach(([k, v]) => {
    existingVarsList.push({
      name: k,
      type: v.type,
      defaultValue: v.defaultValue,
    });
  });
  return existingVarsList;
};

export default useGlobalVariableHook;

export const getDateString = (date: Date) => {
  try {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedMonth = months[date.getMonth()];
    const formattedDay = date.getDate();
    const formattedYear = date.getFullYear();
    const formattedDate = `Last updated at ${formattedHours}:${formattedMinutes} ${ampm}, ${formattedDay} ${formattedMonth} ${formattedYear}`;
    return formattedDate;
  } catch (error: any) {
    return `Last updated at ${date.toLocaleString()}`;
  }
}
import { Icon } from "./icons-props";

const DropdownIcon: Icon = (props) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.42263 8.92259C5.89766 8.39762 6.26946 7.5 7.01188 7.5H12.9882C13.7306 7.5 14.1024 8.39762 13.5774 8.92259L10.5893 11.9107C10.2639 12.2362 9.73622 12.2362 9.41078 11.9107L6.42263 8.92259Z" fill="#212121"/>
        </svg>
    );
}

export default DropdownIcon;

import { Icon } from "./icons-props";

const Cart: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_626_16238)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.833333 0C0.373096 0 0 0.373096 0 0.833333C0 1.29357 0.373096 1.66667 0.833333 1.66667H3.4836L4.17663 5.12923C4.18032 5.15294 4.18501 5.17633 4.19066 5.19934L5.58277 12.1547C5.6972 12.7299 6.01021 13.2466 6.46707 13.6144C6.92184 13.9805 7.49031 14.1758 8.07387 14.1667H16.1595C16.743 14.1758 17.3115 13.9805 17.7663 13.6144C18.2233 13.2465 18.5364 12.7295 18.6507 12.154L19.9852 5.15611C20.0318 4.91215 19.9671 4.66015 19.8087 4.4688C19.6504 4.27744 19.415 4.16667 19.1667 4.16667H5.68369L4.98379 0.669786C4.90584 0.280321 4.56386 0 4.16667 0H0.833333ZM7.21737 11.8293L6.01727 5.83333H18.1594L17.0154 11.832C16.9769 12.0228 16.8728 12.194 16.7212 12.3161C16.5688 12.4388 16.3782 12.5039 16.1826 12.5002L16.1667 12.5H8.06667L8.0507 12.5002C7.85516 12.5039 7.66453 12.4388 7.51218 12.3161C7.35983 12.1935 7.25547 12.0212 7.21737 11.8293Z"
          fill={color || "#212121"}
        />
        <path
          d="M5.83333 17.5C5.83333 16.5795 6.57953 15.8333 7.5 15.8333C8.42047 15.8333 9.16667 16.5795 9.16667 17.5C9.16667 18.4205 8.42047 19.1667 7.5 19.1667C6.57953 19.1667 5.83333 18.4205 5.83333 17.5Z"
          fill={color || "#212121"}
        />
        <path
          d="M15 17.5C15 16.5795 15.7462 15.8333 16.6667 15.8333C17.5871 15.8333 18.3333 16.5795 18.3333 17.5C18.3333 18.4205 17.5871 19.1667 16.6667 19.1667C15.7462 19.1667 15 18.4205 15 17.5Z"
          fill={color || "#212121"}
        />
      </g>
      <defs>
        <clipPath id="clip0_626_16238">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Cart;

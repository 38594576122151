const InstagramSendIcon = (props: any) => {
    const { width, height } = props;
    return (
        <svg width={width || "16"} height={height || "16"} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2660_11916)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1517 0.875991C15.1473 0.871281 15.1428 0.866627 15.1382 0.862029C15.0209 0.74481 14.8709 0.680368 14.7176 0.668702C14.676 0.665504 14.6339 0.666214 14.5919 0.670958C14.5378 0.677039 14.4842 0.68974 14.4326 0.709059L1.11318 5.37086C0.855184 5.46116 0.678448 5.69981 0.667306 5.97292C0.656163 6.24604 0.812872 6.49829 1.06266 6.60931L6.82835 9.17184L9.39088 14.9375C9.50189 15.1873 9.75414 15.344 10.0273 15.3329C10.3004 15.3217 10.539 15.145 10.6293 14.887L15.2912 1.56745C15.3101 1.5169 15.3226 1.46451 15.3289 1.41152C15.3517 1.21775 15.2885 1.02096 15.1517 0.875991ZM12.1296 2.92775L3.1408 6.07384L7.18577 7.8716L12.1296 2.92775ZM8.12858 8.81441L13.0724 3.87055L9.92635 12.8594L8.12858 8.81441Z" fill="#510058"/>
            </g>
            <defs>
                <clipPath id="clip0_2660_11916">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default InstagramSendIcon;

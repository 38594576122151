import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  labels: [],
  category: [],
  themes: [],
  otherCategories: [],
};

const categoryDataSlice = createSlice({
  name: "CategoryData",
  initialState: initialState,
  reducers: {
    addCategories: (state, action: PayloadAction<any>) => {
      state = { ...state, ...action.payload };
      return state;
    },
    setJourneyCategories: (state, action: PayloadAction<any>) => {
      state.themes = action.payload.themes ?? [];
      state.otherCategories = action.payload.other_categories ?? [];
    },

    addLabels: (state, action: PayloadAction<any>) => {
      state = { ...state, ...action.payload };
      return state;
    },
  },
});

export const categoryDataAction = categoryDataSlice.actions;
export default categoryDataSlice;

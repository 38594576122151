import { Icon } from "./icons-props";

const QuestionIcon: Icon = (props) => {
    const { width, height, color, backgroundColor } = props;
    return (
        <svg width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.1878 7.01712C11.8386 6.95722 11.4794 7.02284 11.1739 7.20237C10.8685 7.38191 10.6364 7.66376 10.5188 7.99801C10.3355 8.519 9.76461 8.79277 9.24363 8.6095C8.72264 8.42623 8.44886 7.85531 8.63213 7.33432C8.90645 6.55452 9.4479 5.89696 10.1606 5.47811C10.8733 5.05926 11.7112 4.90615 12.5259 5.04591C13.3407 5.18566 14.0797 5.60925 14.612 6.24165C15.1443 6.87391 15.4356 7.6741 15.4345 8.50055C15.4341 9.86435 14.4244 10.7501 13.739 11.207C13.3659 11.4557 12.9998 11.6381 12.731 11.7575C12.5951 11.8179 12.4806 11.8638 12.3976 11.8954C12.356 11.9113 12.3222 11.9236 12.2972 11.9325L12.2667 11.9432L12.2567 11.9466L12.2531 11.9478L12.2516 11.9483L12.2504 11.9487C11.7264 12.1233 11.1601 11.8402 10.9855 11.3162C10.8109 10.7926 11.0936 10.2267 11.6169 10.0517L11.6272 10.048C11.639 10.0439 11.6588 10.0367 11.6856 10.0264C11.7394 10.006 11.8201 9.97373 11.9187 9.92992C12.1187 9.84103 12.3777 9.7108 12.6296 9.54286C13.1943 9.1664 13.4345 8.80222 13.4345 8.49963V8.49814C13.4351 8.14381 13.3102 7.80073 13.082 7.52965C12.8538 7.25858 12.537 7.07702 12.1878 7.01712Z" fill={color || "#212121"}/>
            <path d="M12 13C11.4477 13 11 13.4477 11 14C11 14.5523 11.4477 15 12 15H12.01C12.5623 15 13.01 14.5523 13.01 14C13.01 13.4477 12.5623 13 12.01 13H12Z" fill={color || "#212121"}/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.87868 2.87868C3.44129 2.31607 4.20435 2 5 2H19C19.7957 2 20.5587 2.31607 21.1213 2.87868C21.6839 3.44129 22 4.20435 22 5V15C22 15.7957 21.6839 16.5587 21.1213 17.1213C20.5587 17.6839 19.7957 18 19 18H7.41421L3.70711 21.7071C3.42111 21.9931 2.99099 22.0787 2.61732 21.9239C2.24364 21.7691 2 21.4045 2 21V5C2 4.20435 2.31607 3.44129 2.87868 2.87868ZM5 4C4.73478 4 4.48043 4.10536 4.29289 4.29289C4.10536 4.48043 4 4.73478 4 5V18.5858L6.29289 16.2929C6.48043 16.1054 6.73478 16 7 16H19C19.2652 16 19.5196 15.8946 19.7071 15.7071C19.8946 15.5196 20 15.2652 20 15V5C20 4.73478 19.8946 4.48043 19.7071 4.29289C19.5196 4.10536 19.2652 4 19 4H5Z" fill={color || "#212121"}/>
        </svg>
    );
}

export default QuestionIcon;
import { Icon } from "./icons-props";

const ArrowLeftIcon: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill={color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.47139 2.86201C8.73174 3.12236 8.73174 3.54447 8.47139 3.80482L4.9428 7.33341H12.6667C13.0348 7.33341 13.3333 7.63189 13.3333 8.00008C13.3333 8.36827 13.0348 8.66675 12.6667 8.66675H4.9428L8.47139 12.1953C8.73174 12.4557 8.73174 12.8778 8.47139 13.1382C8.21104 13.3985 7.78893 13.3985 7.52858 13.1382L2.86192 8.47149C2.60157 8.21114 2.60157 7.78903 2.86192 7.52868L7.52858 2.86201C7.78893 2.60166 8.21104 2.60166 8.47139 2.86201Z"
        fill={color || "#616161"}
      />
    </svg>
  );
};

export default ArrowLeftIcon;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {PublishState} from "./storeDataStateType";

const initialState: PublishState = {
    publishRequests: {}
}

const publishRequestSlice = createSlice({
    name: 'PublishRequests',
    initialState: initialState,
    reducers: {
        setPublishRequests: (state, action: PayloadAction<PublishState>) => {
            return {
                ...state,
                ...action.payload
            };
        },
    }
});

export const publishRequestStateActions = publishRequestSlice.actions;
export default publishRequestSlice;
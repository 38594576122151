import React, {FunctionComponent, useState} from 'react';
import {AdminTableStyles} from "../core/TableStyles";
import {IntegrationService} from "./IntegrationsService";

interface OwnProps {
    storeId: string,
    productList: any[]
}

type Props = OwnProps;

const FacebookProductsDashboard: FunctionComponent<Props> = (props) => {

    const repo = new IntegrationService();

    const [fbProductSyncMessage, setFbProductSyncMessage] = useState('')
    const [fbCatalogConnectMessage, setFbCatalogConnectMessage] = useState('')

    async function importCatalogs() {
        const res = await repo.importCatalogs(props.storeId);
        setFbProductSyncMessage(res);
    }

    async function connectCatalog() {
        const res = await repo.enableWhatsappCatalog(props.storeId);
        setFbCatalogConnectMessage(res);
    }

    return (
        <div>
            <div>
                <AdminTableStyles style={{ padding: 16 }}>
                    <h2>Facebook Products</h2>
                    <p>Total {props.productList.length} products</p>
                    {/*<button onClick={() => importCatalogs()}>Sync everything</button>*/}
                    {/*<p>{fbProductSyncMessage}</p>*/}
                    <button onClick={() => connectCatalog()}>Enable whatsapp catalog and cart</button>
                    <p>{fbCatalogConnectMessage}</p>
                    <table>
                        <tr>
                            <th>ID</th>
                            <th>Content Id</th>
                            <th>Name</th>
                            <th>Errors</th>
                        </tr>
                        {props.productList.map((product, key) => {
                            return (
                                <tr>
                                    <td>{product.id}</td>
                                    <td>{product.combinations[0].id}</td>
                                    <td>{product.name}</td>
                                    <td>{product.error}</td>
                                </tr>
                            )
                        })}
                    </table>

                </AdminTableStyles>
            </div>
        </div>
    );
};

export default FacebookProductsDashboard;

import LazyLoad from "../../LazyLoad";
import { ComponentModalType } from "./componentModal";
import { NodeSubType } from "../../models/nodeSubType";

const ComponentModalHook = (storeId: string, flowId: string) => {
  const lazyLoadComponent = (
    nodeData: any,
    saveTo: string,
    setSaveTo: any,
    config: any,
    error?: string,
    setError?: any,
    setLoading?: Function
  ) => {
    switch (nodeData.subtype) {
      case "starting_point": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../components/AutomationTrigger/AutomationTrigger")
            }
            nodeData={nodeData}
            config={config}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            flowId={flowId}
            storeId={storeId}
            setLoading={setLoading}
          />
        );
        return Comp;
      }

      case "instagram_media": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../instagram-components/media/Media")
            }
            nodeData={nodeData}
            config={config}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            flowId={flowId}
            storeId={storeId}
            error={error}
            setError={setError}
            setLoading={setLoading}
          />
        );
        return Comp;
      }

      case "ai_classify": {
        const Comp = (
          <LazyLoad
            getComponent={() => import("../../components/ai-classify/index")}
            nodeData={nodeData}
            config={config}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            flowId={flowId}
            storeId={storeId}
            error={error}
            setError={setError}
            setLoading={setLoading}
          />
        );
        return Comp;
      }
      case NodeSubType.MANIFEST_SEARCH: {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../components/manifest/search-node/index")
            }
            nodeData={nodeData}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
            error={error}
            setError={setError}
            setLoading={setLoading}
          />
        );
        return Comp;
      }

      case NodeSubType.MANIFEST_BUILD_LLM_INPUT: {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../components/manifest/build-llm-input/index")
            }
            nodeData={nodeData}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
            error={error}
            setError={setError}
            setLoading={setLoading}
          />
        );
        return Comp;
      }
      case NodeSubType.MANIFEST_LLM_CALL: {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../components/manifest/make-llm-call/index")
            }
            nodeData={nodeData}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
            error={error}
            setError={setError}
            setLoading={setLoading}
          />
        );
        return Comp;
      }
      case NodeSubType.MANIFEST_EXIT:
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../components/manifest/exit-node/index")
            }
            nodeData={nodeData}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
            error={error}
            setError={setError}
            setLoading={setLoading}
          />
        );
        return Comp;
      case "ai_product_recommend": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../components/ai-product-recommend/index")
            }
            nodeData={nodeData}
            config={config}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            flowId={flowId}
            storeId={storeId}
            error={error}
            setError={setError}
            setLoading={setLoading}
          />
        );
        return Comp;
      }
      case NodeSubType.AI_GENERAL_PURPOSE_ASSISTANT: {
        const Comp = (
          <LazyLoad
            getComponent={() => import("../../components/ai-assistant/index")}
            nodeData={nodeData}
            config={config}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            flowId={flowId}
            storeId={storeId}
            error={error}
            setError={setError}
            setLoading={setLoading}
          />
        );
        return Comp;
      }
      case "ai_reply": {
        const Comp = (
          <LazyLoad
            getComponent={() => import("../../components/ai-reply/index")}
            nodeData={nodeData}
            config={config}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            flowId={flowId}
            storeId={storeId}
            error={error}
            setError={setError}
            setLoading={setLoading}
          />
        );
        return Comp;
      }

      case "instagram_qrb": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../instagram-components/InstaQRB/InstaQRB")
            }
            nodeData={nodeData}
            config={config}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            flowId={flowId}
            storeId={storeId}
            error={error}
            setError={setError}
            withText={false}
          />
        );
        return Comp;
      }

      case "instagram_text": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../instagram-components/InstaQRB/InstaQRB")
            }
            nodeData={nodeData}
            config={config}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            flowId={flowId}
            storeId={storeId}
            error={error}
            setError={setError}
            withText={true}
          />
        );
        return Comp;
      }

      case "ask_name": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../components/store-value/storeValue")
            }
            nodeData={nodeData}
            type="name"
            config={config}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            setLoading={setLoading}
          />
        );
        return Comp;
      }

      case "ask_email": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../components/store-value/storeValue")
            }
            nodeData={nodeData}
            type="email"
            config={config}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            setLoading={setLoading}
          />
        );
        return Comp;
      }

      case "ask_phone": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../components/store-value/storeValue")
            }
            nodeData={nodeData}
            type="phone"
            config={config}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            setLoading={setLoading}
          />
        );
        return Comp;
      }

      case "Condition": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../components/condition-block/index")
            }
            nodeData={nodeData}
            type="Condition"
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            storeId={storeId}
            config={config}
            error={error}
            setError={setError}
            setLoading={setLoading}
          />
        );
        return Comp;
      }

      case "discount_code": {
        const Comp = (
          <LazyLoad
            getComponent={() => import("../../components/discount-block/index")}
            nodeType={nodeData.subtype}
            nodeData={nodeData}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            storeId={storeId}
            config={config}
            setLoading={setLoading}
          />
        );
        return Comp;
      }

      case "add_event": {
        const Comp = (
          <LazyLoad
            getComponent={() => import("../../app/action-block/addEvent")}
            nodeType={nodeData.subtype}
            nodeData={nodeData}
            type="Add Event"
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            storeId={storeId}
            config={config}
            setLoading={setLoading}
          />
        );
        return Comp;
      }
      case "CSAT":
      case "CSAT/NPS": {
        const Comp = (
          <LazyLoad
            getComponent={() => import("../../app/action-block/csat")}
            nodeType={nodeData.subtype}
            nodeData={nodeData}
            config={config}
            type="CSAT/NPS"
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            storeId={storeId}
            setLoading={setLoading}
          />
        );
        return Comp;
      }
      case "email_csat": {
        const Comp = (
          <LazyLoad
            getComponent={() => import("../../app/action-block/email-csat")}
            nodeType={nodeData.subtype}
            nodeData={nodeData}
            config={config}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            storeId={storeId}
            setLoading={setLoading}
          />
        );
        return Comp;
      }

      case "shopify_add_tags": {
        const Comp = (
          <LazyLoad
            getComponent={() => import("../../app/action-block/addShopifyTag")}
            nodeType={nodeData.subtype}
            nodeData={nodeData}
            type="Add tags"
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            storeId={storeId}
            config={config}
          />
        );
        return Comp;
      }

      case ComponentModalType.wa_carousel: {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import(
                "../../components/WACarouselTemplate/carouselTemplateComponent"
              )
            }
            nodeData={nodeData}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
          />
        );
        return Comp;
      }

      case ComponentModalType.carousel: {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../components/carousel/screen/carousel")
            }
            nodeData={nodeData}
            type="carousal"
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
            setLoading={setLoading}
          />
        );
        return Comp;
      }

      case "product_card": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../components/inhouse-pdp/whatsappPdp")
            }
            nodeData={nodeData}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            storeId={storeId}
            config={config}
            setLoading={setLoading}
            mode={"singleProduct"}
            setError={setError}
          />
        );
        return Comp;
      }

      case "product_carousel": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../components/product-carousel/screen/ProductCarousel")
            }
            nodeData={nodeData}
            type="productCarousel"
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
            setLoading={setLoading}
          />
        );
        return Comp;
      }

      case "shopify_add_notes": {
        const Comp = (
          <LazyLoad
            getComponent={() => import("../../app/action-block/addNotes")}
            nodeType={nodeData.subtype}
            nodeData={nodeData}
            type="Add notes"
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            storeId={storeId}
            config={config}
          />
        );
        return Comp;
      }

      case "start_flow": {
        const Comp = (
          <LazyLoad
            getComponent={() => import("../../app/action-block/createFlow")}
            nodeType={nodeData.subtype}
            nodeData={nodeData}
            type="Start flow"
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            storeId={storeId}
            config={config}
            setError={setError}
          />
        );
        return Comp;
      }
      case "assign_to_agent": {
        const Comp = (
          <LazyLoad
            getComponent={() => import("../../app/action-block/assignAgent")}
            nodeType={nodeData.subtype}
            nodeData={nodeData}
            type="Assign agent"
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            storeId={storeId}
            config={config}
            setLoading={setLoading}
          />
        );
        return Comp;
      }
      case "assign_ticket": {
        return (
          <LazyLoad
            getComponent={() => import("../../app/action-block/assignTicket")}
            nodeType={nodeData.subtype}
            nodeData={nodeData}
            type="Assign agent"
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            storeId={storeId}
            config={config}
            setLoading={setLoading}
          />
        );
      }
      case "broadcast_events": {
        const Comp = (
          <LazyLoad
            getComponent={() => import("../../app/action-block/broadcastOpt")}
            nodeType={nodeData.subtype}
            nodeData={nodeData}
            type="Broadcast Events"
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            storeId={storeId}
            config={config}
          />
        );
        return Comp;
      }

      case "prevent_backtracking": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../app/action-block/preventBacktracking")
            }
            nodeType={nodeData.subtype}
            nodeData={nodeData}
            type="Prevent backtracking"
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            storeId={storeId}
          />
        );
        return Comp;
      }

      case "evaluate": {
        const Comp = (
          <LazyLoad
            getComponent={() => import("../../app/action-block/evaluate")}
            nodeType={nodeData.subtype}
            nodeData={nodeData}
            type="Evaluate"
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            storeId={storeId}
            config={config}
            setLoading={setLoading}
          />
        );
        return Comp;
      }

      case "catalog_list": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../components/dynamic-list/dynamicList")
            }
            config={config}
            nodeData={nodeData}
            type="catalog"
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            setLoading={setLoading}
          />
        );
        return Comp;
      }

      case "store_variable": {
        const Comp = (
          <LazyLoad
            getComponent={() => import("../../app/action-block/storeVariable")}
            nodeType={nodeData.subtype}
            nodeData={nodeData}
            type="Store variable"
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            storeId={storeId}
            config={config}
            setLoading={setLoading}
          />
        );
        return Comp;
      }

      case "call_api": {
        const Comp = (
          <LazyLoad
            getComponent={() => import("../../app/action-block/callAnApi")}
            nodeType={nodeData.subtype}
            nodeData={nodeData}
            type="Call An Api"
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            storeId={storeId}
            config={config}
            setLoading={setLoading}
          />
        );
        return Comp;
      }

      case "order_list": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../components/dynamic-list/dynamicList")
            }
            config={config}
            nodeData={nodeData}
            type="order"
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            setLoading={setLoading}
          />
        );
        return Comp;
      }
      case "catalog": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../components/whatsapp-pdp/whatsappPdp")
            }
            nodeData={nodeData}
            mode={"catalogs"}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            storeId={storeId}
            config={config}
            setLoading={setLoading}
          />
        );
        return Comp;
      }

      case "multi_product": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../components/whatsapp-pdp/whatsappPdp")
            }
            mode={"productList"}
            nodeData={nodeData}
            config={config}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            storeId={storeId}
            setLoading={setLoading}
          />
        );
        return Comp;
      }

      case "question": {
        const Comp = (
          <LazyLoad
            getComponent={() => import("../../components/question/Question")}
            config={config}
            nodeData={nodeData}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            storeId={storeId}
            setLoading={setLoading}
          />
        );
        return Comp;
      }

      case "single_product": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../components/whatsapp-pdp/whatsappPdp")
            }
            mode={"singleProduct"}
            nodeData={nodeData}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            storeId={storeId}
            config={config}
            setLoading={setLoading}
          />
        );
        return Comp;
      }

      case "add_customer_tags": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../app/action-block/addCustomerTags/index")
            }
            nodeData={nodeData}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
            setLoading={setLoading}
            error={error}
            setError={setError}
          />
        );
        return Comp;
      }

      case "create_payment_link_on_Razorpay":
      case "create_payment_link_on_Goswift":
      case "create_ticket_on_freshdesk": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../app/action-block/callCustomAPI/manageTickets")
            }
            nodeData={nodeData}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            mode={"ticket"}
            config={config}
            setLoading={setLoading}
          />
        );
        return Comp;
      }

      case "send_email": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import(
                "../../app/action-block/callCustomAPI/manageCommunications"
              )
            }
            nodeData={nodeData}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            mode={"communications"}
            config={config}
            setLoading={setLoading}
          />
        );
        return Comp;
      }

      case "judgeme": {
        const Comp = (
          <LazyLoad
            getComponent={() => import("../../ui-components/judgeme/Judgeme")}
            nodeData={nodeData}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
            setLoading={setLoading}
          />
        );
        return Comp;
      }

      case ComponentModalType.google_sheet_add_row: {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../app/action-block/googleSheet/AddRow")
            }
            nodeData={nodeData}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
          />
        );
        return Comp;
      }
      case ComponentModalType.google_sheet_update_row: {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../app/action-block/googleSheet/UpdateRow")
            }
            nodeData={nodeData}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
          />
        );
        return Comp;
      }
      case ComponentModalType.google_sheet_get_row_data: {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../app/action-block/googleSheet/GetRowData")
            }
            nodeData={nodeData}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
            setLoading={setLoading}
          />
        );
        return Comp;
      }

      case ComponentModalType.browse_collection: {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../app/action-block/browsingFlow/BrowsingFlow")
            }
            nodeData={nodeData}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
          />
        );
        return Comp;
      }

      case "call_custom_api": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../app/action-block/callCustomAPI/manageTickets")
            }
            nodeData={nodeData}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
            mode={"custom_api"}
            setLoading={setLoading}
          />
        );
        return Comp;
      }
      case "call_external_api": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../app/action-block/callExternalAPI/ExternalAPI")
            }
            nodeData={nodeData}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
          />
        );
        return Comp;
      }
      case "rest_api": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../app/action-block/restApiComponent/RestApi")
            }
            nodeData={nodeData}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
            error={error}
            setError={setError}
          />
        );
        return Comp;
      }
      case "message": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../components/qrb-buttons/qrbButtonList")
            }
            nodeData={nodeData}
            type="Message"
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
            error={error}
            setError={setError}
            setLoading={setLoading}
          />
        );
        return Comp;
      }

      case "template": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import(
                "../../components/approved-template-modal/templateComponent"
              )
            }
            nodeData={nodeData}
            type="Template"
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
          />
        );
        return Comp;
      }

      case ComponentModalType.email_temp_new: {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import(
                "../../components/approved-template-modal/templateComponent"
              )
            }
            nodeData={nodeData}
            type="Template"
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
          />
        );
        return Comp;
      }

      case ComponentModalType.send_sms: {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import(
                "../../components/approved-template-modal/templateComponent"
              )
            }
            nodeData={nodeData}
            type="Template"
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
          />
        );
        return Comp;
      }

      case ComponentModalType.filter: {
        const Comp = (
          <LazyLoad
            getComponent={() => import("../../app/action-block/filter")}
            nodeData={nodeData}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
            setLoading={setLoading}
            storeId={storeId}
          />
        );
        return Comp;
      }
      case NodeSubType.ORDER_FILTER: {
        const Comp = (
          <LazyLoad
            getComponent={() => import("../../app/action-block/orderFilter")}
            nodeData={nodeData}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
            setLoading={setLoading}
            storeId={storeId}
          />
        );
        return Comp;
      }
      case "cart_info": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../components/cart-info/CartInfoComponent")
            }
            nodeData={nodeData}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
          />
        );
        return Comp;
      }

      case "list": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../components/list-buttons/listButtons")
            }
            nodeData={nodeData}
            type="List"
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
            setLoading={setLoading}
          />
        );
        return Comp;
      }
      case "delay": {
        const Comp = (
          <LazyLoad
            getComponent={() => import("../../app/action-block/delay")}
            nodeData={nodeData}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
            error={error}
            setError={setError}
          />
        );
        return Comp;
      }
      case "sticky_notes": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../components/sticky-notes/stickyNotes")
            }
            nodeData={nodeData}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
          />
        );
        return Comp;
      }

      case "email_temp": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../email-components/temporaryEmail/tempEmail")
            }
            nodeData={nodeData}
            config={config}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            flowId={flowId}
            storeId={storeId}
            error={error}
            setError={setError}
          />
        );
        return Comp;
      }

      case "gmail_reply": {
        const Comp = (
          <LazyLoad
            getComponent={() =>
              import("../../email-components/emailRTE/emailRte")
            }
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
            nodeData={nodeData}
            flowId={flowId}
            storeId={storeId}
            error={error}
            setError={setError}
          />
        );
        return Comp;
      }

      case "add_ticket_label": {
        return (
          <LazyLoad
            getComponent={() => import("../../app/action-block/addLabels")}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
            nodeData={nodeData}
            flowId={flowId}
            storeId={storeId}
            error={error}
            setError={setError}
            setLoading={setLoading}
          />
        );
      }

      case "change_ticket_stage": {
        return (
          <LazyLoad
            getComponent={() =>
              import("../../app/action-block/changeChatStage")
            }
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
            nodeData={nodeData}
            flowId={flowId}
            storeId={storeId}
            error={error}
            setError={setError}
            setLoading={setLoading}
          />
        );
      }
      case "check_working_hours": {
        return (
          <LazyLoad
            getComponent={() =>
              import("../../app/action-block/checkWorkingHours")
            }
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
            nodeData={nodeData}
            flowId={flowId}
            storeId={storeId}
            error={error}
            setError={setError}
            setLoading={setLoading}
          />
        );
      }
      case "ab_test": {
        return (
          <LazyLoad
            getComponent={() => import("../../app/action-block/abtesting")}
            nodeData={nodeData}
            nodeType={nodeData.subtype}
            saveTo={saveTo}
            setSaveTo={setSaveTo}
            config={config}
            error={error}
            setError={setError}
          />
        );
      }
    }
  };
  return {
    lazyLoadComponent,
  };
};
export default ComponentModalHook;

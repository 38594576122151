export function unCamelCase(str?: string) {
  return (
    str
      // insert a space between lower & upper
      ?.replace(/([a-z])([A-Z])/g, "$1 $2")
      // space before last upper in a sequence followed by lower
      ?.replace(/\b([A-Z]+)([A-Z])([a-z])/, "$1 $2$3")
      // uppercase the first character
      ?.replace(/^./, function (st: string) {
        return st.toUpperCase();
      }) ?? ""
  );
}

export function unCamelCaseWithFirstUppercase(str: string) {
  const newStr = unCamelCase(str);
  return newStr[0].toUpperCase() + newStr.slice(1);
}

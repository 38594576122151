import { CustomNodeIconProps } from "../utilities/flowBuilder.utility";

const MessageNodeIcon = (props: CustomNodeIconProps) => {
    const {backgroundColor, color, height, width} = props;
    return (
        <svg width={width || "18"} height={height || "18"} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.16666 2.33329C2.94564 2.33329 2.73368 2.42109 2.5774 2.57737C2.42112 2.73365 2.33332 2.94561 2.33332 3.16663V14.4881L4.24407 12.5774C4.40035 12.4211 4.61231 12.3333 4.83332 12.3333H14.8333C15.0543 12.3333 15.2663 12.2455 15.4226 12.0892C15.5789 11.9329 15.6667 11.721 15.6667 11.5V3.16663C15.6667 2.94561 15.5789 2.73365 15.4226 2.57737C15.2663 2.42109 15.0543 2.33329 14.8333 2.33329H3.16666ZM1.39889 1.39886C1.86773 0.930018 2.50362 0.666626 3.16666 0.666626H14.8333C15.4964 0.666626 16.1322 0.930018 16.6011 1.39886C17.0699 1.8677 17.3333 2.50358 17.3333 3.16663V11.5C17.3333 12.163 17.0699 12.7989 16.6011 13.2677C16.1323 13.7366 15.4964 14 14.8333 14H5.1785L2.08925 17.0892C1.85091 17.3275 1.49248 17.3988 1.18109 17.2699C0.869692 17.1409 0.666656 16.837 0.666656 16.5V3.16663C0.666656 2.50358 0.930048 1.8677 1.39889 1.39886Z" fill={color || "#212121"}/>
        </svg>
    );
}

export default MessageNodeIcon;


import { Icon } from "./icons-props";

const TestModeIcon: Icon = (props) => {
    const { color, backgroundColor } = props;
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="4" fill={backgroundColor || "#F0F0F0"} />
            <g clip-path="url(#clip0_1519_90011)">
                <path d="M8.97915 11.8959H11.5833M14.1875 15.5418H13.1458C12.9404 15.5426 12.7368 15.5028 12.5469 15.4246C12.3569 15.3464 12.1843 15.2313 12.0391 15.086C11.8938 14.9408 11.7787 14.7682 11.7005 14.5782C11.6223 14.3883 11.5825 14.1847 11.5833 13.9793V9.81259C11.5825 9.60716 11.6223 9.40359 11.7005 9.21363C11.7787 9.02367 11.8938 8.85108 12.0391 8.70582C12.1843 8.56056 12.3569 8.4455 12.5469 8.36728C12.7368 8.28906 12.9404 8.24923 13.1458 8.2501H14.1875M5.85415 10.073H8.45831C8.74596 10.073 8.97915 10.3062 8.97915 10.5938V13.198C8.97915 13.4856 8.74596 13.7188 8.45831 13.7188H5.85415C5.5665 13.7188 5.33331 13.4856 5.33331 13.198V10.5938C5.33331 10.3062 5.5665 10.073 5.85415 10.073ZM14.7083 6.16675H17.8333C18.121 6.16675 18.3541 6.39993 18.3541 6.68758V9.81258C18.3541 10.1002 18.121 10.3334 17.8333 10.3334H14.7083C14.4207 10.3334 14.1875 10.1002 14.1875 9.81258V6.68758C14.1875 6.39993 14.4207 6.16675 14.7083 6.16675ZM14.7083 13.4584H17.8333C18.121 13.4584 18.3541 13.6916 18.3541 13.9792V17.1042C18.3541 17.3919 18.121 17.6251 17.8333 17.6251H14.7083C14.4207 17.6251 14.1875 17.3919 14.1875 17.1042V13.9792C14.1875 13.6916 14.4207 13.4584 14.7083 13.4584Z" stroke={color || "#212121"} stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M20.75 17.8333V19.0833C20.75 20.0038 20.0038 20.75 19.0833 20.75H17.8333" stroke={color || "#212121"} stroke-width="1.25" stroke-linecap="round" />
                <path d="M3.25 17.8333V19.0833C3.25 20.0038 3.99619 20.75 4.91667 20.75H6.16667" stroke={color || "#212121"} stroke-width="1.25" stroke-linecap="round" />
                <path d="M3.25 6.16667V4.91667C3.25 3.99619 3.99619 3.25 4.91667 3.25H6.16667" stroke={color || "#212121"} stroke-width="1.25" stroke-linecap="round" />
                <path d="M20.75 6.16667V4.91667C20.75 3.99619 20.0038 3.25 19.0833 3.25H17.8333" stroke={color || "#212121"} stroke-width="1.25" stroke-linecap="round" />
            </g>
            <defs>
                <clipPath id="clip0_1519_90011">
                    <rect width="20" height="20" fill="white" transform="translate(2 2)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default TestModeIcon;
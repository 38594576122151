import {
  COLORS,
  TitleRegular,
  BodySecondary,
  Button,
  DynamicTabs,
  Tag,
  IconButton,
  VariablePicker,
  PickerType,
} from "@bikdotai/bik-component-library";
import { generateUniqueId } from "../../app/action-block/Util";
import CrossTagIcon from "../../icons/crossTagIcon";
import InfoIcon from "../../icons/infoIcon";
import PlusIcon from "../../icons/plusIcon";
import { PageTwoStyled, StyledDash, VariableExtractionPanel } from "./styles";
import { IdSelection } from "./id-selection";
import DeleteIcon from "../../icons/DeleteIcon";
import { ID_Option } from "./constants";
import { updateSelectedVariables } from "./helpers";
import {RecordEventHelper} from '../../utilities/RecordEventHelpers';
import {AmplitudeEventStep, AmplitudeKeys, TargetPlatform} from '../../utilities/AnalyticsTypes';

const Page2 = (props: any) => {
  const {
    payloadVariable,
    payloadVariable2,
    idOptions,
    idOptions2,
    setPageTwoError,
    setIdOptions,
    setIdOptions2,
    setPayloadVariable,
    setPayloadVariable2,
    showVarPicker,
    setShowVarPicker,
    showVarPicker2,
    setShowVarPicker2,
    payloadList,
    pageTwoError,
    selectedPayloadVars,
    setSelectedPayloadVars,
    tabId,
    setTabId,
    setClickedPayloadVar,
    clickedPayloadVar,
    setCells,
    showId2,
    setShowId2,
  } = props;

  const eventHelper = new RecordEventHelper();

  return (
    <PageTwoStyled id="page2">
      <div
        style={{
          width: "983px",
          borderRadius: "4px",
          border: `1px solid ${COLORS.content.inactive}`,
        }}
      >
        <div
          style={{
            display: "flex",
            padding: "16px 18px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "10px",
            borderBottom: `1px solid ${COLORS.content.inactive}`,
            borderRadius: "4px 4px 0 0",
            background: COLORS.surface.subdued,
          }}
        >
          <TitleRegular color={COLORS.content.primary}>
            Enter Unique ID
          </TitleRegular>
          <BodySecondary color={COLORS.content.secondary}>
            Add the type of ID to be used and select a payload variable for the
            same. This will be used to map the data received to a specific
            customer.
          </BodySecondary>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "12px",
          }}
        >
          <IdSelection
            payloadVariable={payloadVariable}
            idOptions={idOptions}
            setPageTwoError={setPageTwoError}
            setIdOptions={setIdOptions}
            setPayloadVariable={setPayloadVariable}
            showVarPicker={showVarPicker}
            setShowVarPicker={setShowVarPicker}
            payloadList={payloadList}
            primary={true}
            setSecondaryIdOptions={setIdOptions2}
          />

          {!showId2 ? (
            <StyledDash
              isActive={false}
              style={{
                padding: "24px",
                paddingTop: "12px",
              }}
            >
              <Button
                style={{
                  width: "250px",
                }}
                matchParentWidth={true}
                onClick={() => {
                  setShowId2(true);
                }}
                LeadingIcon={PlusIcon}
                buttonText="Secondary ID (optional)"
                buttonType="dashBold"
                size="large"
              />
            </StyledDash>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                alignItems: "center",
                paddingBottom: "12px",
              }}
            >
              <IdSelection
                payloadVariable={payloadVariable2}
                idOptions={idOptions2}
                setPageTwoError={setPageTwoError}
                setIdOptions={setIdOptions2}
                setPayloadVariable={setPayloadVariable2}
                showVarPicker={showVarPicker2}
                setShowVarPicker={setShowVarPicker2}
                payloadList={payloadList}
                primary={false}
                setSecondaryIdOptions={setIdOptions}
              />
              <IconButton
                Icon={DeleteIcon}
                style={{
                  marginTop: "26px",
                }}
                onClick={() => {
                  setShowId2(false);
                  setIdOptions2((prev: ID_Option[]) => {
                    return prev.map((i) => {
                      return {
                        ...i,
                        selected: false,
                      };
                    });
                  });
                  setPayloadVariable2("");
                }}
              />
            </div>
          )}
          {(pageTwoError.idMissing ||
            pageTwoError.payloadVarMissingOrInvalid) && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 6,
                padding: "0px 0px 4px 24px",
                alignItems: "center",
              }}
            >
              <InfoIcon
                width={16}
                height={16}
                color={COLORS.content.negative}
              />
              <BodySecondary color={COLORS.content.negative}>
                {pageTwoError.payloadVarMissingOrInvalid
                  ? "Please add a payload variable"
                  : pageTwoError.idMissing
                  ? "Please add a unique ID"
                  : ""}
              </BodySecondary>
            </div>
          )}

          {(pageTwoError.secondaryIdMissing ||
            pageTwoError.secondaryIdTypeMissing) && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 6,
                padding: "0px 0px 4px 24px",
                alignItems: "center",
              }}
            >
              <InfoIcon
                width={16}
                height={16}
                color={COLORS.content.negative}
              />
              <BodySecondary color={COLORS.content.negative}>
                {pageTwoError.secondaryIdMissing &&
                pageTwoError.secondaryIdTypeMissing
                  ? "Please select a secondary unique ID and unique ID type or delete"
                  : pageTwoError.secondaryIdMissing
                  ? "Please select a secondary unique ID or delete"
                  : pageTwoError.secondaryIdTypeMissing
                  ? "Please select secondary a unique ID type or delete"
                  : ""}
              </BodySecondary>
            </div>
          )}
        </div>
      </div>

      <div
        style={{
          width: "1px",
          height: "25px",
          background: "#D9D9D9",
          marginLeft: "24px",
        }}
      ></div>

      <div
        style={{
          borderRadius: "5px",
          border: `1px solid ${COLORS.content.inactive}`,
          background: COLORS.surface.standard,
          width: "983px",
        }}
      >
        <div
          style={{
            display: "flex",
            padding: "16px 24px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "10px",
            borderBottom: `1px solid ${COLORS.content.inactive}`,
            background: COLORS.surface.subdued,
            borderRadius: "4px 4px 0 0",
          }}
        >
          <TitleRegular color={COLORS.content.primary}>
            Payload Variables from Webhook
          </TitleRegular>
          <BodySecondary color={COLORS.content.secondary}>
            These variables will be available to use in the journey
          </BodySecondary>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <VariableExtractionPanel>
            <span
              style={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
                marginLeft: 24,
                marginTop: 24,
              }}
            >
              Extract
              <div
                style={{
                  width: "160px",
                }}
              >
                <DynamicTabs
                  selectedTabId={tabId}
                  tabsInfo={[
                    {
                      id: 1,
                      text: "All",
                      action: (tabName: string) => {
                        setTabId(1);
                        setSelectedPayloadVars({});
                        setClickedPayloadVar("");
                        setCells([
                          {
                            cellId: generateUniqueId(),
                            payloadVar: "",
                            existingVar: "",
                          },
                        ]);
                        setPageTwoError((prev: any) => {
                          return {
                            ...prev,
                            payloadVarsNotSelected: false,
                          };
                        });
                      },
                      displayText: "All",
                    },
                    {
                      id: 2,
                      text: "Part",
                      action: (tabName: string) => {
                        setTabId(2);
                        setClickedPayloadVar("");
                        setCells([
                          {
                            cellId: generateUniqueId(),
                            payloadVar: "",
                            existingVar: "",
                          },
                        ]);
                        // TODO: sumesh
                        // reset selected variables inside variable picker
                        // updateSelectedVariables(payloadList[0].variables, []);
                      },
                      displayText: "Part",
                    },
                  ]}
                  tabStyleInfo={{
                    type: "SQUARE",
                  }}
                />
              </div>
              Payload
            </span>

            {tabId === 2 && (
              <div>
                <div>
                  <TitleRegular
                    color={COLORS.content.primary}
                    style={{
                      marginLeft: 24,
                      marginTop: 24,
                    }}
                  >
                    Selected Variables
                  </TitleRegular>
                </div>
                {!Object.keys(selectedPayloadVars).length ? (
                  <div>
                    <BodySecondary
                      color={COLORS.content.primary}
                      style={{
                        marginLeft: 24,
                        marginTop: 4,
                      }}
                    >
                      Add variables from the Right Tab
                    </BodySecondary>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "8px",
                margin: 24,
                marginTop: 12,
              }}
            >
              {Object.keys(selectedPayloadVars).map((item, index) => {
                return (
                  <Tag
                    tagText={item}
                    key={item}
                    TrailingIcon={CrossTagIcon}
                    type="white"
                    theme="light"
                    variant="regular"
                    onTrailingIconClicked={() => {
                      setSelectedPayloadVars((prev: any) => {
                        const newVar = { ...prev };
                        delete newVar[item];
                        // TODO: sumesh
                        // updateSelectedVariables(payloadList[0].variables, Object.keys(newVar));
                        return newVar;
                      });
                    }}
                  />
                );
              })}
            </div>

            <div
              style={{
                marginTop: "auto",
              }}
            >
              {pageTwoError.payloadVarsNotSelected && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 6,
                    padding: "0px 0px 4px 24px",
                    alignItems: "center",
                  }}
                >
                  <InfoIcon
                    width={16}
                    height={16}
                    color={COLORS.content.negative}
                  />
                  <BodySecondary color={COLORS.content.negative}>
                    {"Please select Payload Variables to be extracted"}
                  </BodySecondary>
                </div>
              )}
            </div>
          </VariableExtractionPanel>
          <div
            style={{
              width: "480px",
              borderLeft: `1px solid ${COLORS.content.inactive}`,
            }}
          >
            <VariablePicker autoOpenFirst key={tabId} disabled={tabId === 1} type={PickerType.EMBEDDED} data={payloadList} onSelect={(variable) => {
              setClickedPayloadVar({...variable})
              eventHelper.trackEvent(
                TargetPlatform.Amplitude,
                AmplitudeKeys.service_used,
                {
                  step: AmplitudeEventStep.variable_used,
                  variableName: variable.displayName,
                  screen: 'journey_builder',
                }
              );
            }} />
          </div>
        </div>
      </div>
    </PageTwoStyled>
  );
};

export default Page2;

// @ts-nocheck
import {
  BaseQueryBuilderNode,
  QueryBuilderMeta,
  QueryBuilderProperty,
  QueryBuilderRoot,
} from "@bikdotai/bik-component-library/dist/esm/components/QueryBuilder/types/QueryBuilder.type";

export const POSITIVE_VAL_ERROR = "Enter a positive integer";
export const NON_ZERO_VAL = "Enter a positive value";
export const INTEGER_ERROR = "Enter an integer";
export const REQUIRED_VALUE = "Required field";
export const NON_DECIMAL_NUMBER = "Enter a non-decimal value";
export const COMMA_SEPERATED_VALUES = "Enter values seperated by commas";

export type SegmentBuilder = {
  exclude?: QueryBuilderRoot;
  include: QueryBuilderRoot;
  name?: string;
};

export class SegmentValidator {
  validate(stateArg: SegmentBuilder) {
    const state = { ...stateArg };

    let hasErr = false;

    const includeNodes = (state.include?.nodes ?? []).map((node) => {
      const metas = (node.metas ?? []).map((meta) =>
        this.validateMeta(meta, () => {
          hasErr = true;
        })
      );

      const properties = (node.properties ?? []).map((property) =>
        this.validateProperty({ ...property }, () => {
          hasErr = true;
        })
      );

      if (
        !metas.length &&
        ["EVENT_FILTER", "NO_EVENT_FILTER", "SEGMENT_FILTER"].includes(
          node.type
        )
      ) {
        hasErr = true;
        return {
          ...node,
          metas: [
            {
              key: node.type === "SEGMENT_FILTER" ? "segmentID" : "eventName",
              error: {
                key: REQUIRED_VALUE,
              },
            },
          ],
          properties,
        };
      }

      if (
        !properties.length &&
        (node.type === "USER_PROPERTY_FILTER" ||
          node.type === "ORDER_PROPERTY_FILTER")
      ) {
        hasErr = true;
        return {
          ...node,
          metas: [],
          properties: [
            {
              error: {
                key: REQUIRED_VALUE,
              },
            },
          ],
        };
      }

      return {
        ...node,
        metas,
        properties,
      };
    }) as BaseQueryBuilderNode[];

    const include: QueryBuilderRoot = {
      nodes: includeNodes,
      operator: state.include.operator,
    };

    if (state.exclude?.nodes.length) {
      const excludeNodes = (state.exclude?.nodes ?? []).map((node) => {
        const metas = (node.metas ?? []).map((meta) =>
          this.validateMeta(meta, () => {
            hasErr = true;
          })
        );

        const properties = (node.properties ?? []).map((property) =>
          this.validateProperty({ ...property }, () => {
            hasErr = true;
          })
        );

        if (
          !metas.length &&
          (node.type === "EVENT_FILTER" ||
            node.type === "NO_EVENT_FILTER" ||
            node.type === "SEGMENT_FILTER")
        ) {
          hasErr = true;
          return {
            ...node,
            metas: [
              {
                key: node.type === "SEGMENT_FILTER" ? "segmentID" : "eventName",
                error: {
                  key: REQUIRED_VALUE,
                },
              },
            ],
            properties,
          };
        }

        if (!properties.length && node.type === "USER_PROPERTY_FILTER") {
          hasErr = true;
          return {
            ...node,
            metas: [],
            properties: [
              {
                error: {
                  key: REQUIRED_VALUE,
                },
              },
            ],
          };
        }

        return {
          ...node,
          metas,
          properties,
        };
      }) as BaseQueryBuilderNode[];

      const exclude = {
        nodes: excludeNodes,
        operator: state.exclude.operator,
      };

      return [hasErr, { ...state, exclude, include }];
    }

    return [hasErr, { ...state, include }];
  }

  validateMeta(meta: QueryBuilderMeta, onErr?: () => void) {
    if (meta.key === "frequency") {
      if (!meta.value.value) {
        onErr?.();

        return {
          ...meta,
          error: {
            value: {
              value: REQUIRED_VALUE,
            },
          },
        };
      } else if (isNaN(meta.value.value)) {
        onErr?.();
        return {
          ...meta,
          error: {
            value: {
              value: POSITIVE_VAL_ERROR,
            },
          },
        };
      } else {
        const intVal = parseFloat(meta.value.value.toString());

        if (intVal < 1) {
          onErr?.();
          return {
            ...meta,
            error: {
              value: {
                value: POSITIVE_VAL_ERROR,
              },
            },
          };
        }

        if (intVal % 1 !== 0) {
          onErr?.();
          return {
            ...meta,
            error: {
              value: {
                value: NON_DECIMAL_NUMBER,
              },
            },
          };
        }
      }
    }
    if (meta.key === "date" && meta.value?.operator === "in the last") {
      if (
        !meta.value?.value[0] ||
        (typeof meta.value?.value[0] === "string" &&
          !meta.value?.value[0].trim())
      ) {
        onErr?.();
        return {
          ...meta,
          error: {
            value: {
              value: REQUIRED_VALUE,
            },
          },
        };
      } else if (isNaN(meta.value?.value[0])) {
        onErr?.();
        return {
          ...meta,
          error: {
            value: {
              value: INTEGER_ERROR,
            },
          },
        };
      } else if (!isNaN(meta.value?.value[0]) && meta.value.value < 0) {
        onErr?.();
        return {
          ...meta,
          error: {
            value: {
              value: POSITIVE_VAL_ERROR,
            },
          },
        };
      }
    }

    if (meta.key === "eventName" && !meta.value) {
      onErr?.();
      return {
        ...meta,
        error: {
          value: REQUIRED_VALUE,
        },
      };
    }

    return { ...meta, error: undefined };
  }

  validateProperty(property: QueryBuilderProperty, onErr?: () => void) {
    if (!property.name) {
      onErr?.();
      return { ...property, error: { name: REQUIRED_VALUE } };
    }
    if (property.operator && property.dataType) {
      if (property.dataType === "integer") {
        if (
          property.operator === "lessThan" ||
          property.operator === "lessThanOrEqualTo" ||
          property.operator === "greaterThan" ||
          property.operator === "greaterThanOrEqualTo"
        ) {
          const val = parseInt(property.value?.toString());
          if (isNaN(val)) {
            onErr?.();
            return { ...property, error: { value: INTEGER_ERROR } };
          }
        }
      }

      if (property.dataType === "frequency") {
        if (
          property.operator === "exactly" ||
          property.operator === "atleast" ||
          property.operator === "atMost"
        ) {
          /** Single positive number */
          const val = parseInt(property.value?.toString());
          if (isNaN(val)) {
            onErr?.();
            return { ...property, error: { value: INTEGER_ERROR } };
          } else if (val < 0) {
            onErr?.();
            return { ...property, error: { value: POSITIVE_VAL_ERROR } };
          }
        }
      }

      if (property.dataType === "date" || property.dataType === "exactly") {
        if (
          (property.operator === "exactly" ||
            property.operator === "in the last") &&
          Array.isArray(property.value)
        ) {
          if (!property.value[0].length) {
            onErr?.();
            return { ...property, error: { value: REQUIRED_VALUE } };
          } else {
            const val = parseInt(property.value[0]);
            const floatVal = parseFloat(property.value[0]);
            if (isNaN(val)) {
              onErr?.();
              return { ...property, error: { value: INTEGER_ERROR } };
            } else if (val < 0) {
              onErr?.();
              return { ...property, error: { value: POSITIVE_VAL_ERROR } };
            } else if (floatVal % 1 !== 0) {
              onErr?.();
              return { ...property, error: { value: NON_DECIMAL_NUMBER } };
            }
          }
        } else if (property.operator === "in between last") {
          if (!property.value.offset || !property.value.start) {
            onErr?.();
            return { ...property, error: { value: REQUIRED_VALUE } };
          } else {
            const offsetVal = parseInt(property.value.offset);
            const startVal = parseInt(property.value.start);
            const offsetFloat = parseFloat(property.value.offset);
            const startFloat = parseFloat(property.value.start);
            if (isNaN(offsetVal) || isNaN(startVal)) {
              onErr?.();
              return { ...property, error: { value: INTEGER_ERROR } };
            } else if (offsetVal < 0 || startVal < 0) {
              onErr?.();
              return { ...property, error: { value: POSITIVE_VAL_ERROR } };
            } else if (offsetFloat % 1 !== 0 || startFloat % 1 !== 0) {
              onErr?.();
              return { ...property, error: { value: NON_DECIMAL_NUMBER } };
            }
          }
        }
      }

      if (
        !(
          property.operator === "exists" ||
          property.operator === "doesntExist" ||
          property.operator === "today" ||
          property.operator === "yesterday" ||
          property.operator === "last month" ||
          property.operator === "last quarter" ||
          property.operator === "last week" ||
          property.operator === "isFalse" ||
          property.operator === "isTrue" ||
          property.operator === "last 7 days" ||
          property.operator === "last 30 days" ||
          property.operator === "this week" ||
          property.operator === "this month"
        )
      ) {
        /** not noInput */
        if (!property.value) {
          onErr?.();
          return { ...property, error: { value: REQUIRED_VALUE } };
        }
      }

      if (
        (property.dataType === "integer" &&
          (property.operator === "is" || property.operator === "isNot")) ||
        (property.dataType === "string" &&
          (property.operator === "contains" ||
            property.operator === "doesntContain" ||
            property.operator === "startsWith" ||
            property.operator === "endsWith"))
      ) {
        if (!property.value) {
          onErr?.();
          return { ...property, error: { value: COMMA_SEPERATED_VALUES } };
        } else {
          if (
            typeof property.value === "object" &&
            Array.isArray(property.value) &&
            (property.value as string[]).length === 0
          ) {
            onErr?.();
            return { ...property, error: { value: REQUIRED_VALUE } };
          }
        }
      }

      if (
        property.dataType === "string" &&
        (property.operator === "is" || property.operator === "isNot")
      ) {
        if (
          !property.value ||
          (Array.isArray(property.value) && property.value.length === 0)
        ) {
          onErr?.();
          return { ...property, error: { value: REQUIRED_VALUE } };
        }
      }
    } else if (property.dataType && !property.operator) {
      onErr?.();
      return { ...property, error: { value: REQUIRED_VALUE } };
    }

    return { ...property, error: undefined };
  }
}

//

import {FunctionComponent, useEffect, useState} from "react";
import {SuperAdminAccess} from "./SuperAdminAccess";
import {TableStyle} from "./EntryPoint.style";
import {useAppSelector} from "../../state/store";
import {customisationStateActions} from "../../state/customisationState";
import {useDispatch} from "react-redux";
import BikayiButton from "../../ui-components/button";

interface TableProps {

}

export const CustomizationTable: FunctionComponent<TableProps> = (props) => {
    const allCustomisationList = useAppSelector<any>((state) => state.customisationState?.customisations || {});
    const [itemList, setItemList] = useState<any>({});
    const superAdminFns = new SuperAdminAccess();
    const [selectedFlows, setSelectedFlows] = useState<{[key: string]: boolean}>({});
    const dispatcher = useDispatch();
    const [tabElementName, setTabElementName] = useState<string>('OPEN');
    const [showCloseReqButton, setShowCloseReqButton] = useState(false);
    const storeId = useAppSelector(state => state.homeState?.storeId)

    useEffect(() => {
        filterFlowStatus();
    }, [allCustomisationList, tabElementName])

    useEffect(() => {
        const selectedFlowsNumber = Object.keys(selectedFlows).filter(key => {
            return selectedFlows[key]
        }).length;
        if (selectedFlowsNumber) {
            setShowCloseReqButton(true);
        } else {
            setShowCloseReqButton(false);
        }
    }, [selectedFlows])

    const onClickArchive = async (flowId: string) => {
        const itemListUpdated = {...allCustomisationList};
        itemListUpdated[flowId] = {
            ...itemListUpdated[flowId],
            'status': 'customized'
        }
        await superAdminFns.archiveCustomisation(storeId, itemListUpdated);
        const updatedFlows = {...itemListUpdated};
        dispatcher(customisationStateActions.setCustomisations({
            customisations: updatedFlows
        }));
    }

    const filterFlowStatus = () => {
        if (tabElementName === 'OPEN') {
            const selectedKeys = Object.keys(allCustomisationList).filter(key => {
                return allCustomisationList[key]?.status !== 'customized'
            });
            let selectedMap: { [x: string]: any; } = {};
            selectedKeys.forEach(key => {
                selectedMap[key] = allCustomisationList[key]
            })
            setItemList(selectedMap)
        } else {
            const selectedKeys = Object.keys(allCustomisationList).filter(key => {
                return allCustomisationList[key]?.status === 'customized'
            });
            let selectedMap: { [x: string]: any; } = {};
            selectedKeys.forEach(key => {
                selectedMap[key] = allCustomisationList[key]
            })
            setItemList(selectedMap)
        }
    }

    const closeRequestsBulk = async () => {
        const itemListUpdated = {...allCustomisationList};
        Object.keys(selectedFlows).forEach((flowId: string) => {

            if(selectedFlows[flowId]) {
                itemListUpdated[flowId] = {
                    ...itemListUpdated[flowId],
                    'status': 'customized'
                }
            }
        })
        await superAdminFns.archiveCustomisation(storeId, itemListUpdated);
        dispatcher(customisationStateActions.setCustomisations({
            customisations: itemListUpdated
        }));
        setSelectedFlows({});
        setShowCloseReqButton(false);
    }

    const selectFlows = (flowId: string) => {
        let selectedFlowsUpdated = {...selectedFlows};
        selectedFlowsUpdated[flowId] = !selectedFlows[flowId] || false;
        setSelectedFlows(selectedFlowsUpdated);
    }

    return (
        <>
            <div className="header__tabs">
                <div className={tabElementName === 'OPEN' ? "header__tab-elements-selected" : "header__tab-elements"} onClick={() => setTabElementName('OPEN')}>
                    Open
                </div>
                <div className={tabElementName === 'CLOSED' ? "header__tab-elements-selected" : "header__tab-elements"} onClick={() => setTabElementName('CLOSED')}>
                    Closed
                </div>
                {showCloseReqButton &&
                    <div style={{margin: '12px 12px 12px auto'}}>
                        <BikayiButton
                            buttonSize="large"
                            buttonName="Close request"
                            buttonStyle='primary'
                            buttonWidth='fit-content'
                            buttonHeight={'32px'}
                            click={closeRequestsBulk}
                        />
                    </div>
                }

            </div>
            <TableStyle>
                <div className='table-wrapper'>
                    <table>
                        <thead>
                        {tabElementName === 'OPEN' && <td></td>}
                        <td>
                                Flow name
                            </td>
                            <td>
                                Email
                            </td>
                            <td>
                                Request description
                            </td>
                            {tabElementName === 'OPEN' && <td></td>}
                        </thead>
                        {Object.keys(itemList)!.map((key, index) => {
                            const value = itemList[key];
                            return(
                                <tbody>
                                <tr
                                >
                                    {tabElementName === 'OPEN' &&
                                        <td><
                                            input
                                            key={key}
                                            type="checkbox"
                                            value={selectedFlows[key] as any}
                                            onClick={(event) => {
                                                selectFlows(key);
                                                event.stopPropagation();
                                            }}
                                        />
                                        </td>
                                    }
                                    <td>{value.flowName}</td>
                                    <td>{value.email}</td>
                                    <td style={{maxWidth: '200px', whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}
                                    >{value.description}</td>
                                    {tabElementName === 'OPEN' &&
                                        <td className="archive-button"
                                            onClick={(event) => {
                                            onClickArchive(key).then().catch()
                                        }}
                                        >
                                            Mark as closed
                                        </td>
                                    }
                                </tr>
                                </tbody>
                            )}
                        )}
                    </table>
                </div>
            </TableStyle>
        </>
    )
}

import { BodyPrimary, Button, ButtonLarge, COLORS, TitleMedium } from "@bikdotai/bik-component-library"
import SearchIcon from "../../icons/searchIcon"

const ErrScreen = (props: { onClickBtn?: () => void, body: string, title: string }) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px'
        }}>

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '48px',
                height: '48px',
                backgroundColor: `${COLORS.background.inverse}`,
                borderRadius: '50%'
            }}>
                <SearchIcon color={`${COLORS.content.primaryInverse}`} />
            </div>

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px'
            }}>
                <TitleMedium>{props.title}</TitleMedium>
                <BodyPrimary>{props.body}</BodyPrimary>
            </div>
            {props.onClickBtn && <div onClick={props.onClickBtn}>
                <ButtonLarge color={`${COLORS.content.brand}`}>View all</ButtonLarge>
            </div>}
        </div>
    )
}

export default ErrScreen;
import { Icon } from "./icons-props";

const ShopifyAddTagIcon: Icon = (props) => {
  const { width, height } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_131_1663)">
        <path
          d="M14.1607 3.53547C14.148 3.44547 14.0671 3.39566 14.0002 3.39017C13.507 3.35407 13.0137 3.31825 12.5205 3.28273C12.5205 3.28273 11.5392 2.33372 11.4315 2.22868C11.3237 2.1237 11.1132 2.15563 11.0315 2.17905C11.0195 2.18251 10.8171 2.24335 10.4824 2.34426C10.1546 1.4254 9.57605 0.580999 8.5583 0.580999C8.5302 0.580999 8.50128 0.582109 8.47236 0.583712C8.18291 0.210828 7.82436 0.0488281 7.51466 0.0488281C5.14383 0.0488281 4.01116 2.93592 3.65602 4.40304C2.73477 4.68112 2.08032 4.87881 1.99672 4.90439C1.4825 5.06152 1.46623 5.0773 1.39871 5.54931C1.3479 5.90666 0.00241089 16.0427 0.00241089 16.0427L10.4867 17.9562L16.1675 16.7591C16.1675 16.7591 14.1733 3.62547 14.1608 3.53547H14.1607ZM9.9029 2.51884L9.01576 2.78631C9.01608 2.7254 9.01639 2.66549 9.01639 2.59996C9.01639 2.02889 8.93501 1.56909 8.80446 1.20459C9.32888 1.2687 9.67813 1.84994 9.9029 2.51884ZM8.15393 1.31783C8.29973 1.6737 8.39453 2.18442 8.39453 2.8736C8.39453 2.90886 8.39421 2.9411 8.3939 2.9737C7.81696 3.14779 7.19003 3.33679 6.56171 3.5264C6.9145 2.20007 7.57579 1.55947 8.15393 1.31783ZM7.44955 0.668287C7.55187 0.668287 7.65496 0.702129 7.75361 0.768273C6.9938 1.11656 6.17936 1.99375 5.83543 3.74549L4.38717 4.18242C4.79002 2.84629 5.74658 0.668287 7.44948 0.668287H7.44955Z"
          fill="#95BF46"
        />
        <path
          d="M14.0001 3.3894C13.5069 3.35329 13.0137 3.31748 12.5204 3.28195C12.5204 3.28195 11.5391 2.33294 11.4314 2.2279C11.3911 2.18882 11.3368 2.16879 11.2799 2.16016L10.4872 17.9553L16.1675 16.7583C16.1675 16.7583 14.1732 3.62469 14.1607 3.53469C14.148 3.44469 14.067 3.39488 14.0001 3.3894Z"
          fill="#5E8E3E"
        />
        <path
          d="M8.55827 6.4471L7.85781 8.47678C7.85781 8.47678 7.24411 8.15772 6.49182 8.15772C5.38895 8.15772 5.33345 8.83191 5.33345 9.0018C5.33345 9.9288 7.81408 10.284 7.81408 12.4553C7.81408 14.1637 6.70179 15.2637 5.20202 15.2637C3.4023 15.2637 2.48193 14.1726 2.48193 14.1726L2.96382 12.6217C2.96382 12.6217 3.90988 13.4129 4.70817 13.4129C5.2298 13.4129 5.44198 13.0128 5.44198 12.7205C5.44198 11.5113 3.40685 11.4573 3.40685 9.47036C3.40685 7.79802 4.63907 6.17969 7.1264 6.17969C8.0848 6.17969 8.55827 6.4471 8.55827 6.4471Z"
          fill="white"
        />
      </g>
      <rect x="8" y="8" width="16" height="16" rx="8" fill="white" />
      <path
        d="M16.5215 21.7568L12.0358 17.2711C11.8482 17.0836 11.7429 16.8292 11.7429 16.564L11.7429 12.4925C11.7429 11.9402 12.1906 11.4925 12.7429 11.4925L16.8144 11.4925C17.0796 11.4925 17.3339 11.5979 17.5215 11.7854L22.0072 16.2711C22.3977 16.6616 22.3977 17.2948 22.0072 17.6853L17.9357 21.7568C17.5452 22.1473 16.912 22.1473 16.5215 21.7568Z"
        fill="#008060"
      />
      <ellipse
        cx="1.16843"
        cy="1.16867"
        rx="1.16843"
        ry="1.16867"
        transform="matrix(0.707134 -0.707079 0.707134 0.707079 13.9303 15.334)"
        fill="white"
      />
      <defs>
        <clipPath id="clip0_131_1663">
          <rect width="16.2" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShopifyAddTagIcon;

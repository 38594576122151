import { Icon } from "./icons-props";

const Email: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1668 5.01471V15C19.1668 16.3769 18.0437 17.5 16.6668 17.5H3.3335C1.95659 17.5 0.833496 16.3769 0.833496 15V5C0.833496 3.6231 1.95659 2.5 3.3335 2.5H16.6668C18.0371 2.5 19.156 3.61225 19.1667 4.98003C19.167 4.9916 19.167 5.00316 19.1668 5.01471ZM2.589 4.62825C2.72682 4.35575 3.01021 4.16667 3.3335 4.16667H16.6668C16.9901 4.16667 17.2736 4.35578 17.4114 4.62832L10.0002 9.81612L2.589 4.62825ZM2.50016 6.60049V15C2.50016 15.4564 2.87707 15.8333 3.3335 15.8333H16.6668C17.1233 15.8333 17.5002 15.4564 17.5002 15V6.60059L10.4781 11.516C10.1912 11.7169 9.80928 11.7169 9.52235 11.516L2.50016 6.60049Z"
        fill={color || "#212121"}
      />
    </svg>
  );
};

export default Email;

import {
  ALERT_TYPES,
  Alert,
  BikShimmer,
  BodyCaption,
  BodyPrimary,
  BodySecondary,
  Button,
  COLORS,
  CheckBox,
  DropdownPopover,
  DynamicTabs,
  DynamicTabsDetails,
  DynamicTabsStyleDetails,
  IconButton,
  Input,
  ProductPickerModal,
  ScreenName,
  SelectedItems,
  StyledModal,
  TitleMedium,
  TitleRegular,
  TitleSmall,
  Toaster,
} from "@bikdotai/bik-component-library";
import { Body, Container, Footer, Header } from "../ig-modals/style";
import {
  DropdownContainer,
  DropdownWrapper,
  HeaderContent,
} from "../webhook/styles";
import ChevronDownIcon from "../../icons/chevron_down";
import WhatsappIcon from "../../icons/whatsappIcon";
import { useEffect, useState } from "react";
import { populateChannelIdsDropdown } from "../query-builder/utils";
import { useAppSelector } from "../../state/store";
import InfoIcon from "../../icons/infoIcon";
import FlashIcon from "../../icons/flashIcon";
import PlusIcon from "../../icons/plusIcon";
import {
  fetchCollections,
  fetchProducts,
  searchInCollection,
  searchCollectionsAndProducts,
} from "../../services/helpers/ProductPickerHelper";
import Box from "../../icons/box";
import DeleteIcon from "../../icons/DeleteIcon";
import EditIcon from "../../icons/editIcon";
import { RowCard } from "../picker/cards";
import GroupIcon from "../../icons/groupIcon";
import { v4 as uuidv4 } from "uuid";
import { IntegrationService } from "../../admin/integrations/IntegrationsService";
import {
  TriggerContainer,
  TriggerBody,
  ParamContainer,
  ExcludeProductsContainer,
  CustomersNotifyContainer,
  CustomerNotifyBody,
} from "./styles";
import {
  BackInStockConf,
  PriceDropConf,
  PriceDropParams,
  BackInStockParams,
  BackInStockErrors,
  PriceDropErrors,
  ExcludedProduct,
  BUSINESS_EVENTS,
} from "./models";
import { useParams } from "react-router-dom";
import AlertTriangle from "../../icons/alertTriangle";
import AlertCircle from "../../icons/alertCircle";
import { createSlots, getStoreWidgetStatus, getTrackerStatus } from "./apiRepo";
import { CURRENCY_SYMBOLS } from "../../app/messageBlock/Constants";
import { ChannelDropdown, ErrorLine, LinkText } from "./components";
import { dasboardUrl } from "../../config";

const BusinessEvents: React.FC<{
  visible: boolean;
  onClose: Function;
  currentChannelId: string;
  channel: string;
  data: BackInStockConf[] | PriceDropConf[];
  onSave: Function;
  mode: BUSINESS_EVENTS;
}> = (props) => {
  // general states
  const storeId = useAppSelector((state) => state.homeState.storeId);

  const [widgetStatus, setWidgetStatus] = useState({
    trackers: false,
    widget: false,
  });

  const flowMeta: { [key: string]: any } = useAppSelector(
    (state) => state.flowMetaState.flowMeta
  );
  const { flowId } = useParams();

  const channel = flowMeta[flowId as string]?.channel;

  // states relating to channel dropdown
  const [channelIdOptions, setChannelIdOptions] = useState<
    {
      label: string;
      value: string;
      selected: boolean;
    }[]
  >([]);
  const [selectedChannel, setSelectedChannel] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const channels: { [key: string]: any } = useAppSelector(
    (state) => state.flowMetaState.channels!
  );
  const [channelError, setChannelError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const tabStyleInfo: DynamicTabsStyleDetails = {
    type: "SQUARE",
  };

  const tabsInfo: DynamicTabsDetails[] = [
    {
      id: 0,
      text: "Percentage",
      action: (tabName: string) => {
        setPriceDropParams((prev) => {
          return {
            ...prev,
            amountType: "percentage",
          };
        });
      },
    },
    {
      id: 1,
      text: "Value",
      action: (tabName: string) => {
        setPriceDropParams((prev) => {
          return {
            ...prev,
            amountType: "value",
          };
        });
      },
    },
  ];
  // states relating to product picker
  const [pickedItems, setPickedItems] = useState<{
    data: SelectedItems;
    total: number;
  }>({
    data: {},
    total: 0,
  });

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [productsNotSelectedErr, setProductsNotSelectedErr] =
    useState<boolean>(false);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [startupScreen, setStartupScreen] = useState<ScreenName>(
    ScreenName.Collections
  );
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [showToaster, setShowToaster] = useState(false);

  // state relating to price drop
  const [priceDropParams, setPriceDropParams] = useState<PriceDropParams>({
    amount: "",
    amountType: "percentage",
    eventsSubscribedTo: {
      leadGenerated: true,
      productViewed: false,
    },
    customersEngagementGap: "",
  });

  // state relating to back in stock
  const [backInStockParams, setBackInStockParams] = useState<BackInStockParams>(
    {
      minInventory: "",
      noOfCustomerToNotify: "",
      gapBetweenBatches: "",
      customersEngagementGap: "",
      eventsSubscribedTo: {
        leadGenerated: true,
        productViewed: false,
      },
    }
  );
  const [uniqueId, setUniqueId] = useState<string>(uuidv4());

  const [backInStockErrors, setShowBackInStockErrors] =
    useState<BackInStockErrors>({
      minInventoryError: false,
      noOfCustomerToNotifyError: false,
      gapBetweenBatchesError: false,
      customersEngagementGapError: false,
      eventsSubscribedToError: false,
      excludedProductsError: false,
    });

  const [priceDropErrors, setPriceDropErrors] = useState<PriceDropErrors>({
    amountError: false,
    customersEngagementGapError: false,
    eventsSubscribedToError: false,
    excludedProductsError: false,
  });

  const storeInfoState = useAppSelector((state) => state.storeInfoState);

  const currency = CURRENCY_SYMBOLS[storeInfoState.currency];

  useEffect(() => {
    getStoreWidgetStatus(storeId, props.mode).then((res) => {
      console.log("widget", res);
      if (res) {
        setWidgetStatus((prev) => {
          return {
            ...prev,
            widget: true,
          };
        });
      }
    });

    getTrackerStatus(storeId).then((res) => {
      if (res?.id) {
        setWidgetStatus((prev) => {
          return {
            ...prev,
            trackers: true,
          };
        });
      }
    });
  }, []);

  useEffect(() => {
    if (props.mode === BUSINESS_EVENTS.BACK_IN_STOCK) {
      calculateErrorsBackInStock(true);
    } else {
      calculateErrorsPriceDrop(true);
    }
  }, [backInStockParams, priceDropParams, checkboxChecked]);

  useEffect(() => {
    if (selectedChannel?.value) {
      setChannelError(false);
    }
  }, [selectedChannel]);

  function convertToNumber(num: string) {
    try {
      let ans = Number(num);
      if (isNaN(ans) || ans <= 0) {
        throw new Error("Input must be a positive number");
      }
      return ans;
    } catch (err) {
      return "";
    }
  }

  const calculateErrorsBackInStock = (errCheckMode = false) => {
    let minInventoryError = false;
    let noOfCustomerToNotifyError = false;
    let gapBetweenBatchesError = false;
    let customersEngagementGapError = false;
    let eventsSubscribedToError = false;
    let excludedProductsError = false;

    let count = 0;

    if ((backInStockParams.customersEngagementGap as number) <= 0) {
      if (errCheckMode) {
        if (backInStockErrors.customersEngagementGapError) {
          customersEngagementGapError = true;
        }
      } else {
        customersEngagementGapError = true;
      }
      count += 1;
    }

    if ((backInStockParams.minInventory as number) <= 0) {
      if (errCheckMode) {
        if (backInStockErrors.minInventoryError) {
          minInventoryError = true;
        }
      } else {
        minInventoryError = true;
      }
      count += 1;
    }

    if ((backInStockParams.noOfCustomerToNotify as number) <= 0) {
      if (errCheckMode) {
        if (backInStockErrors.noOfCustomerToNotifyError) {
          noOfCustomerToNotifyError = true;
        }
      } else {
        noOfCustomerToNotifyError = true;
      }
      count += 1;
    }

    if ((backInStockParams.gapBetweenBatches as number) <= 0) {
      if (errCheckMode) {
        if (backInStockErrors.gapBetweenBatchesError) {
          gapBetweenBatchesError = true;
        }
      } else {
        gapBetweenBatchesError = true;
      }
      count += 1;
    }

    if (
      !backInStockParams.eventsSubscribedTo.leadGenerated &&
      !backInStockParams.eventsSubscribedTo.productViewed
    ) {
      if (errCheckMode) {
        if (backInStockErrors.eventsSubscribedToError) {
          eventsSubscribedToError = true;
        }
      } else {
        eventsSubscribedToError = true;
      }
      count += 1;
    }

    if (checkboxChecked && !pickedItems.total) {
      if (errCheckMode) {
        if (backInStockErrors.excludedProductsError) {
          excludedProductsError = true;
        }
      } else {
        excludedProductsError = true;
      }
      count += 1;
    }

    if (!selectedChannel?.value && !errCheckMode) {
      setChannelError(true);
      count += 1;
    }

    setShowBackInStockErrors({
      minInventoryError,
      noOfCustomerToNotifyError,
      gapBetweenBatchesError,
      customersEngagementGapError,
      eventsSubscribedToError,
      excludedProductsError,
    });

    return count;
  };

  const calculateErrorsPriceDrop = (errCheckMode = false) => {
    let amountError = false;
    let customersEngagementGapError = false;
    let eventsSubscribedToError = false;
    let excludedProductsError = false;

    let count = 0;

    if ((priceDropParams.customersEngagementGap as number) <= 0) {
      if (errCheckMode) {
        if (priceDropErrors.customersEngagementGapError) {
          customersEngagementGapError = true;
        }
      } else {
        customersEngagementGapError = true;
      }
      count += 1;
    }

    if ((priceDropParams.amount as number) <= 0) {
      if (errCheckMode) {
        if (priceDropErrors.amountError) {
          amountError = true;
        }
      } else {
        amountError = true;
      }
      count += 1;
    }

    if (
      !priceDropParams.eventsSubscribedTo.leadGenerated &&
      !priceDropParams.eventsSubscribedTo.productViewed
    ) {
      if (errCheckMode) {
        if (priceDropErrors.eventsSubscribedToError) {
          eventsSubscribedToError = true;
        }
      } else {
        eventsSubscribedToError = true;
      }
      count += 1;
    }

    if (checkboxChecked && !pickedItems.total) {
      if (errCheckMode) {
        if (priceDropErrors.excludedProductsError) {
          excludedProductsError = true;
        }
      } else {
        excludedProductsError = true;
      }
      count += 1;
    }

    if (!selectedChannel?.value && !errCheckMode) {
      setChannelError(true);
      count += 1;
    }

    setPriceDropErrors({
      amountError,
      customersEngagementGapError,
      eventsSubscribedToError,
      excludedProductsError,
    });

    return count;
  };

  // generate conf
  const generateConf = async () => {
    let errCount = 0;
    setLoading(true);

    if (props.mode === BUSINESS_EVENTS.BACK_IN_STOCK) {
      errCount = calculateErrorsBackInStock();
    } else {
      errCount = calculateErrorsPriceDrop();
    }

    if (errCount > 0) {
      setLoading(false);
      return;
    }

    const products: ExcludedProduct[] = [];
    Object.entries(pickedItems?.data).forEach(([collectionId, collection]) => {
      Object.entries(collection.products || []).forEach(
        ([productId, product]) => {
          Object.entries(product.variants || []).forEach(
            ([variantId, variant]) => {
              products.push({
                productId,
                collectionId,
                variantId,
              });
            }
          );
        }
      );
    });

    let conf:
      | ((BackInStockConf | PriceDropConf) & {
          channelId: string;
          flowId: string;
        })
      | undefined = undefined;

    if (props.mode === BUSINESS_EVENTS.BACK_IN_STOCK) {
      conf = {
        minInventory: backInStockParams.minInventory as number,
        noOfCustomerToNotify: backInStockParams.noOfCustomerToNotify as number,
        gapBetweenBatches: backInStockParams.gapBetweenBatches as number,
        customersEngagementGap:
          backInStockParams.customersEngagementGap as number,
        eventsSubscribedTo: backInStockParams.eventsSubscribedTo,
        excludedProducts: products,
        type: "businessEvents",
        businessEventType: BUSINESS_EVENTS.BACK_IN_STOCK,
        id: uniqueId,
        header: "Product Back In Stock",
        channelId: selectedChannel?.value || "",
        subHeader: `at least ${backInStockParams.minInventory} items restocked`,
        flowId: flowId || "",
      };
    } else {
      conf = {
        amount: priceDropParams.amount as number,
        amountType: priceDropParams.amountType,
        eventsSubscribedTo: priceDropParams.eventsSubscribedTo,
        customersEngagementGap:
          priceDropParams.customersEngagementGap as number,
        excludedProducts: products,
        type: "businessEvents",
        businessEventType: BUSINESS_EVENTS.PRICE_DROP,
        id: uniqueId,
        header: "Price Drop",
        channelId: selectedChannel?.value || "",
        subHeader: `at least ${priceDropParams.amount}${
          priceDropParams.amountType === "percentage" ? "%" : currency
        } price drop on a product`,
        flowId: flowId || "",
      };
    }

    createSlots(storeId)
      .then(() => {
        props.onSave(conf);
        props.onClose();
        setLoading(false);
      })
      .catch(() => {
        setShowToaster(true);
        setLoading(false);
      });

    return conf;
  };

  // retrieve conf
  useEffect(() => {
    (async function () {
      if (props.data.length) {
        const { businessEventType, excludedProducts, id } = props.data[0];
        let data = null;
        if (businessEventType === BUSINESS_EVENTS.BACK_IN_STOCK) {
          data = props.data[0] as BackInStockConf;
          const {
            minInventory,
            noOfCustomerToNotify,
            gapBetweenBatches,
            customersEngagementGap,
            eventsSubscribedTo,
          } = data;

          setBackInStockParams({
            minInventory,
            noOfCustomerToNotify,
            gapBetweenBatches,
            customersEngagementGap,
            eventsSubscribedTo,
          });
        } else {
          data = props.data[0] as PriceDropConf;

          const {
            amount,
            amountType,
            eventsSubscribedTo,
            customersEngagementGap,
          } = data;

          setPriceDropParams({
            amount,
            amountType: amountType,
            eventsSubscribedTo,
            customersEngagementGap,
          });
        }

        setUniqueId(id);

        const productData: any = {};
        let count = 0;
        const integrationRepo = new IntegrationService();

        setIsProductsLoading(true);

        for (let item of excludedProducts) {
          let collectionId = item?.collectionId;
          const productId = item.productId;
          const variantId = item.variantId;
          const product: any = await integrationRepo.fetchProductById(
            storeId,
            productId
          );

          if (!product || !product.id) {
            continue;
          }
          if (!collectionId) {
            collectionId = product?.catalogIds[0] ?? "-1";
          }
          const variant =
            product.combinations.find((item: any) => item.id === variantId) ??
            {};

          if (!(collectionId in productData)) {
            productData[collectionId] = { products: {} };
          }
          if (!(productId in productData[collectionId]["products"])) {
            productData[collectionId]["products"] = {
              ...productData[collectionId]["products"],
              [product.id]: {
                name: product.name,
                image: product.image,
                variants: {},
              },
            };
          }
          productData[collectionId]["products"][productId]["variants"][
            variantId
          ] = {
            name:
              variant.name === "Default Title"
                ? product.name
                : variant.name ?? "",
            image: variant.image ?? product.image ?? "",
            price: variant.price ?? "",
          };
          count += 1;
        }
        setIsProductsLoading(false);
        setPickedItems({
          data: productData,
          total: count,
        });

        if (count > 0) {
          setCheckboxChecked(true);
        }
      }
    })();
  }, []);

  // functions relating to channel dropdown
  useEffect(() => {
    populateChannelIdsDropdown(
      props.channel,
      channels,
      setIsLoading,
      setSelectedChannel,
      setChannelIdOptions,
      props.currentChannelId
    );
  }, []);

  useEffect(() => {
    if (channelIdOptions && channelIdOptions.length === 1) {
      setSelectedChannel(channelIdOptions[0]);
    }
  }, [channelIdOptions]);

  // functions relating to product picker
  const onItemsPicked = (data: any, total: number) => {
    setPickedItems({ data, total });
    if (!!Object.keys(data).length) {
      setProductsNotSelectedErr(false);
    }
    setShowModal(false);
    setStartupScreen(ScreenName.Collections);
  };

  return (
    <>
      <StyledModal
        centralContainerStyles={{ width: "1032px", height: "560px" }}
        open={props.visible}
        onClose={() => props.onClose()}
        zIndex={200}
        hideCrossButton={showModal}
      >
        <Container>
          <Header>
            <HeaderContent>
              <TitleMedium>
                {props.mode === BUSINESS_EVENTS.BACK_IN_STOCK
                  ? `Create trigger for products coming back in stock`
                  : `Create trigger for Price Drop in a product`}
              </TitleMedium>
            </HeaderContent>
            <ChannelDropdown
              error={channelError}
              setSelectedChannel={setSelectedChannel}
              isLoading={isLoading}
              channelIdOptions={channelIdOptions}
              setChannelIdOptions={setChannelIdOptions}
              selectedChannel={selectedChannel}
              channel={channel}
            />
          </Header>
          <Body>
            <TriggerContainer>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 12,
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    padding: `4px 6px 4px 6px`,
                  }}
                >
                  <FlashIcon color={COLORS.content.secondary} />
                </div>
                <TitleRegular>Trigger</TitleRegular>
              </div>
              {props.mode === BUSINESS_EVENTS.BACK_IN_STOCK ? (
                <TriggerBody>
                  <ParamContainer>
                    <div>
                      <BodySecondary>
                        Start the journey when there is a minimum inventory of
                      </BodySecondary>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 8,
                      }}
                    >
                      <Input
                        errorMessage={
                          backInStockErrors.minInventoryError
                            ? "Required Field"
                            : ""
                        }
                        onChangeText={(input) => {
                          setBackInStockParams((prev) => {
                            return {
                              ...prev,
                              minInventory: convertToNumber(input),
                            };
                          });
                        }}
                        value={backInStockParams.minInventory?.toString()}
                        textControl={true}
                        suffixText="items"
                        type="text"
                        variant="default"
                        width="160px"
                        placeholder="5"
                      />
                    </div>
                  </ParamContainer>
                  <Alert
                    type={ALERT_TYPES.NEUTRAL}
                    icon={() => (
                      <AlertCircle
                        color={COLORS.content.secondary}
                        width={16}
                        height={16}
                      />
                    )}
                    containerStyle={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "fit-content",
                    }}
                    text={
                      <>
                        If you set the minimum inventory value to 5,
                        notifications will only be sent when at least 5 items
                        have been restocked for a variant.
                        {/* <LinkText link={"https://google.com"} /> */}
                      </>
                    }
                  />
                  <ParamContainer>
                    <div>
                      <BodySecondary>
                        Number of customers notified for each item restocked
                      </BodySecondary>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 8,
                      }}
                    >
                      <Input
                        errorMessage={
                          backInStockErrors.noOfCustomerToNotifyError
                            ? "Required Field"
                            : ""
                        }
                        onChangeText={(input) => {
                          setBackInStockParams((prev) => {
                            return {
                              ...prev,
                              noOfCustomerToNotify: convertToNumber(input),
                            };
                          });
                        }}
                        value={backInStockParams.noOfCustomerToNotify?.toString()}
                        textControl={true}
                        suffixText="customers"
                        type="text"
                        variant="default"
                        width="160px"
                        placeholder="20"
                      />
                    </div>
                  </ParamContainer>
                  <ParamContainer>
                    <div>
                      <BodySecondary>
                        Time to wait before another batch of notifications are
                        sent
                      </BodySecondary>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 8,
                      }}
                    >
                      <Input
                        errorMessage={
                          backInStockErrors.gapBetweenBatchesError
                            ? "Required Field"
                            : ""
                        }
                        onChangeText={(input) => {
                          setBackInStockParams((prev) => {
                            return {
                              ...prev,
                              gapBetweenBatches: convertToNumber(input),
                            };
                          });
                        }}
                        value={backInStockParams.gapBetweenBatches?.toString()}
                        textControl={true}
                        suffixText="days"
                        type="text"
                        variant="default"
                        width="160px"
                        placeholder="2"
                      />
                    </div>
                  </ParamContainer>

                  <Alert
                    type={ALERT_TYPES.NEUTRAL}
                    icon={() => (
                      <AlertCircle
                        color={COLORS.content.secondary}
                        width={16}
                        height={16}
                      />
                    )}
                    containerStyle={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "fit-content",
                    }}
                    text={
                      <>
                        If you set the notification limit to 5 customers and the
                        wait time to 2 days, then for 10 items restocked, 50
                        customers (5 x 10) will be notified. After 2 days, if 10
                        items remain in stock, another 50 customers (5 x 10)
                        will be notified. This process repeats until the items
                        are out of stock or all eligible customers have been
                        notified.
                        {/* <LinkText link={"https://google.com"} /> */}
                      </>
                    }
                  />
                </TriggerBody>
              ) : (
                <TriggerBody>
                  <div>
                    <BodySecondary>
                      Start the journey when there is a minimum price drop of
                    </BodySecondary>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 12,
                      alignItems: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 72,
                      }}
                    >
                      <BodySecondary>Amount</BodySecondary>
                      <BodySecondary>Type</BodySecondary>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        gap: 24,
                      }}
                    >
                      <ParamContainer>
                        {priceDropParams.amountType === "percentage" ? (
                          <div>
                            <Input
                              onChangeText={(input) => {
                                setPriceDropParams((prev) => {
                                  return {
                                    ...prev,
                                    amount: convertToNumber(input),
                                  };
                                });
                              }}
                              value={priceDropParams.amount?.toString()}
                              suffixText="%"
                              type="text"
                              textControl={true}
                              variant="default"
                              width="100px"
                              placeholder="15"
                              state={
                                priceDropErrors.amountError ? "invalid" : "none"
                              }
                            />
                          </div>
                        ) : (
                          <Input
                            onChangeText={(input) => {
                              setPriceDropParams((prev) => {
                                return {
                                  ...prev,
                                  amount: convertToNumber(input),
                                };
                              });
                            }}
                            value={priceDropParams.amount?.toString()}
                            prefixText={`${currency}`}
                            type="text"
                            textControl={true}
                            variant="default"
                            width="100px"
                            placeholder="15"
                            state={
                              priceDropErrors.amountError ? "invalid" : "none"
                            }
                          />
                        )}
                      </ParamContainer>

                      <div
                        style={{
                          width: 250,
                          alignSelf: "center",
                        }}
                      >
                        <DynamicTabs
                          tabsInfo={tabsInfo}
                          tabStyleInfo={tabStyleInfo}
                          selectedTabId={
                            priceDropParams.amountType === "percentage" ? 0 : 1
                          }
                        />
                      </div>
                    </div>

                    {priceDropErrors.amountError ? <ErrorLine /> : <></>}
                  </div>
                  <Alert
                    type={ALERT_TYPES.NEUTRAL}
                    icon={() => (
                      <AlertCircle
                        color={COLORS.content.secondary}
                        width={16}
                        height={16}
                      />
                    )}
                    containerStyle={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "fit-content",
                    }}
                    text={
                      <>
                        {priceDropParams.amountType === "percentage" ? (
                          <BodyCaption>
                            If you set the amount as 5%, the journey will begin
                            once the price of a product drops by at least 5%.
                            {/* <LinkText link={"https://google.com"} /> */}
                          </BodyCaption>
                        ) : (
                          <BodyCaption>
                            {`If you set the amount as ${currency}15, the journey will begin once the price of a product drops by at least ${currency}15.`}
                            {/* <LinkText link={"https://google.com"} /> */}
                          </BodyCaption>
                        )}
                      </>
                    }
                  />
                </TriggerBody>
              )}
            </TriggerContainer>
            <ExcludeProductsContainer>
              {isProductsLoading ? (
                <>
                  <BikShimmer boxes={[{ width: "350px", height: "64px" }]} />
                </>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 8,
                      alignItems: "center",
                      marginBottom: checkboxChecked ? 16 : 0,
                    }}
                  >
                    <CheckBox
                      isChecked={checkboxChecked}
                      onValueChange={(checked) => {
                        setCheckboxChecked(checked);
                        if (!checked) {
                          setPickedItems({
                            data: {},
                            total: 0,
                          });
                        }
                      }}
                    />

                    <TitleSmall>Exclude Specific Products</TitleSmall>
                  </div>

                  {checkboxChecked && pickedItems.total == 0 && (
                    <Button
                      buttonText="Select Products"
                      buttonType="dashBold"
                      size="large"
                      LeadingIcon={PlusIcon}
                      onClick={() => setShowModal(true)}
                      error={
                        props.mode === BUSINESS_EVENTS.BACK_IN_STOCK
                          ? backInStockErrors.excludedProductsError
                          : priceDropErrors.excludedProductsError
                      }
                    />
                  )}

                  {(props.mode === BUSINESS_EVENTS.BACK_IN_STOCK
                    ? backInStockErrors.excludedProductsError
                    : priceDropErrors.excludedProductsError) && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 8,
                        marginTop: 8,
                        alignItems: "center",
                      }}
                    >
                      <InfoIcon
                        color={COLORS.content.negative}
                        width={12}
                        height={12}
                      />
                      <BodyCaption color={COLORS.content.negative}>
                        Please add products
                      </BodyCaption>
                    </div>
                  )}

                  {pickedItems.total > 0 && (
                    <div>
                      <RowCard
                        height={"64px"}
                        width={"350px"}
                        rightElement={
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 8,
                            }}
                          >
                            <IconButton
                              Icon={EditIcon}
                              width={24}
                              height={24}
                              iconColor={COLORS.content.placeholder}
                              onClick={(e) => {
                                setShowModal(true);
                              }}
                            />

                            <IconButton
                              Icon={DeleteIcon}
                              width={24}
                              height={24}
                              iconColor={COLORS.content.placeholder}
                              onClick={(e) => {
                                setPickedItems({
                                  data: {},
                                  total: 0,
                                });
                                e.stopPropagation();
                              }}
                            />
                          </div>
                        }
                        elements={[
                          <TitleRegular
                            style={{ color: COLORS.content.secondary }}
                          >{`${pickedItems.total} products added`}</TitleRegular>,
                        ]}
                        leftElement={
                          <div
                            style={{
                              width: "40px",
                              height: "40px",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F0F0F0",
                            }}
                          >
                            <Box height={20} width={20} color={"#616161"} />
                          </div>
                        }
                        justifyContent="center"
                        alignItems="center"
                        paddingBottom="12px"
                        paddingLeft="12px"
                        paddingRight="12px"
                        paddingTop="12px"
                        backgroundColor="#FFFFFF"
                        border={true}
                      />
                    </div>
                  )}
                </>
              )}
            </ExcludeProductsContainer>
            <div
              style={{
                width: 1,
                height: 32,
                backgroundColor: COLORS.content.placeholder,
                marginLeft: 32,
              }}
            ></div>
            <CustomersNotifyContainer>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 12,
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    padding: `4px 6px 4px 6px`,
                  }}
                >
                  <GroupIcon />
                </div>
                <TitleRegular>Customers to notify</TitleRegular>
              </div>
              <CustomerNotifyBody>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 12,
                  }}
                >
                  <BodySecondary>
                    Select how customers engaged with the product variant
                  </BodySecondary>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 8,
                    }}
                  >
                    <CheckBox
                      isChecked={
                        props.mode === BUSINESS_EVENTS.BACK_IN_STOCK
                          ? backInStockParams.eventsSubscribedTo.leadGenerated
                          : priceDropParams.eventsSubscribedTo.leadGenerated
                      }
                      onValueChange={(checked) => {
                        props.mode === BUSINESS_EVENTS.BACK_IN_STOCK
                          ? setBackInStockParams((prev) => {
                              return {
                                ...prev,
                                eventsSubscribedTo: {
                                  ...prev.eventsSubscribedTo,
                                  leadGenerated: checked,
                                },
                              };
                            })
                          : setPriceDropParams((prev) => {
                              return {
                                ...prev,
                                eventsSubscribedTo: {
                                  ...prev.eventsSubscribedTo,
                                  leadGenerated: checked,
                                },
                              };
                            });
                      }}
                    />
                    {(
                      props.mode === BUSINESS_EVENTS.BACK_IN_STOCK
                        ? backInStockParams.eventsSubscribedTo.leadGenerated
                        : priceDropParams.eventsSubscribedTo.leadGenerated
                    ) ? (
                      <div>
                        {props.mode === BUSINESS_EVENTS.BACK_IN_STOCK ? (
                          <BodySecondary
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            <>
                              Signed up for 'Back in Stock' Notifications on
                              website through popup.
                              {widgetStatus.widget && (
                                <LinkText
                                  link={`${dasboardUrl}/acquisitions/web-widgets`}
                                  text={"View Popup"}
                                />
                              )}
                              {` (High Intent)`}
                            </>
                          </BodySecondary>
                        ) : (
                          <div>
                            <BodySecondary
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              <>
                                Signed up for 'Price Drop' Notifications on
                                website through popup.
                                {widgetStatus.widget && (
                                  <LinkText
                                    link={`${dasboardUrl}/acquisitions/web-widgets`}
                                    text={"View Popup"}
                                  />
                                )}
                                {` (High Intent)`}
                              </>
                            </BodySecondary>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        {props.mode === BUSINESS_EVENTS.BACK_IN_STOCK ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 4,
                            }}
                          >
                            <BodySecondary>
                              Signed up for 'Back in Stock' Notifications on
                              website through popup.
                            </BodySecondary>
                            <BodySecondary>(High Intent)</BodySecondary>
                          </div>
                        ) : (
                          <div>
                            <BodySecondary>
                              {`Signed up for 'Price Drop' Notifications on website through popup (High Intent)`}
                            </BodySecondary>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 8,
                    }}
                  >
                    <CheckBox
                      isChecked={
                        props.mode === BUSINESS_EVENTS.BACK_IN_STOCK
                          ? backInStockParams.eventsSubscribedTo.productViewed
                          : priceDropParams.eventsSubscribedTo.productViewed
                      }
                      onValueChange={(checked) => {
                        props.mode === BUSINESS_EVENTS.BACK_IN_STOCK
                          ? setBackInStockParams((prev) => {
                              return {
                                ...prev,
                                eventsSubscribedTo: {
                                  ...prev.eventsSubscribedTo,
                                  productViewed: checked,
                                },
                              };
                            })
                          : setPriceDropParams((prev) => {
                              return {
                                ...prev,
                                eventsSubscribedTo: {
                                  ...prev.eventsSubscribedTo,
                                  productViewed: checked,
                                },
                              };
                            });
                      }}
                    />

                    {(
                      props.mode === BUSINESS_EVENTS.BACK_IN_STOCK
                        ? backInStockParams.eventsSubscribedTo.productViewed
                        : priceDropParams.eventsSubscribedTo.productViewed
                    ) ? (
                      <BodySecondary
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Viewed product (Medium Intent)
                      </BodySecondary>
                    ) : (
                      <BodySecondary>
                        Viewed product (Medium Intent)
                      </BodySecondary>
                    )}
                  </div>
                </div>

                {(
                  props.mode === BUSINESS_EVENTS.BACK_IN_STOCK
                    ? backInStockErrors.eventsSubscribedToError
                    : priceDropErrors.eventsSubscribedToError
                ) ? (
                  <Alert
                    type={ALERT_TYPES.NEGATIVE}
                    text={
                      "Select at least one option for which customers to notify"
                    }
                    icon={() => (
                      <AlertCircle
                        width={16}
                        height={16}
                        color={COLORS.content.negative}
                      />
                    )}
                    containerStyle={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "fit-content",
                    }}
                  />
                ) : (
                  <></>
                )}

                {!widgetStatus.widget &&
                (props.mode === BUSINESS_EVENTS.BACK_IN_STOCK
                  ? backInStockParams.eventsSubscribedTo.leadGenerated
                  : priceDropParams.eventsSubscribedTo.leadGenerated) ? (
                  <Alert
                    type={ALERT_TYPES.WARNING}
                    icon={() => <AlertCircle width={16} height={16} />}
                    containerStyle={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "fit-content",
                    }}
                    text={
                      props.mode === BUSINESS_EVENTS.BACK_IN_STOCK ? (
                        <>
                          "Enable 'Back in Stock' Popup on website to capture
                          high intent customers."
                          <LinkText
                            link={`${dasboardUrl}/acquisitions/web-widgets`}
                            text="View Popup"
                          />
                        </>
                      ) : (
                        <>
                          "Enable 'Price Drop' Popup on website to capture high
                          intent customers."
                          <LinkText
                            link={`${dasboardUrl}/acquisitions/web-widgets`}
                            text="View Popup"
                          />
                        </>
                      )
                    }
                  />
                ) : (
                  <></>
                )}
                <ParamContainer>
                  <BodySecondary>
                    Notify only those customers who engaged with the product
                    variant in the last
                  </BodySecondary>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 8,
                    }}
                  >
                    <Input
                      errorMessage={
                        backInStockErrors.customersEngagementGapError ||
                        priceDropErrors.customersEngagementGapError
                          ? "Required Field"
                          : ""
                      }
                      onChangeText={(input) => {
                        props.mode === BUSINESS_EVENTS.BACK_IN_STOCK
                          ? setBackInStockParams((prev) => {
                              return {
                                ...prev,
                                customersEngagementGap: convertToNumber(input),
                              };
                            })
                          : setPriceDropParams((prev) => {
                              return {
                                ...prev,
                                customersEngagementGap: convertToNumber(input),
                              };
                            });
                      }}
                      value={
                        props.mode === BUSINESS_EVENTS.BACK_IN_STOCK
                          ? backInStockParams.customersEngagementGap?.toString()
                          : priceDropParams.customersEngagementGap?.toString()
                      }
                      suffixText="days"
                      type="text"
                      textControl={true}
                      variant="default"
                      width="160px"
                      placeholder="5"
                    />
                  </div>
                </ParamContainer>
                <Alert
                  type={ALERT_TYPES.NEUTRAL}
                  icon={() => (
                    <AlertCircle
                      color={COLORS.content.secondary}
                      width={16}
                      height={16}
                    />
                  )}
                  containerStyle={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "fit-content",
                  }}
                  text={
                    <>
                      Customers who already bought the product won't be
                      notified.
                      {/* <LinkText link={"https://google.com"} /> */}
                    </>
                  }
                />
              </CustomerNotifyBody>
            </CustomersNotifyContainer>
            <div
              style={{
                marginTop: 24,
              }}
            ></div>
            {props.mode === BUSINESS_EVENTS.BACK_IN_STOCK ? (
              <Alert
                type={ALERT_TYPES.NEUTRAL}
                icon={() => (
                  <AlertCircle
                    color={COLORS.content.secondary}
                    width={16}
                    height={16}
                  />
                )}
                containerStyle={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "fit-content",
                }}
                text={
                  <>
                    Products will be checked if they're back in stock everyday
                    between 10am - 5pm. You may add a delay/till block to send a
                    notification at a specific time.
                    {/* <LinkText link={"https://google.com"} /> */}
                  </>
                }
              />
            ) : (
              <></>
            )}
          </Body>
          <Footer>
            {Object.values(backInStockErrors).some((value) => value === true) ||
            Object.values(priceDropErrors).some((value) => value === true) ||
            channelError ? (
              <Alert
                icon={() => (
                  <AlertTriangle
                    width={20}
                    height={20}
                    color={COLORS.content.negative}
                  />
                )}
                text={"Some inputs are invalid or missing. Please check"}
                type={ALERT_TYPES.NEGATIVE}
              />
            ) : (
              <BodySecondary>
                {props.mode === BUSINESS_EVENTS.BACK_IN_STOCK
                  ? `Create trigger to start journey when a product comes back in stock`
                  : `Create trigger to start journey when the price of the product drops`}
              </BodySecondary>
            )}
            <Button
              buttonText={"Create"}
              buttonType="primary"
              size="medium"
              isLoading={loading}
              onClick={() => {
                generateConf().then();
              }}
            />
          </Footer>
        </Container>
      </StyledModal>
      {showModal && (
        <ProductPickerModal
          zIndex={200}
          storeId={storeId}
          selectedItems={pickedItems.data}
          collectionFetcher={(offset: number, storeId: string) =>
            fetchCollections(offset, storeId, storeInfoState?.isNonShopifyStore)
          }
          productFetcher={(
            collectionId: string,
            storeId: string,
            limit?: number,
            offset?: number
          ) =>
            fetchProducts(
              collectionId,
              storeId,
              limit,
              offset,
              storeInfoState?.isNonShopifyStore
            )
          }
          searchInCollectionFetcher={(
            storeId: string,
            collectionId: string,
            query: string
          ) =>
            searchInCollection(
              storeId,
              collectionId,
              query,
              storeInfoState?.isNonShopifyStore
            )
          }
          searchFetcher={(storeId: string, query: string) =>
            searchCollectionsAndProducts(
              storeId,
              query,
              storeInfoState?.isNonShopifyStore
            )
          }
          onAdd={onItemsPicked}
          visible={showModal}
          onClose={() => {
            setShowModal(false);
            setStartupScreen(ScreenName.Collections);
          }}
          startupScreen={startupScreen as any}
          rearrangeEnabled={false}
          showProductDetails={true}
          containerStyle={{
            height: 434,
          }}
          width="600px"
          left={`${215}px`}
          crossButtonInsideHeader={true}
        />
      )}
      {showToaster && (
        <Toaster
          text={"Error is saving!, Please try again"}
          width={"300px"}
          type={"error"}
          autoClose={3000}
          onCloseToast={() => {
            setShowToaster(false);
          }}
        />
      )}
    </>
  );
};

export default BusinessEvents;

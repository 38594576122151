import { Icon } from "./icons-props";

const ConditionIcon: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5598 4.00012C10.5598 3.20483 11.2046 2.56012 11.9998 2.56012C12.7951 2.56012 13.4398 3.20483 13.4398 4.00012C13.4398 4.79541 12.7951 5.44012 11.9998 5.44012C11.2046 5.44012 10.5598 4.79541 10.5598 4.00012ZM11.9998 1.12012C10.4093 1.12012 9.11984 2.40954 9.11984 4.00012C9.11984 5.23915 9.90228 6.29543 11 6.70182V10.0003H5.83333C4.82081 10.0003 4 10.8211 4 11.8336V15.6157H6V12.0003H18V16.0003H20V11.8336C20 10.8211 19.1792 10.0003 18.1667 10.0003H13V6.70171C14.0976 6.29523 14.8798 5.23903 14.8798 4.00012C14.8798 2.40954 13.5904 1.12012 11.9998 1.12012Z"
        fill={color || "#99631B"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.3435 18.1612C21.734 17.7707 21.734 17.1375 21.3435 16.747C20.9529 16.3565 20.3198 16.3565 19.9293 16.747L18.8182 17.8581L17.7071 16.747C17.3166 16.3565 16.6834 16.3565 16.2929 16.747C15.9024 17.1375 15.9024 17.7707 16.2929 18.1612L17.404 19.2723L16.2929 20.3834C15.9024 20.7739 15.9024 21.407 16.2929 21.7976C16.6834 22.1881 17.3166 22.1881 17.7071 21.7976L18.8182 20.6865L19.9293 21.7976C20.3198 22.1881 20.9529 22.1881 21.3435 21.7976C21.734 21.407 21.734 20.7739 21.3435 20.3834L20.2324 19.2723L21.3435 18.1612Z"
        fill={color || "#99631B"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.16165 18.1612C9.55218 17.7707 9.55218 17.1375 9.16165 16.747C8.77113 16.3565 8.13796 16.3565 7.74744 16.747L4.81818 19.6763L3.70711 18.5652C3.31658 18.1747 2.68342 18.1747 2.29289 18.5652C1.90237 18.9557 1.90237 19.5889 2.29289 19.9794L4.11036 21.7969C4.1106 21.7971 4.11084 21.7973 4.11108 21.7976C4.5016 22.1881 5.13476 22.1881 5.52529 21.7976L9.16165 18.1612Z"
        fill={color || "#99631B"}
      />
    </svg>
  );
};

export default ConditionIcon;

// @ts-nocheck
import React, {useState, useMemo, useEffect} from "react";
import {
  StyledModal,
  QueryBuilder,
  BaseQueryBuilderPayload,
  Tag, DropdownPopover, TitleSmall, COLORS,
} from "@bikdotai/bik-component-library";
import { SegmentValidator } from "../../app/action-block/filter/validator";
import { WarnIcon } from "../../icons/warnIcon";
import {
  extractHeaderAndSubHeader,
  sanitiseState,
  removeSubstring,
} from "../../app/action-block/filter/utils";
import { userPropertyFilterAPIHandler } from "../../app/action-block/filter/api";
import { populateChannelIdsDropdown } from "./utils";
import { useAppSelector } from "../../state/store";
import { InstaDropdownContainer, InstaDropdownWrapper } from "./styled";
import WhatsappIcon from "../../icons/whatsappIcon";
import InstagramIcon from "../../icons/instagramIcon";
import EmailIcon from "../../icons/email";
import ChevronDownIcon from "../../icons/chevron_down";

let builderState = {};
let filterTimer = false;
const segmentValidator = new SegmentValidator();

const SpecialDate: React.FC<any> = (props) => {
  const [errorState, setErrState] = useState(undefined);
  const [error, setError] = useState<boolean>(false);
  const [channelError, setChannelError] = useState<boolean>(false);
  const [channelIdOptions, setChannelIdOptions] = useState<
    {
      label: string;
      value: string;
      selected: boolean;
    }[]
  >([]);
  const [selectedChannel, setSelectedChannel] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { storeId } = props;

  const channels: { [key: string]: any } = useAppSelector(
    (state) => state.flowMetaState.channels!
  );

  const handleOnModalClose = () => {
    props.onClose();
  };

  useEffect(() => {
    populateChannelIdsDropdown(
      props.channel,
      channels,
      setIsLoading,
      setSelectedChannel,
      setChannelIdOptions,
      props.currentChannelId
    );
  }, []);

  useEffect(() => {
    if(channelIdOptions && channelIdOptions.length===1){
      setSelectedChannel(channelIdOptions[0])
    }
  }, [channelIdOptions]);

  const typesAPIHandler = useMemo(
    () => ({
      USER_PROPERTY_FILTER: async (p: BaseQueryBuilderPayload<undefined>) => {
        const response = await delayedUserPropertyResponse(p, storeId);
        return response;
      },
    }),
    [storeId]
  );

  async function delayedUserPropertyResponse(p, storeId) {
    if (filterTimer) {
      clearTimeout(filterTimer);
    }
    return new Promise((resolve) => {
      filterTimer = setTimeout(async () => {
        const response = await userPropertyFilterAPIHandler(p, storeId, true);
        resolve(response);
      }, 800);
    });
  }

  const checkForErros = () => {
    const errorStateRes = segmentValidator.validate({
      include: builderState,
    });
    setErrState({ ...errorStateRes[1].include });
    setError(errorStateRes[0]);
    return { isError: errorStateRes[0], state: errorStateRes[1].include };
  };

  const parseBuilderData = () => {
    if (!selectedChannel?.value) {
      setChannelError(true);
      return;
    }
    const { isError, state } = checkForErros();
    if (isError) return;

    const { frontendState, backendState } = sanitiseState(state);
    const { subHeader, id } = extractHeaderAndSubHeader(frontendState);

    let header = "";
    let newSubHeader = "";
    if (subHeader.includes("Last Order Date")) {
      header = "Last Order Date";
      newSubHeader = removeSubstring(subHeader, "Last Order Date");
    } else if(subHeader.includes("Initial Order Date")) {
      header = "First Order Date";
      newSubHeader = removeSubstring(subHeader, "Initial Order Date");
    } else {
      header = "Total Order Value";
      newSubHeader = removeSubstring(subHeader, "Total Order Value");
    }

    // state.nodes[0].metas = [
    //   {
    //     key: "event_selected",
    //     value: "enteredASegment",
    //   },
    // ];

    props.onSave({
      ...backendState,
      frontendNodes: frontendState.nodes,
      header,
      subHeader: newSubHeader,
      id,
      type: "relay",
      segmentId: props?.segmentId,
      channelId: selectedChannel.value,
    });
    builderState = {};
    props.onClose();
  };

  const InstaDropdown = () => {
    return (
      <InstaDropdownContainer>
        <DropdownPopover
          width="200px"
          disabled={isLoading}
          onSelect={(c: any) => {
            setSelectedChannel(c);
            setChannelError(false);
            const modified = channelIdOptions.map((d) => {
              if (d.value === c.value) {
                return {
                  label: d.label,
                  value: d.value,
                  selected: true,
                };
              } else {
                return {
                  label: d.label,
                  value: d.value,
                  selected: false,
                };
              }
            });
            setChannelIdOptions(modified);
          }}
          options={channelIdOptions}
        >
          <InstaDropdownWrapper>
            {getDropDownIcon(props.channel)}
            <TitleSmall className="dropdown-text" style={{ fontWeight: 400 }}>
              {selectedChannel.label || "Select an account"}
            </TitleSmall>
            <ChevronDownIcon color={COLORS.content.primary} />
          </InstaDropdownWrapper>
        </DropdownPopover>
      </InstaDropdownContainer>
    );
  };

  const getDropDownIcon = (channel: string) => {
    if (channel === "whatsapp") {
      return <WhatsappIcon />;
    } else if (channel === "instagram") {
      return <InstagramIcon />;
    } else {
      return <EmailIcon />;
    }
  };

  return (
    <StyledModal
      onClose={handleOnModalClose}
      zIndex={200}
      open={props.visible}
      headerRightCustomElement={<InstaDropdown />}
      headingTitle="Create trigger based on user property"
      headingSubtitle="The journey will trigger only for customers who enter the segment after the journey goes LIVE"
      centralContainerStyles={{ width: "80%", minHeight: "80%" }}
      footerLeftCustomElement={
        (channelError || error) && (
          <Tag
            tagText={channelError ? "Please select an account" : "Some inputs are missing or incorrect. Please check."}
            type="negative"
            LeadingIcon={WarnIcon}
          />
        )
      }
      primaryButton={{
        buttonType: "primary",
        buttonText: "Create",
        onClick: () => parseBuilderData(),
      }}
    >
      <div style={{ padding: 24 }}>
        <QueryBuilder
          mode={props.mode}
          state={
            props.nodes && props.nodes.length
              ? { nodes: props.nodes }
              : undefined
          }
          addNodeBtnText="Add Filter"
          onStateChange={(state: any) => {
            builderState = state;
          }}
          queryPreText="Whenever customer has"
          selectorKey="eventTrigger"
          typesAPIHandlers={typesAPIHandler}
          infinite={false}
          errorState={errorState}
        />
      </div>
    </StyledModal>
  );
};

export default SpecialDate;

export const regexp = /(?<=\{\{)([^\}]+)(?=\}\})/g;
export const variableRemover = /{{[^{}]*}}/g;
export const WordCounterRTE = (editorText: string) => {
  let resultText = JSON.stringify(editorText || "");

  resultText = resultText.replaceAll("<em>", "_");
  resultText = resultText.replaceAll("</em>", "_");
  resultText = resultText.replaceAll("<strong>", "*");
  resultText = resultText.replaceAll("</strong>", "*");
  resultText = resultText.replaceAll("&nbsp;", "");

  resultText = resultText.replace(new RegExp("<[^>]*>", "g"), "");
  resultText = resultText.replace(/<[^>]*>/g, ""); // Remove HTML tags
  resultText = resultText.replace(variableRemover, "0123456789");
  let elementList = resultText.split("");
  elementList.pop();
  elementList.shift();
  resultText = elementList.join("");

  return resultText;
};

import { HTTPMethods, RequestExecutor } from "../../api-helpers/RequestExecutor"
import { getChatbotEndpoints } from "../../config";

export const copyFlowToStores = async (payload: any) => {
    const url =
      getChatbotEndpoints() + '/copy-flows-to-stores';

    const response = await RequestExecutor.sendData<{
      details: any;
      status: number;
    }>({
      apiPath: url,
      apiMethod: HTTPMethods.POST,
      requestBody: { ...payload },
    });
    return response;
  }
const InstagramCommentIcon = (props: any) => {
    const { width, height } = props;
    return (
        <svg width={width || "16"} height={height || "16"} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.33301 2.66665C3.1562 2.66665 2.98663 2.73688 2.8616 2.86191C2.73658 2.98693 2.66634 3.1565 2.66634 3.33331V12.3905L4.19494 10.8619C4.31996 10.7369 4.48953 10.6666 4.66634 10.6666H12.6663C12.8432 10.6666 13.0127 10.5964 13.1377 10.4714C13.2628 10.3464 13.333 10.1768 13.333 9.99998V3.33331C13.333 3.1565 13.2628 2.98693 13.1377 2.86191C13.0127 2.73688 12.8432 2.66665 12.6663 2.66665H3.33301ZM1.91879 1.9191C2.29387 1.54403 2.80258 1.33331 3.33301 1.33331H12.6663C13.1968 1.33331 13.7055 1.54403 14.0806 1.9191C14.4556 2.29417 14.6663 2.80288 14.6663 3.33331V9.99998C14.6663 10.5304 14.4556 11.0391 14.0806 11.4142C13.7055 11.7893 13.1968 12 12.6663 12H4.94248L2.47108 14.4714C2.28041 14.6621 1.99367 14.7191 1.74455 14.6159C1.49544 14.5127 1.33301 14.2696 1.33301 14V3.33331C1.33301 2.80288 1.54372 2.29417 1.91879 1.9191Z" fill="#510058"/>
        </svg>
    );
};

export default InstagramCommentIcon;

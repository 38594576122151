import { CustomNodeIconProps } from "../utilities/flowBuilder.utility";

const OptInOut = (props: CustomNodeIconProps) => {
  const { color, height, width } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5556 10.8889V4.22223C17.5556 2.99493 16.5607 2 15.3334 2H4.22224C2.99493 2 2 2.99493 2 4.22223V15.3334C2 16.5607 2.99493 17.5556 4.22223 17.5556H10.8889"
        stroke={color || "#D53434"}
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M6.44446 6.44434L13.1112 13.111"
        stroke={color || "#D53434"}
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M6.44446 13.1113L13.1112 6.44462"
        stroke={color || "#D53434"}
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.5564 14.876C15.1433 14.7653 14.7653 15.1433 14.876 15.5564L16.0434 19.9129C16.154 20.326 16.6704 20.4644 16.9728 20.162L17.8672 19.2676C17.8951 19.3046 17.9259 19.3401 17.9596 19.3738L20.1818 21.596C20.5724 21.9865 21.2055 21.9865 21.5961 21.596C21.9866 21.2055 21.9866 20.5723 21.5961 20.1818L19.3738 17.9595C19.3401 17.9258 19.3046 17.8951 19.2676 17.8672L20.162 16.9728C20.4644 16.6704 20.3261 16.154 19.913 16.0433L15.5564 14.876Z"
        fill={color || "#D53434"}
      />
    </svg>
  );
};

export default OptInOut;

import { BodyCaption, COLORS } from "@bikdotai/bik-component-library";
import { FunctionComponent } from "react";
import styled from "styled-components";
import { BETA_SUBTYPES } from "../../app/messageBlock/Constants";
import { backgroundBasedOnSubType } from "../flow-builder/util";

interface HeaderProps {
  headerType: string;
  headerIcon: any;
  type: string;
  subType: string;
  channel: string;
}

type Props = HeaderProps;

const HeaderComponent: FunctionComponent<Props> = (props) => {
  const { headerType, headerIcon, subType } = props;
  return (
    <HeaderStyle type={props.type} subType={props.subType} channel={props.channel}>
      <div className="icon-wrapper">{headerIcon}</div>
      <div className="title">
        {headerType === "Trigger" ? "Starting Point" : headerType}
      </div>
      { BETA_SUBTYPES.includes(subType) && <Beta />}
    </HeaderStyle>
  );
};

export const Beta = () => {
  return <BetaStyle>
    <BodyCaption color="#4B1583">
      Beta
    </BodyCaption>
  </BetaStyle>
}

const BetaStyle = styled.div`
  background-color: ${COLORS.background.brandLight};
  border-radius: 4px;
  border: 1px solid ${COLORS.stroke.brandLightAlt};
  padding: 4px 8px;
`

const HeaderStyle = styled.div.attrs(
  (props: { type: string; subType?: string, channel: string }) => props
)`
  width: 100%;
  height: 60px;
  padding-block: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  border-bottom: 1px solid #e0e0e0;

  .icon-wrapper {
    background: ${(props) =>
    backgroundBasedOnSubType(props.subType!, props.channel)};
  }

  svg {
    cursor: default;
  }

  .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
    margin-top: auto;
    margin-bottom: auto;
    text-transform: capitalize;
  }
`;

export default HeaderComponent;

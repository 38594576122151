import { getManifestEndpoints } from "../../config";
import {
  HTTPMethods,
  RequestExecutor,
} from "../../api-helpers/RequestExecutor";
import { AiAssistantI } from "../../components/ai-assistant/models";

export class ManifestApiHelper {
  private baseUrl: string = getManifestEndpoints();

  async getAssistants(storeId: string) {
    const path = `${this.baseUrl}/get-assistants`;
    const response = (await RequestExecutor.fetch({
      apiPath: path,
      apiMethod: HTTPMethods.POST,
      requestBody: {
        store_id: storeId,
      },
    })) as any;
    return (response?.data?.data ?? []) as AiAssistantI[];
  }

  async linkAssistant(storeId: string, assistant: AiAssistantI) {
    const path = `${this.baseUrl}/link-assistant`;
    const response = (await RequestExecutor.fetch({
      apiPath: path,
      apiMethod: HTTPMethods.POST,
      requestBody: {
        store_id: storeId,
        assistant: assistant,
      },
    })) as any;
    if (response?.data?.errors) {
      return null;
    }
    return (response?.data?.data ?? []) as AiAssistantI[];
  }

  async delinkAssistant(storeId: string, assistantId: string) {
    const path = `${this.baseUrl}/delink-assistant`;
    const response = (await RequestExecutor.fetch({
      apiPath: path,
      apiMethod: HTTPMethods.POST,
      requestBody: {
        store_id: storeId,
        assistant_id: assistantId,
      },
    })) as any;
    if (response?.data?.errors) {
      return null;
    }
    return (response?.data?.data ?? []) as AiAssistantI[];
  }

  async downloadProducts(storeId: string) {
    const path = `${this.baseUrl}/bik-ai/products/${storeId}`;
    const response = (await RequestExecutor.fetch({
      apiPath: path,
      apiMethod: HTTPMethods.GET,
    })) as any;
    if (response?.data?.errors) {
      return null;
    }
    return response?.data?.data ?? [];
  }
}

import styled from "styled-components";
import { BASE_COLORS, COLORS } from "@bikdotai/bik-component-library";

export const HeaderStyled = styled.div`
  height: 56px;
  width: 100vw;
  padding: 0 16px;
  background: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 6;
  position: relative;

  .last__updated {
    position: absolute;
    top: 8px;
    left: 42.5%;
    border-left: 1px solid ${COLORS.stroke.primary};
    border-right: 1px solid ${COLORS.stroke.primary};
    bottom: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    gap: 4px;
  }

  .header {
    &__chatflow-name {
      padding: 16px 8px;
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;
      .back {
        display: flex;
        align-items: center;
      }
      .name-top {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        max-width: 200px;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }
      .status {
        border-radius: 4px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        padding: 4px 8px;
      }
      .active {
        color: #00580e;
        background: #e6f4e7;
        border: 1px solid #c3e4c4;
      }
      .stop {
        color: #b92321;
        background: #ffebef;
        border: 1px solid #ffced4;
      }
      .sunset {
        background: #fff7e5;
        border: 1px solid #ffeabe;
        color: #99631b;
      }
      .review {
        background: #fafafa;
        color: #616161;
        border: 1px solid #e0e0e0;
      }
      .name-bottom {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: #616161;
      }
      .error__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        left: -5px;
      }
    }
    &__right-menu {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      .parent__relative {
        display: flex;
        button {
          margin-left: 10px;
          justify-content: center;
          .button-container {
            .icon-leading {
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  }
`;

export const SummaryAnalyticsStyled = styled.div`
  background: #fafafa;
  padding: 16px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  z-index: 5;
  .header__bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left__side__flex {
      display: flex;
      gap: 12px;
      align-items: center;
      .title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        color: #212121;
      }
      .error__text {
        color: rgb(185, 35, 33);
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }
    .right__side__flex {
      display: flex;
      gap: 8px;
      align-items: center;
      cursor: pointer;

      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;

      color: #616161;
    }
  }
  .summary__cards {
    margin-top: 16px;
  }
`;

export const ShimmerStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  .shimmer__card {
    max-width: 273.8px;
    height: 88px;
    padding: 6px 10px;
    flex: 1 0 0;
    border-radius: 4px;
    background: var(--surface-color-surface-hovered, #f0f0f0);
    .shine {
      margin-top: 12px !important;
    }
  }
`;

export const TestModeContainer = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  border: ${(props) =>
    props.active
      ? `1px solid ${COLORS.stroke.warning.lightAlt}`
      : `1px solid ${COLORS.stroke.primary}`};
  border-radius: 4px;
  padding: 4px;
  gap: 4px;
  cursor: pointer;
  background-color: ${(props) =>
    props.active ? COLORS.background.warning.light : "white"};
`;

export const TooltipContainer = styled.div`
  background-color: white;
  padding: 16px;
  width: 440px;
  border-radius: 4px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
`;

export const WarningContainer = styled.div`
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
  display: flex;
  border-radius: 4px;
  border: 1px solid ${COLORS.stroke.warning.lightAlt};
  background: ${COLORS.background.warning.light};
  a {
    color: ${COLORS.content.brand} !important;
    text-decoration: underline !important;
    font-size: 14px;
  }
`;

import { NodeSubType } from "../../models/nodeSubType";

export enum ASSISTANT_QRB {
  TALK_TO_AGENT = "talkToAgent",
  MAIN_MENU = "mainMenu",
}

export const ASSISTANT_QRB_CARDS = [
  {
    id: ASSISTANT_QRB.TALK_TO_AGENT,
    label: "Talk to agent",
  },
  {
    id: ASSISTANT_QRB.MAIN_MENU,
    label: "Main menu",
  },
];

export type AiAssistantI = {
  id: string;
  name: string;
  type: AiAssistantType;
  is_draft: boolean;
};

export enum AiAssistantType {
  FAQ = "FAQ",
  PRODUCT_SEARCH = "Product Search",
  GENERAL_PURPOSE = "General Purpose",
}

export type AiAssistantBackendConfig = {
  type: string;
  sub_type: NodeSubType;
  node_index: number;
  assistant_id: string;
  assistant_name: string;
  conditions: {
    operator: "goto";
    operator_values: { [key: string]: { key: string; value: string } };
  }[];
};

export type AiAssistantFrontendConfig = {
  type: string;
  subType: NodeSubType;
  isMicroFlow: boolean;
  description: string;
  slang: string;
  childNodes: { [key: string]: { key: string; value: string } };
};

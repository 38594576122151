import { Icon } from "./icons-props";

const JigSawIcon: Icon = (props) => {
    const { width, height, color } = props;
    return (
        <svg width={width || "20"} height={height || "20"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.75377 17.0038C5.58801 17.0038 5.42904 16.9379 5.31183 16.8207C5.19462 16.7035 5.12877 16.5445 5.12877 16.3788V13.0428C4.78589 13.2064 4.40588 13.2765 4.02721 13.246C3.70658 13.2224 3.39508 13.1285 3.11489 12.9708C2.8347 12.8132 2.59269 12.5957 2.40611 12.3339C2.21952 12.0721 2.09294 11.7724 2.03536 11.4561C1.97779 11.1398 1.99064 10.8147 2.07301 10.5039C2.15537 10.1932 2.30522 9.90436 2.51189 9.6581C2.71857 9.41184 2.97698 9.21416 3.26874 9.07913C3.56051 8.9441 3.87845 8.87505 4.19994 8.87687C4.52143 8.8787 4.83856 8.95137 5.12877 9.08971V5.75377C5.12877 5.58801 5.19462 5.42904 5.31183 5.31183C5.42904 5.19462 5.58801 5.12877 5.75377 5.12877H9.40221C9.23864 4.78589 9.16857 4.40588 9.19908 4.02721C9.22264 3.70658 9.31658 3.39508 9.47422 3.11489C9.63186 2.8347 9.84933 2.59269 10.1111 2.40611C10.3729 2.21952 10.6727 2.09294 10.989 2.03536C11.3053 1.97779 11.6304 1.99064 11.9411 2.07301C12.2519 2.15537 12.5407 2.30522 12.7869 2.51189C13.0332 2.71857 13.2309 2.97698 13.3659 3.26874C13.5009 3.56051 13.57 3.87845 13.5682 4.19994C13.5663 4.52143 13.4937 4.83856 13.3553 5.12877H17.0038C17.1695 5.12877 17.3285 5.19462 17.4457 5.31183C17.5629 5.42904 17.6288 5.58801 17.6288 5.75377V9.08971C17.2859 8.92614 16.9059 8.85607 16.5272 8.88658C16.2066 8.91014 15.8951 9.00408 15.6149 9.16172C15.3347 9.31936 15.0927 9.53683 14.9061 9.79864C14.7195 10.0604 14.5929 10.3602 14.5354 10.6765C14.4778 10.9928 14.4906 11.3179 14.573 11.6286C14.6554 11.9394 14.8052 12.2282 15.0119 12.4744C15.2186 12.7207 15.477 12.9184 15.7687 13.0534C16.0605 13.1884 16.3784 13.2575 16.6999 13.2557C17.0214 13.2538 17.3386 13.1812 17.6288 13.0428V16.3788C17.6288 16.5445 17.5629 16.7035 17.4457 16.8207C17.3285 16.9379 17.1695 17.0038 17.0038 17.0038H5.75377Z" stroke={color || "#9E9E9E"} stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export default JigSawIcon;



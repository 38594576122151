import { Icon } from "./icons-props";


export const WhatsAppStroke: Icon = (props) => {
    const { width, height, color } = props;
    return <svg width="24" height="24" viewBox="0 0 24 24" fill={"none"} xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5818 14.0427C16.333 13.9182 15.1097 13.3163 14.8816 13.2332C14.6536 13.1502 14.4877 13.1087 14.3218 13.3577C14.1559 13.6068 13.6791 14.1673 13.5339 14.3334C13.3888 14.4994 13.2437 14.5203 12.9948 14.3956C12.746 14.2711 11.9443 14.0084 10.9939 13.1606C10.2542 12.5008 9.75482 11.6861 9.60971 11.4369C9.46456 11.1878 9.5942 11.0532 9.71877 10.9291C9.83074 10.8177 9.96764 10.6385 10.092 10.4932C10.2165 10.348 10.2579 10.2441 10.3408 10.0781C10.4238 9.91201 10.3823 9.76676 10.3201 9.64223C10.2579 9.5177 9.76026 8.29291 9.55294 7.79465C9.35097 7.30953 9.14584 7.37523 8.99309 7.36754C8.84813 7.36033 8.68206 7.3588 8.51623 7.3588C8.35035 7.3588 8.08076 7.42107 7.85267 7.67012C7.62462 7.91923 6.98183 8.52124 6.98183 9.74589C6.98183 10.9707 7.87339 12.1538 7.99782 12.3199C8.12225 12.486 9.75234 14.9992 12.2484 16.0769C12.842 16.3334 13.3055 16.4864 13.6669 16.6011C14.2629 16.7905 14.8054 16.7638 15.2341 16.6997C15.7122 16.6283 16.7062 16.0978 16.9136 15.5166C17.1209 14.9353 17.1209 14.4371 17.0587 14.3334C16.9965 14.2296 16.8307 14.1673 16.5818 14.0427ZM12.0418 20.2417H12.0385C10.5533 20.2411 9.09656 19.8421 7.82579 19.088L7.52354 18.9086L4.39095 19.7303L5.22708 16.676L5.03031 16.3629C4.20178 15.0451 3.76416 13.522 3.76483 11.9581C3.76664 7.39462 7.47966 3.68189 12.0451 3.68189C14.2559 3.68265 16.334 4.5447 17.8967 6.10923C19.4594 7.67366 20.3195 9.7532 20.3186 11.9648C20.3168 16.5286 16.6038 20.2417 12.0418 20.2417ZM19.0861 4.92044C17.206 3.03815 14.7057 2.0011 12.0417 2C6.55291 2 2.08566 6.46696 2.08347 11.9575C2.08275 13.7126 2.54128 15.4257 3.41273 16.9359L2 22.0961L7.27897 20.7114C8.73348 21.5047 10.3711 21.9229 12.0378 21.9234H12.0419C17.5301 21.9234 21.9978 17.456 22 11.9654C22.001 9.3046 20.9662 6.80268 19.0861 4.92044Z" fill="white" />
    </svg>

}

export default WhatsAppStroke
import styled from "styled-components";

export const ChatbotBuilderStyled = styled.div`
    display: flex;
    width: 100vw;
    height: 100%;
    background-color: #F5F5F5;
    overflow-y: auto;

    .button__save {
        position: absolute;
        bottom: 5vh;
        right: 20px;
        z-index: 4;
    }
`;
import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import RunsSearch from "./RunsSearch";
import RunsComponents from "./RunsComponents";
import {useDebugRuns} from "./useDebugRuns";
import {useNavigate, useSearchParams} from "react-router-dom";

interface OwnProps {
}

type Props = OwnProps;

const DebugRuns: FunctionComponent<Props> = (props) => {
    const [storeId, setStoreId] = useState<string>('')
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [searchParams] = useSearchParams();
    const runsHook = useDebugRuns()

    const navigate = useNavigate();
    useEffect(() => {
        if (storeId) {
            navigate(`/v2/admin/chatbot?storeId=${storeId}&phoneNumber=${phoneNumber}`);
        }
    }, [storeId, phoneNumber])

    // When the user comes back the search results should sustain.
    useEffect(() => {
        onChange(searchParams.get('storeId')!, searchParams.get('phoneNumber')!)
    }, [searchParams])

    const onChange = useCallback(
        (_storeId: string, _phoneNumber: string) => {
            if (_storeId) {
                setStoreId(_storeId);
                setPhoneNumber(_phoneNumber);
                runsHook.setUp({
                    storeId: _storeId,
                    phoneNumber: _phoneNumber,
                    showCompleteAnalytics: false
                })
            }
        },
        [storeId, phoneNumber],
    );

    return (
        <>
            <RunsSearch onChange={onChange}/>
            {runsHook.isSearching && <div>Searching for your data</div>}
            {runsHook.runs && <RunsComponents storeId={storeId} phoneNumber={phoneNumber} runsObj={runsHook.runs!}/>}
        </>
    );
};

export default DebugRuns;

import React from "react";
import PopupStyle from "./Popup.style";

export interface PopUpOptions {
    eventName: string;
    eventHandler: () => void;
}
export interface PopupProps {
    list: PopUpOptions[];
    rightPosition?: string;
    topPosition?: string;
}

const BotPopup = (props: PopupProps) => {
    const selectOption = (option: PopUpOptions) => {
        option.eventHandler();
    };
    return (
        <PopupStyle {...props}>
            <div className="popup__container">
                {props.list.map((item, index) => (
                    <div
                        className="popup__item"
                        key={index}
                        onClick={() => selectOption(item)}
                    >
                        {item.eventName}
                    </div>
                ))}
            </div>
        </PopupStyle>
    );
};

export default BotPopup;

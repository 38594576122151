import {
  HTTPMethods,
  RequestExecutor,
} from "../../api-helpers/RequestExecutor";
import { cloneDeep } from "lodash";
import {
  getCampaignEndPoints,
  getChatbotEndpoints,
  getCloudFunctionEndpoints,
  getFastifyEndpoints,
  getIntegrationsEndpoints,
  isProd,
  getCRMEndpoints,
} from "../../config";
import { FirebaseService } from "../firebase/FirebaseService";
import { collection, getDocs, limit, query } from "firebase/firestore";
import Fuse from "fuse.js";
import { SyncTemplateResponse } from "../../utilities/flowBuilder.utility";
import { captureErrorToSentry } from "../../utilities/sentryHelper";
import { RecordEventHelper } from "../../utilities/RecordEventHelpers";
import {
  AmplitudeEventStep,
  AmplitudeKeys,
  TargetPlatform,
} from "../../utilities/AnalyticsTypes";

export interface ProductData {
  catalogId: string;
  productId: string;
  image: string;
  name: string;
  variantId: string;
  custom: string;
}

export const isStoreIdValid = async (storeId: string): Promise<any> => {
  const url = isProd
    ? "https://bikapi.bikayi.app/growth/bikStoreApiFunctions-getBikStoreById"
    : "https://api.staging.bik.ai/growth/bikStoreApiFunctions-getBikStoreById";

  const response: any = await RequestExecutor.fetch({
    apiPath: url,
    apiMethod: HTTPMethods.POST,
    requestBody: {
      storeId: storeId,
    },
  });

  if (response?.data && response?.data?.status === 200) {
    return response?.data.store;
  }

  return {};
};

export const fetchProductsBySearchText = async (
  storeId: string,
  searchText: string
) => {
  const url =
    getIntegrationsEndpoints() +
    "/integrationsApiFunctions-executeIntegrationApi";

  const firebaseService = new FirebaseService(
    () => {},
    () => {}
  );

  const defaultProducts: any = [];
  const productData: ProductData[] = [];

  const parseProducts = async (items: any) => {
    items?.forEach((productInfo: any) => {
      const combinations = productInfo?.combinations;

      combinations?.forEach((combination: any) => {
        const variantInfo: ProductData = {
          catalogId: "",
          productId: "",
          image: "",
          name: "",
          variantId: "",
          custom: "",
        };

        if (combination.productId) {
          variantInfo.productId = combination.productId;
        }

        if (combination.id) {
          variantInfo.variantId = combination.id;
        }

        if (combination.custom) {
          variantInfo.custom = combination.custom;
        }

        if (productInfo.name) {
          variantInfo.name = productInfo.name;
        }

        if (productInfo.image) {
          variantInfo.image = productInfo.image;
        }

        productData.push(variantInfo);
      });
    });
  };

  try {
    if (!searchText.trim()) {
      const database = firebaseService.getDatabase();
      const colRef = collection(database, "store-new", storeId, "items");
      const queryRef = query(colRef, limit(10));
      const querySnapshot = await getDocs(queryRef);
      if (!querySnapshot.empty) {
        querySnapshot.docs.forEach((doc) => {
          defaultProducts.push({ ...doc.data(), id: doc.id } as any);
        });
      }

      await parseProducts(defaultProducts);
      return { productData };
    }
  } catch (err) {
    captureErrorToSentry(err, "Error in parseProducts 1");
    return { productData: [] };
  }

  try {
    const response: any = await RequestExecutor.fetch({
      apiPath: url,
      apiMethod: HTTPMethods.POST,
      requestBody: {
        storeId: storeId,
        partner: "SHOPIFY",
        code: "searchProducts",
        payload: {
          query: searchText,
        },
      },
    });
    const responseJson = response.data;
    if (responseJson.status === 200) {
      await parseProducts(responseJson.response?.products || []);
      return { productData };
    }
    return { productData: [] };
  } catch (err) {
    captureErrorToSentry(err, "Error in parseProducts 2");
    return { productData: [] };
  }
};

export const fetchProductById = async (storeId: string, productId: string) => {
  const url =
    getFastifyEndpoints() +
    `/storeApiFunctions-getProductV2/${storeId}/${"0"}/${productId}`;

  try {
    const res: any = await RequestExecutor.fetch({
      apiPath: url,
      apiMethod: HTTPMethods.GET,
    });
    const responseJson = res.data;
    const productData: ProductData[] = [];
    const product = responseJson.product;
    if (Object.keys(product).length) {
      if (product?.combinations?.length) {
        product?.combinations?.forEach((variant: any) => {
          if (
            !variant?.id ||
            !product?.id ||
            !(variant?.custom || variant?.name) ||
            !product?.name
          ) {
            return;
          }
          const variantInfo: ProductData = {
            catalogId: "",
            productId: "",
            image: "",
            name: "",
            variantId: "",
            custom: "",
          };
          variantInfo.catalogId = product?.catalogId || "";
          variantInfo.productId = product?.id;
          variantInfo.image = product?.image || "";
          variantInfo.name = product?.name;
          variantInfo.variantId = variant?.id;
          if (variant?.custom) {
            variantInfo["custom"] = variant.custom;
          }
          if (variant?.name) {
            variantInfo["custom"] = variant.name;
          }
          variantInfo.custom = variant?.custom;
          productData.push(variantInfo);
        });
      }
      return { productData };
    } else {
      return { productData: [] };
    }
  } catch (err) {
    captureErrorToSentry(err, "Error in fetchProductById");
    return { productData: [] };
  }
};

export const fetchAllCatalogs = async (storeId: string) => {
  const url = getFastifyEndpoints() + "/storeApiFunctions-getAllCatalog";

  try {
    const response: any = await RequestExecutor.fetch({
      apiPath: url,
      apiMethod: HTTPMethods.POST,
      requestBody: {
        storeId: storeId,
      },
    });

    if (response?.data) {
      const responseJson = response.data;
      return responseJson.filter((item: any) => item?.id && item.id !== 0);
    } else {
      return [];
    }
  } catch (err) {
    captureErrorToSentry(err, "Error in fetchAllCatalogs");
    return [];
  }
};

export const fetchMicroflowConf = async (
  conf_name: string,
  storeId: string
) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const url = `${getChatbotEndpoints()}/get-microflow-template/${conf_name}`;

  try {
    const response: any = await RequestExecutor.fetch({
      apiPath: url,
      apiMethod: HTTPMethods.GET,
      requestBody: {
        store_id: storeId,
      },
    });
    if (response.data && response.data?.data) {
      return response.data.data;
    } else {
      return {};
    }
  } catch (err) {
    captureErrorToSentry(err, "Error in fetchMicroflowConf");
    return {};
  }
};

export const updateKeywordCache = async (
  storeId: string,
  keywords: any,
  reset: boolean = false
) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const body: any = {
    storeId,
    keywords,
  };

  if (reset) {
    body["delete"] = true;
  }

  const url = `${getChatbotEndpoints()}/update-keywords-cache`;

  try {
    await RequestExecutor.fetch({
      apiPath: url,
      apiMethod: HTTPMethods.POST,
      requestBody: body,
    });
  } catch (err: any) {
    captureErrorToSentry(err, "Error in updateKeywordCache");
  }
};

export const parseMediaType = async (url: string, storeId: string) => {
  try {
    const res: any = await RequestExecutor.fetch({
      apiPath: `${getCampaignEndPoints()}/whatsappApiFunctions-getFileInfoFromUrl`,
      apiMethod: HTTPMethods.POST,
      requestBody: {
        url,
        storeId: storeId,
      },
    });

    if (res.data) {
      return res.data;
    } else {
      return res;
    }
  } catch (err: any) {
    return err;
  }
};

export const genConfigOperatorValues = (messageConf: any) => {
  const config_operator_values: any = {};
  const operator_values = messageConf.conditions?.[0]?.operator_values || {};
  if (messageConf["message_template"]["buttonsList"]) {
    messageConf["message_template"]["buttonsList"].forEach(
      (el: any, index: number) => {
        config_operator_values["+" + el.buttonKey] = cloneDeep(
          operator_values[el.buttonKey]
        );
      }
    );
  }

  return config_operator_values;
};

export const testEvalExpression = async (
  expression: string,
  id: string,
  storeId: string
): Promise<{ id: string; status: boolean; type?: string }> => {
  try {
    const response: any = await RequestExecutor.fetch({
      apiPath: `${getChatbotEndpoints()}/test-expression`,
      apiMethod: HTTPMethods.POST,
      requestBody: {
        expression,
        flow_id: "csad13",
        store_id: storeId,
      },
    });

    if (response.error) {
      return { id: id, status: false };
    }

    if (response.data && response.data.valid) {
      if (response.data.result) {
        return { id: id, status: true, type: typeof response.data.result };
      } else {
        return { id: id, status: true };
      }
    }

    return { id: id, status: false };
  } catch (err) {
    return { id: id, status: false };
  }
};

export const getCurrentUser = async (storeId: string, email: string) => {
  try {
    const response: any = await RequestExecutor.fetch({
      apiPath: `${getCRMEndpoints()}/userApiFunctions-getUsers`,
      apiMethod: HTTPMethods.POST,
      requestBody: {
        storeId: storeId,
        email: email,
      },
    });

    if (response.status === 200) {
      if (response.data.users.length > 0) {
        return response.data.users[0];
      }
    }

    return {};
  } catch (err) {
    captureErrorToSentry(err, "Error in getCurrentUser");
    return {};
  }
};

export const compileFlow = async (
  storeId: string,
  flowId: string,
  reactFlow?: any,
  flowConfig?: any,
  testMode?: boolean,
  status?: boolean,
  sunset?: boolean,
  startAFlow?: any,
  agentId?: number | string | null,
  flowMeta?: any,
  triggerType?: any,
  dndEnabled?: boolean
) => {
  try {
    const response: any = await RequestExecutor.fetch({
      apiPath: `${getChatbotEndpoints()}/publish-flow-with-compilation`,
      apiMethod: HTTPMethods.POST,
      requestBody: {
        store_id: storeId,
        flow_id: flowId,
        react_flow: reactFlow || {},
        flow_config: flowConfig || {},
        is_test_mode: testMode,
        status: status,
        is_sunset: sunset,
        start_a_flow: startAFlow,
        agent_id: agentId,
      },
    });

    const uniqueBlockUsed = new Set<string>();
    const blockUsed = Object.entries(flowConfig)
      .map(([key, node]) => {
        const { type = "", sub_type = "", nodeId = "" } = node as any;
        const uniqueKey = `${sub_type}`;

        if (nodeId !== "N1" && !uniqueBlockUsed.has(uniqueKey)) {
          uniqueBlockUsed.add(uniqueKey);
          return uniqueKey;
        }

        return null;
      })
      .filter(Boolean)
      .join(", ");

    const trackEvent = (properties: any) => {
      if (properties.libraryFlowId === "") {
        delete properties.libraryFlowId;
      }
      eventHelper.trackEvent(
        TargetPlatform.Amplitude,
        AmplitudeKeys.service_used,
        properties
      );
    };

    const eventHelper = RecordEventHelper.getInstance();
    const report = response?.data?.report?.[flowId] || {};
    const { compilation_status } = response?.data?.data || {};

    const baseProperties = {
      triggerUsed: triggerType.type || flowMeta[flowId]?.triggerType || "",
      dndActive: dndEnabled ?? false,
      activeStatus: status,
      channels: `${flowMeta[flowId]?.channel ?? ""}${
        response?.data?.data?.secondary_channels?.length ? "," : ""
      }${response?.data?.data?.secondary_channels?.join(",") ?? ""}`,
      libraryFlowId: flowMeta[flowId]?.parentFlowId,
      journeyType: flowMeta[flowId]?.journeyType,
      blockUsed,
      source: "journey-builder",
      screen: "journey_builder",
    };

    if (response) {
      if (compilation_status === "FAILED") {
        trackEvent({
          step: AmplitudeEventStep.saved_failed,
          warningCount: Object.keys(report?.combined_warnings ?? {}).length,
          errorCount: Object.keys(report?.combined_errors ?? {}).length,
          errors: Object.keys(report?.combined_errors ?? {})
            .join(", ")
            .substring(0, 100),
          source: "journey-builder",
          screen: "journey_builder",
        });
      } else if (
        compilation_status === "SUCCESS" ||
        compilation_status === "WARNING"
      ) {
        const properties = {
          ...baseProperties,
          step: AmplitudeEventStep.saved_success,
          warningCount:
            compilation_status === "WARNING"
              ? Object.keys(report?.combined_warnings ?? {}).length
              : undefined,
        };

        if (compilation_status !== "WARNING") {
          delete properties.warningCount;
        }

        trackEvent(properties);
      } else if (compilation_status === undefined) {
        trackEvent({
          ...baseProperties,
          step: AmplitudeEventStep.saved_success,
          channels: `${flowMeta[flowId]?.channel ?? ""}`,
          sunset,
        });
      }
      return response.data;
    }
    return {};
  } catch (err) {
    captureErrorToSentry(err, "Error in compileFlow");
    return {};
  }
};

export const fetchAllProducts = async (storeId: string) => {
  const firebaseService = new FirebaseService(
    () => {},
    () => {}
  );
  const products = await firebaseService.fetchAllProducts(storeId);
  return products;
};

export const searchProductsById = async (query: string, products: any[]) => {
  const options = {
    keys: [
      {
        name: "uniqueId",
        weight: 1,
      },
    ],
    useExtendedSearch: true,
    minMatchCharLength: query.length,
    findAllMatches: false,
    shouldSort: true,
  };
  const fuseIndex = Fuse.createIndex(options.keys, products);
  const fuse = new Fuse(products, options, fuseIndex);
  const results = fuse.search(`"=${query}"`);
  return results
    .sort((a: any, b: any) => {
      return a.score - b.score;
    })
    .map((result: any) => result.item);
};

export const searchProducts = async (storeId: string, query: string) => {
  let products = await fetchAllProducts(storeId);

  const options = {
    keys: [
      {
        name: "title",
        weight: 1,
      },
      {
        name: "uniqueId",
        weight: 1,
      },
    ],
    minMatchCharLength: Math.max(3, (query.length + 1) / 2),
    useExtendedSearch: true,
  };
  const fuseIndex = Fuse.createIndex(options.keys, products);
  const fuse = new Fuse(products, options, fuseIndex);
  const results = fuse.search(`^"${query}" | "${query}"`);
  return results
    .sort((a: any, b: any) => {
      return a.score - b.score;
    })
    .map((result: any) => result.item);
};

export const fetchOrderTags = () => {
  return [];
};

export const fetchCustomerTags = async (storeId: string) => {
  try {
    const response: any = await RequestExecutor.fetch({
      apiPath: `${getCampaignEndPoints()}/queryBuilderApiFunctions-getFilters`,
      apiMethod: HTTPMethods.POST,
      requestBody: {
        storeId: storeId,
        token: storeId,
        action: "GET_USER_PROPERTY_VALUES",
        filterName: "tags",
        useNew: true,
      },
    });

    if (response.data) {
      return response.data.response;
    }

    return [];
  } catch (err) {
    captureErrorToSentry(err, "Error in fetchCustomerTags");
    return [];
  }
};

export const SyncFlowTemplateToLibrary = async (
  storeId: string,
  flowId: string
): Promise<SyncTemplateResponse> => {
  try {
    const response: any = await RequestExecutor.fetch({
      apiPath: `${getChatbotEndpoints()}/sync-flow-templates-to-library`,
      apiMethod: HTTPMethods.POST,
      requestBody: {
        store_id: storeId,
        flow_id: flowId,
      },
    });
    if (response.data) {
      return response.data;
    }
    return {};
  } catch (err) {
    captureErrorToSentry(err, "Error in SyncFlowTemplateToLibrary");
    return {};
  }
};

export const createNewFlow = async (storeId: string, flowDetails: any) => {
  try {
    const payload = {
      storeId: storeId,
      ...flowDetails,
    };
    const url = getChatbotEndpoints() + "/create-new-flow";
    const response: any = await RequestExecutor.sendData<{
      data: any;
    }>({
      apiPath: url,
      apiMethod: HTTPMethods.POST,
      requestBody: payload,
    });
    if (response?.data && response.data?.success) {
      return response.data.data;
    }
    return false;
  } catch (err) {
    captureErrorToSentry(err, "Error in createNewFlow");
    return false;
  }
};

import React, {FunctionComponent, useState} from "react";
import AdminSearchBar from "../core/AdminSearchBar";
import {AdminTableStyles} from "../core/TableStyles";
import {IntegrationService} from "./IntegrationsService";
import {LogsSearchResponse} from "./LogSearchModel";
import ProductsDashboard from "./ProductsDashboard";

interface OwnProps {
}

type Props = OwnProps;

const LogSearch: FunctionComponent<Props> = (props) => {
    const [logsResponse, setLogsResponse] = React.useState<LogsSearchResponse>();
    const [isLogsLoading, setIsLogsLoading] = React.useState<boolean>(false);
    const [storeId, setStoreId] = useState<string>('')

    function parseWebhookData(json: any): any {
        return json;
        if (!json) {
            return {};
        }
        const data = json["structValue"]["fields"];
        const result: {[key: string]: string} = {};
        for (const [key, value] of Object.entries(data)) {
            result[key] = (value as any)["stringValue"] || (value as any)["numberValue"];
        }
        return result;
    }

    function openInCloudLogs() {
        window.alert('Not implemented yet')
    }

    return (
        <>
            <p style={{margin: 16}}> Popular codes are :
                'Abandoned cart - checkouts-create', <br/>
                {/*'Abandoned cart - checkouts-update', <br/>*/}
                'Order creation - orders-create', <br/>
                'Order updates - orders-updated',
            </p>
            <AdminSearchBar
                mandatoryKeys={['storeId', 'podName', 'webhookCode']}
                optionalKeys={['searchToken']}
                onSubmit={(storeId, values) => {
                    setStoreId(storeId);
                    setIsLogsLoading(true);
                    const integrationService = new IntegrationService();
                    integrationService.findLogsById({
                        storeId,
                        ...values
                    }).then((response) => {
                        setIsLogsLoading(false);
                        setLogsResponse(response);
                    }).catch();
                }}
            />
            {isLogsLoading && <div>Loading...</div>}
            {!logsResponse && <div>No logs found</div>}
            <AdminTableStyles>
                <h2>Logs ({logsResponse?.logs?.length || 0})</h2>
                <table>
                    <tr>
                        <th>Logs</th>
                        <th>Customer Details</th>
                    </tr>
                    {logsResponse?.logs?.map((log) => {
                        const parsedMetaData = parseWebhookData(log.metadata);
                        const isSuccessMessage = log.message.includes('success') || parsedMetaData.success === 'true'
                        console.log(isSuccessMessage);
                        return (
                                <tr style={{backgroundColor: isSuccessMessage ? '#a8d0a8' : '#d99999'}}>
                                    <td>{log.message}</td>
                                    <td>
                                        <pre style={{whiteSpace: 'pre-wrap', wordWrap: 'break-word'}}>  {JSON.stringify(parsedMetaData)}</pre>
                                       <br/>
                                        <button onClick={openInCloudLogs}>Open in cloud logs</button>
                                    </td>
                                </tr>
                            )
                        }
                    )}
                </table>
            </AdminTableStyles>
        </>
    );
};

export default LogSearch;

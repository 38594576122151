import React, { FunctionComponent, useState } from 'react';
import { DebugRuns } from "./RunsModel";
import { TableStyle } from "../../../app/entry-point/EntryPoint.style";
import { useNavigate } from "react-router-dom";
import { CrmService } from "../../../admin/services/CrmService";
import { CampaignService } from "../../../admin/services/CampaignService";

interface OwnProps {
    storeId: string,
    phoneNumber: string,
    runsObj: DebugRuns;
}

type Props = OwnProps;


const RunsComponents: FunctionComponent<Props> = (props) => {
    const navigate = useNavigate();
    const [fetchedMap, setFetchedMap] = useState<{ [key: string]: string }>({});

    const navigateToCompleteFlowAnalytics = (flowId: string, sessionId: string, chatFlow: boolean) => {
        if (chatFlow) {
            window.open(`/${props.storeId}/${flowId}/chatflow/whatsapp?&analytics=true&complete=true`, "_blank");
        } else {
            window.open(`/${props.storeId}/${flowId}/automation?analytics=true&complete=true`, "_blank");
        }
    }

    const navigateToFlow = (flowId: string, sessionId: string, chatFlow: boolean) => {
        if (chatFlow) {
            window.open(`/${props.storeId}/${flowId}/chatflow/whatsapp?sessionId=${sessionId}&analytics=true&phoneNumber=${props.phoneNumber}`, "_blank");
        } else {
            window.open(`/${props.storeId}/${flowId}/automation?sessionId=${sessionId}&analytics=true&phoneNumber=${props.phoneNumber}`, "_blank");
        }
    }
    const openGoogleCloud = (sessionId: string) => {
        window.open(`https://console.cloud.google.com/logs/query;query=resource.labels.namespace_name%3D%22chatbot%22%0Alabels.k8s-pod%2Fapp%3D%22chatbot-service%22%0A%0Alabels.sessionId%3D%22${sessionId}%22;timeRange=PT12H;?project=bikai-d5ee5`);
    }
    const openCRMChats = (customerId: string) => {
        const crmService = new CrmService();
        crmService.getCrmContext(customerId, props.storeId)
            .then((sessionId) => {
                console.log(sessionId);
                if (sessionId) {
                    window.open(`https://dashboard.bik.ai/chats?staffId=&type=CHAT_BOT&customerId=${customerId}&sessionId=${sessionId}`)
                }
            })
    }
    const fetchInfo = (customerId: string) => {
        const campaignService = new CampaignService();
        campaignService.getCustomerDetails(customerId, props.storeId).then((data) => {
            setFetchedMap({ ...fetchedMap, [customerId]: JSON.stringify(data) })
        });
    }

    return (
        <div>
            <TableStyle>
                <div className='table-wrapper'>
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    Flow name
                                </th>
                                <th>
                                    customer id
                                </th>
                                <th>
                                    timestamp
                                </th>
                                <th>
                                    nodes
                                </th>
                                <th>
                                    actions
                                </th>
                            </tr>
                        </thead>
                        {props.runsObj.runs.map((run, index) => {
                            return (
                                <tbody key={run.sessionId}>
                                    <tr
                                    >
                                        <td>{run.flowId}</td>
                                        <td>
                                            {run.customerId}
                                            {!!fetchedMap[run.customerId] && <div>{fetchedMap[run.customerId]}</div>}
                                        </td>
                                        <td>{new Date(run.time).toISOString()}</td>
                                        <td>{run.nodes.length}</td>
                                        <td>
                                            <button onClick={() => {
                                                navigateToFlow(run.flowId, run.sessionId, true);
                                            }}>View Chatflow
                                            </button>
                                            <button
                                            style={{ marginLeft: 8 }}   
                                                 onClick={() => {
                                                navigateToFlow(run.flowId, run.sessionId, false);
                                            }}>View Automation
                                            </button>
                                            <button style={{ marginLeft: 8 }} onClick={() => {
                                                openGoogleCloud(run.sessionId);
                                            }}>Open cloud logs
                                            </button>
                                            <button style={{ marginLeft: 8 }} onClick={() => {
                                                openCRMChats(run.customerId);
                                            }}>Open CRM chats
                                            </button>
                                            <button style={{ marginLeft: 8 }} onClick={() => {
                                                fetchInfo(run.customerId);
                                            }}>Fetch Info
                                            </button>

                                            <div style={{marginTop: 8, marginBottom: 8}}>
                                                <button onClick={() => {
                                                    navigateToCompleteFlowAnalytics(run.flowId, run.sessionId, true);
                                                }}>Complete Chatflow Analytics
                                                </button>
                                                <button 
                                                    style={{ marginLeft: 8 }}
                                                    onClick={() => {
                                                        navigateToCompleteFlowAnalytics(run.flowId, run.sessionId, false);
                                                }}>Complete Automation Analytics
                                                </button>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            )
                        }
                        )}
                    </table>
                </div>
            </TableStyle>
        </div>
    );
};

export default RunsComponents;

import React, {FunctionComponent, useState} from "react";
import styled from "styled-components";
import {AdminTableStyles} from "../core/TableStyles";
import {FirebaseService} from "../../services/firebase/FirebaseService";
import {doc, getDoc} from "firebase/firestore";
import AdminSearchBar from "../core/AdminSearchBar";
import {CampaignService} from "../services/CampaignService";
import {IntegrationService} from "../integrations/IntegrationsService";

interface OwnProps {
}

type Props = OwnProps;

const OnboardingDashboardStyles = styled.div`
  padding: 16px;

  h2 {
    font-size: 24px;
    font-weight: 600;
        margin-bottom: 16px;
    }
    table {
        margin-top: 16px;
        width: 100%;
        border-collapse: collapse;
        border: 1px solid #e7e7e7;
        th {
            padding: 8px;
            border: 1px solid #e7e7e7;
            text-align: left;   
        }
        td {
            padding: 8px;
            border: 1px solid #e7e7e7;
            text-align: left;
            button {
                margin-right: 8px;
            }
        }
    }
    button {
        marign-left: 16px;
    }
`


type OnboardingStatus = 'NOT_STARTED' | 'IN_PROGRESS' | 'SUCCESS' | 'FAILED';
const OnboardingDashboard: FunctionComponent<Props> = (props) => {
    const [storeId, setStoreId] = useState('')
    const [onboardingStatus, setOnboardingStatus] = useState<{[key: string]: string}>({
        customerMigration: 'loading',
        productMigration: 'loading',
        orderMigration: 'loading',
        bikSegmentSync: 'loading',
        shopifySegmentSync: 'loading',
        templateSync: 'loading',
        userPropertySync: 'loading',
        scopesMismatch: 'loading',
    })

    const fetchStatusIntegrations = async (storeId: string) => {
        const firebaseService = new FirebaseService(() => {
            },
            () => {
            })
        const database = firebaseService.getDatabase();
        const docsRef = doc(
            database,
            "store",
            storeId
        )
        const docsSnapshot = await getDoc(docsRef);
        const docs = docsSnapshot.data();
        const integrationStatus = docs?.meta?.whatsappChatFlowConfiguration?.onboardingStates?.shopifyStoreIntegrationStatus
        await fetchStatusCampaigns(storeId, integrationStatus);
    }

    const fetchStatusCampaigns = async (storeId: string, integrationStatus: any) => {
        const campaignService = new CampaignService();
        const integrationsService = new IntegrationService();
        const response = ((await  campaignService.fetchOnboardingDetails(storeId)) as any).response;
        const scopesList = ((await  integrationsService.fetchScopes(storeId)) as any);
        response.totalBikSegmentsCount = response.totalBikSegmentsCount || 'Failed'
        response.totalShopifySegmentsCount = response.totalShopifySegmentsCount ||'Failed'
        response.totalBikTemplatesCount = response.totalBikTemplatesCount ||'Failed'
        response.totalMerchantTemplatesCount = response.totalMerchantTemplatesCount ||'Failed'
        response.totalUserProperties = response.totalUserProperties ||'Failed'

        setOnboardingStatus({
                customerMigration: `${integrationStatus.cusomterSync} ${response.totalCustomers}`,
                productMigration: `${integrationStatus.proudctSync}`,
                orderMigration: `${integrationStatus.orderSync} ${response.totalOrderedCount}`,
                bikSegmentSync: `${response.totalBikSegmentsCount} ${response.totalBikSegmentsCount > 0}`,
                shopifySegmentSync: `${response.totalShopifySegmentsCount}`,
                templateSync: `totalBikTemplatesCount : ${response.totalBikTemplatesCount} totalMerchantTemplatesCount: ${response.totalMerchantTemplatesCount}`,
                userPropertySync: `${response.totalUserProperties}`,
            scopesMismatch: `${scopesList.mismatch}`
        })
    }

    return (
        <>
            <AdminSearchBar
                mandatoryKeys={['storeId']}
                optionalKeys={[]}
                onSubmit={(id, values) => {
                    setStoreId(id);
                    fetchStatusIntegrations(id);
                }}
            />

            <AdminTableStyles style={{padding: 16}}>
                <h2>Health Dashboard</h2>
                {/*<button>Sync everything</button>*/}
                <table>
                    <tr>
                        <th>Process Name</th>
                        <th>Process Status</th>
                        {/*<th>Action</th>*/}
                    </tr>
                    <tr style={{ backgroundColor: onboardingStatus.customerMigration.includes('COMPLETED') ? 'green' : 'red'}}>
                        <td>Onboarding : Customer migration</td>
                        <td>{onboardingStatus.customerMigration}</td>
                        {/*<td>*/}
                        {/*    <button>Delete Events</button>*/}
                        {/*    <button>Resync</button>*/}
                        {/*</td>*/}
                    </tr>
                    <tr style={{ backgroundColor: onboardingStatus.productMigration.includes('COMPLETED') ? 'green' : 'red'}}>
                        <td>Onboarding : Products migration</td>
                        <td>{onboardingStatus.productMigration}</td>
                        {/*<td>*/}
                        {/*    <button>Resync</button>*/}
                        {/*</td>*/}
                    </tr>
                    <tr style={{ backgroundColor: onboardingStatus.orderMigration.includes('COMPLETED') ? 'green' : 'red'}}>
                        <td>Onboarding : Orders migration</td>
                        <td>{onboardingStatus.orderMigration}</td>
                        {/*<td>*/}
                        {/*    <button>Delete Events</button>*/}
                        {/*    <button>Resync</button>*/}
                        {/*</td>*/}
                    </tr>
                    <tr style={{ backgroundColor: onboardingStatus.bikSegmentSync.includes('Failed') ? 'red' : 'green'}}>
                        <td>Onboarding : Segment Sync</td>
                        <td>{onboardingStatus.bikSegmentSync}</td>

                        {/*<td>Resync</td>*/}
                    </tr>
                    <tr style={{ backgroundColor: onboardingStatus.shopifySegmentSync.includes('Failed') ? 'red' : 'green'}}>
                        <td>Onboarding : Shopify Segment Sync ()</td>
                        <td>{onboardingStatus.shopifySegmentSync}</td>
                        {/*<td>Resync via dashboard</td>*/}
                    </tr>
                    <tr style={{ backgroundColor: onboardingStatus.templateSync.includes('Failed') ? 'red' : 'green'}}>
                        <td>Onboarding : Template Sync</td>
                        <td>{onboardingStatus.templateSync}</td>
                        {/*<td>Resync</td>*/}
                    </tr>
                    <tr style={{ backgroundColor: onboardingStatus.userPropertySync.includes('Failed') ? 'red' : 'green'}}>
                        <td>User Property</td>
                        <td>{onboardingStatus.userPropertySync}</td>
                        {/*<td>Maria Anders</td>*/}
                    </tr>
                    <tr style={{ backgroundColor: onboardingStatus.scopesMismatch.includes('all good') ? 'green' : 'red'}}>
                        <td>Scope mismatch</td>
                        <td>{onboardingStatus.scopesMismatch}</td>
                        {/*<td>Maria Anders</td>*/}
                    </tr>
                </table>

            </AdminTableStyles>
        </>
    );
};

export default OnboardingDashboard;

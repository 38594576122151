import { useState, useEffect } from 'react';

export interface TimerHook {
  time: number;
  isRunning: boolean;
  startTimer: (initialValue: number) => void;
  stopTimer: () => void;
}

/**
 * Custom Hook for timer.
 * Returns time, startTimer function, stopTimer function, isRunning boolean
 */
export const useTimer = (): TimerHook => {
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isRunning) {
      intervalId = setInterval(() => {
        setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isRunning]);

  /**
   * @param initialValue - Time which the timer will start. E.g 5 will be 5 seconds
   */
  const startTimer = (initialValue: number) => {
    setTime(initialValue);
    setIsRunning(true);
  };

  /**
   * To stop before timer comes to 0
   */
  const stopTimer = () => {
    setIsRunning(false);
    setTime(0);
  };

  return {
    time,
    isRunning,
    startTimer,
    stopTimer,
  };
};
import styled from "styled-components";

export const VariableEditorStyle = styled.div`
  .editor_heading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  .variable_editor {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    &__heading {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    &__body {
      display: flex;
      flex-dirextion: row;
      padding: 16px;
      column-gap: 16px;
      background: #fafafa;
      border: 1px solid #e0e0e0;
      height: 140px;

      &__name_container {
        flex-basis: 28%;
      }

      &__type_container {
        flex-basis: 20%;

        &&__searchbar {
        }
      }

      &__default_value_container {
        flex-basis: 41%;
      }

      &__button {
        flex-basis: 10%;
        align-self: center;
        margin-top: 10px;
      }
    }
  }
`;

import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../../state/store";
import { EventTriggerCountType } from "../CountModal";
import { captureErrorToSentry } from "../../../utilities/sentryHelper";
import { getCustomerCount } from "../helpers/AddPastProfileApi";
import {
  calculateFromDate,
  calculateToDate,
  getAlreadyAddedDays,
  getFlowEnabledOnDate,
} from "../helpers/AddPastProfileHelpers";

export const useGetCustomerCount = (
  maxDelayDays: number,
  alreadyAddedDays: number,
  flowEnabledDate: string,
  isModalOpen: boolean
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [totalCustomerCount, setTotalCustomerCount] = useState(0);
  useState<Record<string, EventTriggerCountType[]>>();
  const [customerCountForEventTrigger, setCustomerCountForEventTrigger] =
    useState<
      Record<
        string,
        { displayName: string; customerCountList: EventTriggerCountType[] }
      >
    >();

  const homeState = useAppSelector((state) => state.homeState);
  const flowMetaState = useAppSelector((state) => state.flowMetaState);

  const payload = useMemo(
    () => ({
      storeId: homeState.storeId,
      flowId: homeState.flowId,
      fromDate: calculateFromDate(
        flowEnabledDate,
        maxDelayDays,
        alreadyAddedDays
      ),
      toDate: calculateToDate(flowEnabledDate, alreadyAddedDays),
    }),
    [
      homeState.storeId,
      homeState.flowId,
      maxDelayDays,
      alreadyAddedDays,
      flowEnabledDate,
    ]
  );

  const parseResponseToUIFormat = (response: EventTriggerCountType[]) => {
    const flowMeta = flowMetaState?.flowMeta as any;
    const currentChannel = homeState?.channel;
    const channels = flowMetaState?.channels as any;

    const channelToDisplay: {
      channelId: string;
      displayName: string;
    }[] = [];
    if (currentChannel === "whatsapp") {
      channels?.multiWhatsappInfo?.forEach((channelObject: any) => {
        channelToDisplay.push({
          channelId: channelObject?.phoneNumberId ?? "",
          displayName: channelObject?.accountNumber ?? "",
        });
      });
    } else if (currentChannel === "email") {
      channels?.multiChannelEmail?.forEach((channelObject: string) => {
        channelToDisplay.push({
          channelId: channelObject ?? "",
          displayName: channelObject ?? "",
        });
      });
    }

    const customerCountForRender: Record<
      string,
      { displayName: string; customerCountList: EventTriggerCountType[] }
    > = {};

    channelToDisplay.forEach((channelObject) => {
      customerCountForRender[channelObject.channelId] = {
        displayName: channelObject.displayName,
        customerCountList: response.filter(
          (resp) => resp.channelId === channelObject.channelId
        ),
      };
    });

    return customerCountForRender;
  };

  const fetchCustomerCountForEventTriggers = async () => {
    setIsLoading(true);
    try {
      const response = await getCustomerCount(payload);
      setIsLoading(false);

      if (response?.errors) {
        setError(response?.errors?.[0]?.message);
      } else if (
        response?.data &&
        response?.data?.customerCountList?.length >= 0
      ) {
        setTotalCustomerCount(response?.data?.totalCustomerCount);
        setCustomerCountForEventTrigger(
          parseResponseToUIFormat(response?.data?.customerCountList)
        );
      } else {
        setError("Something went wrong while fetching customer count");
      }
    } catch (e) {
      setIsLoading(false);
      captureErrorToSentry(e, "Error in useGetCustomerCount");
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchCustomerCountForEventTriggers();
    }
  }, [isModalOpen]);

  return {
    isLoading,
    error,
    customerCountForEventTrigger,
    totalCustomerCount,
  };
};

import { ControlButton, Controls, useReactFlow } from "reactflow";
import { MinimizeIcon } from "../../icons/minimize";
import FullScreenIcon from "../../icons/fullScreenIcon";
import { setViewPortToLocalStorage } from "../../utilities/localStorageUtility";
import { useEffect, useState } from "react";

const CustomControls = (props: {
  storeId: string;
  flowId: string;
}) => {
  const { getViewport } = useReactFlow();
  const { storeId, flowId } = props;
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  const resizeHandler = () => {
    // eslint-disable-next-line no-restricted-globals
    if (window.innerHeight === screen.height) {
      setIsFullScreen(true);
    } else {
      setIsFullScreen(false);
    }
  };
  
  const enableFullScreen = () => {
    const elem = document.documentElement;
    if (isFullScreen) {
      document.exitFullscreen();
    } else {
      elem?.requestFullscreen().then();
    }
  };

  return (
    <Controls
      showFitView={true}
      showInteractive={false}
      position={"bottom-center"}
      onFitView={() => {
        const v = getViewport();
        if (storeId && flowId) {
          setViewPortToLocalStorage(storeId, flowId, v);
        }
      }}
    >
      <ControlButton
        onClick={enableFullScreen}
        title={`${isFullScreen ? "exit" : "enter"} fullscreen`}
      >
        {isFullScreen ? (
          <MinimizeIcon width={20} height={20} />
        ) : (
          <FullScreenIcon />
        )}
      </ControlButton>
    </Controls>
  );
};

export default CustomControls;
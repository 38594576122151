import { useEffect, useState, useMemo } from "react";
import { getMaxDelayApi } from "../helpers/AddPastProfileApi";
import { captureErrorToSentry } from "../../../utilities/sentryHelper";
import { useAppSelector } from "../../../state/store";
import { MaxDelayType } from "../AddPastProfile";
import { secondsToDays } from "../helpers/AddPastProfileHelpers";

export const useGetMaxDelay = (
  selectedCard: MaxDelayType,
  isModalOpen: boolean,
  alreadyAddedDays: number
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [maxDelay, setMaxDelay] = useState<Record<MaxDelayType, number | null>>(
    {
      at_the_start: null,
      throughout_the_flow: null,
    }
  );

  const flowState = useAppSelector((state) => state.storeState.flow);
  const homeState = useAppSelector((state) => state.homeState);

  const payload = useMemo(
    () => ({
      storeId: homeState.storeId,
      flowId: homeState.flowId,
      flow_config: flowState,
      max_delay_type: selectedCard,
    }),
    [homeState.storeId, homeState.flowId, flowState, selectedCard]
  );

  const fetchMaxDelayfromApi = async () => {
    setIsLoading(true);
    try {
      const response = await getMaxDelayApi(payload);
      setIsLoading(false);

      if (response?.errors) {
        setError(response?.errors?.[0]?.message);
      } else if (response?.data && response?.data?.maxDelay >= 0) {
        const newMaxDelay = { ...maxDelay };
        const calculatedDays =
          secondsToDays(response.data.maxDelay) - alreadyAddedDays;
        newMaxDelay[response.data.type] =
          calculatedDays < 0 ? 0 : calculatedDays;
        setMaxDelay(newMaxDelay);
      } else {
        setError(
          "Something went wrong while calculating Maximum Delay for this flow"
        );
      }
    } catch (e) {
      setIsLoading(false);
      captureErrorToSentry(e, "Error in useGetMaxDelay");
    }
  };

  const resetStates = () => {
    setError("");
    setMaxDelay({
      at_the_start: null,
      throughout_the_flow: null,
    });
  };

  useEffect(() => {
    if (isModalOpen && maxDelay[selectedCard] == null && !error) {
      fetchMaxDelayfromApi();
    }

    if (!isModalOpen) {
      resetStates();
    }
  }, [selectedCard, isModalOpen]);

  return {
    isLoading,
    maxDelay,
    error,
    setError,
  };
};

import { CustomNodeIconProps } from "../utilities/flowBuilder.utility";

const ConditionNodeIcon = (props: CustomNodeIconProps) => {
    const {backgroundColor, color, height, width} = props;
    return (
        <svg width={width || "20"} height={height || "20"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.625 6.5625C6.83312 6.5625 7.8125 5.58312 7.8125 4.375C7.8125 3.16688 6.83312 2.1875 5.625 2.1875C4.41688 2.1875 3.4375 3.16688 3.4375 4.375C3.4375 5.58312 4.41688 6.5625 5.625 6.5625Z" stroke={color || "#212121"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.625 17.8125C6.83312 17.8125 7.8125 16.8331 7.8125 15.625C7.8125 14.4169 6.83312 13.4375 5.625 13.4375C4.41688 13.4375 3.4375 14.4169 3.4375 15.625C3.4375 16.8331 4.41688 17.8125 5.625 17.8125Z" stroke={color || "#212121"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.625 12.8125C16.8331 12.8125 17.8125 11.8331 17.8125 10.625C17.8125 9.41688 16.8331 8.4375 15.625 8.4375C14.4169 8.4375 13.4375 9.41688 13.4375 10.625C13.4375 11.8331 14.4169 12.8125 15.625 12.8125Z" stroke={color || "#212121"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.9375 12.8125V7.1875C7.1875 9.6875 8.4375 10.9375 12.8125 10.9375" stroke={color || "#212121"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
}

export default ConditionNodeIcon;
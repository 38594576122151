import {StyledModal} from "@bikdotai/bik-component-library";

type ConfirmationModalProps = {
   open: boolean;
   title: string;
   subTitle: string;
   positiveButtonText: string;
   negativeButtonText: string;
   onPositiveClick: () => void;
   onNegativeClick: () => void;
   onCloseClick: () => void;
   loading?: boolean;
}

export const ConfirmationModal = (props : ConfirmationModalProps) =>
    <StyledModal
        open={props.open}
        headingTitle={props.title}
        headingSubtitle={props.subTitle}
        primaryButton={{
            isLoading: props.loading ?? false,
            buttonText: props.positiveButtonText,
            onClick: props.onPositiveClick
        }}
        secondaryButton={{
            buttonText: props.negativeButtonText,
            buttonType: "tertiaryGray",
            onClick: props.onNegativeClick
        }}
        centralContainerStyles={{
           width: '450px'
        }}
        onClose={props.onCloseClick}
    />;
import { saveAs } from "file-saver";

export const generateCSV = (data: string, fileName: string) => {
  const blob = new Blob([data], { type: "text/csv;charset=utf-8" });
  saveAs(blob, fileName);
};

export const generateTxt = (data: string, fileName: string) => {
  const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
  saveAs(blob, fileName);
};

import { COLORS, FONTS } from "@bikdotai/bik-component-library";
import styled from "styled-components";

const addPixels = (value: number) => {
  return value + "px";
};

export const TitleMedium = styled.div`
  font-family: Inter;
  color: ${(props) => props.color || COLORS.text.primary};
  font-weight: ${(props) => FONTS.titleMedium.fontWeight};
  font-size: ${(props) => addPixels(FONTS.titleMedium.fontSize)};
  line-height: ${(props) => addPixels(FONTS.titleMedium.lineHeight)};
`;

export const TitleRegular = styled.div`
  font-family: Inter;
  color: ${(props) => props.color || COLORS.text.primary};
  font-weight: ${(props) => FONTS.titleRegular.fontWeight};
  font-size: ${(props) => addPixels(FONTS.titleRegular.fontSize)};
  line-height: ${(props) => addPixels(FONTS.titleRegular.lineHeight)};
`;

export const TitleSmall = styled.div`
  font-family: Inter;
  color: ${(props) => props.color || COLORS.text.primary};
  font-weight: ${(props) => FONTS.titleSmall.fontWeight};
  font-size: ${(props) => addPixels(FONTS.titleSmall.fontSize)};
  line-height: ${(props) => addPixels(FONTS.titleSmall.lineHeight)};
`;

export const BodyCaption = styled.div`
  font-family: Inter;
  color: ${(props) => props.color || COLORS.text.primary};
  font-weight: ${(props) => FONTS.caption.fontWeight};
  font-size: ${(props) => addPixels(FONTS.caption.fontSize)};
  line-height: ${(props) => addPixels(FONTS.caption.lineHeight)};
`;

export const BodySecondary = styled.div`
  font-family: Inter;
  color: ${(props) => props.color || COLORS.text.primary};
  font-weight: ${(props) => FONTS.bodySecondary.fontWeight};
  font-size: ${(props) => addPixels(FONTS.bodySecondary.fontSize)};
  line-height: ${(props) => addPixels(FONTS.bodySecondary.lineHeight)};
`;

import { ToastOptions } from "react-toastify";
import { ListItemProps } from "../../ui-components/searchBar";
import { Step } from "react-joyride";
import ShopifyAddTagIcon from "../../icons/shopifyAddTagIcon";
import Box from "../../icons/box";
import Box2 from "../../icons/box2";
import TablerTicketIcon from "../../icons/tablerTicket";
import StarHalfIcon from "../../icons/starHalf";

export const channel = {
  INSTAGRAM: "INSTAGRAM",
  WHATSAPP: "WHATSAPP",
};

export const tourStepsAnalytics: Step[] = [
  {
    target: '#pick-date',
    title: 'Date filter',
    content:
      'Apply the date filter to see node and overall journey analytics (maximum 90 days filter applicable)',
    disableBeacon: true,
    placement: 'left'
  },
  {
    target: '#summary-analytics',
    title: 'Overall analytics',
    content: 'Review overall journey funnel and download the list of customers',
    disableBeacon: true,
    placement: 'left'
  },
  {
    target: '#nla',
    title: 'Node analytics',
    content: 'Review the overall sent, open, click rates on message blocks and trigger/success for system blocks',
    disableBeacon: true,
    placement: 'right'
  },
  {
    target: '#download-nla',
    title: 'Node analytics download',
    content: 'Download the list of customers for whom this node was executed',
    disableBeacon: true,
    placement: 'left'
  },
  {
    target: '#edit',
    title: 'Edit Journey',
    content: 'Click here to edit your journey',
    disableBeacon: true,
    placement: 'bottom'
  },
]

export const tourSteps: Step[] = [
  {
    target: '#node-popup',
    title: 'Journey nodes',
    content: 'A list of all journey nodes that can be added - message, action, condition, link another flow or internal notes',
    disableBeacon: true,
    placement: 'left'
  },

  {
    target: '#trigger-node',
    title: 'Starting point',
    content: 'This is the starting point for any journey. Typically a keyword or user initiated chat flows or an event for business initiated flows',
    disableBeacon: true,
    placement: 'right'
  },

  {
    target: 'div[data-nodeid="N1"]',
    title: 'Node connection',
    content: 'Connect two nodes through this starting point',
    disableBeacon: true,
    placement: 'right'
  },
  {
    target: '#status-toggle',
    title: 'Journey on / off',
    content: 'On switching on the journey, a list of system hygiene checks are also done',
    disableBeacon: true,
    placement: 'bottom'
  },
  {
    target: '#test-mode',
    title: 'Test mode',
    content: 'Journeys can also be made live on Test mode where they are live just for a pre configured set of user ids (phone, email or IG ids)',
    disableBeacon: true,
    placement: 'bottom'
  },
  {
    target: '#save',
    title: 'Save Journey',
    content: 'Click here to save your journey so that your progress is not lost',
    disableBeacon: true,
    placement: 'bottom'
  },
]

export const CURRENCY_SYMBOLS: { [key: string]: string } = {
  USD: '$',
  GBP: '£',
  CAD: 'C$',
  AUD: 'A$',
  DKK: 'DKK',
  SEK: 'SEK',
  EUR: '€',
  BRL: 'R$',
  TRY: '₺',
  JPY: '¥',
  SAR: 'ر.س',
  AED: 'د.إ',
  EGP: 'E£',
  MAD: 'د.م.',
  INR: '₹',
  CHF: 'Fr',
  CNY: '¥',
  NZD: 'NZ$',
  MXN: 'Mex$',
  ZAR: 'R',
  RUB: '₽',
  KRW: '₩',
  SGD: 'S$',
  PKR: 'Rs',
  RO: 'lei',
}


export const BETA_SUBTYPES: string[] = []

export const cancelOptions = [
  {
    label: "Ordered",
    value: "ordered",
    description: "When a customer places an order"
  },
  {
    label: "Abandoned cart created",
    value: "abandonedCartCreated",
    description: "When a customer starts the checkout on the website by adding contact information but doesn't complete the purchase"
  },
  {
    label: "Review created",
    value: "reviewCreated",
    description: "When review was created"
  },
  {
    label: "Payment link update",
    value: "paymentLinkUpdate",
    description: "When payment link was updated"
  },
  {
    label: "Product viewed",
    value: "productViewed",
    description: "When a product is viewed"
  },
  {
    label: "Keyword searched",
    value: "keywordSearched",
    description: "When a customer searches for a keyword"
  },
  {
    label: "Add to cart",
    value: "addToCart",
    description: "When a product is added to cart"
  },
  {
    label: "Abandoned a product",
    value: "abandonedAProduct",
    description: "When a customer adds a particular product to the cart but doesn't complete the purchase"
  },
  {
    label: "Customer created",
    value: "customerCreated",
    description: "When a new customer is created"
  },
  {
    label: "Purchased a product",
    value: "purchasedAProduct",
    description: "When a product is purchased"
  },
  {
    label: "Order delivered",
    value: "orderDelivered",
    description: "When all the products in an order are delivered"
  },
  {
    label: "Line item shipping update",
    value: "lineItemShippingUpdate",
    description: "Single product delivery status"
  },
  {
    label: "Reward disbursed",
    value: "rewardDisbursed",
    description: "When a reward is disbursed for an user via reward based widgets(Spin the wheel, direct reward, scratch card)"
  },
  {
    label: "Customer acquired",
    value: "customerAcquired",
    description: "When a new customer is acquired via Bik widgets(reward based, non reward based, chat widgets or growth tools)"
  },
  {
    label: "Started a conversation",
    value: "conversationStarted",
    description: "When a new message is sent by user via widgets(Chat redirection widgets, QR code, URL generator)"
  },
  {
    label: "Order Cancelled",
    value: "orderCancelled",
    description: "When a particular order is marked as cancelled"
  },
  {
    label: "Product delivered",
    value: "productDelivered",
    description: "When a product is delivered"
  }
]

export const channelDropdown = [
  {
    id: "WHATSAPP",
    header: "Whatsapp",
  },
];

export const isSearchRequiredChecker: any = {
  "Product id": true,
  "Catalog id": false,
  "Order id": false,
};

export const voidFunction = () => { };

// TODo sambit to remove this
export const options = [
  {
    id: "None",
    header: "None",
  },
  {
    id: "QRB",
    header: "Quick Reply Button",
  },
  {
    id: "CTA",
    header: "CTA",
  },
  {
    id: "LIST",
    header: "List Message",
  },
  {
    id: "CAROUSEL",
    header: "Carousel Message",
  },
];

// TODo sambit to remove this
export const actionValueType = [
  {
    id: "none",
    header: "none",
  },
  {
    id: "str",
    header: "str",
  },
  {
    id: "int",
    header: "int",
  },
  {
    id: "float",
    header: "float",
  },
  {
    id: "bool",
    header: "bool",
  },
];

// TODo sambit to remove this
export const headerOptions = [
  {
    id: "None",
    header: "None",
  },
  {
    id: "Text",
    header: "Text",
  },
  {
    id: "Image",
    header: "Image",
  },
  {
    id: "Video",
    header: "Video",
  },
  {
    id: "Document",
    header: "Document",
  },
];

export const websiteUrlType = [
  {
    id: "custom_url",
    header: "Custom Url",
  },
  {
    id: "{{catalog.url}}",
    header: "Catalog Url",
  },
  {
    id: "{{order.url}}",
    header: "Order Url",
  },
  {
    id: "{{cart.cartUrl}}",
    header: "Cart Url",
  },
  {
    id: "{{checkout.checkoutUrl}}",
    header: "Checkout url",
  },
  {
    id: "{{product.url}}",
    header: "Product Url",
  },
  {
    id: "{{store.url}}",
    header: "Home Page Url",
  },
  {
    id: "{{referral.discountLink}}",
    header: "Referral discount url",
  },
  {
    id: "{{product.trackingUrl}}",
    header: "Product Tracking Url",
  },
];

export const ctaOptions = [
  {
    "placeHolder": "{{catalog.url}}",
    "label": "Catalog URL",
    "value": ""
  },
  {
    "placeHolder": "{{order.url}}",
    "label": "Order status page URL",
    "value": ""
  },
  {
    "placeHolder": "{{cart.cartUrl}}",
    "label": "Abandoned checkout URL",
    "value": ""
  },
  {
    "placeHolder": "{{checkout.checkoutUrl}}",
    "label": "Add to cart URL",
    "value": ""
  },
  {
    "placeHolder": "{{product.url}}",
    "label": "Product Url",
    "value": ""
  },
  {
    "placeHolder": "{{store.url}}",
    "label": "Store URL",
    "value": ""
  },
  {
    "label": "Product-level tracking URL",
    "value": "https://www.shopify.com/3",
    "placeHolder": "{{product.trackingUrl}}"
  },
  {
    "value": "custom_link",
    "label": "Custom URL",
    "placeHolder": "custom placeholder"
  }
];



export const urlList: any[] = [
  {
    text: "Product Photo Url",
    id: "{{product.photoUrl}}",
  },
  {
    text: "Catalog Photo Url",
    id: "{{catalog.photoUrl}}",
  },
  {
    text: "Order productImage",
    id: "{{order.productImage}}",
  },
  {
    text: "Abandoned cart imageUrl",
    id: "{{cart.imageUrl}}",
  },
];

export const variableHeadingMapper: { [index: string]: { text: string, isHeading: boolean, blockSubType?: string } } = {
  "store": {
    text: "Store variables",
    isHeading: true,
  },
  "customer": {
    text: "Customer variables",
    isHeading: true,
  },
  "cart": {
    text: "Cart variables",
    isHeading: true,
  },
  "order": {
    text: "Order variables",
    isHeading: true,
  },
  "product": {
    text: "Product variables",
    isHeading: true,
  },
  "payment": {
    text: "Payment variables",
    isHeading: true,
  },
  "review": {
    text: "Review variables",
    isHeading: true,
  },
  "catalog": {
    text: "Catalog variables",
    isHeading: true,
  },
  "checkout": {
    text: "Checkout variables",
    isHeading: true,
  },
  "referral": {
    text: "Referral variables",
    isHeading: true,
  },
  "lastOrder": {
    text: "Last order",
    isHeading: true,
    blockSubType: "evaluate",
  },
  "event_ordered": {
    text: "ordered Event",
    isHeading: true,
  },
  "event_abandonedAProduct": {
    text: "abandonedAProduct Event",
    isHeading: true,
  },
  "event_abandonedCartCreated": {
    text: "abandonedCartCreated Event",
    isHeading: true,
  },
  "event_lineItemShippingUpdate": {
    text: "lineItemShippingUpdate Event",
    isHeading: true,
  },
  "event_customerCreated": {
    text: "customerCreated Event",
    isHeading: true,
  },
  "event_purchasedAProduct": {
    text: "purchasedAProduct Event",
    isHeading: true,
  },
  "event_reviewCreated": {
    text: "reviewCreated Event",
    isHeading: true,
  },
  "event_paymentLinkUpdate": {
    text: "paymentLinkUpdate Event",
    isHeading: true,
  },
  "event_orderDelivered": {
    text: "orderDelivered Event",
    isHeading: true,
  },
  "widgetReward": {
    text: "Widget Reward",
    isHeading: true,
  },
  "flowVariables": {
    text: "Flow variables",
    isHeading: true,
  },
}

export const variableToBeStoredList: ListItemProps[] = [
  {
    text: "Customer variables",
    isHeading: true,
  },
  {
    text: "Customer name",
    id: "{{customer.name}}",
  },
  {
    text: "Customer phone",
    id: "{{customer.phone}}",
  },
  {
    text: "Customer email",
    id: "{{customer.email}}",
  },
  {
    text: "Customer id",
    id: "{{customer.id}}",
  },
  {
    text: "Customer fullAddress",
    id: "{{customer.fullAddress}}",
  },
  {
    text: "Customer cityAddress",
    id: "{{customer.cityAddress}}",
  },
  {
    text: "Customer pincode",
    id: "{{customer.pincode}}",
  },

  {
    text: "Store details",
    isHeading: true,
  },
  {
    text: "Store id",
    id: "{{store.id}}",
  },
  {
    text: "Store url",
    id: "{{store.url}}",
  },
  {
    text: "Store name",
    id: "{{store.name}}",
  },
  {
    text: "Store phone number",
    id: "{{store.phoneNumber}}",
  },
  {
    text: "Store email",
    id: "{{store.email}}",
  },

  {
    text: "Product details",
    isHeading: true,
  },
  {
    text: "Product id",
    id: "{{product.id}}",
  },
  {
    text: "Product Name",
    id: "{{product.name}}",
  },
  {
    text: "Product Price",
    id: "{{product.price}}",
  },
  {
    text: "Product photoUrl",
    id: "{{product.photoUrl}}",
  },
  {
    text: "Product catalogId",
    id: "{{product.catalogId}}",
  },
  {
    text: "Product discountPrice",
    id: "{{product.discountPrice}}",
  },
  {
    text: "Product description",
    id: "{{product.description}}",
  },
  {
    text: "Product url",
    id: "{{product.url}}",
  },

  {
    text: "Catalog Details",
    isHeading: true,
  },
  {
    text: "Catalog id",
    id: "{{catalog.id}}",
  },
  {
    text: "Catalog description",
    id: "{{catalog.description}}",
  },
  {
    text: "Catalog name",
    id: "{{catalog.name}}",
  },
  {
    text: "Catalog photoUrl",
    id: "{{catalog.photoUrl}}",
  },
  {
    text: "Catalog url",
    id: "{{catalog.url}}",
  },

  {
    text: "Order details",
    isHeading: true,
  },
  {
    text: "Order productImage",
    id: "{{order.productImage}}",
  },
  {
    text: "Order productId",
    id: "{{order.productId}}",
  },
  {
    text: "Order productName",
    id: "{{order.productName}}",
  },
  {
    text: "Order allProductNames",
    id: "{{order.allProductNames}}",
  },
  {
    text: "Order catalogId",
    id: "{{order.catalogId}}",
  },
  {
    text: "Order url",
    id: "{{order.url}}",
  },
  {
    text: "Order status",
    id: "{{order.status}}",
  },
  {
    text: "Order itemCount",
    id: "{{order.itemCount}}",
  },
  {
    text: "Order total",
    id: "{{order.total}}",
  },
  {
    text: "Order payment mode",
    id: "{{order.paymentMode}}",
  },
  {
    text: "Order customerName",
    id: "{{order.customerName}}",
  },
  {
    text: "Order Id",
    id: "{{order.id}}",
  },
  {
    text: "Order paymentAmount",
    id: "{{order.paymentAmount}}",
  },
  {
    text: "Order Number",
    id: "{{order.orderNo}}",
  },
  {
    text: "Order Name",
    id: "{{order.name}}",
  },
  {
    text: "Partner Order Id",
    id: "{{order.partnersDataId}}",
  },
  {
    text: "Checkout details",
    isHeading: true,
  },
  {
    text: "Checkout url",
    id: "{{checkout.checkoutUrl}}",
  },
  {
    text: "Checkout items",
    id: "{{checkout.items}}",
  },
  {
    text: "Abandoned Cart (AC)",
    isHeading: true,
  },
  {
    text: "AC imageUrl",
    id: "{{cart.imageUrl}}",
  },
  {
    text: "AC productQuantity",
    id: "{{cart.productQuantity}}",
  },
  {
    text: "AC productName",
    id: "{{cart.productName}}",
  },
  {
    text: "AC productId",
    id: "{{cart.productId}}",
  },
  {
    text: "AC cartUrl",
    id: "{{cart.cartUrl}}",
  },
  {
    text: "AC productPrice",
    id: "{{cart.productPrice}}",
  },
  {
    text: "AC triggerSource",
    id: "{{cart.triggerSource}}",
  },
  {
    text: "AC triggerType",
    id: "{{cart.triggerType}}",
  },
  {
    text: "AC storeId",
    id: "{{cart.storeId}}",
  },
  {
    text: "AC userId",
    id: "{{cart.userId}}",
  },
  {
    text: "AC cartId",
    id: "{{cart.id}}",
  },
  {
    text: "AC converted",
    id: "{{cart.converted}}",
  },
  {
    text: "Review",
    isHeading: true,
  },
  {
    text: "Reviews rating",
    id: "{{reviews.rating}}",
  },
  {
    text: "PaymentLink",
    isHeading: true,
  },
  {
    text: "paymentLink Id",
    id: "{{paymentLink.id}}",
  },
  {
    text: "paymentId",
    id: "{{paymentLink.paymentId}}",
  },
  {
    text: "Payment storeId",
    id: "{{paymentLink.storeId}}",
  },
  {
    text: "paymentLink",
    id: "{{paymentLink.paymentLink}}",
  },
  {
    text: "paymentLinkStatus",
    id: "{{paymentLink.paymentLinkStatus}}",
  },
  {
    text: "paymentAmount",
    id: "{{paymentLink.paymentAmount}}",
  },
  {
    text: "Payment orderId",
    id: "{{paymentLink.orderId}}",
  },
  {
    text: "Payment orderAmount",
    id: "{{paymentLink.orderAmount}}",
  },
  {
    text: "Response",
    isHeading: true,
  },
  {
    text: "User Response",
    id: "{{user.response}}",
  },
  {
    text: "Referral",
    isHeading: true,
  },
  {
    text: "Referral Discount",
    id: "{{referral.discountValue}}",
  },
];

export const buttonIds = [
  {
    id: "Catalog id",
    header: "Catalog id",
  },
  {
    id: "Product id",
    header: "Product id",
  },
  {
    id: "Order id",
    header: "Order id",
  },
];

//Todo sambit to remove this
export const ctaUrlList = [
  {
    id: "None",
    header: "None",
  },
  {
    id: "{{catalog.url}}",
    header: "{{catalog.url}}",
  },
  {
    id: "{{product.url}}",
    header: "{{product.url}}",
  },
  {
    id: "{{order.url}}",
    header: "{{order.url}}",
  },
  {
    id: "{{store.url}}",
    header: "{{store.url}}",
  },
  {
    id: "{{cart.cartUrl}}",
    header: "{{cart.cartUrl}}",
  },
  {
    id: "{{checkout.checkoutUrl}}",
    header: "{{checkout.checkoutUrl}}",
  },
  {
    id: "{{product.trackingUrl}}",
    header: "{{product.trackingUrl}}",
  },
];

export const validationOptions = [
  {
    id: "Number",
    header: "Number",
  },
  {
    id: "String",
    header: "String",
  },
  {
    id: "Email",
    header: "Email",
  },
  {
    id: "PhoneNumber",
    header: "PhoneNumber",
  },
];

export const countryList = [
  {
    id: "+91",
    header: "IN +91",
  },
];

export const ApiSamplePayloads = {
  createCustomer: {
    storeId: "aT6yUYmMIHUkeD8HePfvQV5Tury2",
    partner: "SHOPIFY",
    code: "createCustomer",
    payload: {
      firstName: "parth",
      lastName: "bhard",
      email: "testing26@bikayi.com",
      phone: 8812253517,
    },
  },
  searchCustomers: {
    storeId: "aT6yUYmMIHUkeD8HePfvQV5Tury2",
    partner: "SHOPIFY",
    code: "searchCustomers",
    payload: {
      query: "parth",
    },
  },
  fetchCustomerById: {
    storeId: "aT6yUYmMIHUkeD8HePfvQV5Tury2",
    partner: "SHOPIFY",
    code: "fetchCustomerById",
    payload: {
      customerId: "6272268566691",
    },
  },
  fetchProductById: {
    storeId: "aT6yUYmMIHUkeD8HePfvQV5Tury2",
    partner: "SHOPIFY",
    code: "fetchProductById",
    payload: {
      productId: "7805160390819",
    },
  },
  fetchSimilarProductsForProduct: {
    storeId: "aT6yUYmMIHUkeD8HePfvQV5Tury2",
    partner: "SHOPIFY",
    code: "fetchSimilarProductsForProduct",
    payload: {
      productId: "7805160390819",
    },
  },
  fetchUpSellsForProduct: {
    storeId: "aT6yUYmMIHUkeD8HePfvQV5Tury2",
    partner: "SHOPIFY",
    code: "fetchUpSellsForProduct",
    payload: {
      productId: "7805160390819",
    },
  },
  fetchProducts: {
    storeId: "aT6yUYmMIHUkeD8HePfvQV5Tury2",
    partner: "SHOPIFY",
    code: "fetchProducts",
  },
  fetchOrderById: {
    storeId: "aT6yUYmMIHUkeD8HePfvQV5Tury2",
    partner: "SHOPIFY",
    code: "fetchOrderById",
    payload: {
      orderId: "4630312714403",
    },
  },
  fetchOrdersForCustomer: {
    storeId: "aT6yUYmMIHUkeD8HePfvQV5Tury2",
    partner: "SHOPIFY",
    code: "fetchOrdersForCustomer",
    payload: {
      customerId: "6046719213731",
    },
  },
  fetchPromoCodesForCollection: {
    storeId: "aT6yUYmMIHUkeD8HePfvQV5Tury2",
    partner: "SHOPIFY",
    code: "fetchPromoCodesForCollection",
    payload: {
      collectionId: "4584860942499",
    },
  },
};

export const sampleResponses = {
  createCustomer: {
    customer: {
      enquiryCount: 0,
      orderCount: 0,
      viewCount: 0,
      totalOrderAmount: 0,
      allowAccess: false,
      showB2BPricing: false,
      whatsappUtil: {},
      userId: 6376885977251,
      storeId: "aT6yUYmMIHUkeD8HePfvQV5Tury2",
      phone: "8812253417",
      name: "parth bhard",
      firstName: "parth",
      lastName: "bhard",
      email: "testing26@bikayii.com",
      note: null,
      tags: "",
      currency: "INR",
      address: {
        address: " undefined",
        city: "",
        state: "",
        pinCode: "",
        country: "",
        name: " undefined",
        phoneNumber: "",
      },
      partnersData: {
        id: 6376885977251,
        source: "shopify",
        acceptsMarketing: true,
        state: "disabled",
      },
      optedOutMarketing: true,
      dateOfCreation: "2022-10-13T09:44:27.000Z",
      id: 6376885977251,
    },
  },
  createPaymentLink: {
    paymentLinkId: "plink_KW3XnIqc0D8acc",
    paymentLink: "https://rzp.io/i/jJLTIiRVi",
    paymentAmount: 199,
    orderId: "4630312714403",
    orderAmount: 299,
  },
  createOrder: {
    order: {
      uid: 1123,
      items: [
        {
          catalogId: 0,
          productId: 7913545695449,
          variantId: 43415286022361,
          name: "CA Desk Plate",
          price: "1099.00",
          quantity: 1,
          colorVariant: null,
          typeVariant: null,
          offlineEnabled: false,
          image:
            "https://cdn.shopify.com/s/files/1/0661/7225/6473/products/ca-deskplate.jpg?v=1664349276",
          mrp: "1099.00",
          shippingRate: 0,
          skuId: "cadeskplate",
        },
      ],
      id: 4972177850585,
      currency: "INR",
      checkoutToken: null,
      status: "ORDERED",
      customerName: "Tanmay Maheshwari",
      notes: "test note",
      tags: "test_tag",
      paymentStatus: "paid",
      customerPin: "K2P 1L4",
      total: 1099,
      tax: 0,
      shippingCharge: 0,
      discount: null,
      storeId: "OeeDDiiwGDXkoAWs2hroxSgY2ny1",
      paymentAmount: 109900,
      customerAddress: "123 Fake Street,Fakecity,Ontario,K2P 1L4",
      phoneNumber: "+918407931834",
      address: {
        first_name: "Jane",
        address1: "123 Fake Street",
        phone: "777-777-7777",
        city: "Fakecity",
        zip: "K2P 1L4",
        province: "Ontario",
        country: "Canada",
        last_name: "Smith",
        address2: null,
        company: null,
        latitude: 45.4205513,
        longitude: -75.6929455,
        name: "Jane Smith",
        country_code: "CA",
        province_code: "ON",
      },
      timestamp: "2022-1109T1819:1505:30",
      deliveryInfo: {
        trackingURL:
          "https://shanya-testing.myshopify.com/66172256473/orders/a870d732f428eba70ee5f556b1bb6715/authenticate?key=cdb69ccdddf7d4ff80528bd608fc8719",
      },
      isReviewLinkSent: false,
      paymentBreakdown: {
        onlinePayment: 1099,
        codPayment: 0,
        offlinePayment: 0,
        walletCredits: 0,
        loyaltyPoints: 0,
        walletPoints: 0,
      },
      partnersData: {
        source: "shopify",
        id: 4972177850585,
        customerId: 6477723271385,
      },
    },
  },
};

export const storeSpecificApis: { [key: string]: ListItemProps[] } = {
  'DDQoj5ZVYwYd8aEtdFP5NRvL2Mk2': [{
    text: "Livspace",
    isHeading: true,
  },
  {
    text: "Create Affiliate Lead",
    id: "createAffiliateLead",
    partner: "LIVSPACE",
  },
  {
    text: "Create Lead Qanvast",
    id: "createLeadQanvast",
    partner: "LIVSPACE",
  }]
}

const orderPaths = [
  'order.id',
  'order.storeId',
  'order.customerId',
  'order.name',
  'order.phoneNumber',
  'order.total',
  'order.subTotal',
  'order.totalTax',
  'order.discount',
  'order.paymentMode',
  'order.shippingCharge',
  'order.status',
  'order.tags',
  'order.deliveryInfo.trackingURL',
  'order.address.address1',
  'order.address.address2',
  'order.address.city',
  'order.address.country',
  'order.address.state',
  'order.partnersData.id',
  'order.fulfillmentStatus',
  'order.billingAddress.address1',
  'order.billingAddress.address2',
  'order.billingAddress.city',
  'order.billingAddress.country',
  'order.billingAddress.phoneNumber'
]
export const responsePaths: { [key: string]: string[] } = {
  'cancelOrder': orderPaths,
  'completeDraftOrder': orderPaths,
  'updateShippingAddressForOrder': orderPaths,
  'fetchOrderByName': orderPaths,
  'fetchOrderById': orderPaths,
  'updateOrderTagsForDraftOrder': orderPaths,
}

export function getApis(storeId: string) {
  if (storeSpecificApis?.[storeId]) {
    return [...ApiName, ...storeSpecificApis[storeId]]
  }
  return ApiName
}


export function getDropdownApis(storeId: string) {
  if (storeSpecificApiOptions?.[storeId]) {
    return [...ApiDropdownOptions, ...storeSpecificApiOptions[storeId]]
  }
  return ApiDropdownOptions
}

const livspaceApis = [
  {
    label: "Livspace",
    options: [
      {
        label: "Create Affiliate Lead",
        value: "createAffiliateLead",
        partner: "LIVSPACE",
        groupId: "livspace"
      },
      {
        label: "Create Lead Qanvast",
        value: "createLeadQanvast",
        partner: "LIVSPACE",
        groupId: "livspace"
      }
    ]
  }
]

export const storeSpecificApiOptions: { [key: string]: any } = {
  'DDQoj5ZVYwYd8aEtdFP5NRvL2Mk2': livspaceApis,
  '3ICzE2W89GW2RUuUV0t1NsNgUJH3': livspaceApis,
  'YRvGEcthQnWFkN5IGnwJkSD9bob2': livspaceApis,
  'qRJPWmzSm9elWPMQwTi28E6wgjs2': livspaceApis
}

export const ApiDropdownOptions = [
  {
    label: "Products",
    leadingIcon: <Box width={16} height={16} color="#616161" />,
    options: [
      {
        label: "Fetch Product By Id",
        value: "fetchProductById",
        partner: "SHOPIFY",
        groupId: "products"
      },
      {
        label: "Fetch Similar Products For Product",
        value: "fetchSimilarProductsForProduct",
        partner: "SHOPIFY",
        groupId: "products"
      },
      {
        label: "Fetch Upsells For Product",
        value: "fetchUpSellsForProduct",
        partner: "SHOPIFY",
        groupId: "products"
      },

    ]
  },
  {
    label: "Orders",
    leadingIcon: <Box2 />,
    options: [
      {
        label: "Fetch Order By Id",
        value: "fetchOrderById",
        partner: "SHOPIFY",
        groupId: "orders"
      },
      {
        partner: "SHOPIFY",
        value: "applyDiscountOnDraftOrder",
        label: "Apply Discount On Draft Order",
        groupId: "orders"
      },
      {
        partner: "SHOPIFY",
        value: "markOrderAsPaid",
        label: "Mark Order As Paid",
        groupId: "orders"
      },
      {
        value: "fetchOrdersForCustomer",
        label: "Fetch Orders For Customer",
        partner: "SHOPIFY",
        groupId: "orders"
      },
      {
        value: "cancelOrder",
        label: "Cancel Order",
        partner: "SHOPIFY",
        groupId: "orders"
      },
      {
        value: "createDraftOrder",
        label: "Create Draft Order",
        partner: "SHOPIFY",
        groupId: "orders"
      },
      {
        label: "Complete Draft Order",
        partner: "SHOPIFY",
        value: "completeDraftOrder",
        groupId: "orders"
      },
      {
        label: "Update Shipping Address For Order",
        partner: "SHOPIFY",
        value: "updateShippingAddressForOrder",
        groupId: "orders"
      },
      {
        label: "Update Shipping Address For Draft Order",
        partner: "SHOPIFY",
        value: "updateShippingAddressForDraftOrder",
        groupId: "orders"
      },
      {
        label: "Apply Shipping On Draft Order",
        partner: "SHOPIFY",
        value: "applyShippingOnDraftOrder",
        groupId: "orders"
      },
      {
        label: "Fetch Order By Name",
        partner: "SHOPIFY",
        value: "fetchOrderByName",
        groupId: "orders"
      }, {
        label: "Create Draft Order From Order Id",
        value: "createDraftOrderFromOrderId",
        partner: "SHOPIFY",
        groupId: "orders"
      },
      {
        partner: "SHOPIFY",
        value: "updateTagsForDraftOrder",
        label: "Update order tags for draft order",
        groupId: "orders"
      },
    ]
  },
  {
    label: 'Promocodes',
    leadingIcon: <TablerTicketIcon />,
    options: [
      {
        label: "Fetch Promocodes",
        value: "fetchPromoCodesForCollection",
        partner: "SHOPIFY",
        groupId: "promocodes"
      },
      {
        partner: "SHOPIFY",
        value: "createDiscountCode",
        label: "Create Discount Code",
        groupId: "promocodes"
      },
      {
        partner: "SHOPIFY",
        value: "createPriceRule",
        label: "Create Price Rule",
        groupId: "promocodes"
      },
    ]
  },
  {
    label: "Review",
    leadingIcon: <StarHalfIcon />,
    options: [
      {
        label: "Create Review",
        value: "createReview",
        partner: "JUDGEME",
        groupId: "review"
      }
    ]
  },
  {
    label: "Unicommerce",
    options: [
      {
        label: "Cancel Sale Order",
        value: "cancelSaleOrder",
        partner: "UNICOMMERCE",
        groupId: "unicommerce"
      },
      {
        label: "Verify Sale Order",
        value: "verifySaleOrder",
        partner: "UNICOMMERCE",
        groupId: "unicommerce"
      },
      {
        label: "Search Sale Order",
        value: "searchSaleOrder",
        partner: "UNICOMMERCE",
        groupId: "unicommerce"
      },
    ]
  },
  {
    label: "Shopflo",
    options: [
      {
        label: "Create ShopFlo Payment Link",
        value: "createShopFloPaymentLink",
        partner: "SHOPFLO",
        groupId: "shopflo"
      },
    ]
  },
  {
    label: "Nector",
    options: [
      {
        label: "Get User",
        value: "getUser",
        partner: "NECTOR",
        groupId: "nector"
      }
    ]
  }
]

export const ApiName: ListItemProps[] = [
  {
    text: "Products",
    isHeading: true,
  },
  {
    id: "fetchProductById",
    text: "Fetch Product By Id",
    partner: "SHOPIFY",
  },
  {
    id: "fetchSimilarProductsForProduct",
    text: "Fetch Similar Products For Product",
    partner: "SHOPIFY",
  },
  {
    id: "fetchUpSellsForProduct",
    text: "Fetch Upsells For Product",
    partner: "SHOPIFY",
  },
  {
    text: "Orders",
    isHeading: true,
  },
  {
    id: "fetchOrderById",
    text: "Fetch Order By Id",
    partner: "SHOPIFY",
  },
  {
    partner: "SHOPIFY",
    id: "applyDiscountOnDraftOrder",
    text: "Apply Discount On Draft Order",
  },
  {
    partner: "SHOPIFY",
    id: "markOrderAsPaid",
    text: "Mark Order As Paid",
  },
  {
    id: "fetchOrdersForCustomer",
    text: "Fetch Orders For Customer",
    partner: "SHOPIFY",
  },
  {
    id: "markOrderAsPaid",
    text: "Mark Order as paid in shopify",
    partner: "SHOPIFY"
  },
  {
    id: "cancelOrder",
    text: "Cancel Order",
    partner: "SHOPIFY",
  },
  {
    id: "createDraftOrder",
    text: "Create Draft Order",
    partner: "SHOPIFY",
  },
  {
    text: "Complete Draft Order",
    partner: "SHOPIFY",
    id: "completeDraftOrder",
  },
  {
    text: "Update Shipping Address For Order",
    partner: "SHOPIFY",
    id: "updateShippingAddressForOrder",
  },
  {
    text: "Update Shipping Address For Draft Order",
    partner: "SHOPIFY",
    id: "updateShippingAddressForDraftOrder",
  },
  {
    text: "Apply Shipping On Draft Order",
    partner: "SHOPIFY",
    id: "applyShippingOnDraftOrder",
  },
  {
    text: "Fetch Order By Name",
    partner: "SHOPIFY",
    id: "fetchOrderByName",
  }, {
    text: "Create Draft Order From Order Id",
    id: "createDraftOrderFromOrderId",
    partner: "SHOPIFY"
  },
  {
    partner: "SHOPIFY",
    id: "updateTagsForDraftOrder",
    text: "Update order tags for draft order",
  },
  {
    text: "Promocodes",
    isHeading: true,
  },
  {
    text: "Fetch Promocodes",
    id: "fetchPromoCodesForCollection",
    partner: "SHOPIFY",
  },
  {
    partner: "SHOPIFY",
    id: "createDiscountCode",
    text: "Create Discount Code",
  },
  {
    partner: "SHOPIFY",
    id: "createPriceRule",
    text: "Create Price Rule",
  },
  {
    text: "REVIEW",
    isHeading: true,
  },
  {
    text: "Create Review",
    id: "createReview",
    partner: "JUDGEME",
  },
  {
    text: "Unicommerce",
    isHeading: true,
  },
  {
    text: "Cancel Sale Order",
    id: "cancelSaleOrder",
    partner: "UNICOMMERCE",
  },
  {
    text: "Verify Sale Order",
    id: "verifySaleOrder",
    partner: "UNICOMMERCE",
  },
  {
    text: "Search Sale Order",
    id: "searchSaleOrder",
    partner: "UNICOMMERCE",
  },
  {
    text: "SHOPFLO",
    isHeading: true,
  },
  {
    text: "Create ShopFlo Payment Link",
    id: "createShopFloPaymentLink",
    partner: "SHOPFLO",
  },
  {
    text: "NECTOR",
    isHeading: true,
  },
  {
    text: "Get User",
    id: "getUser",
    partner: "NECTOR",
  }
];

export const ActionType: ListItemProps[] = [
  {
    id: "get",
    text: "GET",
  },
  {
    id: "post",
    text: "POST",
  },
  {
    id: "put",
    text: "PUT",
  },
  {
    id: "patch",
    text: "PATCH",
  },

  {
    id: "delete",
    text: "DELETE",
  },
];

export const BodyType: ListItemProps[] = [
  {
    id: "form",
    text: "FORM",
  },
  {
    id: "json",
    text: "JSON",
  },
  {
    id: "raw",
    text: "RAW",
  },
];

export const EXCLUSION_PRODUCT_BLOCKS_START = [
  "{{##orderProducts}}",
  "{{##abandonedCartProducts}}",
  "{{##mostViewed}}",
  "{{##bestSelling}}",
  "{{##newest}}",
  "{{##similar}}",
  "{{##abandonedCartProductsV2}}",
  "{{##crossSelling}}",
  "{{##single}}",
  "{{##ordered}}",
];

export const EXCLUSION_PRODUCT_BLOCKS_END = [
  "{{//orderProducts}}",
  "{{//abandonedCartProducts}}",
  "{{//mostViewed}}",
  "{{//bestSelling}}",
  "{{//newest}}",
  "{{//similar}}",
  "{{//abandonedCartProductsV2}}",
  "{{//crossSelling}}",
  "{{//single}}",
  "{{//ordered}}"
];

export const INCLUSION_PRODUCT_BLOCKS = ["{{##single}}"];
import { Icon } from "./icons-props";

const CloudOfflineIcon: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "19"}
      height={height || "20"}
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.87273 4.05993C7.61271 3.60733 8.49621 3.33301 9.50033 3.33301C12.1527 3.33301 14.3899 5.24698 14.9453 8.29018C15.595 8.41624 16.2685 8.68429 16.8388 9.13185C17.6306 9.75328 18.2087 10.7161 18.2087 12.0205C18.2087 13.2533 17.7609 14.2342 16.9851 14.8942C16.645 15.1835 16.1466 15.1278 15.8717 14.7699C15.5969 14.4119 15.6498 13.8873 15.9898 13.598C16.3632 13.2803 16.6253 12.7926 16.6253 12.0205C16.6253 11.2998 16.3293 10.8109 15.8924 10.468C15.4295 10.1047 14.7983 9.90504 14.2102 9.8736C13.8198 9.85273 13.5023 9.53509 13.4627 9.12576C13.2078 6.49092 11.4751 4.99967 9.50033 4.99967C8.77861 4.99967 8.16916 5.19458 7.6682 5.50098C7.29016 5.73221 6.80563 5.59706 6.58596 5.19913C6.3663 4.80119 6.49469 4.29115 6.87273 4.05993ZM4.89996 7.40332C5.03557 7.84086 4.80855 8.31127 4.39288 8.45402C3.18671 8.86825 2.37533 9.78474 2.37533 11.208C2.37533 12.8897 3.70877 14.1663 5.54199 14.1663H11.6236C12.0609 14.1663 12.4153 14.5394 12.4153 14.9997C12.4153 15.4599 12.0609 15.833 11.6236 15.833H5.54199C3.02105 15.833 0.791992 13.9951 0.791992 11.208C0.791992 8.91408 2.19002 7.4574 3.9018 6.86955C4.31746 6.7268 4.76436 6.96578 4.89996 7.40332Z"
        fill={color || "#616161"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.60687 2.74408C2.91604 2.41864 3.41729 2.41864 3.72646 2.74408L10.0598 9.41074L16.3931 16.0774C16.7023 16.4028 16.7023 16.9305 16.3931 17.2559C16.084 17.5814 15.5827 17.5814 15.2735 17.2559L8.94021 10.5893L2.60687 3.92259C2.29771 3.59715 2.29771 3.06951 2.60687 2.74408Z"
        fill={color || "#616161"}
      />
    </svg>
  );
};

export default CloudOfflineIcon;

import { Icon } from "./icons-props";

const ShopifyDiscountCodeIcon: Icon = (props) => {
  const { width, height } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_131_1589)">
        <path
          d="M14.1607 3.53449C14.148 3.44449 14.067 3.39468 14.0001 3.38919C13.5069 3.35309 13.0137 3.31728 12.5204 3.28175C12.5204 3.28175 11.5391 2.33274 11.4314 2.2277C11.3237 2.12272 11.1132 2.15465 11.0315 2.17808C11.0195 2.18153 10.8171 2.24237 10.4823 2.34328C10.1545 1.42442 9.57602 0.580023 8.55827 0.580023C8.53017 0.580023 8.50125 0.581132 8.47233 0.582735C8.18288 0.209852 7.82433 0.0478516 7.51463 0.0478516C5.1438 0.0478516 4.01113 2.93494 3.65599 4.40206C2.73474 4.68014 2.08029 4.87783 1.99669 4.90341C1.48247 5.06054 1.4662 5.07632 1.39868 5.54833C1.34787 5.90568 0.00238037 16.0417 0.00238037 16.0417L10.4867 17.9553L16.1675 16.7581C16.1675 16.7581 14.1733 3.62449 14.1608 3.53449H14.1607ZM9.90287 2.51786L9.01573 2.78533C9.01605 2.72443 9.01636 2.66451 9.01636 2.59898C9.01636 2.02791 8.93498 1.56811 8.80443 1.20361C9.32885 1.26772 9.6781 1.84896 9.90287 2.51786ZM8.1539 1.31685C8.2997 1.67272 8.3945 2.18344 8.3945 2.87262C8.3945 2.90788 8.39418 2.94012 8.39386 2.97273C7.81693 3.14681 7.19 3.33581 6.56168 3.52543C6.91447 2.1991 7.57576 1.5585 8.1539 1.31685ZM7.44952 0.66731C7.55184 0.66731 7.65493 0.701153 7.75358 0.767297C6.99376 1.11558 6.17933 1.99278 5.8354 3.74451L4.38714 4.18144C4.78999 2.84531 5.74655 0.66731 7.44945 0.66731H7.44952Z"
          fill="#95BF46"
        />
        <path
          d="M14.0001 3.3894C13.5069 3.35329 13.0137 3.31748 12.5204 3.28195C12.5204 3.28195 11.5391 2.33294 11.4314 2.2279C11.3911 2.18882 11.3368 2.16879 11.2799 2.16016L10.4872 17.9553L16.1675 16.7583C16.1675 16.7583 14.1732 3.62469 14.1607 3.53469C14.148 3.44469 14.067 3.39488 14.0001 3.3894Z"
          fill="#5E8E3E"
        />
        <path
          d="M8.55827 6.4471L7.85781 8.47678C7.85781 8.47678 7.24411 8.15772 6.49182 8.15772C5.38895 8.15772 5.33345 8.83191 5.33345 9.0018C5.33345 9.9288 7.81408 10.284 7.81408 12.4553C7.81408 14.1637 6.70179 15.2637 5.20202 15.2637C3.4023 15.2637 2.48193 14.1726 2.48193 14.1726L2.96382 12.6217C2.96382 12.6217 3.90988 13.4129 4.70817 13.4129C5.2298 13.4129 5.44198 13.0128 5.44198 12.7205C5.44198 11.5113 3.40685 11.4573 3.40685 9.47036C3.40685 7.79802 4.63907 6.17969 7.1264 6.17969C8.0848 6.17969 8.55827 6.4471 8.55827 6.4471Z"
          fill="white"
        />
      </g>
      <rect x="8" y="8" width="16" height="16" rx="8" fill="white" />
      <path
        d="M13.144 13.1426H13.1554V13.154H13.144V13.1426ZM18.8584 18.8571H18.8698V18.8685H18.8584V18.8571Z"
        stroke="#008060"
        stroke-width="3"
        stroke-linejoin="round"
      />
      <path
        d="M19.4298 12.5723L12.5725 19.4297"
        stroke="#008060"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <clipPath id="clip0_131_1589">
          <rect width="16.2" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShopifyDiscountCodeIcon;

import { Icon } from "./icons-props";

const Reply: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M8.77778 7.53333V8.39818L9.63359 8.52288C13.2868 9.05522 15.845 10.6371 17.6299 12.7178C18.5266 13.7631 19.2385 14.9469 19.7931 16.208C17.1544 14.1667 13.8585 13.22 9.77778 13.22H8.77778V14.22V16.4182L3.40042 10.9333L8.77778 5.44843V7.53333Z"
        stroke={color || "#FF0000"}
        stroke-width="2"
      />
    </svg>
  );
};

export default Reply;

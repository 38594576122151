import {
  COLORS,
  IconButton,
  TitleSmall,
  Tooltip,
} from "@bikdotai/bik-component-library";
import {
  MaxDelayInputComponent,
  MaxDelayInputComponentProps,
} from "./sub-components/MaxDelayInputComponent";
import { AddPastProfileModalWrapper } from "./sub-components/AddPastProfileModalWrapper";
import InfoIcon from "../../icons/infoIcon";

export type ZeroDelayModalPropsType = MaxDelayInputComponentProps & {
  open: boolean;
  formattedFlowEnabledDate: string;
  formattedLastProfileAdditionDate: string;
  onClick: () => void;
  onClose: () => void;
};

/**
 * When there is a zero delay and user hasn't added past profiles before, we will show this modal.
 * This modal has input component where user can enter any number of days upto the MAX_LIMIT.
 * Uses AddPastProfileModalWrapper for StyledModal
 */
export const ZeroDelayModal = (props: ZeroDelayModalPropsType) => {
  const {
    open,
    onClick,
    onClose,
    formattedFlowEnabledDate,
    formattedLastProfileAdditionDate,
  } = props;

  return (
    <AddPastProfileModalWrapper open={open} onClick={onClick} onClose={onClose}>
      <div
        style={{
          display: "flex",
          padding: "10px",
          marginLeft: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {formattedLastProfileAdditionDate ? (
              <TitleSmall>
                No. of days for which profiles get added before last profile
                addition on {formattedLastProfileAdditionDate}
              </TitleSmall>
            ) : (
              <TitleSmall>
                No. of days for which profiles get added before Journey became
                active on {formattedFlowEnabledDate}
              </TitleSmall>
            )}

            <Tooltip
              body="All Profiles will get added to the start of the journey"
              placement="bottom"
            >
              <IconButton
                style={{
                  display: "flex",
                  marginRight: "20px",
                  marginLeft: "2px",
                }}
                Icon={() => (
                  <InfoIcon
                    width={16}
                    height={16}
                    color={COLORS.content.placeholder}
                  />
                )}
              />
            </Tooltip>
          </div>

          <MaxDelayInputComponent
            inputValue={props.inputValue}
            setInputValue={props.setInputValue}
            inputState={props.inputState}
          />
        </div>
      </div>
    </AddPastProfileModalWrapper>
  );
};

import { Icon } from "./icons-props";

const ProductTourIcon: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2359 8.24286C14.6447 7.98048 14.7631 7.4369 14.5002 7.02875C14.2374 6.6206 13.693 6.50244 13.2841 6.76482L11.2391 8.0773L10.9471 7.68374C10.6578 7.29385 10.1067 7.2119 9.7162 7.50071C9.32566 7.78953 9.24358 8.33973 9.53287 8.72962L10.3151 9.78389C10.5909 10.1557 11.1083 10.2502 11.4981 9.99997L14.2359 8.24286Z"
        fill={color || "black"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.08 5.74673H6.32805C7.2654 3.54442 9.45205 2 12 2C14.548 2 16.7346 3.54442 17.6719 5.74673H19.92C20.892 5.74673 21.68 6.53341 21.68 7.50384V17.1679C22.409 17.1679 23 17.758 23 18.4858V19.3643C23 20.82 21.818 22 20.36 22H3.64C2.18197 22 1 20.82 1 19.3643V18.4858C1 17.758 1.59098 17.1679 2.32 17.1679V7.50384C2.32 6.53341 3.10798 5.74673 4.08 5.74673ZM12 3.75711C9.56995 3.75711 7.6 5.72382 7.6 8.14989C7.6 10.1955 9.00141 11.9166 10.8993 12.4043L11.2845 12.5033L12 13.9098L12.7155 12.5033L13.1007 12.4043C14.9986 11.9166 16.4 10.1955 16.4 8.14989C16.4 5.72382 14.4301 3.75711 12 3.75711ZM5.84 8.14989C5.84 7.9317 5.85138 7.71616 5.87358 7.50384H4.08V17.1679H19.92V7.50384H18.1264C18.1486 7.71616 18.16 7.9317 18.16 8.14989C18.16 10.8732 16.3877 13.1813 13.9328 13.9907L13.1769 15.4767C12.6878 16.4382 11.3122 16.4382 10.8231 15.4767L10.0672 13.9907C7.61235 13.1813 5.84 10.8732 5.84 8.14989ZM15.4289 18.9251C15.4882 19.1345 15.52 19.3555 15.52 19.584C15.52 19.8124 15.4882 20.0334 15.4289 20.2429H20.36C20.846 20.2429 21.24 19.8495 21.24 19.3643V18.9251H15.4289ZM10.9 18.9251C10.5355 18.9251 10.24 19.2201 10.24 19.584C10.24 19.9479 10.5355 20.2429 10.9 20.2429H13.1C13.4645 20.2429 13.76 19.9479 13.76 19.584C13.76 19.2201 13.4645 18.9251 13.1 18.9251H10.9ZM8.57109 18.9251H2.76V19.3643C2.76 19.8495 3.15399 20.2429 3.64 20.2429H8.57109C8.51175 20.0334 8.48 19.8124 8.48 19.584C8.48 19.3555 8.51175 19.1345 8.57109 18.9251Z"
        fill={color || "black"}
      />
    </svg>
  );
};

export default ProductTourIcon;

const BulbIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white" />
      <g clip-path="url(#clip0_1952_55462)">
        <path
          d="M27.5003 20.0013H28.3337M25.417 14.5846L26.2503 13.7513M20.0003 12.5013V11.668M14.5837 14.5846L13.7503 13.7513M12.5003 20.0013H11.667M18.3337 28.3346H21.667M24.167 20.0013C24.1668 19.2501 23.9635 18.513 23.5787 17.8679C23.1939 17.2228 22.6418 16.6937 21.981 16.3367C21.3201 15.9796 20.575 15.8078 19.8245 15.8395C19.074 15.8712 18.3461 16.1052 17.7177 16.5167C17.0892 16.9282 16.5838 17.502 16.2547 18.1772C15.9257 18.8525 15.7853 19.6041 15.8484 20.3526C15.9115 21.1011 16.1758 21.8186 16.6133 22.4293C17.0508 23.0399 17.6452 23.5208 18.3337 23.8213V25.8346H21.667V23.8213C22.4102 23.4968 23.0425 22.9626 23.4866 22.2841C23.9307 21.6056 24.1671 20.8122 24.167 20.0013V20.0013Z"
          stroke="#616161"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#E0E0E0" />
      <defs>
        <clipPath id="clip0_1952_55462">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(10 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BulbIcon;

import { Icon } from "./icons-props";

const List: Icon = (props) => {
    const { width, height, color } = props;
    return (
        <svg width={width || "20"} height={height || "20"} viewBox="0 0 20 20" fill={color || '#000000'} xmlns="http://www.w3.org/2000/svg">
            <path d="M2.49984 5.83329C2.96007 5.83329 3.33317 5.4602 3.33317 4.99996C3.33317 4.53972 2.96007 4.16663 2.49984 4.16663C2.0396 4.16663 1.6665 4.53972 1.6665 4.99996C1.6665 5.4602 2.0396 5.83329 2.49984 5.83329Z"/>
            <path d="M6.6665 4.16663C6.20627 4.16663 5.83317 4.53972 5.83317 4.99996C5.83317 5.4602 6.20627 5.83329 6.6665 5.83329H17.4998C17.9601 5.83329 18.3332 5.4602 18.3332 4.99996C18.3332 4.53972 17.9601 4.16663 17.4998 4.16663H6.6665Z"/>
            <path d="M6.6665 9.16663C6.20627 9.16663 5.83317 9.53972 5.83317 9.99996C5.83317 10.4602 6.20627 10.8333 6.6665 10.8333H17.4998C17.9601 10.8333 18.3332 10.4602 18.3332 9.99996C18.3332 9.53972 17.9601 9.16663 17.4998 9.16663H6.6665Z"/>
            <path d="M5.83317 15C5.83317 14.5397 6.20627 14.1666 6.6665 14.1666H17.4998C17.9601 14.1666 18.3332 14.5397 18.3332 15C18.3332 15.4602 17.9601 15.8333 17.4998 15.8333H6.6665C6.20627 15.8333 5.83317 15.4602 5.83317 15Z"/>
            <path d="M3.33317 9.99996C3.33317 10.4602 2.96007 10.8333 2.49984 10.8333C2.0396 10.8333 1.6665 10.4602 1.6665 9.99996C1.6665 9.53972 2.0396 9.16663 2.49984 9.16663C2.96007 9.16663 3.33317 9.53972 3.33317 9.99996Z"/>
            <path d="M2.49984 15.8333C2.96007 15.8333 3.33317 15.4602 3.33317 15C3.33317 14.5397 2.96007 14.1666 2.49984 14.1666C2.0396 14.1666 1.6665 14.5397 1.6665 15C1.6665 15.4602 2.0396 15.8333 2.49984 15.8333Z"/>
        </svg>
    );
}

export default List;
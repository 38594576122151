import {
  COLORS,
  BodyCaption,
  DropdownPopover,
  TitleSmall,
  DropdownOptions,
} from "@bikdotai/bik-component-library";
import InfoIcon from "../../icons/infoIcon";
import ChevronDownIcon from "../../icons/chevron_down";
import WhatsappIcon from "../../icons/whatsappIcon";
import { DropdownContainer, DropdownWrapper } from "../webhook/styles";
import EmailIcon from "../../icons/email";
import { DropdownOption } from "@bikdotai/bik-component-library/dist/esm/components/dropdown/type";

export const ErrorLine = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 4,
      }}
    >
      <InfoIcon width={16} height={16} color={COLORS.content.negative} />
      <BodyCaption color={COLORS.content.negative}>Required Field</BodyCaption>
    </div>
  );
};

export const ChannelDropdown = (props: {
  error: boolean;
  setSelectedChannel: Function;
  isLoading: boolean;
  channelIdOptions: {
    label: string;
    value: string;
    selected: boolean;
  }[];
  setChannelIdOptions: Function;
  selectedChannel: any;
  channel: string;
}) => {
  return (
    <DropdownContainer error={props.error}>
      <DropdownPopover
        width="200px"
        disabled={props.isLoading}
        onSelect={(c) => {
          props.setSelectedChannel(c);
          const modified = props.channelIdOptions.map((d) => {
            if (d.value === (c as any).value) {
              return {
                label: d.label,
                value: d.value,
                selected: true,
              };
            } else {
              return {
                label: d.label,
                value: d.value,
                selected: false,
              };
            }
          });
          props.setChannelIdOptions(modified);
        }}
        options={props.channelIdOptions}
      >
        <DropdownWrapper>
          {props.channel === "whatsapp" ? <WhatsappIcon /> : <EmailIcon />}
          <TitleSmall
            className="dropdown-text"
            style={{
              fontWeight: 400,
              color: props.error
                ? COLORS.content.negative
                : COLORS.content.primary,
            }}
          >
            {props.selectedChannel.label || "Select an account"}
          </TitleSmall>
          <ChevronDownIcon
            color={
              props.error ? COLORS.content.negative : COLORS.content.primary
            }
          />
        </DropdownWrapper>
      </DropdownPopover>
    </DropdownContainer>
  );
};

export const LinkText = (props: { link: string, text: string }) => {
  return (
    <span
      style={{
        color: "#731DCF",
        textDecoration: "underline",
        cursor: "pointer",
        marginLeft: 4,
      }}
      onClick={() => {
        window.open(props.link);
      }}
    >
      {props.text}
    </span>
  );
};

import styled from "styled-components";
import {TagProps} from "./index";

export const TagManagerStyle = styled.div<TagProps>`
  background-color: ${(props) => props.tagColor ?? "#ffffff"};
  padding: 4px 8px;
  height: fit-content;
  width: fit-content;
  border: 1px solid ${(props) => props.borderColor ?? "#C3E4C4"};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.textColor ?? "#ffffff"};
  border-radius: 4px;
  .icon-style {
    padding: 0 10px 0 12px;
  }
`
